import { Tag, TagLabel, TagLeftIcon, TagCloseButton} from '@chakra-ui/react'
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";
import { House } from "phosphor-react";
import React from "react";

type PillProps = {
  size: "sm" | "md" | "lg";
  status: string;
  label: string;
};

const getColorScheme = (status) => {
  return "pill" + status.charAt(0).toUpperCase() + status.substr(1)
};

const getContentColor = (status) => {
  switch (status) {
    case "theme":
      return fT.colors.primary.base;
      break;
    case "success":
      return fT.colors.pill.text.success;
      break;
    case "warning":
      return fT.colors.pill.text.warning;
      break;
    case "danger":
      return fT.colors.pill.text.danger;
      break;
    case "active":
      return fT.colors.pill.text.active;
      break;
    default:
      return fT.colors.label.primary;
  }
}

const getBorderColor = (status) => {
  switch (status) {
    case "theme":
      return fT.colors.primary.accent;
      break;
    case "success":
      return fT.colors.success;
      break;
    case "warning":
      return fT.colors.yellow;
      break;
    case "danger":
      return fT.colors.alert;
      break;
    case "active":
      return fT.colors.pill.border.active;
      break;
    default:
      return fT.colors.label.secondary;
  }
}

const Pill: React.FC<PillProps> = ({size, status, label}) => {
  const colorScheme = getColorScheme(status);
  const color = getContentColor(status);
  const borderColor = getBorderColor(status);

  return (
    <Tag size={size || "md"} variant="solid" colorScheme={colorScheme} color={color} border="1px" borderRadius="full" borderColor={borderColor} px="15px">
      <TagLeftIcon as={House} weight="fill" />
      <TagLabel>{label}</TagLabel>
      <TagCloseButton />
    </Tag>
  );
};

export default Pill;
