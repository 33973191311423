import React from "react";
import { act, screen, fireEvent } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import PresentationForm, { PresentationFormProps } from "./PresentationForm";
import userEvent from "@testing-library/user-event";
import { FAKE_TALK, render } from "../../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: PresentationFormProps = {
  talk: FAKE_TALK,
  eventId: "1",
  tzinfoName: "Australia/Melbourne",
  submitForm,
  handleErrors,
  formErrors,
};

describe("Presentation Drawer Step One Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<PresentationForm {...requiredProps} />);
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  describe("title", () => {
    test("is required", async () => {
      render(<PresentationForm {...requiredProps} />);

      const input = screen.getByLabelText("presentation.title.label", {
        exact: false,
      });

      await act(async () => {
        userEvent.clear(input);
        fireEvent.blur(input);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("description", () => {
    test("is required", async () => {
      render(<PresentationForm {...requiredProps} />);

      const input = screen.getByLabelText("presentation.description.label", {
        exact: false,
      });

      await act(async () => {
        userEvent.clear(input);
        fireEvent.blur(input);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("start time and end time", () => {
    test("is required", async () => {
      render(<PresentationForm {...requiredProps} />);
      const timeInput = screen.getByLabelText("presentation.time.label", {
        exact: false,
      });

      await act(async () => {
        userEvent.click(timeInput);
        userEvent.click(screen.getByText("event_tools.buttons.clear"));
        fireEvent.blur(timeInput);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });
});
