import React, { useState } from "react";
import styled from "styled-components";
import { X } from "phosphor-react";
import { useTranslation } from "react-i18next";

import SearchDataFetcher from "../SearchDataFetcher";
import SearchResults from "./SearchResults";
import { SearchProp, FetcherProps } from "../types";

const Container = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: stretch;
`;

const SearchFieldInput = styled.input`
  border: none;
  border-radius: 8px;
  padding: 8px;
  margin-left: 16px;
  flex-grow: 1;
  background: ${props => props.bgColor};
  color: ${props => props.textColor};
  filter: contrast(80%);
  ::placeholder {
    color: ${props => props.textColor};
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
`;

const Button = styled.button`
  background: none;
  border: none;
  color: white;
  margin-left: 16px;
  cursor: pointer;
`;

type SearchFieldProps = {
  onSearch: SearchProp;
  onFocus: () => void;
  textColor: string;
  bgColor: string;
};

function SearchField({
  onSearch,
  onFocus,
  textColor,
  bgColor,
}: SearchFieldProps): JSX.Element {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const updateSearchValue = event => {
    setSearchValue(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <SearchFieldInput
      type="text"
      placeholder={t("actions.search_placeholder")}
      value={searchValue}
      onChange={updateSearchValue}
      onFocus={onFocus}
      bgColor={bgColor}
      textColor={textColor}
    />
  );
}

type MobileSearchProps = {
  bgColor: string;
  textColor: string;
  dataFetcher: (children: FetcherProps) => JSX.Element;
};

export default function MobileSearch({
  bgColor,
  textColor,
  dataFetcher = SearchDataFetcher,
}: MobileSearchProps) {
  const [showingResults, setShowingResults] = useState(false);

  const showResults = () => setShowingResults(true);
  const hideResults = () => setShowingResults(false);

  return dataFetcher((onSearch, searchResults) => (
    <Container>
      <Controls>
        <SearchField
          onSearch={onSearch}
          onFocus={showResults}
          bgColor={bgColor}
          textColor={textColor}
        />
        {showingResults && (
          <Button onClick={hideResults}>
            <X size={32} color={textColor} onClick={hideResults} />
          </Button>
        )}
      </Controls>
      {showingResults && (
        <SearchResults searchResults={searchResults} textColor={textColor} />
      )}
    </Container>
  ));
}
