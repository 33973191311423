import React from "react";
import { useNavigate } from "react-router-dom";
import EventService from "../../../../../../EventWizard/services/eventService";
import AppAdapter from "../../../../../../../utils/adapter";
import FormPageLayout from "../../../../../common/FormPageLayout";
import WebsiteStylingForm from "../../../../../forms/WebsiteStylingForm";
import { useEvent } from "../../../../Event/index";
import { useTranslation } from "react-i18next";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";

const WebsiteStyle: React.FC = (): JSX.Element => {
  const {event, setEvent} = useEvent();
  const navigate = useNavigate();
  const navigator = path => () => navigate(path);
  const { t } = useTranslation();

  const stepDetails = [
    { name: t("event_tools.navigation.website_style"), status: "active", path: `/admin/event-tools/${event.id}/steps/website-style` },
    { name: t("event_tools.navigation.more_custom_styling"), status: "default", path: `/admin/event-tools/${event.id}/steps/custom-styling` },
  ];

  const updateEvent = async (data) => {
    if(!data.favicon) delete data.favicon;

    const payload = await EventService.updateEventWithFormData(event.id, data);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, { transform: EventService.transform });
      setEvent(response);
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  return (
    <FormPageLayout event={event}
      stepDetails={stepDetails}
      update={updateEvent}
      previousForm={navigator(`/admin/event-tools/${event.id}/steps/event-details`)}
      exitForm={navigateToEventList}
      nextForm={navigator(`/admin/event-tools/${event.id}/steps/custom-styling`)}
      previewVariant="Web"
    >
      {
        (props) => <WebsiteStylingForm event={event} {...props} />
      }
    </FormPageLayout>
  );
};

export default WebsiteStyle;
