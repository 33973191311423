import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import '@testing-library/jest-dom';
import { axe, toHaveNoViolations } from 'jest-axe';
expect.extend(toHaveNoViolations);
import SwiftiumStatuses from './SwiftiumStatuses';
import { act } from 'react-dom/test-utils';
import * as requests from "./requests";
import userEvent from '@testing-library/user-event';

type SwiftiumSyncStatus = {
  userName: string
  email: string
  status: string
  errorCode: string
  errorMessage: string
}

type SwiftiumSyncStatusProps = {
  statuses: SwiftiumSyncStatus[]
  resyncUrl: string
  integrationsPath: string
  swiftiumEnabled: boolean
}

const swiftiumStatuses: SwiftiumSyncStatus[] = [
  {
    userName: "Jim Davies",
    email: "jim.d@gmail.com",
    status: "false",
    errorCode: "-9",
    errorMessage: "Badge ID not provided"
  },
  {
    userName: "Tim Davies",
    email: "tim.d@gmail.com",
    status: "false",
    errorCode: "-8",
    errorMessage: "Some failure"
  }
];

const swiftiumStatusesProps: SwiftiumSyncStatusProps = {
  statuses: swiftiumStatuses,
  resyncUrl: 'www.google.com',
  integrationsPath: 'http://localhost:3000/integration',
  swiftiumEnabled: true
};

describe('Data Kiosk Sync Page', () => {
  test('has no accessibility violations', async () => {
    const { container } = render(<SwiftiumStatuses {...swiftiumStatusesProps} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  test('displays the correct sync status data', async () => {
    render(<SwiftiumStatuses {...swiftiumStatusesProps} />);

    expect(screen.getByText(swiftiumStatuses[0].userName)).toBeInTheDocument();
    expect(screen.getByText(swiftiumStatuses[0].email)).toBeInTheDocument();
    expect(screen.getAllByText(swiftiumStatuses[0].status)[0].textContent).toEqual('false');
    expect(screen.getByText(swiftiumStatuses[0].errorCode)).toBeInTheDocument();
    expect(screen.getByText(swiftiumStatuses[0].errorMessage)).toBeInTheDocument();

    expect(screen.getByText(swiftiumStatuses[1].userName)).toBeInTheDocument();
    expect(screen.getByText(swiftiumStatuses[1].email)).toBeInTheDocument();
    expect(screen.getAllByText(swiftiumStatuses[1].status)[0].textContent).toEqual('false');
    expect(screen.getByText(swiftiumStatuses[1].errorCode)).toBeInTheDocument();
    expect(screen.getByText(swiftiumStatuses[1].errorMessage)).toBeInTheDocument();
  });

  describe('with no unsynced registrations', () => {
    test('displays no unsynced registrations success box', async () => {
      render(<SwiftiumStatuses {...{ ...swiftiumStatusesProps, statuses: [] }} />);

      expect(screen.getByText('registrations.swiftium.statuses.alert.success.description')).toBeInTheDocument();
    });
  });

  describe('with swiftium not enabled', () => {
    test('displays swiftium not enabled alert box', async () => {
      render(<SwiftiumStatuses {...{ ...swiftiumStatusesProps, statuses: [], swiftiumEnabled: false }} />);

      expect(screen.getByText('registrations.swiftium.statuses.alert.warning.description')).toBeInTheDocument();
    });
  });

  describe('resync statuses button', () => {
    test('resync statuses button updates button text to show resync is in progress before resetting text', async () => {
      jest.useFakeTimers();
      render(<SwiftiumStatuses {...swiftiumStatusesProps} />);
      const resyncSpy = jest.spyOn(requests, "getFailedRegistrationSyncs").mockImplementation(() => Promise.resolve([]))
      const resyncButton = screen.getByText('registrations.swiftium.statuses.resync_button.inactive');
      act(() => { userEvent.click(resyncButton); })

      await waitFor(() => {
        expect(resyncSpy).toHaveBeenCalledTimes(1);
        expect(screen.getByText('registrations.swiftium.statuses.resync_button.active')).toBeInTheDocument();
      });
      jest.runAllTimers();
      await waitFor(() => {
        expect(screen.getByText('registrations.swiftium.statuses.resync_button.inactive')).toBeInTheDocument();
      });
    });
  });
});
