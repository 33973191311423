import * as React from 'react';
import { useTable, Column } from 'react-table';
import {
  Table, Thead, Tbody, Tr, Th, Td,
  ChakraProvider, TableContainer, Button,
  Alert, AlertIcon, AlertTitle, AlertDescription,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import dcTheme from "../react/dcTheme";
import { useState } from 'react';
import { getFailedRegistrationSyncs } from './requests';
import { useTranslation } from "react-i18next";

export type SwiftiumSyncStatus = {
  userName: string
  email: string
  status: string
  errorCode: string
  errorMessage: string
}

type SwiftiumSyncStatusProps = {
  statuses: SwiftiumSyncStatus[]
  resyncUrl: string
  integrationsPath: string
  swiftiumEnabled: boolean
}

const SwiftiumNotEnabledAlert = (
  swiftiumEnabled: boolean,
  integrationsPath: string,
) => {
  const { t } = useTranslation();

  if (!swiftiumEnabled) {
    return (
      <>
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle aria-label={t('registrations.swiftium.statuses.aria.labels.alert.header')}>{t('registrations.swiftium.statuses.alert.warning.header')}</AlertTitle>
          <AlertDescription aria-label={t('registrations.swiftium.statuses.aria.labels.alert.description')}>{t('registrations.swiftium.statuses.alert.warning.description')} <a style={{ color: 'blue' }} href={integrationsPath}> {t('registrations.swiftium.statuses.alert.warning.integrations_link_text')}</a></AlertDescription>
        </Alert>
        <br />
      </>
    );
  } else {
    return null;
  }
};

const SwiftiumStatuses = (props: SwiftiumSyncStatusProps) => {
  const { t } = useTranslation();
  const { statuses, resyncUrl, integrationsPath, swiftiumEnabled } = props;
  const [syncStatuses, setSyncStatuses] = useState(statuses);
  const [isSyncing, setIsSyncing] = useState(false);

  const handleClick = async () => {
    setIsSyncing(true);
    try {
      const failedRegistrationSyncs = await getFailedRegistrationSyncs(resyncUrl);
      setSyncStatuses(failedRegistrationSyncs);
    } finally {
      setIsSyncing(false);
    }
  }

  const columns: Column<SwiftiumSyncStatus>[] = React.useMemo(
    () => [
      {
        Header: t('registrations.swiftium.statuses.table_headers.username'),
        accessor: 'userName'
      },
      {
        Header: t('registrations.swiftium.statuses.table_headers.email'),
        accessor: 'email'
      },
      {
        Header: t('registrations.swiftium.statuses.table_headers.status'),
        accessor: 'status'
      },
      {
        Header: t('registrations.swiftium.statuses.table_headers.error_code'),
        accessor: 'errorCode'
      },
      {
        Header: t('registrations.swiftium.statuses.table_headers.error_message'),
        accessor: 'errorMessage'
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<SwiftiumSyncStatus>({
    columns: columns,
    data: syncStatuses,
  });

  return (
    <div className='card'>
      <div className='card-body'>
        <ChakraProvider theme={dcTheme}>
          {SwiftiumNotEnabledAlert(swiftiumEnabled, integrationsPath)}

          <div style={{ textAlign: 'right', marginBottom: '12px' }}>
            {isSyncing ? (
              <Button isLoading loadingText={t('registrations.swiftium.statuses.resync_button.active')} aria-label={t('registrations.swiftium.statuses.aria.labels.sync_in_progress_btn')}>
                {t('registrations.swiftium.statuses.resync_button.active')}
              </Button>
            ) : (
              <Button leftIcon={<RepeatIcon />} onClick={handleClick} aria-label={t('registrations.swiftium.statuses.aria.labels.resync_btn')}>
                {t('registrations.swiftium.statuses.resync_button.inactive')}
              </Button>
            )}
          </div>

          <TableContainer>
            <Table variant={'unstyled'} size={'lg'} {...getTableProps()}>
              <Thead borderBottom={'1px solid #EAEAEA'}>
                {headerGroups.map((headerGroup) => (
                  <Tr key={`swiftium-header-row-${headerGroup.id}`} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th textAlign={'left'} key={`swiftium-header-row-${headerGroup.id}-${column.id}`} {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody fontWeight={'200'} {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <Tr key={`swiftium-row-${row.id}`} {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <Td key={`swiftium-row-${cell.row.id}-${cell.column.id}`} {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          {!rows.length ?
            <Alert status='success'>
              <AlertIcon />
              {t('registrations.swiftium.statuses.alert.success.description')}
            </Alert> : null}
        </ChakraProvider>
      </div>
    </div>
  );
};

export default SwiftiumStatuses;
