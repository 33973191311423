import React from "react";
import { act, screen, fireEvent } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import SessionForm, { SessionFormProps } from './SessionForm';
import { FAKE_SESSION, render } from "../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: SessionFormProps = {
  day: "1/1/2022",
  session: FAKE_SESSION,
  submitForm, handleErrors, formErrors
};

describe("Session Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<SessionForm {...requiredProps} />);

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  describe("Session name", () => {
    test("must be provided", async () => {
      render(<SessionForm {...requiredProps} />);

      const label = screen.getByLabelText(
        "session_form.name.label",
        { exact: false }
      );

      await act(async () => {
        fireEvent.change(label, {
          target: { value: "" },
        });
        fireEvent.blur(label);
      });

      const validationError = screen.getByText(
        "event_tools.validations.required",
        { exact: false }
      );
      expect(validationError).toBeInTheDocument();
    });
  });

  describe("Session description", () => {
    test("must be provided", async () => {
      render(<SessionForm {...requiredProps} />);

      const label = screen.getByLabelText(
        "session_form.description.label",
        { exact: false }
      );

      await act(async () => {
        fireEvent.change(label, {
          target: { value: "" },
        });
        fireEvent.blur(label);
      });

      const validationError = screen.getByText(
        "event_tools.validations.required",
        { exact: false }
      );
      expect(validationError).toBeInTheDocument();
    });
  });
});
