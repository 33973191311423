import React from "react";
import { Text } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";

const TenantOnlyFieldNotice = () => {
  const { t } = useTranslation();

  return (
    <Text mb={3}>{t('event_tools.tenant_only_fields')}</Text>
  );
};

export default TenantOnlyFieldNotice;
