export const segments = [
  "preview_svg_Timezone",
  "preview_svg_Hero Section",
  "preview_svg_Feature Date & Location",
  "preview_svg_Feature Video",
  "preview_svg_Section 1",
  "preview_svg_Presentations",
  "preview_svg_Footer"
];

const offset = 19;

export const segmentHeights = {
  "preview_svg_Timezone": 16 + offset,
  "preview_svg_Hero Section": 122,
  "preview_svg_Feature Date & Location": 36,
  "preview_svg_Feature Video": 66,
  "preview_svg_Section 1": 108,
  "preview_svg_Presentations": 102,
  "preview_svg_Footer": 23
};
