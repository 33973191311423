import React from "react";
import { Flex } from "@chakra-ui/react";
import TopNavigationBar from '../TopNavigationBar';
import { StepDetailType } from "../TopNavigationBar/TopNavigationBar";

export type TopNavigationProps = {
  stepDetails: Array<StepDetailType>;
};

export const TopNavigation: React.FC<TopNavigationProps> = ({ stepDetails }) => {
  return (
    <Flex py={8} pl={10}>
      {stepDetails.map((stepDetail, i) => {
        return (
          <TopNavigationBar
            stepDetail={stepDetail}
            firstStep={i == 0}
            lastStep={i == stepDetails.length - 1}
            key={"step-" + i}
          />
        );
      })}
    </Flex>
  );
};
