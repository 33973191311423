import { useContext } from 'react';
import { AuthContext, AuthContextState } from './AuthProvider';

export function useAuth(): AuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context as AuthContextState;
}
