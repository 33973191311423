import React from "react";
import {
  Button,
  Flex,
  FlexProps,
  Image,
  Text,
  TextProps,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Plus } from "phosphor-react";

import createFirstEventGraphic from "../../../EventWizard/assets/images/amusement-park-graphic.svg";

type CreateFirstEventCardProps = {
  userFirstName: string;
  onCreateEvent: () => void;
  isCreatingEvent: boolean;
};

const CreateFirstEventCard: React.FC<CreateFirstEventCardProps> = ({
  userFirstName,
  onCreateEvent,
  isCreatingEvent,
}) => {
  const { t } = useTranslation();
  const { subHeadingText, createFirstEventCard } = useMultiStyleConfig(
    "EventsList",
    {}
  );

  return (
    <Flex {...(createFirstEventCard as FlexProps)}>
      <Image
        src={createFirstEventGraphic}
        alt={t("images.image.alt")}
        maxW="245px"
        m={10}
        mr={{ base: 10, md: 20 }}
      />
      <Flex
        flexDir="column"
        flex="auto"
        align={{ base: "center", md: "initial" }}
      >
        <Text {...(subHeadingText as TextProps)} mb="30px">
          {t("event_tools.welcome_message.first_event", {
            first_name: userFirstName,
            event_type: t("events.types.webinar").toLocaleLowerCase(),
          })}
        </Text>
        <Button
          isLoading={isCreatingEvent}
          loadingText={t("actions.creating")}
          onClick={onCreateEvent}
          leftIcon={<Plus weight="fill" size="20" />}
          maxW="167px"
          variant="secondary"
        >
          {t("actions.create_webinar")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default CreateFirstEventCard;
