import React from "react";
import { act } from "@testing-library/react";
import { axe, toHaveNoViolations } from "jest-axe";

import { render, screen } from "../../../EventWizard/test-utils";
import SwitchInput, { SwitchInputProps } from "./SwitchInput";
expect.extend(toHaveNoViolations);

const switchInputProps: SwitchInputProps = {
  label: "Test label",
};
describe("Switch Input component", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<SwitchInput {...switchInputProps} />);

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  test("renders error message", () => {
    render(<SwitchInput {...switchInputProps} errorMessage={"error"} />);

    expect(screen.getByText("error")).toBeInTheDocument();
  });

  test("renders helper text", () => {
    render(
      <SwitchInput {...switchInputProps} helperText={"Test helper text"} />
    );

    expect(screen.getByText("Test helper text")).toBeInTheDocument();
  });
});
