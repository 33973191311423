import CONFIG from '../../../utils/config';
import RestClient from '../../../utils/restClient';

class TimezoneService {
  static async list() {
    try {
      const response = await RestClient.get(CONFIG.URLS.TIMEZONES);
      if (!response.ok) {
        response.message = 'error in response';
      }
      return response;
    } catch (e) {
      console.log(e);
      return { ok: false, message: 'error in fetching', result: null };
    }
  }
}

export default TimezoneService;
