import * as yup from "yup";
import { timeDiffValidator } from "../../../../EventWizard/helpers/customValidators";

const schemaGenerator = (t) => {
  return yup.object({
    title: yup.string()
      .required(t("event_tools.validations.required", { field: t("presentation.title.label") })),
    description: yup.string()
      .required(t("event_tools.validations.required", { field: t("presentation.description.label") })),
    startTime: yup.string()
      .required(t("event_tools.validations.required", { field: t("event_details.time.start_field_name") })),
    endTime: yup.string()
      .required(t("event_tools.validations.required", { field: t("event_details.time.end_field_name") }))
      .test("afterStart", t('event_details.time.invalid_length'), timeDiffValidator)
  })
}

export default schemaGenerator;
