import FacilitatorService from "./facilitatorService";
import {
  mockRestClientFailure,
  mockRestClientRejected,
  mockRestClientResolved,
} from "../test-utils";

describe("FacilitatorService", () => {
  describe("list", () => {
    test("valid request 200 - 299", async () => {
      mockRestClientResolved("get", { data: {} });

      const response = await FacilitatorService.list(1);
      expect(response.ok).toBe(true);
      expect(response.result).toEqual({ data: {} });
    });

    test("error in response 400 - 599", async () => {
      mockRestClientRejected("get", "FAKE_ERROR");

      const response = await FacilitatorService.list(null);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in response");
    });

    test("error in fetching", async () => {
      mockRestClientFailure("get", "FAKE_FAILURE");

      const response = await FacilitatorService.list(1);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in fetching");
    });
  });
});
