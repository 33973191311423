import { Outlet, useNavigate, useParams } from "react-router-dom";

import ProgressService from "../services/progressService";
import { useWebinarFormActions } from "../../EventTools/Webinar/hooks/useWebinarFormActions";
import React, { ReactElement, useEffect } from "react";

const WizardEntry = (): ReactElement => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const progress = ProgressService.getProgress(eventId);
  const { pathsByRoute } = useWebinarFormActions();
  useEffect(() => {
    if (progress && progress !== "completed") {
      navigate(pathsByRoute[progress]);
    } else {
      navigate(pathsByRoute.webinarDetails);
    }
  });

  return <Outlet />;
};

export default WizardEntry;
