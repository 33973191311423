import { WebinarConfigType, WebinarPage } from "../EventTools/config";

export const getPageName = (pathname: string): WebinarPage => {
  const eventId = pathname.split("/")[3];
  const baseEventUrl = `/admin/event-tools/${eventId}`;
  const pathsByRoute = {
    webinarDetails: `${baseEventUrl}/webinar/details`,
    // attendeeConfigurations: `${baseEventUrl}/webinar/attendee-configurations`,
    agenda: `${baseEventUrl}/agenda`,
    facilitators: `${baseEventUrl}/agenda/facilitators`,
    websiteStyle: `${baseEventUrl}/webinar-website/website-style`,
    customStyling: `${baseEventUrl}/webinar-website/custom-styling`,
    heroSection: `${baseEventUrl}/webinar-website/hero-section`,
    additionalFeatures: `${baseEventUrl}/webinar-website/additional-features`,
    addSections: `${baseEventUrl}/webinar-website/add-sections`,
    footer: `${baseEventUrl}/webinar-website/footer`,
    supportEmail: `${baseEventUrl}/webinar-website/support-email`,
    metadata: `${baseEventUrl}/webinar-website/metadata`,
    publish: `${baseEventUrl}/webinar/publish`,
  };
  const routesByPath = {};
  Object.entries(pathsByRoute).forEach(([page, route]) => {
    routesByPath[route] = page;
  });
  return routesByPath[pathname] as WebinarPage;
};

export const getInitialStatusState = (config: WebinarConfigType) => {
  const pageStatuses = JSON.parse(JSON.stringify(config));
  for (const pageName of Object.keys(config.groups)) {
    pageStatuses.groups[pageName] = false;
  }
  for (const pageName of Object.keys(config.subGroups)) {
    pageStatuses.subGroups[pageName] = false;
  }
  for (const pageName of Object.keys(config.pages)) {
    pageStatuses.pages[pageName] = false;
  }

  return pageStatuses;
};
