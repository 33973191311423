import React from "react";
import { render } from "@testing-library/react";
import "@testing-library/jest-dom";
import ColorRadioButtons from "./ColourRadioButtons";
import { axe, toHaveNoViolations } from "jest-axe";
expect.extend(toHaveNoViolations);

const props = {
  colors: [
    { name: "White", hexCode: "#FAFAFA" },
    { name: "Muted Grey", hexCode: "#E1DDDD" },
  ],
  fieldName: "Color Field Name",
  defaultValue: { name: "White", hexCode: "#FAFAFA" },
  onChange: console.log
};

describe("ColorRadioButtons", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<ColorRadioButtons {...props} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });
});
