import { figmaTheme as fT } from "../figmaTheme";

export const buttonTheme = {
  baseStyle: {
    lineHeight: fT.lineHeights[20],
    _disabled: {
      opacity: fT.opacities.opaque,
    },
    _focus: {
      boxShadow: "0",
    },
  },
  variants: {
    solid: {
      fontSize: fT.components.fontSize,
      bg: fT.colors.primary.base,
      _disabled: {
        bg: fT.colors.label.secondary,
      },
      _hover: {
        bg: fT.colors.primary.light,
        _disabled: {
          bg: fT.colors.label.secondary,
        },
      },
    },
    skyBlue: {
      fontSize: fT.components.fontSize,
      color: fT.colors.white,
      bg: fT.colors.skyBlue.base,
      _focus: {
        outline: 'transparent'
      }
    },
    purple: {
      fontSize: fT.components.fontSize,
      color: fT.colors.white,
      bg: fT.colors.purple,
    },
    orange: {
      fontSize: fT.components.fontSize,
      color: fT.colors.white,
      bg: fT.colors.orange,
    },
    outline: {
      fontSize: fT.components.fontSize,
      opacity: fT.opacities.inactive,
      borderColor: fT.colors.grey[300],
      _hover: {
        bg: fT.colors.grey[200],
      },
      _active: {
        bg: fT.colors.grey[300],
      },
      _disabled: {
        borderColor: fT.colors.label.secondary,
        color: fT.colors.label.secondary,
      },
    },
    ghost: {
      fontSize: fT.components.fontSize,
      opacity: fT.opacities.inactive,
      _hover: {
        opacity: fT.opacities.opaque,
        textDecoration: "underline"
      },
      _active: {
        opacity: fT.opacities.opaque,
        textDecoration: "underline"
      },
      _disabled: {
        color: fT.colors.label.secondary,
      },
    },
    "ghost-pure": {
      fontSize: fT.components.fontSize,
      color: fT.colors.primary.base,
      _disabled: {
        color: fT.colors.label.secondary,
      },
    },
    colorPreview: {
      cursor: "pointer",
      borderWidth: fT.border.width.sm,
      borderRadius: fT.border.radius.md,
      borderColor: fT.colors.secondary,
      marginBottom: "2",
      _checked: {
        borderColor: fT.colors.primary.light,
      },
    },
    grayBg: {
      color: "black",
      bg: "#C4C4C4",
      fontSize: fT.components.fontSize,
      _hover: {
        bg: fT.colors.primary.accent,
      },
    },
    secondary: {
      color: 'white',
      bg: fT.colors.secondary.base,
      fontWeight: "600",
      fontSize: fT.components.fontSize,
      _hover: {
        bg: fT.colors.secondary[600],
        _disabled: {
          bg: fT.colors.label.secondary,
        },
      },
      _active: {
        bg: fT.colors.secondary[700],
      },
      _disabled: {
        bg: fT.colors.label.secondary,
      },
    },
    paginationGrey: {
      bg: 'transparent',
      color: '#424242c9',
      _hover: {
        bg: '#ffffffa8',
        cursor: 'pointer'
      },
      _focus: {
        outline: 'none'
      },
      _disabled: {
        _hover: {
          cursor: 'default'
        }
      }
    },
    paginationRange: {
      bg: 'transparent',
      color: '#424242c9',
      _hover: {
        bg: 'transparent',
        cursor: 'auto'
      },
      _focus: {
        outline: 'transparent'
      }
    },
    paginationActive: {
      fontSize: fT.components.fontSize,
      color: fT.colors.white,
      bg: fT.colors.skyBlue.base,
      _focus: {
        outline: 'transparent'
      },
      _hover: {
        cursor: 'auto'
      },
    },
  },
};
