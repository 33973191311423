import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getInitialStatusState, getPageName } from "../../helpers/navigation";
import { WebinarConfigType } from "../config";

const useActivePageStatus = (config: WebinarConfigType) => {
  const [activePageStatus, setActivePageStatus] = useState(
    getInitialStatusState(config)
  );

  const setActivePage = (pathname: string) => {
    const newState = {
      groups: { ...activePageStatus.groups },
      subGroups: { ...activePageStatus.subGroups },
      pages: { ...activePageStatus.pages },
    };
    const currentPage = getPageName(pathname);
    for (const pageName of Object.keys(newState.pages)) {
      if (currentPage === pageName) {
        newState.pages[pageName] = true;
      } else {
        newState.pages[pageName] = false;
      }
    }
    for (const subGroupName of Object.keys(newState.subGroups)) {
      if (config.subGroups[subGroupName].children.includes(currentPage)) {
        newState.subGroups[subGroupName] = true;
      } else {
        newState.subGroups[subGroupName] = false;
      }
    }
    for (const groupName of Object.keys(newState.groups)) {
      if (config.groups[groupName].children.includes(currentPage)) {
        newState.groups[groupName] = true;
      } else {
        newState.groups[groupName] = false;
      }
    }
    setActivePageStatus(newState);
  };

  const { pathname } = useLocation();
  const { eventId } = useParams();

  useEffect(() => {
    if (pathname !== `/admin/event-tools/${eventId}`) {
      setActivePage(pathname);
    }
  }, [pathname]);

  return { activePageStatus };
};

export default useActivePageStatus;
