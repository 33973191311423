import React, { MutableRefObject, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Flex, Icon, Text, IconButton, Spacer, Divider, Button, useMultiStyleConfig } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TrashSimple, PlusCircle } from "phosphor-react";
import { yupResolver } from "@hookform/resolvers/yup";
import NestedHomePageSection, { customHomePageSectionError } from "./NestedHomePageSection";
import { EventType } from '../../../EventWizard/models/Event';
import { LayoutForm } from "../../common/FormPageLayout";
import { usePreviewData } from '../../Preview';
import schemaGenerator from "./formSchema";
import { useSchema } from '../../../helpers/useSchema';


export type CustomHomepageSectionFormProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
}

const CustomHomepageSectionForm: React.FC<CustomHomepageSectionFormProps> = ({ event, submitForm, handleErrors, formErrors }) => {
  const styles = useMultiStyleConfig("BrandingPage", {});
  const { t } = useTranslation();

  const SECTION_LIMIT = 3;

  const schema = useSchema(schemaGenerator);

  const { 
    customHomepageSections,
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  } = event;

  const defaultValues = { customHomepageSections };

  const { control, register, handleSubmit, setError, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);


  const { fields, append, remove } = useFieldArray({
    control,
    name: "customHomepageSections"
  });

  const handleAddSection = () => {
    if (fields.length < SECTION_LIMIT ){
      append({
        id: "",
        title: "",
        description: "",
        imageUrl: ""
      });
    } else {
      alert(t("events.form.home_page.add_sections.sections_max_length_error"));
    }
  };

  usePreviewData({
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  });

  return(
    <LayoutForm noValidate onSubmit={e => e.preventDefault()}>
      <Text variant="form-description" mt="20px" mb="30px">{t("events.form.home_page.add_sections.hint")}</Text>
      {fields.map((field, index) => {
        return(
          <React.Fragment key={index}>
            <Flex mb="20px">
              <div>{t('form.sections.section_numbered', { numbering: index+1 })}</div>
              <Spacer />
              <IconButton aria-label={t("events.form.home_page.add_sections.remove_section_button.name")} variant="ghost" icon={<Icon as={TrashSimple} weight="fill" boxSize="24px" />} onClick={() => remove(index)} />
            </Flex>
            <NestedHomePageSection nestIndex={index} customHomepageSection={field} errors={errors.customHomepageSections?.[index] as customHomePageSectionError} register={register} setValue={setValue} />
            <Divider __css={styles.eventFormDivider} />
          </React.Fragment>
        );
      })}
      {fields.length < SECTION_LIMIT &&
        <Button
          mb="20px"
          variant="outline"
          leftIcon={<Icon as={PlusCircle} boxSize="24px" />}
          onClick={handleAddSection}
        >
          {t("events.form.home_page.add_sections.section_button.name")}
        </Button>
      }
    </LayoutForm>
  );
};

export default CustomHomepageSectionForm;
