import { timeToString, webinarCalendarStartTime, webinarCalendarEndTime } from "./timeConverter";
import moment from "moment-timezone";

describe("timeConverter helper methods", () => {
  const timeZone = "Australia/Melbourne";

  describe("timeToString", () => {
    it("returns default date/time string", () => {
      const date = new Date("2022-07-19T00:15:00.000+10:00");

      moment.tz.setDefault('Australia/Melbourne');

      expect(timeToString(date, "DD MMMM YYYY")).toBe("19 July 2022");
      expect(timeToString(date, "hh:mm a")).toBe("12:15 am");
    });

    it("returns time zone date string", () => {
      const date = new Date("2022-07-19T08:00:00.000+10:00");

      expect(timeToString(date, "DD MMMM YYYY", timeZone)).toBe("19 July 2022");
    });

    it("returns time string", () => {
      const date = new Date("2022-07-19T09:30:00.000+10:00");

      expect(timeToString(date, "hh:mm a", timeZone)).toBe("09:30 am");
    });

    it("returns time string", () => {
      const date = new Date("2022-07-19T09:30:00.000+10:00");

      expect(timeToString(date, "hh:mm a", "Australia/Adelaide")).toBe("09:00 am");
    });
  });

  describe("webinarCalendarStartTime", () => {
    test("round down to the whole time", () => {
      const date = new Date("2022-07-19T13:15:00.000+10:00");

      expect(webinarCalendarStartTime(date, timeZone)).toBe("13:00:00")
    });
  });

  describe("webinarCalendarEndTime", () => {
    test("add one hour and round down to the whole time", () => {
      const date = new Date("2022-07-19T13:15:00.000+10:00");

      expect(webinarCalendarEndTime(date, timeZone)).toBe("14:00:00")
    });

    test("returns 24:00:00 instead of 00:00:00", () => {
      const date = new Date("2022-07-19T23:15:00.000+10:00");

      expect(webinarCalendarEndTime(date, timeZone)).toBe("24:00:00")
    });
  });
});
