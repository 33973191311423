import React from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import PrivacyPolicyForm from ".";
import { PrivacyPolicyFormProps } from "./PrivacyPolicyForm";
import { FAKE_TENANT, render, screen, act } from "../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const formErrors = { current: null };

const emptyValues = {
  gpdrComplicance: false,
  gdprCookieBannerHeader: "",
  gdprCookieBannerText: "",
  privacyPolicyLink: "",
};

const filledValues = {
  gpdrComplicance: true,
  gdprCookieBannerHeader: "CookieTitle",
  gdprCookieBannerText: "CookieBodytext",
  privacyPolicyLink: "https://privacy.com",
};


const requiredEmptyProps: PrivacyPolicyFormProps = {
  tenant: {...FAKE_TENANT, ...emptyValues},
  submitForm, formErrors
};

const requiredFilledProps: PrivacyPolicyFormProps = {
  tenant: {...FAKE_TENANT, ...filledValues},
  submitForm, formErrors
};

describe("Homepage Setup Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<PrivacyPolicyForm {...requiredEmptyProps} />);
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  describe("with prefilled values",() => {
    describe("when present", () => {
      it("uses the provided value", () => {
        render(<PrivacyPolicyForm {...requiredFilledProps} />);

        expect(screen.getByDisplayValue(filledValues.gdprCookieBannerHeader)).toBeInTheDocument();
        expect(screen.getByDisplayValue(filledValues.gdprCookieBannerText)).toBeInTheDocument();
      });
    });
  });
});
