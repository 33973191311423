import LivefeedDCIcon from "../../../../EventWizard/assets/images/livefeed-dc.svg";
import LivefeedIcon from "../../../../EventWizard/assets/images/livefeed.svg";
import InteractiveIcon from "../../../../EventWizard/assets/images/interactive.svg";
import LivefeedDCActiveIcon from "../../../../EventWizard/assets/images/livefeed-dc-active.svg";
import LivefeedActiveIcon from "../../../../EventWizard/assets/images/livefeed-active.svg";
import InteractiveActiveIcon from "../../../../EventWizard/assets/images/interactive-active.svg";

import { figmaTheme as fT } from "../../../../react/dcTheme/figmaTheme";

const liveFeedDCIconStyles = {
  iconSrc: LivefeedDCIcon,
  activeIconSrc: LivefeedDCActiveIcon,
  bg: fT.colors.webinarTypeBg.livefeedDC
};

const liveFeedIconStyles = {
  iconSrc: LivefeedIcon,
  activeIconSrc: LivefeedActiveIcon,
  bg: fT.colors.webinarTypeBg.livefeed
};

const interactiveIconStyles = {
  iconSrc: InteractiveIcon,
  activeIconSrc: InteractiveActiveIcon,
  bg: fT.colors.webinarTypeBg.interactive
};

export {
  liveFeedDCIconStyles, liveFeedIconStyles, interactiveIconStyles
}
