import React from "react";
import { act, render } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import UsersSelect, { UsersSelectProps } from "./UsersSelect";

const mockData = {
  ok: true,
  result: {
    data: [
      {
        id: "10",
        attributes: {
          fullname: "Harry Potter",
        },
      },
      {
        id: "11",
        attributes: {
          fullname: "Hermione Granger",
        },
      },
    ],
  },
};

jest.mock("../../../EventWizard/services/userService", () => ({
  list: () => Promise.resolve(mockData),
}));

const requiredProps: UsersSelectProps = {
  eventId: "1",
  userIds: [],
  error: { type: "string" },
  handleUserIdsChange: () => true,
  reFetch: { current: null },
  fieldLabel: "Test Field",
  newUserFormHeading: "New User",
  editUserFormHeading: "Edit User",
};

expect.extend(toHaveNoViolations);

describe("Users Select", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<UsersSelect {...requiredProps} />);

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
