import React, { useState } from "react";
import {
  Flex,
  Icon,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import { CaretDown } from "phosphor-react";
import { useTranslation } from "react-i18next";

import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";
import { noop } from "../../../../utils";


export type FontSelectProps = {
  isError: boolean;
  preselectedOption?: string;
  value: string;
  onChange: (event: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
  id: string;
};

const getVariant = (isOpen, isSelected, isError) => {
  if (isError) return "isError";
  if (isOpen) return "isOpen";
  if (isSelected) return "isSelected";
};

const iconColor = (isOpen, isFocused) => {
  return isOpen || isFocused
    ? fT.colors.label.primary
    : fT.colors.label.secondary;
};

const FontSelect: React.FC<FontSelectProps> = ({
  isError,
  value,
  onChange,
  onOpen = noop,
  onClose = noop,
  id
}) => {
  const options = [
    "Roboto",
    "Open Sans",
    "Lato",
    "Montserrat",
    "Source Sans Pro",
    "Poppins",
    "Raleway",
    "Merriweather",
    "Fira Sans",
    "Arimo",
  ];
  const [isFocused, setIsFocused] = useState(false);
  const { isOpen, onOpen: controlOpen, onClose: controlClose } = useDisclosure();
  const { t } = useTranslation();
  const placeholder = t("events.form.select_placeholder");

  function handleOpen() {
    controlOpen();
    onOpen();
  }

  function handleClose() {
    controlClose();
    onClose();
  }


  return (
    <Menu
      autoSelect={false}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      variant={getVariant(isOpen, value, isError)}>
      <MenuButton
        w="100%"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        id={id}>
        <Flex align="center" justify="space-between">
          {value ? value : placeholder}
          <Icon
            as={CaretDown}
            weight="fill"
            color={iconColor(isOpen, isFocused)}
          />
        </Flex>
      </MenuButton>
      <MenuList>
        {options.map(option => {
          return (
            <MenuItem
              key={option}
              onClick={() => onChange(option)}
              fontFamily={option}>
              {option}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default FontSelect;
