import { PencilSimple } from '@phosphor-icons/react';
import React from 'react';

type Props = {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ChannelInfoIcon = ({ isEditing, setIsEditing }: Props) => (
  <PencilSimple
    width="24"
    height="24"
    onClick={() => {
      if (!isEditing) {
        setIsEditing(true);
      }
    }}
  />
);
