import React from "react";
import { act } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import UsersTable from "./UsersTable";
import { render } from "../../../EventWizard/test-utils";
import { noop } from "../../../../utils";

expect.extend(toHaveNoViolations);

describe("Users Table", () => {
  it("passes without axe errors", async () => {
    const { container } = render(
      <UsersTable
        data={[
          {
            id: 1,
            fullname: "Dr. Geoffrey Mosciski",
            email: "geoffrey.m@melbournehospital.com",
            profile_title: "Dean",
            bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, dui non ullamcorper porttitor.",
            avatar: "https://bit.ly/dan-abramov",
          },
        ]}
        handleDelete={noop}
        handleEdit={noop}
      />
    );
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
