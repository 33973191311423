import React, { JSXElementConstructor, ReactElement, ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./auth/AuthProvider";
import { render, RenderOptions, RenderResult } from "@testing-library/react";
import { renderHook } from "@testing-library/react-hooks";
import { RenderHookOptions } from "@testing-library/react-hooks/lib/types";
import { MemoryRouter } from "react-router-dom";
import "@testing-library/jest-dom";
import RestClient, { JSONResponse } from "../../utils/restClient";
import dcTheme from "../react/dcTheme";
import { EventType } from "./models/Event";
import { EVENT_TYPES } from "./constants";
import * as Event from "../EventTools/pages/Event/Event";
import { EventContextType } from "../EventTools/pages/Event/Event";

interface RequiredProvidersProps {
  children?: ReactNode;
}

export const FAKE_USER = {
  id: "1",
  email: "superadmin@delegateconnect.dev",
  first_name: "Delegate",
  last_name: "Connect",
  fullname: "Delegate Connect",
  bio: "Jammie, Dodger-extraordinaire",
  job_title: "Dodger",
  phone: "+61 2222-222",
  pronoun: "Dr.",
  company: "Bob's Burgers",
  location: "Bobtown, USA",
  profile_url: "",
};

export const FAKE_TENANT_PROP = {
  id: "1",
  name: "Delegate Connect",
  domain_name: "delegateconnect.co",
  primary_colour: "#0097DC",
  secondary_colour: "#ED017F",
  active: true,
};

export const FAKE_TENANT = {
  id: "1",
  name: "Delegate Connect",
  domainName: "delegateconnect.co",
  primaryColour: "#0097DC",
  secondaryColour: "#ED017F",
  active: true,
  colourEmailHeader: "#ffffff",
  colourEmailFooter: "#ffffff",
  headerBannerImageUrl: "http://www.example.com",
  helpEmail: "hello@delegateconnect.co",
  linkedin: "https://linkedin.com/joyn-co",
  facebook: "https://facebook.com/joynuscoofficial",
  websiteUrl: "https://delegateconnect.co",
  instagram: "https://instagram.com/delegateconnectevents",
  twitter: "https://twitter.com/joynusco",
};

export const FAKE_EVENT: EventType = {
  id: "1",
  eventType: EVENT_TYPES.virtual,
  name: "Delegate Connect",
  public: true,
  startDate: new Date(),
  endDate: new Date(),
  startTime: new Date(),
  endTime: new Date(),
  timeZone: "Melbourne",
  tzinfoName: "Australia/Melbourne",
  location: "Hybrid",
  featurePageBackgroundColour: "#FAFAFA",
  primaryColour: "#FAFAFA",
  secondaryColour: "#FAFAFA",
  fontHeader: "",
  fontBody: "",
  faviconUrl: undefined,
  menuLogoUrl: undefined,
  headerTagline: "FAKE_HEADER_TAG_LINE",
  subTagline: "FAKE_SUB_TAG_LINE",
  tagline: "FAKE_TAGLINE",
  backgroundVideoUrl: "",
  countdownTimer: "",
  coverImageUrl: undefined,
  footerText: "FAKE_FOOTER",
  featureBackgroundColour: "#FAFAFA",
  featureBackgroundSecondaryColour: "#FAFAFA",
  featureHeaderTextColour: "#FAFAFA",
  featureSectionHeaderTextColour: "#FAFAFA",
  featureBackgroundMenuColour: "#FAFAFA",
  featureTextColour: "#FAFAFA",
  featureBackgroundSideMenuColour: "#FAFAFA",
  featureSideMenuTextColour: "#FAFAFA",
  featureSideMenuIconColour: "#FAFAFA",
  featureProfileSideMenuTextColour: "#FAFAFA",
  featureScrollLoadColour: "#FAFAFA",
  moderatorCcEmail: "moderator@test.com",
  tenant: FAKE_TENANT,
  customHomepageSections: [],
  eventFeatures: {
    countdownTimer: { id: "1", enabled: false },
  },
  homePageSections: {
    location: { id: "1", enabled: false },
    featuredVideo: { id: "2", enabled: false },
  },
};

export const FAKE_SESSION = {
  id: "1",
  name: "Session",
  description: "This is a session",
  time: new Date(),
  duration: 60,
  indexImageUrl: "",
};

export const FAKE_TALK = {
  id: "1",
  title: "Delegate Connect",
  description: "",
  startTime: new Date(),
  endTime: new Date(),
  duration: 0,
  indexImageUrl: "",
  vod: false,
  speakers: ["1"],
};

export const FAKE_ZOOM_CONFIGURATION = {
  id: "1",
  waitingRoom: false,
  screenSharing: false,
  chat: false,
  allowParticipantsToRename: false,
  requestPermissionToUnmute: false,
  whiteboard: false,
  participantVideo: false,
};

export const FAKE_ACTIVE_STATUS_OBJ = {
  groups: {
    webinarDetailsGroup: true,
    webinarWebsite: false,
  },
  subGroups: {
    brandAndStyle: false,
    homePage: false,
    configurations: false,
  },
  pages: {
    webinarDetails: true,
    websiteStyle: false,
    // attendeeConfigurations: false,
    agenda: false,
    customStyling: false,
    heroSection: false,
    additionalFeatures: false,
    addSections: false,
    footer: false,
    supportEmail: false,
    metadata: false,
    publish: false,
  },
};

export const FAKE_COMPLETE_STATUS_OBJ = {
  groups: {
    webinarDetailsGroup: true,
    webinarWebsite: false,
  },
  subGroups: {
    brandAndStyle: true,
    homePage: false,
    configurations: false,
  },
  pages: {
    webinarDetails: true,
    websiteStyle: true,
    // attendeeConfigurations: true,
    agenda: true,
    customStyling: true,
    heroSection: true,
    additionalFeatures: true,
    addSections: false,
    footer: false,
    supportEmail: false,
    metadata: false,
    publish: false,
  },
};

export const FAKE_ENABLED_STATUS_OBJ = {
  groups: {
    webinarDetailsGroup: true,
    webinarWebsite: true,
  },
  subGroups: {
    brandAndStyle: true,
    homePage: true,
    configurations: true,
  },
  pages: {
    webinarDetails: true,
    websiteStyle: true,
    // attendeeConfigurations: true,
    agenda: true,
    customStyling: true,
    heroSection: true,
    additionalFeatures: true,
    addSections: true,
    footer: true,
    supportEmail: true,
    metadata: true,
    publish: true,
  },
};

const RequiredProviders = ({ children }: RequiredProvidersProps) => {
  return (
    <ChakraProvider theme={dcTheme}>
      <MemoryRouter>{children}</MemoryRouter>
    </ChakraProvider>
  );
};

const TestAuthProvider = ({ children }: RequiredProvidersProps) => (
  <AuthProvider user={FAKE_USER} tenant={FAKE_TENANT_PROP}>
    <RequiredProviders>{children}</RequiredProviders>
  </AuthProvider>
);

const customRender = (
  component: ReactElement<unknown, string | JSXElementConstructor<unknown>>,
  options?: RenderOptions | undefined
): RenderResult =>
  render(component, { wrapper: RequiredProviders, ...options });

export const renderWithAuthProvider = (
  component: ReactElement<unknown, string | JSXElementConstructor<unknown>>,
  options?: RenderOptions | undefined
): RenderResult => render(component, { wrapper: TestAuthProvider, ...options });

export const renderHookWithThemeProvider = <TProps, TResult>(
  hook: (props: TProps) => TResult,
  options?: RenderHookOptions<TProps> | undefined
) => renderHook(hook, { wrapper: RequiredProviders, ...options });

export const renderHookWithAuthProvider = <TProps, TResult>(
  hook: (props: TProps) => TResult,
  options?: RenderHookOptions<TProps> | undefined
) => renderHook(hook, { wrapper: TestAuthProvider, ...options });

export const getSuccessJSONResponse = (result: unknown): JSONResponse => {
  return {
    ok: true,
    status: 200,
    statusText: "ok",
    result,
  };
};

export const getFailureJSONResponse = (message: string): JSONResponse => {
  return {
    ok: false,
    status: 500,
    statusText: "error",
    message: message,
  };
};

type Method =
  | "get"
  | "patch"
  | "delete"
  | "post"
  | "request"
  | "postWithFormData"
  | "patchWithFormData";

export const mockRestClientResolved = (
  method: Method,
  value: unknown
): jest.SpyInstance<Promise<JSONResponse>> => {
  return jest
    .spyOn(RestClient, method)
    .mockResolvedValueOnce(getSuccessJSONResponse(value));
};

export const mockRestClientRejected = (
  method: Method,
  value: string
): jest.SpyInstance<Promise<JSONResponse>> => {
  return jest
    .spyOn(RestClient, method)
    .mockResolvedValueOnce(getFailureJSONResponse(value));
};

export const mockRestClientFailure = (
  method: Method,
  value: unknown
): jest.SpyInstance<Promise<JSONResponse>> => {
  return jest.spyOn(RestClient, method).mockRejectedValueOnce(value);
};

export const mockCustomContexts = () => {
  const mockUseEvent = jest.spyOn(Event, "useEvent");
  const mockUsePageStatus = jest.spyOn(Event, "usePageStatus");

  const mockEventContext: EventContextType = {
    event: FAKE_EVENT,
    setEvent: jest.fn(),
  };

  const mockUsePageStatusContext: Event.PageStatusContextType = {
    activePageStatus: FAKE_ACTIVE_STATUS_OBJ,
    completePageStatus: FAKE_COMPLETE_STATUS_OBJ,
    updateProgress: jest.fn(),
    enabledPageStatus: FAKE_ENABLED_STATUS_OBJ,
  };

  mockUseEvent.mockReturnValue(mockEventContext);
  mockUsePageStatus.mockReturnValue(mockUsePageStatusContext);

  return { mockEventContext, mockUsePageStatusContext };
};

// re-export everything
export * from "@testing-library/react";
export { act, renderHook } from "@testing-library/react-hooks";

// override render method
export { customRender as render };
