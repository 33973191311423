import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageLayout from "../../../../common/PageLayout";
import SectionIntro from "../../../../common/SectionIntro";
import { useEvent } from "../../../Event/index";
import sessionIntroImage from "../../../../../EventWizard/assets/images/session-intro.svg";


const SessionIntro: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const {event} = useEvent();
  const navigate = useNavigate();

  return (
    <PageLayout
      event={event}
    >
      <SectionIntro
        imageSrc={sessionIntroImage}
        textContent={
          <>
            <p>{t("session_intro.line_one")}</p>
            <br />
            <p dangerouslySetInnerHTML={{__html: t(`session_intro.line_two`, { openstrong: '<strong>', closestrong: '</strong>' })}}/>
          </>
        }
        onClickNext={() => navigate(`/admin/event-tools/${event.id}/steps/metadata`)}
      />
    </PageLayout>
  );
};

export default SessionIntro;
