import { JsonApiEventType } from '../models/Event';
import AppAdapter from '../../../utils/adapter';
import CONFIG from '../../../utils/config';
import RestClient from '../../../utils/restClient';
import snakeCase from 'lodash/snakeCase';

/**
 * service for events
 */

export type ListResponseType = {
  ok: boolean;
  result: Array<JsonApiEventType> | null;
  message?: string;
}
class EventService {
  static async list(): Promise<ListResponseType> {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.EVENTS}`);
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return {
        ok: responseOk,
        result: response.result as Array<JsonApiEventType>,
        message: response.message
      };
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async getEvent(eventId: string) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.EVENTS}/${eventId}`);
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async createEvent() {
    try {
      const response = await RestClient.post(`${CONFIG.URLS.EVENTS}`, { event: { event_type: "webinar" } });
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async updateEventWithFormData(eventId: string, payload) {
    try {
      this.transformPayload(payload);
      const response = await RestClient.patchWithFormData(`${CONFIG.URLS.EVENTS}/${eventId}`, { event: AppAdapter.snakeCaseKeys(payload) });
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async updateEvent(eventId: string, payload) {
    try {
      this.transformPayload(payload);
      const response = await RestClient.patch(`${CONFIG.URLS.EVENTS}/${eventId}`, { event: AppAdapter.snakeCaseKeys(payload) });
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async publishEventCheck(eventId: string) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.EVENTS}/${eventId}/publish_check`);
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async publishEvent(eventId: string, payload) {
    try {
      const response = await RestClient.post(`${CONFIG.URLS.EVENTS}/${eventId}/publish`, AppAdapter.snakeCaseKeys(payload));
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static transformPayload(payload) {
    this.transformTime(payload);
    this.transformEventFeatures(payload);
    this.transformHomePageSections(payload);
    this.transformCustomHomepageSections(payload);
    this.transformTenant(payload);
  }

  static transformTime(payload) {
    if(payload.startTime) payload.startTime = `${payload.startDate.toDateString()} ${payload.startTime}`;
    if(payload.endTime) payload.endTime = `${payload.endDate.toDateString()} ${payload.endTime}`;
  }

  static transformEventFeatures(payload) {
    if(!payload.eventFeatures) return;

    payload.eventFeaturesAttributes = [];
    for (const key in payload.eventFeatures) {
      const featureAttribute = {...payload.eventFeatures[key], key: snakeCase(key)};
      payload.eventFeaturesAttributes.push(featureAttribute);
    }
    if(!payload.eventFeaturesAttributes.length) delete payload.eventFeaturesAttributes;
    delete payload.eventFeatures;
  }

  static transformHomePageSections(payload) {
    if(!payload.homePageSections) return;

    payload.homePageSectionsAttributes = [];
    for (const key in payload.homePageSections) {
      const status = (payload.homePageSections[key].enabled ? 'enable' : 'disable');
      const id = payload.homePageSections[key].id;
      const sectionName = snakeCase(key);
      const homePageSectionAttribute = {id, sectionName, status};
      payload.homePageSectionsAttributes.push(homePageSectionAttribute);
    }
    if(!payload.homePageSectionsAttributes.length) delete payload.homePageSectionsAttributes;
    delete payload.homePageSections;
  }

  static transformCustomHomepageSections(payload) {
    if(!payload.customHomepageSections) return;

    payload.customHomepageSectionsAttributes = payload.customHomepageSections;
    delete payload.customHomepageSections;
  }

  static transformTenant(payload) {
    if(!payload.tenant) return;

    payload.tenantAttributes = payload.tenant;
    delete payload.tenant;
  }

  static transform(record) {
    record['startDate'] = new Date(record['startDate']);
    record['endDate'] = new Date(record['endDate']);
    record['startTime'] = new Date(record['startTime']);
    record['endTime'] = new Date(record['endTime']);
    record['faviconUrl'] = record?.favicon?.url;
    record['menuLogoUrl'] = record?.menuLogo?.url;
    record['coverImageUrl'] = record?.coverImage?.url;
    record?.customHomepageSections?.forEach?.((customHomepageSection, index) => {
      record.customHomepageSections[index]['imageUrl'] = customHomepageSection?.image?.url;
      delete record.customHomepageSections[index]['image'];
    });
    delete record['favicon'];
    delete record['coverImage'];
    return record;
  }
}

export default EventService;
