import { requestHeaders } from "../react/config/requestHeader";
import { SwiftiumSyncStatus } from "./SwiftiumStatuses";

export const getFailedRegistrationSyncs = async (resyncUrl: string): Promise<SwiftiumSyncStatus[]> => {
  const response = await fetch(resyncUrl, {
    method: "GET",
    headers: requestHeaders(),
  });

  const responseBody = await response.json();
  return responseBody.failed_registrations;
};
