import React from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";

import PageLayout, { PageLayoutProps } from "./PageLayout";
import { FAKE_EVENT, mockCustomContexts, render, screen } from "../../../EventWizard/test-utils";
import { EVENT_TYPES } from "../../../EventWizard/constants";

expect.extend(toHaveNoViolations);

mockCustomContexts()

const props: PageLayoutProps = {
  event: FAKE_EVENT,
  children: null,
};

describe("PageLayout", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<PageLayout {...props} />);
    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe("virtual event", () => {
    test("has event flow sidebar", () => {
      render(
        <PageLayout
          {...props}
          event={{ ...FAKE_EVENT, eventType: EVENT_TYPES.virtual }}
        />
      );

      expect(
        screen.getByText("event_tools.navigation.event_details")
      ).toBeInTheDocument();
    });

    test("has event flow title", () => {
      const name = "Virtual Event";
      render(
        <PageLayout
          {...props}
          event={{ ...FAKE_EVENT, name, eventType: EVENT_TYPES.virtual }}
        />
      );

      expect(screen.getByText(/actions\.create_event/)).toBeInTheDocument();
      expect(screen.getByText(new RegExp(name))).toBeInTheDocument();
    });
  });

  describe("webinar event", () => {
    test("has webinar flow sidebar", () => {
      render(
        <PageLayout
          {...props}
          event={{ ...FAKE_EVENT, eventType: EVENT_TYPES.webinar }}
        />
      );

      expect(
        screen.getByText("event_tools.navigation.webinar.webinar_details")
      ).toBeInTheDocument();
    });

    test("has webinar flow title", () => {
      const name = "Webinar Event";
      render(
        <PageLayout
          {...props}
          event={{ ...FAKE_EVENT, name, eventType: EVENT_TYPES.webinar }}
        />
      );

      expect(screen.getByText(/actions\.create_webinar/)).toBeInTheDocument();
      expect(screen.getByText(new RegExp(name))).toBeInTheDocument();
    });
  });
});
