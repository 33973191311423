import React from "react";
import * as mockReactRouterDom from "react-router-dom";
import { FAKE_EVENT, mockCustomContexts } from "../test-utils";
import { render } from "@testing-library/react";
import ProgressService from "../services/progressService";
import WizardEntry from "./WizardEntry";

mockCustomContexts();

const eventId = FAKE_EVENT.id;

const mockNavigate = jest.fn();

jest.mock("react-router-dom", () => ({
  ...(jest.requireActual("react-router-dom") as typeof mockReactRouterDom),
  useNavigate: () => mockNavigate,
  useLocation: jest.fn(),
  useParams: () => {
    return { eventId: 1 };
  },
}));

const assignMock = jest.fn();

describe("ProgressProvider", () => {
  const mockStorage = {};
  let setItemSpy, getItemSpy;

  beforeAll(() => {
    setItemSpy = jest
      .spyOn(global.Storage.prototype, "setItem")
      .mockImplementation((key, value) => {
        mockStorage[key] = value;
      });
    getItemSpy = jest
      .spyOn(global.Storage.prototype, "getItem")
      .mockImplementation((key) => mockStorage[key]);
  });

  afterEach(() => {
    assignMock.mockClear();
    mockNavigate.mockClear();
  });

  afterAll(() => {
    getItemSpy.mockRestore();
    setItemSpy.mockRestore();
  });

  test("renders", () => {
    expect(() =>
      render(
        <mockReactRouterDom.BrowserRouter>
          <WizardEntry />
        </mockReactRouterDom.BrowserRouter>
      )
    ).not.toThrow();
  });

  test("redirects to current progress page when entering flow and not completed", async () => {
    ProgressService.setProgress("additionalFeatures", eventId);
    render(
      <mockReactRouterDom.BrowserRouter>
        <WizardEntry />
      </mockReactRouterDom.BrowserRouter>
    );

    expect(mockNavigate).toHaveBeenCalledWith(
      `/admin/event-tools/${eventId}/webinar-website/additional-features`
    );
  });

  test("redirects to webinar details page when entering flow and completed", async () => {
    ProgressService.setProgress("completed", eventId);
    render(
      <mockReactRouterDom.BrowserRouter>
        <WizardEntry />
      </mockReactRouterDom.BrowserRouter>
    );

    expect(mockNavigate).toHaveBeenCalledWith(
      `/admin/event-tools/${eventId}/webinar/details`
    );
  });
});
