import React, { ReactNode, useRef } from "react";
import { Flex, Box, Text, useDisclosure, Spacer, Hide } from "@chakra-ui/react";
import PageLayout from "../../common/PageLayout";
import { figmaTheme as fT } from "../../../react/dcTheme";
import { BottomFormButtons, TopNavigation } from ".";
import { Preview, EmailPreview, PreviewProvider } from "../../Preview";
import { EventType } from "../../../EventWizard/models/Event";
import DCModal from "../DCModal";
import { useTranslation } from "react-i18next";
import { StepDetailType } from "../TopNavigationBar/TopNavigationBar";
import { usePageStatus } from "../../pages/Event/Event";

type FormPageLayoutProps = {
  event: EventType;
  update: (data: unknown) => Promise<boolean>;
  stepDetails: Array<StepDetailType>;
  previousForm?: () => void;
  exitForm: () => void;
  nextForm: () => void;
  children: (Function) => ReactNode;
  previewVariant?: "Email" | "Web" | "None";
  hideEventName?: boolean;
};

const FormPageLayout: React.FC<FormPageLayoutProps> = ({
  event,
  stepDetails,
  update,
  previousForm,
  exitForm,
  nextForm,
  children,
  previewVariant = "None",
  hideEventName
}) => {
  const { t } = useTranslation();

  const submitForm = useRef(null);
  const handleErrors = useRef(null);
  const formErrors = useRef(null);
  const {
    onOpen: onExitModalOpen,
    isOpen: isExitModalOpen,
    onClose: onExitModalClose,
  } = useDisclosure();
  const { updateProgress } = usePageStatus()

  const saveAndContinue = async () => {
    try {
      await submitForm.current(update)();
      if (Object.keys(formErrors.current).length === 0) {
        updateProgress();
        nextForm()
      }
    } catch (errors) {
      for (const name in errors) {
        handleErrors.current(name, errors[name]);
      }
    }
  };

  const saveAndExit = async () => {
    try {
      await submitForm.current(update)();
      if (Object.keys(formErrors.current).length === 0) {
        exitForm();
      } else {
        onExitModalOpen();
      }
    } catch (errors) {
      for (const name in errors) {
        handleErrors.current(name, errors[name]);
      }
    }
  };

  return (
    <PageLayout event={event} hideEventName={hideEventName}>
      <Flex flexDir="column" grow={1}>
        <TopNavigation stepDetails={stepDetails} />
        <PreviewProvider>
          <Flex flexDir="column" justify="space-between" grow={1}>
            <Flex grow={1}>
              <Flex px={10} overflow="auto" grow={1} flexDir="column">
                {children({ submitForm, handleErrors, formErrors })}
              </Flex>
              {previewVariant !== "None" ? (
                <>
                  <Box
                    width="1px"
                    bgGradient={`linear(transparent, ${fT.colors.grey.base}, ${fT.colors.grey.base})`}
                  />
                  <Flex
                    flexDir="column"
                    overflow="auto"
                    px={3.5}
                    minW="350px"
                    display={["none", "none", "flex"]}
                  >
                    <Text fontWeight="bold" size="xs" mb={4}>
                      Preview
                    </Text>
                    {previewVariant === "Email" ? (
                      <EmailPreview />
                    ) : (
                      <Preview />
                    )}
                  </Flex>
                </>
              ) : (
                <Hide below="lg">
                  <Spacer minW="350px" />
                </Hide>
              )}
            </Flex>
            <BottomFormButtons
              previousLink={previousForm}
              saveAndContinue={saveAndContinue}
              saveAndExit={saveAndExit}
            />
          </Flex>
        </PreviewProvider>
      </Flex>
      <DCModal
        title={t("save_and_exit_modal.title")}
        content={t("save_and_exit_modal.body")}
        primaryButtonLabel={t("save_and_exit_modal.primary_button")}
        secondaryButtonLabel={t("save_and_exit_modal.secondary_button")}
        isOpen={isExitModalOpen}
        onClose={onExitModalClose}
        onClickPrimary={exitForm}
        onClickSecondary={onExitModalClose}
      />
    </PageLayout>
  );
};

export default FormPageLayout;
