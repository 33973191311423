import { Icon, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { CalendarBlank } from 'phosphor-react';
import React, { forwardRef } from 'react';
import { figmaTheme as fT } from '../../../../react/dcTheme/figmaTheme';

type DCDateInputProps = {
  placeholder: string;
  value: string;
  isInvalid: boolean;
  onClick: (e) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DCDateInput = forwardRef(({ placeholder, value, isInvalid, onClick }: DCDateInputProps, ref) => {
  return (
    <InputGroup onClick={onClick} width="336px">
      <Input readOnly
        placeholder={placeholder}
        value={value}
        isInvalid={isInvalid}
        _active={{ borderColor: fT.colors.primary.base }}
        _focus={{ borderColor: fT.colors.primary.base }}
      />
      <InputRightElement zIndex={0}>
        <Icon as={CalendarBlank} size={32} weight="fill" />
      </InputRightElement>
    </InputGroup>
  );
});

DCDateInput.displayName = 'DCDateInput';

export default DCDateInput;
