import React, { ReactNode } from "react";
import { act, fireEvent, render, screen } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import InvoiceForm, { InvoiceFormProps } from "./InvoiceForm";
import { INVOICE } from "../../GroupRegistration/constants";

expect.extend(toHaveNoViolations);

const submitButton: ReactNode = (
  <div>
    <button type="submit">Confirm payment</button>
  </div>
);

const props: InvoiceFormProps = {
  pageActions: submitButton,
  paymentIntentsPath: "some-random-path",
  groupRegistrationId: "1",
  verifyPaymentUrl: "verified-invoice-path",
  countryCode: "AU",
  updateCountry: () => { console.log("country updated"); },
  setIsCheckingOut: () => { true; },
};

describe("InvoiceForm", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<InvoiceForm {...props} />);
    const results = await axe(container);

    expect(results).toHaveNoViolations();
  });

  describe("default values", () => {
    test("country code", async () => {
        render(<InvoiceForm {...props} />);
        const countryLabel = screen.getByLabelText("addresses.billing.country.label") as HTMLSelectElement;

        expect(countryLabel.value).toBe("AU");
    });
  });

  describe("validations", () => {
    test("Address line 1 shows a validation error", async () => {
      render(<InvoiceForm {...props} />);
      const address1Input = screen.getByLabelText(
        "addresses.billing.address1.label"
      );

      await act(async () => {
        fireEvent.change(address1Input, { target: { value: "" } });
        fireEvent.blur(address1Input);
      });

      const validationError = screen.getByText("group_registrations.errors.form.required.address_line_1");
      expect(validationError).toBeInTheDocument();
    });

    test("Address line 1 is valid", async () => {
      render(<InvoiceForm {...props} />);
      const validInput = "Valid Input Yo";
      const address1Input = screen.getByLabelText("addresses.billing.address1.label") as HTMLInputElement;

      await act(async () => {
        fireEvent.change(address1Input, { target: { value: validInput } });
        fireEvent.blur(address1Input);
      });

      expect(address1Input.value).toBe(validInput);
    });

    test("Country can be selected", async () => {
      const mockedFunction = jest.fn();
      render(<InvoiceForm {...props} updateCountry={mockedFunction} />);

      const countrySelect = screen.getByLabelText(
        "addresses.billing.country.label"
      );

      await act(async () => {
        fireEvent.change(countrySelect, { target: { value: "NZ" } });
        fireEvent.blur(countrySelect);
      });

      expect(mockedFunction).toHaveBeenCalled();
      const updatedCountry = screen.getByText("New Zealand");
      expect(updatedCountry).toBeInTheDocument();
    });

    describe("submits a valid form", () => {
      test("Submit", async () => {
        render(<InvoiceForm {...props} />);

        global.fetch = jest.fn(() =>
          Promise.resolve({ json: () => Promise.resolve({ success: true }) })
        ) as jest.Mock;

        const address1Input = screen.getByLabelText("addresses.billing.address1.label");
        const countrySelect = screen.getByLabelText("addresses.billing.country.label");
        const state = screen.getByLabelText("addresses.billing.state.label");

        await act(async () => {
          fireEvent.change(address1Input, {target: { value: "Some silly address" }});
          fireEvent.change(state, {target: { value: "QLD" }});
          fireEvent.change(countrySelect, { target: { value: "AU" } });
          fireEvent.blur(countrySelect);
        });

        const button = screen.getByRole("button", { name: "Confirm payment" });

        await act(async () => {
          fireEvent.submit(button);
        });

        expect(global.fetch).toHaveBeenCalledWith("some-random-path",{
          body: JSON.stringify({
            billing_address: {
              address: 'Some silly address',
              address_2: '',
              country: "Australia",
              post_code: "",
              suburb: "",
              state: "QLD"
            },
            country_code: "AU",
            country: 'Australia',
            group_registration_id: '1',
            payment_method_name: INVOICE,
          }),
          method: "POST",
          headers: { "Content-Type": "application/json" }
        });

        jest.clearAllMocks();
      });
    });
  });
});
