import * as mockReactRouterDom from "react-router-dom";
import { renderHook } from "@testing-library/react-hooks";
import { FAKE_EVENT } from "../../EventWizard/test-utils";
import useActivePageStatus from "./useActivePageStatus";
import { WEBINAR_CONFIG } from "../config";

const mockNavigate = jest.fn();

const eventId = FAKE_EVENT.id;

jest.mock("react-router-dom", () => ({
  ...(jest.requireActual("react-router-dom") as typeof mockReactRouterDom),
  useOutletContext: jest.fn(),
  useLocation: jest.fn(),
  useNavigate: () => mockNavigate,
}));

describe("useActivePageStatus", () => {
  test("returns initialised active status object for /admin/event-tools/:event_id", () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}`,
    });
    const { result } = renderHook(() => useActivePageStatus(WEBINAR_CONFIG));
    expect(result.current.activePageStatus).toEqual({
      groups: {
        webinarDetailsGroup: false,
        webinarWebsite: false,
      },
      subGroups: {
        brandAndStyle: false,
        homePage: false,
        configurations: false,
      },
      pages: {
        webinarDetails: false,
        websiteStyle: false,
        // attendeeConfigurations: false,
        agenda: false,
        customStyling: false,
        heroSection: false,
        additionalFeatures: false,
        addSections: false,
        footer: false,
        supportEmail: false,
        metadata: false,
        publish: false,
      },
    });
  });

  test("returns correct active status object for /webinar/details", () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar/details`,
    });
    const { result } = renderHook(() => useActivePageStatus(WEBINAR_CONFIG));
    expect(result.current.activePageStatus).toEqual({
      groups: {
        webinarDetailsGroup: true,
        webinarWebsite: false,
      },
      subGroups: {
        brandAndStyle: false,
        homePage: false,
        configurations: false,
      },
      pages: {
        webinarDetails: true,
        websiteStyle: false,
        // attendeeConfigurations: false,
        agenda: false,
        customStyling: false,
        heroSection: false,
        additionalFeatures: false,
        addSections: false,
        footer: false,
        supportEmail: false,
        metadata: false,
        publish: false,
      },
    });
  });

  test("returns correct active status object for /webinar-website/additional-features", () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar-website/additional-features`,
    });
    const { result } = renderHook(() => useActivePageStatus(WEBINAR_CONFIG));
    expect(result.current.activePageStatus).toEqual({
      groups: {
        webinarDetailsGroup: false,
        webinarWebsite: true,
      },
      subGroups: {
        brandAndStyle: false,
        homePage: true,
        configurations: false,
      },
      pages: {
        webinarDetails: false,
        websiteStyle: false,
        // attendeeConfigurations: false,
        agenda: false,
        customStyling: false,
        heroSection: false,
        additionalFeatures: true,
        addSections: false,
        footer: false,
        supportEmail: false,
        metadata: false,
        publish: false,
      },
    });
  });
});
