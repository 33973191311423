import { Box, HStack, Icon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, useMultiStyleConfig } from '@chakra-ui/react';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretDown } from "phosphor-react";
import { getInitHour, getInitMeridian, getInitMinute, maxHour, pad2Num } from './utils';

type DCTimeInputProps = {
  initTime: Date;
  setTime: (time: string) => void;
  format24: boolean;
  htmlId?: string;
  label?: string;
  tzinfoName?: string;
}

const DCTimeInput = React.forwardRef(({ initTime, setTime, format24, htmlId, label, tzinfoName }: DCTimeInputProps, ref) => {
  const styles = useMultiStyleConfig('TimePicker', { variant: (format24 ? 'format24': undefined) });
  const { t } = useTranslation();
  const am = t('events.form.time.am');
  const pm = t('events.form.time.pm');
  const [hour, setHour] = useState(getInitHour(format24, initTime, tzinfoName));
  const [minute, setMinute]= useState(getInitMinute(initTime, tzinfoName));
  const [meridiem, setMeridiem] = useState(getInitMeridian(initTime, am, pm, tzinfoName));

  useImperativeHandle(ref, () => ({
    clearTime() {
      setHour('');
      setMinute('');
      setMeridiem(am);
    }
  }));

  useEffect(() => {
    if(!hour || !minute || !meridiem) return;
    const time = `${pad2Num(hour)}:${pad2Num(minute)}${format24 ? `` : ` ${meridiem}`}`;
    setTime(time);
  }, [minute, hour, meridiem]);

  return (
    <HStack width='100%' justify="space-between">
      <Box __css={styles.numberInputBox}>
        <NumberInput __css={styles.numberInput}
          isInvalid={false}
          min={0} max={maxHour(format24, minute)}
          value={hour && pad2Num(hour)}
          onChange={(val) => val && setHour(val)}
          id={ htmlId && `${htmlId}-hour`}
          aria-label={`${label} ${t("time_picker.hour")}`}
        >
          <NumberInputField placeholder='12' __css={styles.numberInputField} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
      <Box>:</Box>
      <Box __css={styles.numberInputBox}
      >
        <NumberInput __css={styles.numberInput}
          isInvalid={false}
          min={0} max={hour === '24' ? 0 : 59}
          value={minute && pad2Num(minute)}
          onChange={(val) => val && setMinute(val)}
          id={ htmlId && `${htmlId}-minute` }
          aria-label={`${label} ${t("time_picker.minute")}`}
        >
          <NumberInputField placeholder='00' __css={styles.numberInputField} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
      {!format24 && <Box __css={styles.selectBox}>
        <Select __css={styles.select}
          icon={<Icon as={CaretDown} weight="fill" />}
          value={meridiem}
          isInvalid={false}
          onChange={(e) => setMeridiem(e.target.value)}
          aria-label={`${label} ${t("time_picker.meridiem")} `}
        >
          <option value={am}>{am}</option>
          <option value={pm}>{pm}</option>
        </Select>
      </Box>}
    </HStack>
  );
});

DCTimeInput.displayName = 'DCTimeInput';
export default DCTimeInput;
