import React from "react";
import { act, screen, fireEvent, waitFor } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import HeroSectionForm, { HeroSectionFormProps } from "./HeroSectionForm";
import { FAKE_EVENT, render } from "../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: HeroSectionFormProps = {
  event: FAKE_EVENT,
  submitForm,
  handleErrors,
  formErrors,
};

describe("Homepage Setup Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<HeroSectionForm {...requiredProps} />);
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  describe("header tag line", () => {
    test("is required", async () => {
      render(<HeroSectionForm {...requiredProps} />);

      const label = screen.getByLabelText(
        "homepage_setup.header_tagline.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(label, { target: { value: "" } });
        fireEvent.blur(label);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("sub tag line", () => {
    test("is required", async () => {
      render(<HeroSectionForm {...requiredProps} />);

      const label = screen.getByLabelText("homepage_setup.sub_tagline.label", {
        exact: false,
      });

      await waitFor(() => {
        fireEvent.change(label, { target: { value: "" } });
        fireEvent.blur(label);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("tag line", () => {
    test("is required", async () => {
      render(<HeroSectionForm {...requiredProps} />);

      const label = screen.getByLabelText("homepage_setup.tagline.label", {
        exact: false,
      });

      await waitFor(() => {
        fireEvent.change(label, { target: { value: "" } });
        fireEvent.blur(label);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    test("mininum 3 characters long", async () => {
      render(<HeroSectionForm {...requiredProps} />);

      const label = screen.getByLabelText("homepage_setup.tagline.label", {
        exact: false,
      });

      await waitFor(() => {
        fireEvent.change(label, { target: { value: "HI" } });
        fireEvent.blur(label);
      });

      expect(
        screen.getByText("homepage_setup.tagline.too_short")
      ).toBeInTheDocument();
    });

    test("maximum 124 characters long", async () => {
      render(<HeroSectionForm {...requiredProps} />);

      const label = screen.getByLabelText("homepage_setup.tagline.label", {
        exact: false,
      });

      await waitFor(() => {
        fireEvent.change(label, { target: { value: "X".repeat(125) } });
        fireEvent.blur(label);
      });

      expect(
        screen.getByText("homepage_setup.tagline.too_long")
      ).toBeInTheDocument();
    });
  });

  describe("backgroundVideoUrl must be valid", () => {
    test("incorrect url format", async () => {
      render(<HeroSectionForm {...requiredProps} />);

      const label = screen.getByLabelText(
        "homepage_setup.background_video_url.label"
      );

      await act(async () => {
        fireEvent.change(label, {
          target: { value: "notAUrl" },
        });
        fireEvent.blur(label);
      });

      const validationError = screen.getByText(
        "homepage_setup.video_url_format_error"
      );
      expect(validationError).toBeInTheDocument();
    });
    test("backgroundVideoUrl can be blank", async () => {
      render(<HeroSectionForm {...requiredProps} />);

      const label = screen.getByLabelText(
        "homepage_setup.background_video_url.label"
      );

      await act(async () => {
        fireEvent.change(label, {
          target: { value: "" },
        });
        fireEvent.blur(label);
      });

      expect(label.closest("input").value).toBe("");
    });
  });
});
