export const figmaTheme = {
  colors: {
    primary: {
      base: "#202020",
      light: "#565656",
      dark: "#000016",
      accent: "#5AB2F9",
      0: "#000000",
      10: "#0D0564",
      20: "#252477",
      30: "#3D3D8F",
      40: "#5555A9",
      50: "#6E6EC4",
      60: "#8888DF",
      70: "#A2A2FC",
      80: "#C1C1FF",
      90: "#E1E0FF",
      95: "#F1EFFF",
      99: "#FEFBFF",
      100: "#FFFFFF",
    },
    secondary: {
      base: "#FF5621",
      600: "#CC451A",
      700: "#993414",
    },
    label: {
      primary: "#1C1B1E",
      secondary: "#767676",
      tertiary: "#C8C5CA",
      quarternary: "#F4F0F4",
      background: "#464A65",
    },
    navigation: {
      inactive: "rgba(255, 255, 255, 0.5)",
      topNavigation: {
        default: "#E5E5EA",
      }
    },
    pill: {
      text: {
        success: "#005136",
        warning: "#624C20",
        danger: "#85211D",
        active: "#244A7A",
      },
      bg: {
        default: "#E5E1E6",
        theme: "#E1E0FF",
        success: "#CCE7DE",
        warning: "#FDF2DC",
        danger: "#F8D7D6",
        active: "#D8E5F5",
      },
      border: {
        active: "#3D7BCC",
      },
    },
    border: {
      grey: "#CCCCCC",
    },
    webinarTypeBg: {
      livefeedDC: "#FDECD4",
      livefeed: "#DEF8FD",
      interactive: "#FCE4F8",
    },
    success: "#00875A",
    alert: "#DD3730",
    black: "#000000",
    white: "#FFFFFF",
    grey: {
      base: "#E5E1E6",
      200: "#D2D2D2",
      300: "#C0C0C0",
    },
    blue: "#165FCD",
    yellow: "#F5BF4F",
    purple: "#D05BC5",
    skyBlue: {
      base: "#5AB2F9",
      100: "#DEF0FE",
      700: "#366B95",
      800: "#244764",
    },
    teal: "#00DAB5",
    orange: "#FF5621",
  },
  opacities: {
    opaque: "1",
    inactive: "0.7",
  },
  lineHeights: {
    16: "16px",
    20: "20px",
    24: "24px",
  },
  components: {
    fontSize: "14px",
  },
  border: {
    width: {
      sm: "1px",
      md: "2px",
      lg: "3px",
    },
    radius: {
      md: "8px",
      lg: "12px",
    },
  },
  fontWeights: {
    bold: "700",
    normal: "400",
  },
};
