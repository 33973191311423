import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { figmaTheme as fT } from "../figmaTheme";

export const webinarTypeTheme: ComponentStyleConfig = {
  parts: ["webinarTypeCard", "webinarTypeCardActive", "webinarTypeIcon", "webinarTypeSelectingPage", "webinarTypeSelectingFooter"],
  baseStyle: {
    webinarTypeCard: {
      border: fT.border.width.sm,
      borderStyle: "solid",
      borderColor: fT.colors.label.secondary,
      borderRadius: fT.border.radius.md,
      bg: fT.colors.white,
      w: "224px",
      h: "280px",
      p: "40px 32px 32px 32px",
      _hover: {
        cursor: "pointer",
        border: fT.border.width.lg,
        borderStyle: "solid",
        borderColor: fT.colors.primary.accent
      }
    },
    webinarTypeCardActive: {
      border: fT.border.width.lg,
      borderStyle: "solid",
      borderColor: fT.colors.primary.accent,
      borderRadius: fT.border.radius.md,
      bg: fT.colors.pill.bg.theme,
      w: "224px",
      h: "280px",
      p: "40px 32px 32px 32px",
      _hover: { cursor: "pointer" },
      boxShadow: "0px 10px 40px rgba(46, 92, 153, 0.2)"
    },
    webinarTypeIcon: {
      justify: "center",
      align: "center",
      w: "80px",
      h: "80px",
      borderRadius: "40px",
      m: "auto",
    },
    webinarTypeSelectingPage: {
      w: "100%",
      h: "100%",
      minW: "800px",
      minH: "500px",
      justify: "center",
      align: "center",
      direction: "column"
    },
    webinarTypeSelectingFooter: {
      direction: "row-reverse",
      py: "20px",
      px: "40px",
      borderTop: "1px",
      h: "80px",
      borderTopColor: fT.colors.border.grey
    }
  }
};
