import { figmaTheme as fT } from "../figmaTheme";

export const textTheme = {
  variants: {
    "list-heading": {
      marginBlockEnd: "0",
      color: "#4F4E4E",
      fontSize: "small",
      alignSelf: "flex-end",
    },
    "with-shorter-underline": {
      pos: "relative",
      marginBlockStart: "0",
      marginBlockEnd: "20px",
      w: "fit-content",
      _before: {
        content: `''`,
        position: "absolute",
        width: "90%",
        height: "1px",
        bottom: "-10px",
        borderBottom: "2px solid",
      },
    },
    "card-title": {
      color: fT.colors.label.primary,
      fontSize: "16px",
    },
    "card-body": {
      color: fT.colors.label.secondary,
      fontSize: "12px",
      fontWeight: fT.fontWeights.normal,
    },
    "menu-item": {
      fontSize: "14px",
    },
    "form-description": {
      fontFamily: "Poppins",
      color: "#787575",
    },
    success: {
      fontWeight: 600,
      color: fT.colors.success,
    },
    "webinar-selecting-title": {
      fontWeight: "600",
      mb: "10px" ,
      fontSize: "24px" ,
      lineHeight: "36px" ,
      fontFamily: "Inter"
    },
    "webinar-type-error": {
      color: fT.colors.alert,
      fontSize: "14px",
      lineHeight: "20px",
      mb: "40px"
    },
    "webinar-type-title": {
      w: "160px",
      textAlign: "center",
      lineHeight: "20px",
      fontSize: "14px",
      fontWeight: "600",
      my: "16px"
    },
    "webinar-type-description": {
      w: "160px",
      textAlign: "center",
      lineHeight: "16px",
      fontSize: "10px",
      fontWeight: "400"
    },
    "webinar-success": {
      fontWeight: 700,
      color: fT.colors.success,
      fontSize: "18px",
      lineHeight: "24px",
      mb: "16px"
    },
    "calendar-date": {
      fontWeight: 600,
      mb: "16px",
      fontSize: "16px",
      lineHeight: "16px",
      fontFamily: "Inter"
    },
    "calendar-week-day": {
      fontWeight: 400,
      pb: "16px",
      borderBottom: `2px solid ${fT.colors.secondary.base}`,
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      fontFamily: "Inter"
    }
  },
};
