import moment from "moment-timezone";

const timeToString = (time: Date, formatType: string, timeZone = "") => {
  if (timeZone) {
    return moment(time).tz(timeZone).format(formatType);
  } else {
    return moment(time).format(formatType);
  }
};

const webinarCalendarStartTime = (time: Date, timeZone = "") => {
  return timeToString(time, "HH:00:00", timeZone);
}

const webinarCalendarEndTime = (time: Date, timeZone = "") => {
  const tempResult = timeToString(moment(time).add(1, "hour").toDate(), "HH:00:00", timeZone);

  if (tempResult == "00:00:00"){
    return "24:00:00";
  } else{
    return tempResult;
  }
}

export { timeToString, webinarCalendarStartTime, webinarCalendarEndTime };
