import { JsonApiTalkType } from '../models/talk';
import AppAdapter from '../../../utils/adapter';
import CONFIG from '../../../utils/config';
import RestClient from '../../../utils/restClient';

/**
 * service for talks
 */

export type TalkListResponseType = {
  ok: boolean;
  result: Array<JsonApiTalkType> | null;
  message?: string;
}

class TalkService {
  static async list(eventId: string): Promise<TalkListResponseType> {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.EVENTS}/${eventId}/talks`);
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in response';
      return {
        ok: responseOk,
        result: response.result as Array<JsonApiTalkType>,
        message: response.message
      };
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async createOrUpdateTalk(eventId: string, eventDate: Date, talkId: string, payload) {
    if (talkId) {
      return this.updateTalk(eventId, eventDate, talkId, payload);
    } else {
      return this.createTalk(eventId, eventDate, payload);
    }
  }

  static async createTalk(eventId: string, eventDate: Date, payload) {
    try {
      this.transformPayload(payload, eventDate);
      const response = await RestClient.postWithFormData(`${CONFIG.URLS.EVENTS}/${eventId}/talks`, { talk: AppAdapter.snakeCaseKeys(payload) });
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in response';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async updateTalk(eventId: string, eventDate: Date, talkId: string, payload) {
    try {
      this.transformPayload(payload, eventDate);
      const response = await RestClient.patchWithFormData(`${CONFIG.URLS.EVENTS}/${eventId}/talks/${talkId}`, { talk: AppAdapter.snakeCaseKeys(payload) });
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in response';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static transformPayload(payload, eventDate: Date) {
    this.transformTime(payload, eventDate);
    this.transformSpeakerIds(payload);
  }

  static transformTime(payload, eventDate: Date) {
    if (payload.startTime) {
      payload.time = `${eventDate.toDateString()} ${payload.startTime}`;
      delete payload.startTime
    }
    if(payload.endTime) delete payload.endTime
  }

  static transformSpeakerIds(payload) {
    if(!payload.speakerIds) return;

    payload.speakers = payload.speakerIds.join(',');
    delete payload.speakerIds;
  }

  static transform(record) {
    record['startAt'] = new Date(record['startAt']);
    record['endAt'] = new Date(record['endAt']);
    record['indexImageUrl'] = record?.indexImage?.url;
    return record;
  }
}

export default TalkService;
