import React from "react";
import styled from "styled-components";
import IconPreview from "./IconPreview";
import { IconEntry } from "../../phosphorIcons/types";

const DropdownShell = styled.div`
  position: relative;
`;

const PaletteWrapper = styled.div`
  background-color: white;
  z-index: 10000;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  padding: 20px 15px;
  display: flex;
  flex-wrap: wrap;
`;

interface Props {
  dropdownActive: boolean;
  iconList: IconEntry[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectIcon: any;
}

const IconDropdown: React.FunctionComponent<Props> = ({
  dropdownActive,
  iconList,
  selectIcon,
}: Props): JSX.Element => {
  return (
    <DropdownShell>
      {dropdownActive && (
        <PaletteWrapper id="IconPickerDropdown">
          {iconList.map((icon: IconEntry) => (
            <IconPreview icon={icon} selectIcon={selectIcon} key={icon.name} />
          ))}
        </PaletteWrapper>
      )}
    </DropdownShell>
  );
};

export default IconDropdown;
