import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../../../common/PageLayout";
import SectionIntro from "../../../../common/SectionIntro";
import { useEvent } from "../../../Event/index";
import presentationIntroImage from "../../../../../EventWizard/assets/images/presentation-intro.svg";

const PresentationIntro: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const {event} = useEvent();
  const navigate = useNavigate();

  return (
    <PageLayout
      event={event}
    >
      <SectionIntro
        imageSrc={presentationIntroImage}
        textContent={() => (
          <>
            <p>{t("presentation_intro.line_one")}</p>
            <br />
            <p>{t("presentation_intro.line_two")}</p>
            <br />
            <p>{t("presentation_intro.line_three_start")}<b>{t("presentation_intro.line_three_bolded")}</b>{t("presentation_intro.line_three_end")}</p>
          </>
        )}
        onClickNext={() => navigate(`/admin/event-tools/${event.id}/steps/metadata`)}
      />
    </PageLayout>
  );
};

export default PresentationIntro;
