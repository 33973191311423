import { ChakraProvider } from "@chakra-ui/provider";
import React, { useEffect, useState } from "react";
import PollService from "./pollService";
import dcTheme from "../react/dcTheme";
import { PollPropsSource, UserProps } from "./types";
import { Heading, Flex } from "@chakra-ui/react";
import { useCable } from "../CustomHooks/useCable";
import AppAdapter from "../../utils/adapter";
import { useTranslation } from "react-i18next";
import { priorityPoll } from "./utils";
import PollForm from "./Responses/PollForm";

interface Props {
  mountableType: string;
  mountableId: number;
  user: UserProps;
}

const PollConsumer: React.FC<Props> = ({
  mountableType,
  mountableId,
  user,
}): JSX.Element => {
  const { t } = useTranslation();
  const [currentPoll, setCurrentPoll] = useState(null);

  const getPoll = async (mountableType, mountableId) => {
    const payload = await PollService.getPolls(mountableType, mountableId);

    if (payload.ok) {
      const response: Array<PollPropsSource> = await AppAdapter.camelCaseKeys(
        payload.result.data
      );
      const selectedPoll = priorityPoll(response);
      if (selectedPoll) {
        setCurrentPoll(selectedPoll.attributes);
      } else {
        setCurrentPoll(null);
      }
    }
  };

  const triggerGetPoll = () => getPoll(mountableType, mountableId);

  useCable(
    { channel: "PollChannel", type: mountableType, id: mountableId },
    { name: "Poll", callback: triggerGetPoll }
  );

  useEffect(() => {
    triggerGetPoll();
  }, []);

  return (
    <ChakraProvider theme={dcTheme}>
      {currentPoll && (
        <Flex flexDir="column" alignItems="center">
          <Heading fontSize={"22px"} mb={2}>
            {t("pollings.name")}
          </Heading>
          <PollForm user={user} poll={currentPoll} />
        </Flex>
      )}
    </ChakraProvider>
  );
};

export default PollConsumer;
