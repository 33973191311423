import moment from "moment-timezone";

const padNum = (num, padding = 2, padVal = '0') => String(num).padStart(padding, padVal);

const pad2Num = (num) => {
  return String(num%100).padStart(2, '0');
};

const maxHour = (format24, minute) => {
  if(format24) {
    return minute == '0' ? 24 : 23;
  }
  return 12;
};

const getInitHour = (format24: boolean, time: Date, tzinfoName: string) => {
  if(!time) return;

  const formatType = format24 ? "HH" : "hh";

  if (tzinfoName) {
    return moment(time).tz(tzinfoName).format(formatType);
  } else {
    return moment(time).format(formatType);
  }
};

const getInitMinute = (time: Date, tzinfoName: string) => {
  if(!time) return;
  
  if (tzinfoName) {
    return moment(time).tz(tzinfoName).format("mm");
  } else {
    return moment(time).format("mm");
  }
};

const getInitMeridian = (time: Date, am: string, pm: string, tzinfoName: string) => {
  if(!time) return am;

  const meridian = tzinfoName ? moment(time).tz(tzinfoName).format("a") : moment(time).format("a")
  if (meridian == "am") {
    return am;
  } else {
    return pm;
  }
};

export {
  padNum, pad2Num, maxHour, getInitHour, getInitMinute, getInitMeridian
};
