import React, { useMemo } from "react";
import { Link, Box, LinkProps } from "@chakra-ui/react";
import { figmaTheme as fT } from "../../../react/dcTheme";
import { CheckCircle } from "phosphor-react";
import { Link as RouterLink } from "react-router-dom";
import type { SideNavigationVariants } from "./SideNavigation";
import { setItemColor } from "./utils";

type SideNavigationItemProps = {
  displayText: string;
  path: string;
  isActive?: boolean;
  isComplete?: boolean;
  isEnabled?: boolean;
  variant?: SideNavigationVariants;
};

export const SideNavigationItem: React.FC<SideNavigationItemProps> = ({
  displayText,
  path,
  isActive,
  isComplete,
  isEnabled = true,
  variant = "default",
}) => {
  const linkStyles = useMemo<LinkProps>(() => {
    return {
      color: setItemColor(isActive, variant),
      py: 2,
      px: 4,
      my: 4,
      mx: 6,
      borderRadius: 4,
      position: "relative",
      d: "block",
    };
  }, [variant, isActive]);

  return (
    <Link
      as={RouterLink}
      to={path}
      style={{
        textDecoration: "none",
        pointerEvents: isEnabled ? "auto" : "none",
      }}
      bg={isActive && fT.colors.label.background}
      {...linkStyles}
    >
      <Box
        as="span"
        lineHeight={"16px"}
        fontSize="12px"
        fontWeight={isActive ? "bold" : "normal"}
        color={isActive ? fT.colors.white : fT.colors.navigation.inactive}
      >
        {displayText}
      </Box>
      {isComplete && (
        <CheckCircle
          weight="fill"
          size="16"
          color={fT.colors.success}
          style={{
            marginTop: "6px",
            marginRight: "9px",
            display: "inline",
            right: 0,
            position: "absolute",
          }}
        />
      )}
    </Link>
  );
};

export default SideNavigationItem;
