import React from "react";
import { Flex, Image } from "@chakra-ui/react";
import {
  NumberCircleOne,
  NumberCircleTwo,
  NumberCircleThree,
  NumberCircleFour,
} from "phosphor-react";
import { useTranslation } from "react-i18next";

import {
  SideNavigationItem,
  SideNavigationGroup,
  SideNavigationHeader,
} from "../../../common/SideNavigation";
import { figmaTheme as fT } from "../../../../react/dcTheme";
import { useWebinarFormActions } from "../../hooks/useWebinarFormActions";
import joynLogo from "../../../../EventWizard/assets/images/joyn-logo.svg";
import { usePageStatus } from "../../../pages/Event/Event";

type SideNavigationProps = {
  facilitators?: boolean;
  speakers?: boolean;
};

export const SideNavigation: React.FC<SideNavigationProps> = ({
  facilitators = false,
  speakers = false,
}) => {
  const { t } = useTranslation();
  const { pathsByRoute } = useWebinarFormActions();
  const { activePageStatus, completePageStatus, enabledPageStatus } =
    usePageStatus();

  return (
    <Flex
      h="100%"
      direction="column"
      bg={fT.colors.primary.base}
      basis="280px"
      shrink={0}
      p={6}
      overflow="auto"
    >
      <Image src={joynLogo} alt={t("images.image.alt")} w="100px" mb={10} />
      <SideNavigationHeader
        isActive={activePageStatus.groups.webinarDetailsGroup}
        isComplete={completePageStatus.groups.webinarDetailsGroup}
        isEnabled={enabledPageStatus.groups.webinarDetailsGroup}
        displayText={t("event_tools.navigation.webinar.webinar_details")}
        path={pathsByRoute.webinarDetails}
        iconComponent={NumberCircleOne}
        variant="webinar"
      />
      {facilitators || speakers ? (
        <SideNavigationGroup
          displayText={t("event_tools.navigation.agenda")}
          iconComponent={NumberCircleTwo}
          variant="webinar"
        >
          <SideNavigationItem
            displayText={t("event_tools.navigation.presentation")}
            path={pathsByRoute.agenda}
            variant="webinar"
          />
          {facilitators && (
            <SideNavigationItem
              displayText={t("event_tools.navigation.facilitator")}
              path={pathsByRoute.facilitators}
              variant="webinar"
            />
          )}
          {speakers && (
            <SideNavigationItem
              displayText={t("event_tools.navigation.speaker")}
              path="#"
              variant="webinar"
            />
          )}
        </SideNavigationGroup>
      ) : (
        <SideNavigationHeader
          isActive={activePageStatus.pages.agenda}
          isComplete={completePageStatus.pages.agenda}
          isEnabled={enabledPageStatus.pages.agenda}
          displayText={t("event_tools.navigation.agenda")}
          path={pathsByRoute.agenda}
          iconComponent={NumberCircleTwo}
          variant="webinar"
        />
      )}
      <SideNavigationGroup
        isActive={activePageStatus.groups.webinarWebsite}
        isComplete={completePageStatus.groups.webinarWebsite}
        displayText={t("event_tools.navigation.webinar.webinar_website")}
        iconComponent={NumberCircleThree}
        variant="webinar"
      >
        <SideNavigationItem
          isActive={activePageStatus.subGroups.brandAndStyle}
          isComplete={completePageStatus.subGroups.brandAndStyle}
          isEnabled={enabledPageStatus.subGroups.brandAndStyle}
          displayText={t("event_tools.navigation.brand_and_style")}
          path={pathsByRoute.websiteStyle}
          variant="webinar"
        />
        <SideNavigationItem
          isActive={activePageStatus.subGroups.homePage}
          isComplete={completePageStatus.subGroups.homePage}
          isEnabled={enabledPageStatus.subGroups.homePage}
          displayText={t("event_tools.navigation.home_page")}
          path={pathsByRoute.heroSection}
          variant="webinar"
        />
        <SideNavigationItem
          isActive={activePageStatus.subGroups.configurations}
          isComplete={completePageStatus.subGroups.configurations}
          isEnabled={enabledPageStatus.subGroups.configurations}
          displayText={t("event_tools.navigation.configurations")}
          path={pathsByRoute.supportEmail}
          variant="webinar"
        />
      </SideNavigationGroup>
      <SideNavigationHeader
        isActive={activePageStatus.pages.publish}
        isComplete={completePageStatus.pages.publish}
        isEnabled={enabledPageStatus.pages.publish}
        displayText={t("event_tools.navigation.publish")}
        path={pathsByRoute.publish}
        iconComponent={NumberCircleFour}
        variant="webinar"
      />
    </Flex>
  );
};

export default SideNavigation;
