import { renderHook } from "@testing-library/react-hooks";
import EventEmitter from "events";
import { Concern, Room, useCable } from "../useCable";

const room: Room = {
  id: 555,
  type: "room_type",
  channel: "room_channel",
};

const concernCallback = jest.fn();
const concern: Concern = {
  name: "concern_name",
  callback: concernCallback
};

const mockEmitter = new EventEmitter();
const mockUnsubscribe = jest.fn();

jest.mock("../../../channels/consumer", () => ({
  subscriptions: {
    create: jest.fn().mockImplementation((_room, listeners) => {
      Object.entries(listeners).forEach(
        ([eventName, handler]: [string, () => void]) => {
          mockEmitter.on(eventName, handler);
        }
      );

      return {
        unsubscribe: mockUnsubscribe,
      };
    }),
  },
}));

afterEach(() => {
  mockEmitter.removeAllListeners();
  mockUnsubscribe.mockClear();
});

describe("useCable", () => {
  it("calls concern.callback with a response if the names match", () => {
    renderHook(() => useCable(room, concern));

    const emittedData = {
      concern: concern.name,
      content: "some_content",
    }
    mockEmitter.emit("received", emittedData);

    expect(concernCallback).toHaveBeenCalledWith(emittedData);
  });

  describe("the concern is scoped to certain tags", () => {
    const concernWithTag = {
      name: "concern_with_tag",
      callback: concernCallback,
      tags: ["tag1", "tag2"],
    };

    afterEach(() => {
      concernCallback.mockClear();
    });

    it("calls concern.callback with a response if the names match with at least 1 tag", () => {
      renderHook(() => useCable(room, concernWithTag));

      const emittedData = {
        concern: concernWithTag.name,
        content: "some_content",
        tags: ["tag2"],
      };
      mockEmitter.emit("received", emittedData);

      expect(concernCallback).toHaveBeenCalledWith(emittedData);
    });

    it("doesn't call concern.callback with a response if the names don't match", () => {
      renderHook(() => useCable(room, concernWithTag));

      const emittedData = {
        concern: concernWithTag,
        content: "some_content",
        tags: ["mismatching_tag"],
      };
      mockEmitter.emit("received", emittedData);

      expect(concernCallback).not.toHaveBeenCalled();
    });
  })

  it("unmounts when the parent component calls unmount", () => {
    const { unmount } = renderHook(() => {
      useCable(room, concern);
    });

    unmount();
    expect(mockUnsubscribe).toHaveBeenCalledTimes(1);
  });
})
