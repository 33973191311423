import React, { useState } from "react";
import AgendaView from "../../../Agenda";
import { useTranslation } from "react-i18next";
import { EventType } from "../../../../EventWizard/models/Event";
import { AddIcon } from "@chakra-ui/icons";
import PresentationDrawerForm from "../../forms/PresentationForm";
import { NEW_TALK } from '../../../../EventWizard/models/talk';
import { webinarCalendarStartTime, webinarCalendarEndTime } from "../../../../EventWizard/helpers/timeConverter";

import { Flex, Text, Button, Spacer, useDisclosure, VStack } from "@chakra-ui/react";

type AgendaTalkType = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  displayDate: Date;
  startAt: Date;
  endAt: Date;
  location: string;
  speakers: Array<string>;
}

export type WebinarAgendaProps = {
  event: EventType;
  talks: Array<AgendaTalkType>;
  refreshTalks: () => void;
}

const Agenda: React.FC<WebinarAgendaProps> = ({ event, talks, refreshTalks }) => {
  const { t } = useTranslation();
  const [talk, setTalk] = useState(NEW_TALK);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const addNewTalk = () => {
    const newTalk = NEW_TALK;
    newTalk.startTime = event.startTime;
    newTalk.endTime = event.endTime;
    setTalk(newTalk);
    onOpen();
  }

  const editTalk = (talk) => {
    setTalk(talk)
    onOpen();
  }

  return(
    <>
      <Flex py={8} justifyContent="space-between">
        <VStack alignItems="flex-start" width="55%">
          <Text fontWeight="700" lineHeight="40px">
            {t("events.agenda.presentation.schedule_presentation")}
          </Text>
          <Spacer />
          {talks.length == 0 && <Text fontSize="xs">
            {t("presentation.new_helper_text")}
          </Text>}
        </VStack>
        <Button leftIcon={<AddIcon fontSize="12px" />} onClick={addNewTalk} variant="secondary">
          {t("events.agenda.presentation.add_presentation_button")}
        </Button>
      </Flex>
      <PresentationDrawerForm talk={talk} event={event} refreshTalks={refreshTalks} isOpen={isOpen} onClose={onClose} />
      <AgendaView
        tzinfoName={event.tzinfoName}
        calendarStartTime={webinarCalendarStartTime(event.startTime, event.tzinfoName)}
        calendarEndTime={webinarCalendarEndTime(event.endTime, event.tzinfoName)}
        events={talks}
        initialDate={event.startDate}
        locale="en"
        editTalk={editTalk}
      />
    </>
  )
};

export default Agenda;
