import React from 'react';
import { act, fireEvent, render, screen } from '@testing-library/react';
import '@testing-library/jest-dom';
import { axe, toHaveNoViolations } from 'jest-axe';
expect.extend(toHaveNoViolations);
import userEvent from '@testing-library/user-event';

import GroupRegistrationPage from './GroupRegistrationPage';
import {
  DelegateGroup,
  DelegateGroupsAndTypes,
  DelegateType,
  GroupRegistrationPageProps,
  GroupRegistrationRecord
} from './types';
import * as updateGroupRegistration from './updateGroupRegistration';

const blankRow: GroupRegistrationRecord = {
  recordId: "1",
  firstName: "",
  lastName: "",
  email: "",
  delegateTypeId: undefined,
  addonIds: []
};

const existingRecord: GroupRegistrationRecord = {
  recordId: "0",
  firstName: "Harry",
  lastName: "Potter",
  email: "harry_potter@hogwarts.com",
  delegateTypeId: 1,
  addonIds: [1, 2]
};

const delegateType: DelegateType = {
  id: 1,
  name: "Defence against the dark arts",
  price: 0.0,
  addons: [
    { id: 1, price: 100.0, name: '1 on 1 with Snape ($100.00 AUD)' },
    { id: 2, price: 35.0, name: 'Advanced session ($35.00 AUD)' },
    { id: 3, price: 10.0, name: 'unselected addon ($10.00 AUD)' },
  ]
};

const delegateGroup: DelegateGroup = {
  name: "Wizard group",
  delegateTypes: [delegateType]
};

const delegateGroupsAndTypes: DelegateGroupsAndTypes = {
  delegateGroups: [delegateGroup],
  delegateTypes: [delegateType]
};


const groupRegistrationId = "1";

const props: GroupRegistrationPageProps = {
  currencyCode: "AUD",
  groupRegistrationId,
  groupRegistrationRecords: [existingRecord],
  delegateGroupsAndTypes: delegateGroupsAndTypes
};

describe('GroupRegistrationPage', () => {
  test('has no accessibility violations', async () => {
    const { container } = render(<GroupRegistrationPage {...props} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe('keyboard accessibility', () => {
    test('the form can be navigated with the tab key', async () => {
      render(<GroupRegistrationPage {...props} />);

      const addAttendeeButton = screen.getByTestId('add-new-row');
      // click add attendee button to ensure remove row button is revealed.
      await act(async () => userEvent.click(addAttendeeButton));

      const firstNameinput = screen.queryByTestId('firstName-row-0');
      const lastNameInput = screen.getByTestId('lastName-row-0');
      const emailInput = screen.getByTestId('email-row-0');
      // The getAllByLabelText selectors below are a work around for the react-select components
      // which make it difficult to set test ID's without adding unnecessary logic.
      // In addition since i18n hasn't been introduced for this test suite, these labels will
      //  only work until the point in which this is introduced.
      const delegateTypeSelect = screen.getAllByLabelText("delegate_types.name")[0];
      const addOnsSelect = screen.getAllByLabelText("group_registrations.form.addons.label")[0];
      const priceDisplay = screen.getByTestId('cost-row-0');
      const removeRowButton = screen.getByTestId('remove-row-0');

      const elementsInTabOrder = [
        firstNameinput,
        lastNameInput,
        emailInput,
        delegateTypeSelect,
        addOnsSelect,
        priceDisplay,
        removeRowButton,
      ];

      await act(async () => firstNameinput.focus());

      for (const element of elementsInTabOrder) {
        expect(element).toHaveFocus();
        await act(async () => userEvent.tab());
      }
    });
  });


  describe('renders an existing record correctly', () => {
    test('Input fields', async () => {
      render(<GroupRegistrationPage {...props} />);

      expect(screen.getByDisplayValue(existingRecord.firstName)).toBeInTheDocument();
      expect(screen.getByDisplayValue(existingRecord.lastName)).toBeInTheDocument();
      expect(screen.getByDisplayValue(existingRecord.email)).toBeInTheDocument();
    });

    test('Select fields', async () => {
      render(<GroupRegistrationPage {...props} />);
      const { addons } = delegateType;

      expect(screen.getByText(delegateType.name)).toBeInTheDocument();
      expect(screen.getByText(addons[0].name)).toBeInTheDocument();
      expect(screen.getByText(addons[1].name)).toBeInTheDocument();
      expect(() => screen.getByText(addons[2].name)).toThrow();
    });

    test('Cost field', async () => {
      render(<GroupRegistrationPage {...props} />);
      const readOnlyInput = screen.getByTestId('cost-row-0') as HTMLInputElement;

      expect(readOnlyInput.value).toBe("A$135.00");
    });

    test('Cost field for with different currencies', async () => {
      const newProps: GroupRegistrationPageProps = {
        ...props,
        currencyCode: "EUR",
      };

      render(<GroupRegistrationPage {...newProps} />);
      const readOnlyInput = screen.getByTestId('cost-row-0') as HTMLInputElement;

      expect(readOnlyInput.value).toBe("€135.00");
    });
  });

  describe('adding a new record', () => {
    test('creates another form row', async () => {
      render(<GroupRegistrationPage {...props} />);

      await act(async () => {
        fireEvent.click(screen.getByTestId('add-new-row'));
      });

      expect(screen.getByTestId('firstName-row-1')).toBeTruthy();
      expect(screen.getByTestId('lastName-row-1')).toBeTruthy();
      expect(screen.getByTestId('email-row-1')).toBeTruthy();
      expect(screen.getByTestId('delegate-types-row-1')).toBeTruthy();
      expect(screen.getByTestId('addons-row-1')).toBeTruthy();
    });
  });

  describe('validation', () => {
    describe('first name must be valid', () => {
      test('first name is present', async () => {
        render(<GroupRegistrationPage {...props} />);

        const input = screen.queryByTestId('firstName-row-0') as HTMLInputElement;
        await act(async () => {
          fireEvent.change(input, { target: { value: 'first name of human' } });
          fireEvent.blur(input);
        });

        expect(input.value).toBe('first name of human');
      });

      test('first name is present', async () => {
        render(<GroupRegistrationPage {...props} />);

        const input = screen.queryByTestId('firstName-row-0');
        await act(async () => {
          fireEvent.change(input, { target: { value: '' } });
          fireEvent.blur(input);
        });

        const validationError = screen.getByText("validations.blank_error");
        expect(validationError).toBeInTheDocument();
      });
    });

    describe('last name must be valid', () => {
      test('last name is present', async () => {
        render(<GroupRegistrationPage {...props} />);

        const input = screen.queryByTestId('lastName-row-0') as HTMLInputElement;
        await act(async () => {
          fireEvent.change(input, { target: { value: 'last name of human' } });
          fireEvent.blur(input);
        });

        expect(input.value).toBe('last name of human');
      });

      test('last name is present', async () => {
        render(<GroupRegistrationPage {...props} />);

        const input = screen.queryByTestId('lastName-row-0') as HTMLInputElement;
        await act(async () => {
          fireEvent.change(input, { target: { value: '' } });
          fireEvent.blur(input);
        });

        const validationError = screen.getByText("validations.blank_error");
        expect(validationError).toBeInTheDocument();
      });
    });

    describe('email name must be valid', () => {
      test('incorrect email format', async () => {
        render(<GroupRegistrationPage {...props} />);

        const input = screen.queryByTestId('email-row-0') as HTMLInputElement;
        await act(async () => {
          fireEvent.change(input, { target: { value: 'email@this_is_not_valid' } });
          fireEvent.blur(input);
        });

        const validationError = screen.getByText("validations.invalid_email");
        expect(validationError).toBeInTheDocument();

      });

      test('email is present', async () => {
        render(<GroupRegistrationPage {...props} />);

        const input = screen.queryByTestId('email-row-0') as HTMLInputElement;
        await act(async () => {
          fireEvent.change(input, { target: { value: '' } });
          fireEvent.blur(input);
        });

        const validationError = screen.getByText("validations.blank_error");
        expect(validationError).toBeInTheDocument();
      });
    });

    test('delegate type is required', async () => {
      const record = { ...existingRecord, ...{ delegateTypeId: undefined } };
      const props: GroupRegistrationPageProps = {
        currencyCode: "AUD",
        groupRegistrationId,
        groupRegistrationRecords: [record],
        delegateGroupsAndTypes: delegateGroupsAndTypes
      };

      render(<GroupRegistrationPage {...props} />);

      await act(async () => {
        const button = screen.getByTestId('submit');
        button.click();
      });

      const validationError = screen.getByText('validations.required');
      expect(validationError).toBeInTheDocument();
    });
  });

  describe('removing a record', () => {
    test('does not show remove button when only one row', async () => {
      const props: GroupRegistrationPageProps = {
        currencyCode: "AUD",
        groupRegistrationId,
        groupRegistrationRecords: [blankRow],
        delegateGroupsAndTypes: delegateGroupsAndTypes
      };

      render(<GroupRegistrationPage {...props} />);

      expect(screen.queryByTestId('remove-row-1')).toEqual(null);
    });

    test('removes the selected form row', async () => {
      const props: GroupRegistrationPageProps = {
        currencyCode: "AUD",
        groupRegistrationId,
        groupRegistrationRecords: [existingRecord, blankRow],
        delegateGroupsAndTypes: delegateGroupsAndTypes
      };

      render(<GroupRegistrationPage {...props} />);

      await act(async () => {
        const removeRowEle = screen.getByTestId('remove-row-1');
        fireEvent.click(removeRowEle);
      });

      expect(screen.queryByTestId('firstName-row-1')).toEqual(null);
      expect(screen.queryByTestId('lastName-row-1')).toEqual(null);
      expect(screen.queryByTestId('email-row-1')).toEqual(null);
      expect(screen.queryByTestId('delegate-types-row-1')).toEqual(null);
      expect(screen.queryByTestId('addons-row-1')).toEqual(null);
    });
  });

  describe('submits the form when valid', () => {
    test('submits the form', async () => {
      render(<GroupRegistrationPage {...props} />);
      const button = screen.getByTestId('submit');

      const mock = jest.spyOn(updateGroupRegistration, 'updateGroupRegistration').mockImplementation(
        () => Promise.resolve({
          success: true,
          redirect: "#",
        })
      );

      await act(async () => {
        fireEvent.click(button);
      });

      expect(mock).toHaveBeenCalledWith({
        groupRegistrationId,
        data: {
          records: [{
            firstName: existingRecord.firstName,
            lastName: existingRecord.lastName,
            email: existingRecord.email,

            delegateTypeId: existingRecord.delegateTypeId,
            addonIds: [1, 2],
            recordId: existingRecord.recordId,
            positionId: 0,
          }]
        }
      });
    });
  });
});
