import '@testing-library/jest-dom';

import {
  AddonOption,
  DelegateGroup,
  DelegateGroupsAndTypes,
  DelegateType,
  DelegateTypeOption,
} from './types';

import {
  getDelegateType,
  getAddons,
  getAddonOptionsByType,
  mapOption,
  calculateRowPrice
} from "./helpers";

const basicDelegateType: DelegateType = {
  id: 3,
  name: "Basic",
  price: 0.0,
  addons: []
};

const ravenClaw: DelegateType = {
  id: 2,
  name: "Raven claw 101",
  price: 10.0,
  addons: [
    { id: 1, price: 15.0, name: 'How to Raven claw ($15.00 AUD)' },
    { id: 2, price: 10.0, name: 'How not to Raven claw ($10.00 AUD)' },
  ]
};

const darkArtsType: DelegateType = {
  id: 1,
  name: "Defence against the dark arts",
  price: 10.0,
  addons: [
    { id: 1, price: 100.0, name: '1 on 1 with Snape ($100.00 AUD)' },
    { id: 2, price: 35.0, name: 'Advanced session ($35.00 AUD)' },
    { id: 3, price: 10.0, name: 'Beginner addon ($10.00 AUD)' },
  ]
};

const delegateTypes: DelegateType[] = [ravenClaw, darkArtsType];

const delegateGroups: DelegateGroup[] = [
  {
    name: "Wizarding group",
    delegateTypes: delegateTypes
  }
];

const delegateGroupsAndTypes: DelegateGroupsAndTypes = {
  delegateGroups: delegateGroups,
  delegateTypes: delegateTypes
};

describe('helpers', () => {
  describe('getDelegateType', () => {

    test('gets the correct delegate type', () => {
      const delegateType = getDelegateType(delegateGroupsAndTypes, ravenClaw.id);

      expect(delegateType).toEqual(ravenClaw);
    });

    test('when there is no delegate type id', () => {
      const delegateType = getDelegateType(delegateGroupsAndTypes, null);

      expect(delegateType).toEqual(undefined);
    });

    describe('when there is no delegateGroups', () => {
      const delegateGroupsAndTypesWithoutGroups: DelegateGroupsAndTypes = {
        delegateGroups: undefined,
        delegateTypes: delegateTypes
      };

      test('still gets the correct delegate type', () => {
        const delegateType = getDelegateType(delegateGroupsAndTypesWithoutGroups, ravenClaw.id);

        expect(delegateType).toEqual(ravenClaw);
      });
    });
  });

  describe('getAddons', () => {
    test('gets multiple correct addons', () => {
      const addonIds = darkArtsType.addons.map(addon => addon.id);
      const addons = getAddons(darkArtsType, addonIds);

      expect(addons).toEqual(darkArtsType.addons);
    });

    test('when there are no addons', () => {
      const addonIds = basicDelegateType.addons.map(addon => addon.id);
      const addons = getAddons(darkArtsType, addonIds);

      expect(addons).toEqual([]);
    });

    test('when there is no delegate type', () => {
      const addonIds = basicDelegateType.addons.map(addon => addon.id);
      const addons = getAddons(undefined, addonIds);

      expect(addons).toEqual(undefined);
    });
  });

  describe('getAddonOptionsByType', () => {
    test('returns the correct add on options', () => {
      const addons = getAddonOptionsByType(darkArtsType);

      expect(addons.length).toEqual(3);
      expect(addons).toEqual(
        expect.arrayContaining([
          expect.objectContaining({ value: 1, price: 100.0, label: '1 on 1 with Snape ($100.00 AUD)' }),
          expect.objectContaining({ value: 2, price: 35.0, label: 'Advanced session ($35.00 AUD)' }),
          expect.objectContaining({ value: 3, price: 10.0, label: 'Beginner addon ($10.00 AUD)' })
        ])
      );
    });
    test('when there is no delegate type', () => {
      const noAddons = getAddonOptionsByType(undefined);

      expect(noAddons).toEqual(undefined);
    });
  });

  describe('calculateRowPrice', () => {
    const delegateTypeOption: DelegateTypeOption = {
      value: 1,
      label: "option",
      price: 10.0
    };

    const addonOptions: AddonOption[] = [
      {
      value: 1,
      label: "option",
      price: 10.0,
      },
      {
        value: 2,
        label: "string",
        price: 10.0
      }
    ];

    test('calculates the correct row price for selected delegate type and addons', () => {
      const calculatedPrice = calculateRowPrice(delegateTypeOption, addonOptions);

      expect(calculatedPrice).toEqual(30.0);
    });
  });

  describe('mapOption', () => {
    test('maps delegate type to have the correct option format', () => {
      const delegateTypeOption = mapOption(ravenClaw);

      expect(delegateTypeOption.value).toEqual(ravenClaw.id);
      expect(delegateTypeOption.label).toEqual(ravenClaw.name);
      expect(delegateTypeOption.price).toEqual(ravenClaw.price);
    });
    test('maps addons to have the correct option format', () => {
      const addon = ravenClaw.addons[0];
      const addonOption = mapOption(addon);

      expect(addonOption.value).toEqual(addon.id);
      expect(addonOption.label).toEqual(addon.name);
      expect(addonOption.price).toEqual(addon.price);
    });
  });
});
