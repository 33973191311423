import AppAdapter from "../../../utils/adapter";
import CONFIG from "../../../utils/config";
import RestClient from "../../../utils/restClient";

class SessionService {
  static async getSession(sessionId: string) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.SESSIONS}/${sessionId}`);
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async updateSession({ sessionId, ...rest }) {
    try {
      this.transformPayload(rest);
      const response = await RestClient.patch(
        `${CONFIG.URLS.SESSIONS}/${sessionId}`,
        { session: AppAdapter.snakeCaseKeys(rest) }
      );
      const { ok: responseOk } = response;
      if (!responseOk) response.message = "error in fetching";
      return response;
    } catch (e) {
      return { ok: false, message: "error in fetching", result: null };
    }
  }

  static transformPayload(payload) {
    this.transformTime(payload);
  }

  static transformTime(payload) {
    if (payload.time)
      payload.time = `${payload.time.toDateString()} ${payload.time}`;
  }

  static transform(record) {
    record["time"] = new Date(record["startTime"]);
    return record;
  }
}

export default SessionService;
