import React from "react";
import * as mockReactRouterDom from "react-router-dom";
import {
  FAKE_ACTIVE_STATUS_OBJ,
  FAKE_COMPLETE_STATUS_OBJ,
  FAKE_ENABLED_STATUS_OBJ,
  FAKE_EVENT,
} from "../test-utils";
import {
  EventContextType,
  PageStatusContextType,
} from "../../EventTools/pages/Event/Event";
import * as Event from "../../EventTools/pages/Event/Event";
import { render } from "@testing-library/react";
import ProgressProvider from "./ProgressProvider";
import ProgressService from "../services/progressService";

const mockUseEvent = jest.spyOn(Event, "useEvent");
const mockUsePageStatus = jest.spyOn(Event, "usePageStatus");

const mockEventContext: EventContextType = {
  event: FAKE_EVENT,
  setEvent: jest.fn(),
};

const mockUsePageStatusContext: PageStatusContextType = {
  activePageStatus: FAKE_ACTIVE_STATUS_OBJ,
  completePageStatus: FAKE_COMPLETE_STATUS_OBJ,
  updateProgress: jest.fn(),
  enabledPageStatus: FAKE_ENABLED_STATUS_OBJ,
};

mockUseEvent.mockReturnValue(mockEventContext);
mockUsePageStatus.mockReturnValue(mockUsePageStatusContext);

const eventId = mockEventContext.event.id;

const mockNavigate = jest.fn();

jest.mock("react-router-dom", () => ({
  ...(jest.requireActual("react-router-dom") as typeof mockReactRouterDom),
  useNavigate: () => mockNavigate,
  useLocation: jest.fn(),
  useParams: () => {
    return { eventId: 1 };
  },
}));

const assignMock = jest.fn();

describe("ProgressProvider", () => {
  const mockStorage = {};
  let setItemSpy, getItemSpy;

  beforeAll(() => {
    setItemSpy = jest
      .spyOn(global.Storage.prototype, "setItem")
      .mockImplementation((key, value) => {
        mockStorage[key] = value;
      });
    getItemSpy = jest
      .spyOn(global.Storage.prototype, "getItem")
      .mockImplementation((key) => mockStorage[key]);
  });

  afterEach(() => {
    assignMock.mockClear();
    mockNavigate.mockClear();
  });

  afterAll(() => {
    getItemSpy.mockRestore();
    setItemSpy.mockRestore();
  });

  test("renders", () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar/details`,
    });
    expect(() =>
      render(
        <mockReactRouterDom.BrowserRouter>
          <ProgressProvider />
        </mockReactRouterDom.BrowserRouter>
      )
    ).not.toThrow();
  });

  test("redirects to current progress page when attempting to access later page", async () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar-website/support-email`,
    });
    ProgressService.setProgress("additionalFeatures", eventId);
    render(
      <mockReactRouterDom.BrowserRouter>
        <ProgressProvider />
      </mockReactRouterDom.BrowserRouter>
    );

    expect(mockNavigate).toHaveBeenCalledWith(
      `/admin/event-tools/${eventId}/webinar-website/additional-features`
    );
  });

  test("does not redirect when attempting to access page before and including progress page", async () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar-website/website-style`,
    });
    ProgressService.setProgress("additionalFeatures", eventId);
    render(
      <mockReactRouterDom.BrowserRouter>
        <ProgressProvider />
      </mockReactRouterDom.BrowserRouter>
    );

    expect(mockNavigate).not.toHaveBeenCalled();
  });

  test("does not redirect when progress is complete", async () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar-website/website-style`,
    });
    ProgressService.setProgress("completed", eventId);
    render(
      <mockReactRouterDom.BrowserRouter>
        <ProgressProvider />
      </mockReactRouterDom.BrowserRouter>
    );

    expect(mockNavigate).not.toHaveBeenCalled();
  });
});
