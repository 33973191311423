import { figmaTheme as fT } from "../figmaTheme";

const defaultStatusContainerProperties = {
  color: fT.colors.label.primary,
  fontWeight: fT.fontWeights.normal,
  bg: fT.colors.primary[90],
  borderRadius: fT.border.radius.lg,
  wordBreak: "break-all",
  w: 'fit-content',
  px: "16px",
};

export const tagTheme = {
  variants: {
    status: {
      container: {
        bg: fT.colors.yellow,
        color: "#F1F1F1",
        w: "fit-content",
        px: "10px",
        py: "5px",
        fontSize: "small",
        fontStyle: "italic",
        borderRadius: "3px",
      },
    },
    "card-status": {
      lineHeight: "14px",
      container: defaultStatusContainerProperties
    },
    "draft-status": {
      lineHeight: "14px",
      container: {
        ...defaultStatusContainerProperties,
        bg: fT.colors.yellow,
        margin: "0 0 8px 8px",
      },
    },
    "published-status": {
      lineHeight: "14px",
      container: {
        ...defaultStatusContainerProperties,
        color: fT.colors.white,
        bg:fT.colors.success,
        margin: "0 0 8px 8px",
      },
    },
    "card-detail": {
      container: {
        color: fT.colors.label.secondary,
      },
    },
    "host-meeting": {
      container: {
        ...defaultStatusContainerProperties,
        padding: "8px, 20px, 8px, 20px",
        borderRadius: "8px",
        height: "30px",
        color: fT.colors.white,
        bg: fT.colors.secondary.base
      }
    }
  },
};
