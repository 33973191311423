import * as React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from "react-i18next";

import {
  calculateRowPrice,
  getDelegateType,
  mapOption,
  getAddons,
  getAddonOptionsByType
} from './helpers';

import {
  AddonOption,
  DelegateGroup,
  DelegateGroupsAndTypes,
  DelegateGroupOptions,
  DelegateTypeOption,
  FormErrors,
  GroupRegistrationFormAttributes,
  GroupRegistrationRecord,
} from "./types";

import {
  groupBadgeStyles,
  groupStyles,
} from './styles';
import { setCurrencyFormat } from '../helpers/currencyFormatter';

type FormFieldProps = {
  index: number;
  formId: string;
  registrationRecord: GroupRegistrationRecord;
  delegateGroupsAndTypes: DelegateGroupsAndTypes;
  currencyCode: string;
  register: UseFormRegister<GroupRegistrationFormAttributes>;
  errors?: FormErrors;
  remove: (index?: number | number[]) => void;
  control: Control<GroupRegistrationFormAttributes, object>;
  canRemoveRow?: boolean;
};

const removeAttendeeButton = (
  canRemoveRow: boolean,
  index: number,
  remove: FormFieldProps["remove"]
) => {
  const { t } = useTranslation();

  if (canRemoveRow) {
    return (
      <div className='form-group col-1 d-flex justify-content-center align-items-center' style={{ height: 38 }}>
        <button
          type="button"
          className='dc-icons icon-trash remove-attendee-btn'
          data-testid={`remove-row-${index}`}
          onClick={() => remove(index)}
          aria-label={t('group_registrations.form.remove_attendee.label')}
        >
        </button>
      </div>
    );
  } else {
    return null;
  }
};

const FormFields = (props: FormFieldProps) => {
  const { t } = useTranslation();

  const { index, formId, registrationRecord, delegateGroupsAndTypes, currencyCode, register, remove, errors, control, canRemoveRow } = props;
  const {
    recordId,
    delegateTypeId,
    addonIds
  } = registrationRecord;
  const currencyFormatter = setCurrencyFormat(currencyCode);
  const delegateType = getDelegateType(delegateGroupsAndTypes, delegateTypeId);
  const addons = getAddons(delegateType, addonIds);
  const addonOptionsOnByType = getAddonOptionsByType(delegateType);

  const [selectedDelegateType, setDelegateType] = useState<DelegateTypeOption>(mapOption(delegateType));
  const [selectedAddons, setAddons] = useState<AddonOption[]>(addons?.map(addon => (mapOption(addon))));

  const [addonOptions, setAddonOptions] = useState<AddonOption[]>(addonOptionsOnByType);
  const [rowPrice, setRowPrice] = useState(0);

  useEffect(() => {
    setRowPrice(calculateRowPrice(selectedDelegateType, selectedAddons));
  }, [selectedDelegateType, selectedAddons]);

  const selectDelegateType = (selectedDelegateType: DelegateTypeOption) => {
    const delegateType = getDelegateType(delegateGroupsAndTypes, selectedDelegateType.value);

    setAddons([]);
    setDelegateType(selectedDelegateType);
    setAddonOptions(getAddonOptionsByType(delegateType));
  };

  const selectedAddon = (selectedAddon: AddonOption[]) => {
    setAddons(selectedAddon);
  };

  const delegateGroupOptions = (): DelegateGroupOptions[] => {
    const delegateGroups = delegateGroupsAndTypes.delegateGroups;
    if (delegateGroups?.length > 0) {
      return delegateGroups.map((group: DelegateGroup) => (
        {
          label: group.name,
          options: group?.delegateTypes.map(delegateType => (mapOption(delegateType)))
        }
      ));
    }
    else {
      return [
        {
          label: undefined,
          options: delegateGroupsAndTypes.delegateTypes.map(delegateType => (mapOption(delegateType)))
        }
      ];
    }
  };

  const formatGroupLabel = (groupOptions: DelegateGroupOptions) => (
    delegateGroupsAndTypes.delegateGroups.length > 0 &&
    <div style={groupStyles}>
      <span>{groupOptions.label}</span>
      <span style={groupBadgeStyles}>{groupOptions.options.length}</span>
    </div>
  );

  return (
    <>
      <div className='form-group col-2'>
        <input
          {...register(`records.${index}.positionId`)}
          form={formId}
          type='hidden'
          name={`records.${index}.positionId`}
          value={index}
        />

        <input
          {...register(`records.${index}.recordId`)}
          form={formId}
          type='hidden'
          name={`records.${index}.recordId`}
          value={recordId}
        />

        <input
          {...register(`records.${index}.firstName`)}
          form={formId}
          name={`records.${index}.firstName`}
          className='form-control text-left'
          style={{ height: 38 }}
          type='text'
          data-testid={`firstName-row-${index}`}
          aria-label={t("form.user_name.first_name.label")}
        />
        <span className='error js-error-field'>{errors?.firstName?.message}</span>
      </div>

      <div className='form-group col-2'>
        <input
          {...register(`records.${index}.lastName`)}
          form={formId}
          name={`records.${index}.lastName`}
          className='form-control text-left'
          style={{ height: 38 }}
          type="text"
          data-testid={`lastName-row-${index}`}
          aria-label={t("form.user_name.last_name.label")}
        />
        <span className='error js-error-field'>{errors?.lastName?.message}</span>
      </div>

      <div className='form-group col-2'>
        <input
          {...register(`records.${index}.email`)}
          form={formId}
          name={`records.${index}.email`}
          className='form-control text-left'
          style={{ height: 38 }}
          type="text"
          data-testid={`email-row-${index}`}
          aria-label={t("form.email.label")}
        />
        <span className='error js-error-field'>{errors?.email?.message}</span>
      </div>

      <div className='form-group col-sm-2' data-testid={`delegate-types-row-${index}`}>
        <Controller
          name={`records.${index}.delegateTypeId`}
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              form={formId}
              options={delegateGroupOptions()}
              isSearchable={false}
              formatGroupLabel={formatGroupLabel}
              value={selectedDelegateType}
              onChange={(option) => {
                onChange(option.value);
                selectDelegateType(option);
              }}
              getOptionLabel={(option) => option.label}
              aria-label={t("delegate_types.name")}
            />
          )}
        />
        <span className='error js-error-field'>{errors?.delegateTypeId?.message}</span>
      </div>

      <div className='form-group col-2' data-testid={`addons-row-${index}`}>
        <Controller
          name={`records.${index}.addonIds`}
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              isMulti
              form={formId}
              options={addonOptions}
              isSearchable={false}
              value={selectedAddons}
              onChange={(selected) => {
                selectedAddon([...selected]);
                onChange(selected.map(option => option.value));
              }}
              getOptionLabel={(option) => option.label}
              aria-label={t("group_registrations.form.addons.label")}
            />
          )}
        />
      </div>

      <div className='form-group col-1'>
        <input
          className='form-control'
          style={{ height: 38 }}
          type='text'
          value={currencyFormatter.format(rowPrice)}
          aria-label={t('group_registrations.form.price.label')}
          aria-readonly='true'
          data-testid={`cost-row-${index}`}
          readOnly />
      </div>

      {removeAttendeeButton(canRemoveRow, index, remove)}
    </>
  );
};

export default FormFields;
