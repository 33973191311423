import { hexColourRegEx } from "../../../EventWizard/helpers/customValidators";

const updateColourHistory = (
  oldColourHistory: Array<string>,
  colour: string
): Array<string> => {
  if (!colour.match(hexColourRegEx)) return oldColourHistory;

  const colourHistory = oldColourHistory.filter((value) => value !== colour);
  colourHistory.unshift(colour);
  return colourHistory.slice(0, 10);
};

export { updateColourHistory };
