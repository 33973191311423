import React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Spacer,
  Switch,
} from "@chakra-ui/react";
import { Warning } from "phosphor-react";

import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";

export type SwitchInputProps = {
  label: string;
  helperText?: string;
  errorMessage?: string;
};

const SwitchInput = React.forwardRef<HTMLInputElement, SwitchInputProps>(
  ({ label, helperText, errorMessage, ...props }, ref) => {
    return (
      <FormControl isInvalid={!!errorMessage}>
        <Flex>
          <div>
            <FormLabel>{label}</FormLabel>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </div>
          <Spacer />
          <Switch aria-label="toggle" ref={ref} {...props} />
        </Flex>
        {errorMessage && (
          <FormErrorMessage>
            <Icon as={Warning} weight="fill" color={fT.colors.alert} />
            {errorMessage}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

SwitchInput.displayName = "SwitchInput";
export default SwitchInput;
