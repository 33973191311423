import React from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';
import { CreateChannelIcon } from '../../assets';
import { X } from '@phosphor-icons/react';
import type { StreamChatGenerics } from '../../types';

type Props = {
  onCreateChannel?: () => void;
  toggleMobile: () => void;
};

const MessagingChannelListHeader = (props: Props) => {
  const { onCreateChannel, toggleMobile } = props;

  const { client } = useChatContext<StreamChatGenerics>();

  const { id, image, name } = client.user;

  return (
    <div className="messaging__channel-list__header">
      <Avatar image={image} name={name} size={40} />
      <div className={`messaging__channel-list__header__name`}>
        {name || id}
      </div>
      <button
        className={`messaging__channel-list__header__button`}
        onClick={onCreateChannel}
      >
        <CreateChannelIcon />
      </button>
      <button
        id="#mobile-collapse-icon"
        className={`messaging__channel-list__header__button stream-nav-collapse-icon`}
        onClick={toggleMobile}
      >
        <X />
      </button>
    </div>
  );
};

export default React.memo(MessagingChannelListHeader);
