import { SearchIcon } from '@chakra-ui/icons'
import { Flex, FormLabel, Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { debounce } from 'throttle-debounce';
import { SearchInputProps } from './types'

const SearchInput = ({ label, placeholder, searchHandler }: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchInProgress, setSearchInProgress] = useState(false)
  const throttledSearch = useCallback(
    debounce(
      300,
      async (value: string) => {
        await searchHandler(value);
        setSearchInProgress(false);
      }
    ), []);

  const handleInput = (value: string) => {
    setSearchInProgress(true)
    setSearchValue(value)
    throttledSearch(value)
  }

  const searchBarIcon = searchInProgress ? <Spinner size={'sm'} /> : <SearchIcon />;

  return (
    <Flex direction={'column'}>
      <FormLabel>
        {label}
      </FormLabel>

      <InputGroup maxWidth={'md'}>
        <InputRightElement pointerEvents='none'>
          {searchBarIcon}
        </InputRightElement>

        <Input
          type='text'
          placeholder={placeholder}
          onChange={(e) => handleInput(e.target.value)}
          name='admin-search'
          value={searchValue}
        />
      </InputGroup>
    </Flex>
  )
}

export default SearchInput
