import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { Warning } from "phosphor-react";
import { figmaTheme as fT } from "../../../react/dcTheme";

type WrappedComponentProps = {
  label: string;
  helperText: string;
  errorMessage: string;
  isRequired?: boolean;
  handleChange: (e: Record<string, string>) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

const withInputLabel = (WrappedComponent) => {
  const WithInputLabelWrappedComponent: React.FC<WrappedComponentProps> = ({
    label,
    helperText,
    errorMessage,
    isRequired = false,
    ...props
  }) => {
    const isError = errorMessage !== "";
    return (
      <FormControl
        isInvalid={isError}
        isRequired={isRequired}
      >
        <FormLabel>{label}</FormLabel>
        <FormHelperText mb={2} mt={0}>
          {helperText}
        </FormHelperText>
        <WrappedComponent isError={isError} {...props} />
        {isError && (
          <FormErrorMessage>
            <Icon as={Warning} weight="fill" color={fT.colors.alert} />
            {errorMessage}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  };

  return WithInputLabelWrappedComponent;
};

export default withInputLabel;
