import * as yup from "yup";
import { timeDiffValidator } from "../../../EventWizard/helpers/customValidators";

export default t => {
  return (yup
  .object({
    nicknameSchedule: yup.string(),
    days: yup
      .array()
      .required()
      .of(
        yup.object({
          date: yup.string().required(
            t("event_tools.validations.required", {
              field: t("days_set_up_form.date.name"),
            })
          ),
          startTime: yup.string().required(
            t("event_tools.validations.required", {
              field: t("days_set_up_form.start_time.name"),
            })
          ),
          endTime: yup
            .string()
            .required(
              t("event_tools.validations.required", {
                field: t("days_set_up_form.end_time.name"),
              })
            )
            .test(
              "afterStart",
              t("event_details.time.invalid_length"),
              timeDiffValidator
            ),
        })
      ),
  })
  .required());
};
