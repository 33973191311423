import React, { MutableRefObject, useEffect } from "react";
import { Box, Heading, Divider, Grid, GridItem, GridProps, useMultiStyleConfig } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import DCColourPicker from "../../common/DCColourPicker";
import FormControlLabel from "../../common/shared/FormControlLabel";
import { useTranslation } from "react-i18next";
import { EventType } from "../../../EventWizard/models/Event";
import { usePreviewData } from "../../Preview/PreviewContext";
import { LayoutForm } from "../../common/FormPageLayout";
import schemaGenerator from "./formSchema";
import { useSchema } from "../../../helpers/useSchema";

export type CustomStylingSectionFormProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
}

const CustomStylingSectionForm: React.FC<CustomStylingSectionFormProps> = ({ event, submitForm, handleErrors, formErrors }) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);
  
  const styles = useMultiStyleConfig("BrandingPage", {});
  const {
    featureBackgroundColour,
    featureBackgroundSecondaryColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundMenuColour,
    featureTextColour,
    featureIconColour,
    featureBackgroundSideMenuColour,
    featureSideMenuTextColour,
    featureSideMenuIconColour,
    featureProfileSideMenuTextColour,
    featureScrollLoadColour,
    primaryColour,
    secondaryColour,
    footerText
  } = event;

  const defaultValues = {
    featureBackgroundColour,
    featureBackgroundSecondaryColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundMenuColour,
    featureTextColour,
    featureIconColour,
    featureBackgroundSideMenuColour,
    featureSideMenuTextColour,
    featureSideMenuIconColour,
    featureProfileSideMenuTextColour,
    featureScrollLoadColour
  };

  const { control, watch, handleSubmit, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: 'all',
    defaultValues,
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  const { setActiveComponent, setAttribute } = usePreviewData({
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText
  });

  useEffect(() => {
    const mapping = {
      featureBackgroundColour: 'featureBackgroundColour',
      featureHeaderTextColour: 'featureHeaderTextColour',
      featureSectionHeaderTextColour: 'featureSectionHeaderTextColour',
      featureBackgroundSideMenuColour: 'featureBackgroundSideMenuColour',
      featureBackgroundMenuColour: 'featureBackgroundMenuColour'
    };
    const subscription = watch((value, { name }) => {
      if (mapping[name]) {
        setAttribute(mapping[name], value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setAttribute('featureBackgroundColour', featureBackgroundColour);
  }, [featureBackgroundColour]);

  useEffect(() => {
    setAttribute('featureHeaderTextColour', featureHeaderTextColour);
  }, [featureHeaderTextColour]);

  useEffect(() => {
    setAttribute('featureSectionHeaderTextColour', featureSectionHeaderTextColour);
  }, [featureSectionHeaderTextColour]);

  useEffect(() => {
    setAttribute('featureBackgroundSideMenuColour', featureBackgroundSideMenuColour);
  }, [featureBackgroundSideMenuColour]);

  useEffect(() => {
    setAttribute('featureBackgroundMenuColour', featureBackgroundMenuColour);
  }, [featureBackgroundMenuColour]);

  return(
    <LayoutForm onSubmit={(e) => e.preventDefault()}>
      <Box>
        <Heading as="h6" size="md" variant="form-heading">
          {t("events.form.customise_sections.headers.name")}
        </Heading>
        <Grid {...styles.eventsStylingSectionBoxGrid as GridProps}>
          <GridItem>
            <FormControlLabel
              label={t('events.form.styling.header_background_colour.label')}
              error={errors.featureBackgroundColour}
              helperText={t("events.form.styling.header_background_colour.hint")}
              name={"featureBackgroundColour"}
            >
              <Controller
                control={control}
                name="featureBackgroundColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Home Banner')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.header_gradient_colour.label")}
              error={errors.featureBackgroundSecondaryColour}
              helperText={t("events.form.styling.header_gradient_colour.hint")}
              name={"featureBackgroundSecondaryColour"}
            >
              <Controller
                control={control}
                name="featureBackgroundSecondaryColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Home Banner')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.header_text_colour.label")}
              error={errors.featureHeaderTextColour}
              helperText={"\u00A0"}
              name={"featureHeaderTextColour"}
            >
              <Controller
                control={control}
                name="featureHeaderTextColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Headline')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.section_header_text_colour.label")}
              error={errors.featureSectionHeaderTextColour}
              helperText={t("events.form.styling.section_header_text_colour.hint")}
              name={"featureSectionHeaderTextColour"}
            >
              <Controller
                control={control}
                name="featureSectionHeaderTextColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Section Header')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
        </Grid>
        <Divider __css={styles.eventFormDivider} />
        <Heading as="h6" size="md" variant="form-heading">
          {t("events.form.customise_sections.top_menu.name")}
        </Heading>
        <Grid {...styles.eventsStylingSectionBoxGrid as GridProps}>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.top_nav_menu_background_colour.label")}
              error={errors.featureBackgroundMenuColour}
              name={"featureBackgroundMenuColour"}
            >
              <Controller
                control={control}
                name="featureBackgroundMenuColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Top Nav BG')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.top_menu_text_colour.label")}
              error={errors.featureTextColour}
              name={"featureTextColour"}
            >
              <Controller
                control={control}
                name="featureTextColour"
                render={({ field: { onChange, value, ref, name, onBlur} }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Top Nav BG')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.top_menu_icon_colour.label")}
              error={errors.featureIconColour}
              name={"featureIconColour"}
            >
              <Controller
                control={control}
                name="featureIconColour"
                render={({ field: { onChange, value, ref, name } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Top Nav BG')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
        </Grid>
        <Divider __css={styles.eventFormDivider} />
        <Heading as="h6" size="md" variant="form-heading">
          {t("events.form.customise_sections.side_menu.name")}
        </Heading>
        <Grid {...styles.eventsStylingSectionBoxGrid as GridProps}>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.side_menu_background_colour.label")}
              error={errors.featureBackgroundSideMenuColour}
              name={"featureBackgroundSideMenuColour"}
            >
              <Controller
                control={control}
                name="featureBackgroundSideMenuColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Left Nav BG')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.side_menu_text_colour.label")}
              error={errors.featureSideMenuTextColour}
              name={"featureSideMenuTextColour"}
            >
              <Controller
                control={control}
                name="featureSideMenuTextColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Left Nav BG')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.side_menu_icon_colour.label")}
              error={errors.featureSideMenuIconColour}
              name={"featureSideMenuIconColour"}
            >
              <Controller
                control={control}
                name="featureSideMenuIconColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Left Nav BG')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
        </Grid>
        <Divider __css={styles.eventFormDivider} />
        <Heading as="h6" size="md" variant="form-heading">
          {t("events.form.customise_sections.profile_side_menu.name")}
        </Heading>
        <Grid {...styles.eventsStylingSectionBoxGrid as GridProps}>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.profile_side_menu_text_colour.label")}
              error={errors.featureProfileSideMenuTextColour}
              name={"featureProfileSideMenuTextColour"}
            >
              <Controller
                control={control}
                name="featureProfileSideMenuTextColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    onOpen={() => setActiveComponent('preview_svg_Nav_2')}
                    onClose={() => setActiveComponent(null)}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
        </Grid>
        <Divider __css={styles.eventFormDivider} />
        <Heading as="h6" size="md" variant="form-heading">
          {t("events.form.customise_sections.scroll_and_loading_bar.name")}
        </Heading>
        <Grid {...styles.eventsStylingSectionBoxGrid as GridProps}>
          <GridItem>
            <FormControlLabel
              label={t("events.form.styling.scroll_and_loading_bar_colour.label")}
              error={errors.featureScrollLoadColour}
              name={"featureScrollLoadColour"}
            >
              <Controller
                control={control}
                name="featureScrollLoadColour"
                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                  <DCColourPicker
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    id={name}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControlLabel>
          </GridItem>
        </Grid>
      </Box>
    </LayoutForm>
  );
};

export default CustomStylingSectionForm;
