import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, SimpleGrid, Textarea } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../common/shared/FormControlLabel";
import { LayoutForm } from "../../common/FormPageLayout";
import DCFileUploader from "../../DCFileUploader";
import schemaGenerator from "./formSchema";
import { UserFormProps } from "..";
import { useSchema } from "../../../helpers/useSchema";

export const UserForm: React.FC<UserFormProps> = ({
  user,
  submitForm,
  handleErrors,
  formErrors,
}) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const {
    firstName,
    lastName,
    bio,
    jobTitle,
    pronoun,
    email,
    phone,
    company,
    location,
    profile,
    profileUrl,
  } = user;

  const defaultValues = {
    firstName,
    lastName,
    bio,
    jobTitle,
    pronoun,
    email,
    phone,
    company,
    location,
    profile,
  };

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  const saveProfilePic = (file: File) => {
    setValue("profile", [file]);
  };

  return (
    <LayoutForm onSubmit={e => e.preventDefault()}>
      <SimpleGrid columns={2} gap={4}>
        <FormControlLabel
          label={t("user_form.first_name.label")}
          error={errors.firstName}
          isRequired
        >
          <Input {...register("firstName")} />
        </FormControlLabel>
        <FormControlLabel
          label={t("user_form.last_name.label")}
          error={errors.lastName}
          isRequired
        >
          <Input {...register("lastName")} />
        </FormControlLabel>
        <FormControlLabel
          label={t("user_form.pronoun.label")}
          error={errors.pronoun}
        >
          <Input {...register("pronoun")} />
        </FormControlLabel>
        <FormControlLabel
          label={t("user_form.job_title.label")}
          error={errors.jobTitle}
        >
          <Input {...register("jobTitle")} />
        </FormControlLabel>
      </SimpleGrid>

      <FormControlLabel label={t("user_form.bio.label")} error={errors.bio}>
        <Textarea {...register("bio")} />
      </FormControlLabel>

      <SimpleGrid columns={2} gap={4}>
        <FormControlLabel
          label={t("user_form.email.label")}
          error={errors.email}
          isRequired
        >
          <Input {...register("email")} isDisabled={!!user.id} />
        </FormControlLabel>
        <FormControlLabel
          label={t("user_form.phone.label")}
          error={errors.phone}
        >
          <Input {...register("phone")} />
        </FormControlLabel>
        <FormControlLabel
          label={t("user_form.company.label")}
          error={errors.company}
        >
          <Input {...register("company")} />
        </FormControlLabel>
        <FormControlLabel
          label={t("user_form.location.label")}
          error={errors.location}
        >
          <Input {...register("location")} />
        </FormControlLabel>
      </SimpleGrid>

      <FormControlLabel name="profile" label={t("user_form.profile.label")}>
        <DCFileUploader
          acceptedFormats="image/*"
          aspectRatio={1}
          maxSize={10000000}
          uploadPhoto={saveProfilePic}
          source={profileUrl}
        />
      </FormControlLabel>
    </LayoutForm>
  );
};

export default UserForm;
