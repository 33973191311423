import React from "react";
import { Flex, Button, useCheckbox } from "@chakra-ui/react";
import { UseRadioProps } from "@chakra-ui/radio/src/use-radio";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "phosphor-react";
import { bgColourProps } from "../../utils";

interface Props extends UseRadioProps {
  showResult: boolean;
  votes: number;
  percentage: number;
  existingResponse: string;
}

const MSOption: React.FC<Props> = ({
  showResult,
  votes,
  percentage,
  existingResponse,
  value,
  ...rest
}): JSX.Element => {
  const { t } = useTranslation();
  const { getInputProps, getCheckboxProps } = useCheckbox({ value, ...rest });

  const isExistingResponse = existingResponse
    ?.split(",")
    ?.includes(value.toString());
  const [hiddenResultsColorProps, resultsColorProps] = bgColourProps(
    isExistingResponse,
    percentage
  );

  return (
    <Flex as="label" justifyContent="center" alignItems="center" my={0}>
      <input {...getInputProps()} aria-label={value.toString()} />
      {value && isExistingResponse && (
        <Flex alignItems="center">
          <Flex pos="absolute" zIndex={1} pl={2}>
            <CheckCircle
              weight="fill"
              size={24}
              opacity={rest.isDisabled ? 0.7 : 1}
            />
          </Flex>
        </Flex>
      )}
      <Button
        as="div"
        whiteSpace="break-spaces"
        wordBreak="break-all"
        h="fit-content"
        py={2}
        opacity="100%"
        variant="outline"
        cursor="pointer"
        w="100%"
        pl={34}
        rightIcon={
          showResult && (
            <>
              ({votes}{'%'} {t("pollings.votes")})
            </>
          )
        }
        {...getCheckboxProps()}
        {...(!showResult && hiddenResultsColorProps)}
        {...(showResult && resultsColorProps)}
      >
        {value}
      </Button>
    </Flex>
  );
};

export default MSOption;
