import React from "react";
import {
  Flex,
  Text,
  Heading,
  Input,
  Button,
  Icon,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TrashSimple, Plus } from "phosphor-react";
import moment from "moment";
import FormControlLabel from "../../common/shared/FormControlLabel";
import DayForm from "./DayForm";
import { getAvailableDays } from "./utils";
import { DayValues } from "./types";
import schemaGenerator from "./formSchema";
import { useSchema } from "../../../helpers/useSchema";

export type DaysSetUpFormValues = {
  nicknameSchedule: string;
  days?: DayValues[];
};

export type DaysSetUpFormProps = {
  startDate: string;
  endDate: string;
  defaultValues: DaysSetUpFormValues;
  onSubmit?: (values: DaysSetUpFormValues) => Promise<void>;
};

const DaysSetUpForm: React.FC<DaysSetUpFormProps> = ({
  onSubmit,
  startDate,
  endDate,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "days",
  });

  const days = watch("days");
  const existingDays = getValues("days")?.map(day => day.date);

  const availableDays = getAvailableDays(startDate, endDate, existingDays);

  const handleAddDay = () => {
    if (availableDays.length > 0) {
      append({
        startTime: days[days.length - 1]?.startTime || "09:00",
        endTime: days[days.length - 1]?.endTime || "17:00",
        date: availableDays[0],
      });
    }
  };

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...days[index],
    };
  });

  const indexes = new Map(controlledFields.map(({ id }, index) => [id, index]));

  return (
    <Flex as="form" onSubmit={handleSubmit(onSubmit)} flexDir="column">
      <FormControlLabel
        label={t("days_set_up_form.nickname_schedule.label")}
        error={errors.nicknameSchedule}
        name="nicknameSchedule"
      >
        <Input {...register("nicknameSchedule")} id="nicknameSchedule" />
      </FormControlLabel>
      <Flex justifyContent="space-between" align="center">
        <Box>
          <Heading size="sm" mb={2} fontFamily="body" fontWeight="medium">
            {t("days_set_up_form.event_date")}
          </Heading>
          <Text mb={2} color="gray.600">
            {moment(startDate).format("LL")} - {moment(endDate).format("LL")}
          </Text>
        </Box>
        <Button
          variant="secondary"
          leftIcon={<Icon as={Plus} weight="bold" boxSize="16px" />}
          onClick={handleAddDay}
          isDisabled={availableDays.length <= 0}
        >
          {t("days_set_up_form.add_day_label")}
        </Button>
      </Flex>
      {controlledFields
        .sort((a, b) => {
          return moment(a.date).unix() - moment(b.date).unix();
        })
        .map((field, index) => {
          return (
            <Flex
              key={field.id}
              justifyContent="space-between"
              alignItems="center"
            >
              <DayForm
                day={`${t("days_set_up_form.day_label")} - ${index + 1}`}
                availableDays={availableDays.map(date => {
                  const formattedDate = moment(date).format("YYYY-MM-DD");
                  return { value: date, label: formattedDate };
                })}
                dayValues={field}
                errors={errors?.days?.[indexes.get(field.id)] as FieldErrors}
                nestIndex={indexes.get(field.id)}
                setValue={setValue}
                trigger={trigger}
              />
              <IconButton
                aria-label={t("days_set_up_form.remove_day_label")}
                variant="ghost"
                icon={<Icon as={TrashSimple} weight="fill" boxSize="24px" />}
                onClick={() => remove(indexes.get(field.id))}
              />
            </Flex>
          );
        })}
      <Button type="submit" mt={2}>
        TEST SUBMIT
      </Button>
    </Flex>
  );
};

export default DaysSetUpForm;
