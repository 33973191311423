import { useInterval } from "@chakra-ui/react";

interface Props {
  setResults: () => void;
  delay: number;
}

const ResultsFetcher = ({
  setResults,
  delay
}: Props): JSX.Element => {
  useInterval(() => {
    setResults();
  }, delay);

  return null;
};

export default ResultsFetcher;
