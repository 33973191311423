import React, { MutableRefObject, useEffect } from "react";
import { Box, Heading, Input, FormHelperText, FormControl, Flex, Spacer, Switch } from "@chakra-ui/react";
import FormControlLabel from "../../common/shared/FormControlLabel";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EventType } from "../../../EventWizard/models/Event";
import { LayoutForm } from "../../common/FormPageLayout";
import { usePreviewData } from "../../Preview";
import schemaGenerator from "./formSchema";
import { useSchema } from "../../../helpers/useSchema";

export type AdditionalFeaturesProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
};

const AdditionalFeatures: React.FC<AdditionalFeaturesProps> = ({ event, submitForm, handleErrors, formErrors }) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const { 
    homePageSections, 
    homeFeaturedVideoUrl,
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  } = event;

  const defaultValues = { homePageSections, homeFeaturedVideoUrl };

  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  usePreviewData({
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  });

  return (
    <Box>
      <LayoutForm onSubmit={e => e.preventDefault()}>
        <Box py={8}>
          <Heading as="h6" size="sm" mb="8px" variant="form-heading">
            {t(
              "events.form.additional_features.display_event_date_location.toggle_label"
            )}
          </Heading>
          <Flex>
            <FormControl>
              <FormHelperText>
                {t(
                  "events.form.additional_features.display_event_date_location.toggle_helper"
                )}
              </FormHelperText>
            </FormControl>
            <Spacer />
            <Switch id='location-toggle' {...register("homePageSections.location.enabled")} aria-label="toggle" />
            <Input hidden {...register("homePageSections.location.id")} />
          </Flex>
        </Box>
        <Box py={8}>
          <Heading as="h6" size="sm" mb="8px" variant="form-heading">
            {t(
              "events.form.additional_features.display_featured_video.toggle_label"
            )}
          </Heading>
          <Flex>
            <FormControl>
              <FormHelperText>
                {t(
                  "events.form.additional_features.display_featured_video.toggle_helper"
                )}
              </FormHelperText>
            </FormControl>
            <Spacer />
            <Switch id='featured-video-toggle' {...register("homePageSections.featuredVideo.enabled")} aria-label="toggle" />
            <Input hidden {...register("homePageSections.featuredVideo.id")} />
          </Flex>

          <FormControlLabel
            label={t(
              "events.form.additional_features.display_featured_video.input_label"
            )}
            error={errors.homeFeaturedVideoUrl}
          >
            <Input
              {...register("homeFeaturedVideoUrl")}
              placeholder="https://vimeo.com/525240647"
              htmlSize={33}
              width="auto"
            />
          </FormControlLabel>
        </Box>
      </LayoutForm>
    </Box>
  );
};

export default AdditionalFeatures;
