import React from "react";
import { HStack, useRadioGroup } from "@chakra-ui/react";
import ColorRadioButton from "./ColourRadioButton";
import { noop } from "../../../../utils";


interface ColorOption {
  name: string;
  hexCode: string;
}

interface Props {
  colors: Array<ColorOption>;
  fieldName: string;
  defaultValue: ColorOption;
  onChange: (any) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const ColorRadioButtons: React.FunctionComponent<Props> = ({
  colors,
  fieldName,
  defaultValue,
  onChange,
  onMouseOver = noop,
  onMouseOut = noop
}: Props): JSX.Element => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: fieldName,
    defaultValue: defaultValue.hexCode,
    onChange: onChange,
  });

  return (
    <HStack onMouseOver={onMouseOver} onMouseOut={onMouseOut} {...getRootProps()}>
      {colors.map((color) => {
        const { name, hexCode } = color;
        return (
          <ColorRadioButton key={hexCode} {...getRadioProps({ value: hexCode })}>
            {name}
          </ColorRadioButton>
        );
      })}
    </HStack>
  );
};

export default ColorRadioButtons;
