import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { figmaTheme as fT } from "../figmaTheme";

export const eventsListTheme: ComponentStyleConfig = {
  parts: [
    "headingContainer",
    "headingText",
    "subHeadingContainer",
    "subheadingText",
    "cardContainer",
    "createFirstEventCard",
  ],
  baseStyle: {
    headingContainer: {
      paddingLeft: { base: "0px", md: "40px" },
      height: "80px",
      borderBottom: `1px solid ${fT.colors.grey.base}`,
      alignItems: "center",
      justify: { base: "center", md: "initial" },
    },
    headingText: {
      fontSize: "20px",
    },
    subHeadingContainer: {
      height: "104px",
      padding: "0 40px",
      alignItems: "center",
    },
    subHeadingText: {
      fontSize: "16px",
      fontWeight: "bold",
      textAlign: { base: "center", md: "left" },
    },
    cardContainer: {
      padding: "0 40px",
      flexWrap: "wrap",
    },
    createFirstEventCard: {
      height: "235px",
      padding: "0 40px",
      alignItems: "center",
      bg: fT.colors.white,
      flexDir: { base: "column", md: "row" },
      mt: 5,
    },
  },
};
