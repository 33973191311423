import React, { useState, useRef, RefObject } from "react";
import { MagnifyingGlass } from "phosphor-react";
import styled, { keyframes } from "styled-components";
import useOnClickOutside from "use-onclickoutside";
import { useTranslation } from "react-i18next";

import SearchResultsView from "./SearchResultsView";
import SearchDataFetcher from "../SearchDataFetcher";
import { FetcherProps, SearchProp } from "../types";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  display: ${props => (props.active ? "flex" : "none")};
  align-items: center;
  position: relative;
  opacity: 1;
  padding-top: 4px;
  background: ${props => props.bgColor};
  color: ${props => props.textColor};
  font-family: Montserrat;
`;
const LockedContainer = styled(Container)`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  animation: ${fadeIn} 0.5s linear;
  width: 70%;
  margin-right: 20px;
  z-index: 1;
`;
const Anchor = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  top: 96px;
  width: 100%;
`;
const Button = styled.div`
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  display: flex;
  opacity: 0.7;
`;
const SearchInput = styled.input`
  display: flex;
  flex-grow: 1;
  background: ${props => props.bgColor};
  padding: 20px;
  border: none;
  corner-radius: 4px;
  color: ${props => props.textColor};
  font-size: 12px;
  filter: contrast(80%);
  ::placeholder {
    color: ${props => props.textColor};
  }
`;

type SearchFieldProps = {
  onSearch: SearchProp;
  fieldRef: RefObject<HTMLInputElement>;
  bgColor: string;
  textColor: string;
};

function SearchField({
  onSearch,
  fieldRef,
  bgColor,
  textColor,
}: SearchFieldProps): JSX.Element {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <SearchInput
      placeholder={t("actions.search_placeholder")}
      value={searchValue}
      onChange={updateSearchValue}
      ref={fieldRef}
      bgColor={bgColor}
      textColor={textColor}
    />
  );
}

type DesktopSearchProps = {
  bgColor: string;
  textColor: string;
  dataFetcher: (children: FetcherProps) => JSX.Element;
};
function DesktopSearch({
  bgColor,
  textColor,
  dataFetcher = SearchDataFetcher,
}: DesktopSearchProps) {
  const { t } = useTranslation();
  const launchButtonRef = useRef<HTMLButtonElement>(null);
  const searchFieldRef = useRef<HTMLInputElement>(null);

  const [active, setActive] = useState(false);
  const toggleActive = () => {
    active ? makeInactive() : makeActive();
  };
  const makeActive = () => {
    setActive(true);
    setTimeout(() => searchFieldRef.current.focus(), 0);
  };
  const makeInactive = () => {
    setActive(false);
    setTimeout(() => launchButtonRef.current.focus(), 0);
  };
  const handleOpenKeyDown = keyEvent => {
    if (keyEvent.key == "Escape") {
      makeInactive();
    }
  };
  const handleClosedKeyDown = keyEvent => {
    if (keyEvent.key == "Enter") {
      makeActive();
    }
  };

  const outsideClickRef = React.useRef(null);
  useOnClickOutside(outsideClickRef, () => setActive(false));

  return dataFetcher((onSearch, searchResults) => (
    <>
      <LockedContainer
        bgColor={bgColor}
        ref={outsideClickRef}
        onKeyDown={handleOpenKeyDown}
        active={active}>
        <Button
          active={active}
          onClick={toggleActive}
          className="dc-icons feature_icon_colour">
          <MagnifyingGlass size={32} weight="fill" />
        </Button>
        <SearchField
          onSearch={onSearch}
          fieldRef={searchFieldRef}
          bgColor={bgColor}
          textColor={textColor}
        />
        <Anchor>
          <SearchResultsView
            bgColor={bgColor}
            textColor={textColor}
            searchResults={searchResults}
          />
        </Anchor>
      </LockedContainer>
      <Container active={!active} onKeyDown={handleClosedKeyDown}>
        <Button
          active={active}
          onClick={toggleActive}
          className="dc-icons feature_icon_colour"
          tabIndex={0}
          ref={launchButtonRef}>
          <MagnifyingGlass size={32} weight="fill" />
          <div className="label" style={{ color: textColor }}>
            {t("actions.search")}
          </div>
        </Button>
      </Container>
    </>
  ));
}

DesktopSearch.displayName = "DesktopSearch";

export default DesktopSearch;
