import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeft } from "phosphor-react";
import { Button, Flex, Spacer, Text, Box } from "@chakra-ui/react";

import PageLayout from "../../../../common/PageLayout";
import { useEvent } from "../../../../pages/Event";
import UsersTable from "../../../../tables/UsersTable";
import { figmaTheme as fT } from "../../../../../react/dcTheme";
import FacilitatorService from "../../../../../EventWizard/services/facilitatorService";

const FacilitatorPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { event } = useEvent();
  const navigate = useNavigate();
  const [facilitators, setFacilitators] = useState([]);

  const updateFacilitators = async () => {
    const { result, ok } = await FacilitatorService.list(
      event.sessionIds.at(-1)
    );
    if (ok) {
      setFacilitators(
        result.data.map((sessionFacilitator) => ({
          ...sessionFacilitator.attributes.facilitator.data.attributes,
          sessionFacilitatorId: sessionFacilitator.id,
        }))
      );
    }
  };

  const handleDelete = (sessionFacilitatorId: string) => {
    FacilitatorService.delete(sessionFacilitatorId);
    updateFacilitators();
  };

  useEffect(() => {
    updateFacilitators();
  }, []);

  return (
    <PageLayout event={event}>
      <Flex flexDir="column" flex="auto" px={10} py={2}>
        <Flex py={5} align="center">
          <Text fontWeight="700">{t("facilitator_page.page_title")} </Text>
          <Spacer />
          <Button>+ {t("facilitator_page.add_facilitator")}</Button>
        </Flex>
        <Box flexGrow={1}>
          <UsersTable
            data={facilitators}
            handleEdit={() => console.log("EDIT")}
            handleDelete={handleDelete}
          />
        </Box>
      </Flex>
      <Flex
        align="center"
        py={5}
        px={10}
        borderTop="1px"
        borderTopColor={fT.colors.grey.base}
        bg={fT.colors.primary[100]}
      >
        <Button
          m={2}
          variant="ghost"
          leftIcon={<ArrowLeft weight="fill" />}
          onClick={() => navigate("/admin/event-tools")}
        >
          {t("views.pagination.previous_item")}
        </Button>
        <Spacer />
        <Button m={2} onClick={() => navigate("/admin/event-tools")}>
          {t("event_tools.buttons.next")}
        </Button>
      </Flex>
    </PageLayout>
  );
};

export default FacilitatorPage;
