export type WebinarPage =
  | "webinarDetails"
  // | "attendeeConfigurations"
  | "agenda"
  | "websiteStyle"
  | "customStyling"
  | "heroSection"
  | "additionalFeatures"
  | "addSections"
  | "footer"
  | "supportEmail"
  | "metadata"
  | "publish";
export type WebinarGroup = "webinarDetailsGroup" | "webinarWebsite";
export type WebinarSubGroup = "brandAndStyle" | "homePage" | "configurations";
export type WebinarGroupType = {
  path?: string;
  children: WebinarPage[];
};
export type WebinarSubGroupType = {
  path: string;
  children: WebinarPage[];
  group: WebinarGroup;
};
export type WebinarPageType = {
  step: number;
  group?: WebinarGroup;
  subGroup?: WebinarSubGroup;
  path: string;
};
export type WebinarPagesType = { [key in WebinarPage]: WebinarPageType };
export type WebinarConfigType = {
  groups: { [key in WebinarGroup]: WebinarGroupType };
  subGroups: { [key in WebinarSubGroup]: WebinarSubGroupType };
  pages: WebinarPagesType;
};

export type WebinarStatusType = {
  groups: { [key in WebinarGroup]: boolean };
  subGroups: { [key in WebinarSubGroup]: boolean };
  pages: { [key in WebinarPage]: boolean };
};

const WEBINAR_CONFIG: WebinarConfigType = {
  groups: {
    webinarDetailsGroup: {
      path: "/webinar/details",
      children: ["webinarDetails"],
    },
    webinarWebsite: {
      children: [
        "websiteStyle",
        "customStyling",
        "heroSection",
        "additionalFeatures",
        "addSections",
        "footer",
        "supportEmail",
        "metadata",
      ],
    },
  },
  subGroups: {
    brandAndStyle: {
      group: "webinarWebsite",
      path: "/webinar-website/website-style",
      children: ["websiteStyle", "customStyling"],
    },
    homePage: {
      group: "webinarWebsite",
      path: "/webinar-website/hero-section",
      children: ["heroSection", "additionalFeatures", "addSections", "footer"],
    },
    configurations: {
      group: "webinarWebsite",
      path: "/webinar-website/support-email",
      children: ["supportEmail", "metadata"],
    },
  },
  pages: {
    webinarDetails: {
      step: 1,
      group: "webinarDetailsGroup",
      path: "/webinar/details",
    },
    // attendeeConfigurations: {
    //   step: 2,
    //   group: "webinarDetailsGroup",
    //   path: "/webinar/attendee-configurations",
    // },
    agenda: { step: 3, path: "/agenda" },
    websiteStyle: {
      step: 4,
      group: "webinarWebsite",
      subGroup: "brandAndStyle",
      path: "/webinar-website/website-style",
    },
    customStyling: {
      step: 5,
      group: "webinarWebsite",
      subGroup: "brandAndStyle",
      path: "/webinar-website/custom-styling",
    },
    heroSection: {
      step: 6,
      group: "webinarWebsite",
      subGroup: "homePage",
      path: "/webinar-website/hero-section",
    },
    additionalFeatures: {
      step: 7,
      group: "webinarWebsite",
      subGroup: "homePage",
      path: "/webinar-website/additional-features",
    },
    addSections: {
      step: 8,
      group: "webinarWebsite",
      subGroup: "homePage",
      path: "/webinar-website/add-sections",
    },
    footer: {
      step: 9,
      group: "webinarWebsite",
      subGroup: "homePage",
      path: "/webinar-website/footer",
    },
    supportEmail: {
      step: 10,
      group: "webinarWebsite",
      subGroup: "configurations",
      path: "/webinar-website/support-email",
    },
    metadata: {
      step: 11,
      group: "webinarWebsite",
      subGroup: "configurations",
      path: "/webinar-website/metadata",
    },
    publish: {
      step: 12,
      path: "/webinar/publish",
    },
  },
};

export { WEBINAR_CONFIG };
