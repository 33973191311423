import React from "react";
import styled from "styled-components";
import { CaretDown } from "phosphor-react";
import { IconEntry } from "../../phosphorIcons/types";
import { useTranslation } from "react-i18next";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openDropdown: any;
  selectedIcon: IconEntry;
  inputId: string;
  inputFor: string;
  inputName: string;
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: any;
  disabled: boolean;
  required: boolean;
}

const SearchBar = styled.div<Pick<Props, 'disabled'>>`
  border: 1px solid #ababab;
  border-radius: 5px;
  width: 100%;
  display: flex;
  padding: 0 10px;

  &:focus-within {
    outline: #ff5621 auto 5px;
  }

  background-color: ${p => p.disabled ? "rgba(29, 37, 59, 0.1)" : "transparent"};
`;

const SearchInput = styled.input<Pick<Props, 'disabled'>>`
  padding: 0 5px;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 40px;
  opacity: 0.7;
  font-size: 14px;
  line-height: 17px;

  &:focus {
    outline: transparent;
  }

  opacity: ${p => (p.disabled ? "1" : "0.7")};
`;

const phosphorSearchValue = (iconName: string): string =>
  iconName.length > 0 ? "ph-" + iconName : "";

const SearchBarIcon = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.7;
`;


const IconSearch: React.FunctionComponent<Props> = ({
  openDropdown,
  selectedIcon,
  inputId,
  inputName,
  value,
  setValue,
  disabled,
  required
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { name: iconName = "" } = selectedIcon;
  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <SearchBar
      onFocus={openDropdown}
      onClick={openDropdown}
      disabled={disabled}
    >
      {selectedIcon && (
        <SearchBarIcon>
          <selectedIcon.Icon
            size={16}
            weight="fill"
            color="#0E153A"
            aria-label={iconName}
          />
        </SearchBarIcon>
      )}
      <input
        type="hidden"
        id={inputId}
        name={inputName}
        value={phosphorSearchValue(value)}
        aria-hidden={true}
        role="none"
      />
      <SearchInput
        value={value}
        onChange={handleOnChange}
        placeholder={t("actions.search")}
        type="text"
        role="combobox"
        aria-label="selected icon"
        aria-expanded="false"
        aria-owns="IconPickerDropdown"
        aria-autocomplete="list"
        aria-required={required}
        autoComplete="off"
        disabled={disabled}
      />
      <SearchBarIcon>
        <CaretDown
          size={16}
          weight="bold"
          color="#0E153A"
          aria-label="Caret pointing down"
        />
      </SearchBarIcon>
    </SearchBar>
  );
};

export default IconSearch;
