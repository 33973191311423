import React from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import MetadataForm from ".";
import {
  FAKE_EVENT,
  render,
  waitFor,
  fireEvent,
  screen,
} from "../../../EventWizard/test-utils";
import { MetadataFormProps } from "./MetadataForm";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: MetadataFormProps = {
  event: FAKE_EVENT,
  submitForm,
  handleErrors,
  formErrors,
};

describe("Meta Data Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<MetadataForm {...requiredProps} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe("meta title", () => {
    test("is required", async () => {
      render(<MetadataForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText("metadata.meta_title.label", {
          exact: false,
        });
        fireEvent.change(inputLabel, {
          target: { value: "" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("meta description", () => {
    test("is required", async () => {
      render(<MetadataForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "metadata.meta_description.label",
          { exact: false }
        );
        fireEvent.change(inputLabel, {
          target: { value: "" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("meta keywords", () => {
    test("is required", async () => {
      render(<MetadataForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText("metadata.meta_tags.label", {
          exact: false,
        });
        fireEvent.change(inputLabel, {
          target: { value: "" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });
});
