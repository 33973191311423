import React from "react";
import { act } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import DCTimePicker from "./DCTimePicker";
import { render } from "../../../EventWizard/test-utils";
import { noop } from "../../../../utils";
expect.extend(toHaveNoViolations);

const props = {
  isError: true,
  handleChange: noop,
  initialStartTime: new Date(),
  initialEndTime: new Date(),
  onOpen: noop,
  onClose: noop,
  htmlId: "test",
  tzinfoName: "Australia/Melbourne"
};

describe("SessionPageLayout", () => {
  describe("with 24 hour format", () => {
    test("has no accessibility violations", async () => {
      const { container } = render(<DCTimePicker format24 {...props} />);

      await act(async () => {
        const results = await axe(container);
        expect(results).toHaveNoViolations();
      });
    });
  });

  describe("without 24 hour format", () => {
    test("has no accessibility violations", async () => {
      const { container } = render(<DCTimePicker format24={false} {...props} />);

      await act(async () => {
        const results = await axe(container);
        expect(results).toHaveNoViolations();
      });
    });
  });
});
