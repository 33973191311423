import React from "react";
import { fireEvent, screen, waitFor } from "@testing-library/react";
import CustomStylingSectionForm, {
  CustomStylingSectionFormProps,
} from "./CustomStylingSectionForm";
import { render } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { FAKE_EVENT } from "../../../EventWizard/test-utils";
expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: CustomStylingSectionFormProps = {
  event: FAKE_EVENT,
  submitForm,
  handleErrors,
  formErrors,
};

const EventCustomStylingSection: React.FC = (): JSX.Element => {
  return <CustomStylingSectionForm {...requiredProps} />;
};

describe("DC Custom Styling Section", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<EventCustomStylingSection />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe("feature background colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.header_background_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature background secondary colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.header_gradient_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature header text colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.header_text_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature section header text colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.section_header_text_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature background menu colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.top_nav_menu_background_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature text colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.top_menu_text_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature background side menu colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.side_menu_background_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature background side menu colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.side_menu_text_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature side menu icon colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.side_menu_icon_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature profile side menu text colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.profile_side_menu_text_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("feature scroll load colour", () => {
    test("must be a hexadecimal string", async () => {
      render(<EventCustomStylingSection />);
      const input = screen.getByLabelText(
        "events.form.styling.scroll_and_loading_bar_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });
});
