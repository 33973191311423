import { setItemColor } from "./utils";
import { figmaTheme } from "../../../react/dcTheme/";

const expectedColors: Record<string, Record<string, string>> = {
  webinar: {
    active: figmaTheme.colors.white,
    inactive: figmaTheme.colors.navigation.inactive
  },
  default: {
    active: figmaTheme.colors.white,
    inactive: figmaTheme.colors.navigation.inactive
  }
}

describe("setItemColor", () => {
  describe("with webinar", () => {
    test("it returns webinar active color", () => {
      expect(setItemColor(true, "webinar")).toEqual(expectedColors.webinar.active);
    });

    test("it returns webinar inactive color", () => {
      expect(setItemColor(false, "webinar")).toEqual(expectedColors.webinar.inactive);
    });
  });

  describe("with default", () => {
    test("it returns active color", () => {
      expect(setItemColor(true, "default")).toEqual(expectedColors.default.active);
    });

    test("it returns inactive color", () => {
      expect(setItemColor(false, "default")).toEqual(expectedColors.default.inactive);
    });
  });
});
