import React, { MutableRefObject, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../common/shared/FormControlLabel";
import DCColourPicker from "../../common/DCColourPicker";
import DCFileUploader from "../../DCFileUploader";
import TenantOnlyFieldNotice from "../../common/TenantOnlyFieldNotice";
import { TenantType } from "../../../EventWizard/models/Tenant";
import { LayoutForm } from "../../common/FormPageLayout";
import { usePreviewData } from "../../Preview";
import schemaGenerator from "./formSchema";
import { useSchema } from "../../../helpers/useSchema";

export type EmailCustomisationFormProps = {
  tenant: TenantType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
};

const EmailCustomisationForm: React.FC<EmailCustomisationFormProps> = ({
  tenant,
  submitForm,
  handleErrors,
  formErrors,
}) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const { colourEmailHeader, colourEmailFooter, headerBannerImage } = tenant;

  const defaultValues = {
    colourEmailHeader,
    colourEmailFooter,
    headerBannerImage,
  };

  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });

  formErrors.current = errors;

  usePreviewData({
    colourEmailHeader,
    colourEmailFooter,
  });

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  const setFile = (file: File) => {
    setValue("headerBannerImage", [file]);
  };

  return (
    <LayoutForm onSubmit={e => e.preventDefault}>
      <TenantOnlyFieldNotice />
      <Text mb={3}>
        {t("branding_page.email_styling.email_customisation_title")}
      </Text>

      <Flex gap={3} flexDir={["column", "row"]}>
        <FormControlLabel
          label={t("branding_page.email_styling.header_colour.label")}
          error={errors.colourEmailHeader}
          toolTip={t("branding_page.email_styling.header_colour.tool_tip")}
          name="colourEmailHeader"
          isRequired
        >
          <Controller
            control={control}
            name="colourEmailHeader"
            render={({ field: { onChange, value, ref, name, onBlur } }) => (
              <DCColourPicker
                onChange={onChange}
                value={value}
                inputRef={ref}
                id={name}
                disabled={true}
                onBlur={onBlur}
              />
            )}
          />
        </FormControlLabel>
        <FormControlLabel
          label={t("branding_page.email_styling.footer_colour.label")}
          error={errors.colourEmailFooter}
          toolTip={t("branding_page.email_styling.footer_colour.tool_tip")}
          name="colourEmailFooter"
          isRequired
        >
          <Controller
            control={control}
            name="colourEmailFooter"
            render={({ field: { onChange, value, ref, name, onBlur } }) => (
              <DCColourPicker
                onChange={onChange}
                value={value}
                inputRef={ref}
                id={name}
                disabled={true}
                onBlur={onBlur}
              />
            )}
          />
        </FormControlLabel>
      </Flex>

      <FormControlLabel
        name="headerBannerImage"
        label={t("branding_page.email_styling.banner_background.label")}
      >
        <DCFileUploader
          acceptedFormats="image/*"
          aspectRatio={1}
          maxSize={10000000}
          uploadPhoto={setFile}
          source={tenant.headerBannerImageUrl}
          disabled
        />
      </FormControlLabel>
    </LayoutForm>
  );
};

export default EmailCustomisationForm;
