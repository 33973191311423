import React from "react";
import { Flex, Box, Grid } from "@chakra-ui/react";
import ColourHistoryItem from "./ColourHistoryItem";

type ColourHistoryProps = {
  colour: string;
  setColour: (string) => void;
  colourHistory: Array<string>;
};

const ColourHistory: React.FunctionComponent<ColourHistoryProps> = ({
  colour,
  setColour,
  colourHistory,
}: ColourHistoryProps): JSX.Element => {
  return (
    <Flex align="center" mt={4}>
      <Box
        w="80px"
        h="80px"
        background={colour}
        border="1px"
        borderColor="gray.200"
        borderRadius="8px"
      />
      <Grid
        templateColumns="repeat(5, 1fr)"
        columnGap={2}
        rowGap={3}
        ml="auto"
        h="80px"
      >
        {colourHistory.map((presetColour, index) => (
          <ColourHistoryItem
            key={index}
            colour={presetColour}
            setColour={setColour}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export default ColourHistory;
