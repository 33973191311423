class ProgressService {
  static getProgress(eventId: string) {
    return window.localStorage.getItem(`currentPage-${eventId}`);
  }
  static setProgress(pageName: string, eventId: string) {
    window.localStorage.setItem(`currentPage-${eventId}`, pageName);
  }
}

export default ProgressService;
