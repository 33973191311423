import React, { MutableRefObject, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import schemaGenerator from "./formSchema";
import { LayoutForm } from "../../common/FormPageLayout";
import SwitchInput from "../../common/SwitchInput/SwitchInput";
import { useSchema } from "../../../helpers/useSchema";
import { EventType } from "../../../EventWizard/models/Event";
import { ZoomConfigurationType } from "../../../EventWizard/models/ZoomConfiguration";

export type AttendeeConfigurationProps = {
  event?: EventType;
  zoomConfiguration?: ZoomConfigurationType;
  submitForm?: MutableRefObject<unknown>;
  handleErrors?: MutableRefObject<unknown>;
  formErrors?: MutableRefObject<unknown>;
};

export const AttendeeConfigurationForm: React.FC<AttendeeConfigurationProps> = ({
  zoomConfiguration,
  submitForm,
  handleErrors,
  formErrors,
}) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const {
    waitingRoom,
    screenSharing,
    chat,
    allowParticipantsToRename,
    requestPermissionToUnmute,
    whiteboard,
    participantVideo,
  } = zoomConfiguration;

  const defaultValues = {
    waitingRoom,
    screenSharing,
    chat,
    allowParticipantsToRename,
    requestPermissionToUnmute,
    whiteboard,
    participantVideo,
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  formErrors.current = errors;

  return (
    <LayoutForm onSubmit={e => e.preventDefault()}>
      <Heading size="sm" mb={4} fontFamily="body" fontWeight="medium">
        {t("attendee_configuration_form.interactive_webinar_heading")}
      </Heading>
      <SwitchInput
        label={t("attendee_configuration_form.waiting_room.label")}
        helperText={t("attendee_configuration_form.waiting_room.hint")}
        errorMessage={errors.waitingRoom?.message}
        {...register("waitingRoom")}
      />
      <Heading size="sm" mb={2} fontFamily="body" fontWeight="medium">
        {t("attendee_configuration_form.attendee_settings.heading")}
      </Heading>
      <Text mb={4} color="gray.600" fontSize="xs">
        {t("attendee_configuration_form.attendee_settings.subtitle")}
      </Text>
      <SwitchInput
        label={t("attendee_configuration_form.screen_sharing.label")}
        errorMessage={errors.screenSharing?.message}
        {...register("screenSharing")}
      />
      <SwitchInput
        label={t("attendee_configuration_form.chat.label")}
        errorMessage={errors.chat?.message}
        {...register("chat")}
      />
      <SwitchInput
        label={t(
          "attendee_configuration_form.allow_participants_to_rename.label"
        )}
        errorMessage={errors.allowParticipantsToRename?.message}
        {...register("allowParticipantsToRename")}
      />
      <SwitchInput
        label={t(
          "attendee_configuration_form.request_permission_to_unmute.label"
        )}
        errorMessage={errors.requestPermissionToUnmute?.message}
        {...register("requestPermissionToUnmute")}
      />
      <SwitchInput
        label={t("attendee_configuration_form.whiteboard.label")}
        errorMessage={errors.whiteboard?.message}
        {...register("whiteboard")}
      />
      <SwitchInput
        label={t("attendee_configuration_form.participant_video.label")}
        errorMessage={errors.participantVideo?.message}
        {...register("participantVideo")}
      />
    </LayoutForm>
  );
};

export default AttendeeConfigurationForm;
