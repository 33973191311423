import React from "react";
import { Flex } from "@chakra-ui/react";
import { NumberCircleOne, NumberCircleTwo, NumberCircleThree, NumberCircleFour } from "phosphor-react";

import SideNavigationItem from "./SideNavigationItem";
import SideNavigationGroup from "./SideNavigationGroup";
import SideNavigationHeader from "./SideNavigationHeader";
import { figmaTheme as fT } from "../../../react/dcTheme";
import { useTranslation } from "react-i18next";

export type SideNavigationVariants = 'default' | 'webinar';

type SideNavigationProps = {
  eventId: string;
}

export const SideNavigation: React.FC<SideNavigationProps> = ({eventId}) => {
  const { t } = useTranslation();

  return (
    <Flex
      h="100%"
      direction="column"
      bg={fT.colors.primary.base}
      basis="280px"
      shrink={0}
      py={12}
      pl={12}
      overflow="auto"
      borderRadius="0 32px 32px 0"
    >
      <SideNavigationHeader
        displayText={t("event_tools.navigation.event_details")}
        path={`/admin/event-tools/${eventId}/steps/event-details`}
        iconComponent={NumberCircleOne}
        isComplete
      />
      <SideNavigationGroup
        displayText={t("event_tools.navigation.event_website")}
        iconComponent={NumberCircleTwo}
        isActive
      >
        <SideNavigationItem
          displayText={t("event_tools.navigation.brand_and_style")}
          path={`/admin/event-tools/${eventId}/steps/website-style`}
          isActive
        />
        <SideNavigationItem
          displayText={t("event_tools.navigation.home_page")}
          path={`/admin/event-tools/${eventId}/steps/hero-section`}
          isComplete
        />
        <SideNavigationItem
          displayText={t("event_tools.navigation.configurations")}
          path={`/admin/event-tools/${eventId}/steps/support-email`}
        />
      </SideNavigationGroup>
      <SideNavigationGroup
        displayText="Agenda"
        iconComponent={NumberCircleThree}
      >
        <SideNavigationItem
          displayText={"Date & Time"}
          path="#"
        />
        <SideNavigationItem
          displayText={"Session"}
          path="#"
        />
        <SideNavigationItem
          displayText={"Presentation"}
          path="#"
        />
        <SideNavigationItem
          displayText={"Theme"}
          path="#"
        />
      </SideNavigationGroup>
      <SideNavigationHeader
        displayText="Publish"
        path="#"
        iconComponent={NumberCircleFour}
      />
    </Flex>
  );
};

export default SideNavigation;
