import React from "react";
import { useNavigate } from "react-router-dom";
import FormPageLayout from "../../../../../common/FormPageLayout";
import { useEvent } from "../../../../Event/index";
import PrivacyPolicyForm from "../../../../../forms/PrivacyPolicyForm";
import { useTenant } from "../../../../Event/Event";
import { useTranslation } from "react-i18next";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";

const PrivacyPolicy: React.FC = (): JSX.Element => {
  const {event} = useEvent();
  const {tenant} = useTenant();
  const navigate = useNavigate();
  const navigator = path => () => navigate(path);
  const { t } = useTranslation();

  const stepDetails = [
    { name: t("event_tools.navigation.support_email"), status: "done", path: `/admin/event-tools/${event.id}/steps/support-email` },
    { name: t("event_tools.navigation.meta_data"), status: "done", path: `/admin/event-tools/${event.id}/steps/metadata` },
    { name: t("event_tools.navigation.privacy_policy"), status: "active", path: `/admin/event-tools/${event.id}/steps/privacy-policy` },
  ];

  const updateTenant = async () => {
    return true;
  };

  return (
    <FormPageLayout event={event}
      stepDetails={stepDetails}
      update={updateTenant}
      previousForm={navigator(`/admin/event-tools/${event.id}/steps/metadata`)}
      exitForm={navigateToEventList}
      nextForm={navigateToEventList}
      previewVariant="None"
    >
      {
        (props) => <PrivacyPolicyForm tenant={tenant} {...props} />
      }
    </FormPageLayout>
  );
};

export default PrivacyPolicy;
