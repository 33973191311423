export const tooltipTheme = {
  baseStyle: {
    borderRadius: '8',
    fontFamily: "Poppins, sans-serif",
    padding: 4,
  },
  defaultProps: {
    bg: 'black',
    color: '#F4F0F4',
  }
};
