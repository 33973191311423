import React from 'react';

export { segments } from './data';
export * from './PreviewContext';

import PreviewComponent from './Preview';
import EmailPreviewComponent from './EmailPreview';
import PreviewWithLabels from './PreviewWithLabels';


function Preview() {
  return <PreviewWithLabels><PreviewComponent/></PreviewWithLabels>;
}

function EmailPreview() {
  return <PreviewWithLabels><EmailPreviewComponent/></PreviewWithLabels>;
}

export { Preview, EmailPreview };
