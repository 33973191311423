import * as React from 'react';
import { useTranslation } from "react-i18next";

const FormLabels = () => {
  const { t } = useTranslation();

  return (
    <div className='form-row'>
        <div className='form-group col-sm-2'>
          <label htmlFor='firstName'>{t("form.user_name.first_name.label")}</label>
        </div>

        <div className='form-group col-sm-2'>
          <label htmlFor='lastName'>{t("form.user_name.last_name.label")}</label>
        </div>

        <div className='form-group col-sm-2'>
          <label htmlFor='email'>{t("form.email.label")}</label>
        </div>

        <div className='form-group col-sm-2'>
          <label htmlFor='delegateType'>{t("delegate_types.name")}</label>
        </div>

        <div className='form-group col-2'>
          <label htmlFor='addons'>{t("group_registrations.form.addons.label")}</label>
        </div>

        <div className='form-group col-1'>
          <label>{t("pricing.price_ex.label")}</label>
        </div>
      </div>
  );
};

export default FormLabels;
