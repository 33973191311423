import React, { useState } from "react";
import { Flex, Icon, MenuButton, Menu, MenuList, MenuItem, useDisclosure } from "@chakra-ui/react";
import { CaretDown } from "phosphor-react";
import { useTranslation } from "react-i18next";

import { figmaTheme as fT } from "../../../react/dcTheme";
import withInputLabel from '../shared/withInputLabel';

export type OptionType = {
  label: string;
  value: string;
};

export type DCDropdownProps = {
  isError: boolean;
  options: Array<OptionType>;
  preselectedOption?: OptionType;
  handleChange: (e: Record<string, string>) => void;
};

const getVariant = (isOpen, isSelected, isError) => {
  if(isError) return 'isError';
  if(isOpen) return 'isOpen';
  if(isSelected) return 'isSelected';
};

const iconColor = (isOpen, isFocused) => {
  return (isOpen || isFocused) ? fT.colors.label.primary : fT.colors.label.secondary;
};

const DCDropdown: React.FC<DCDropdownProps> = ({options, preselectedOption, isError, handleChange}) => {
  const [selectedOption, setSelectedOption] = useState(preselectedOption);
  const [isFocused, setIsFocused] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const { t } = useTranslation();
  const placeholder = t("events.form.select_placeholder");

  const onChange = (option) => {
    setSelectedOption(option);
    handleChange({option});
  };

  return (
    <Menu autoSelect={false} isOpen={isOpen} onClose={onClose} onOpen={onOpen} variant={getVariant(isOpen, selectedOption, isError)}>
      <MenuButton onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
          <Flex align="center" justify="space-between">
            {selectedOption ? selectedOption.label : placeholder}
            <Icon as={CaretDown} weight="fill" color={iconColor(isOpen, isFocused)} />
          </Flex>
      </MenuButton>
      <MenuList>
        {options.map(option => {
          return <MenuItem
                   key={option.value}
                   onClick={() => onChange(option)}
                   bg={selectedOption == option ? fT.colors.primary[90] : ""}
                   data-selected={selectedOption == option}
                 >
                   {option.label}
                 </MenuItem>;
        })}
      </MenuList>
    </Menu>
  );
};

export default DCDropdown;
export const DCDropdownWithLabel = withInputLabel(DCDropdown);
