import React, { MutableRefObject, useEffect } from "react";
import { Flex, Heading, Input } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../common/shared/FormControlLabel";
import TenantOnlyFieldNotice from "../../common/TenantOnlyFieldNotice";
import { EventType } from "../../../EventWizard/models/Event";
import { LayoutForm } from "../../common/FormPageLayout";
import { usePreviewData } from "../../Preview";
import schemaGenerator from "./formSchema";
import { useSchema } from "../../../helpers/useSchema";

export type FooterFormProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
  tenantFieldsVisible?: boolean;
};

const FooterForm: React.FC<FooterFormProps> = ({
  event,
  submitForm,
  handleErrors,
  formErrors,
  tenantFieldsVisible
}) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const { 
    footerText ,
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
  } = event;

  const defaultValues = { footerText };

  const {
    watch,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });

  formErrors.current = errors;

  const { setActiveComponent, setAttribute } = usePreviewData();

  useEffect(() => {
    const mapping = {
      footerText: "footerText",
    };
    const subscription = watch((value, { name }) => {
      if (mapping[name]) {
        setAttribute(mapping[name], value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setAttribute("footerText", footerText);
  }, [footerText]);

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  usePreviewData({
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  });

  return (
    <Flex as={LayoutForm} onSubmit={(e) => e.preventDefault()} flexDir="column">
      <FormControlLabel
        label={t("footer_form.footer_text.label")}
        helperText={t("footer_form.footer_text.hint")}
        error={errors.footerText}
        name="footerText"
        isRequired
      >
        <Input
          onFocus={() => setActiveComponent("preview_svg_Copy right")}
          onBlur={() => setActiveComponent(null)}
          {...register("footerText")}
          id="footerText"
        />
      </FormControlLabel>
      {tenantFieldsVisible && (
        <Flex flexDir="column">
          <Heading size="sm" mb={2} fontFamily="body">
            {t("footer_form.social_sub_title")}
          </Heading>
          <TenantOnlyFieldNotice />
          <Flex gap={3} mb={3} flexDir={["column", "row"]}>
            <FormControlLabel
              label={t("footer_form.linkedin.label")}
              name="linkedin"
            >
              <Input value={event.tenant.linkedin} id="linkedin" disabled />
            </FormControlLabel>
            <FormControlLabel
              label={t("footer_form.instagram.label")}
              name="instagram"
            >
              <Input value={event.tenant.instagram} id="instagram" disabled />
            </FormControlLabel>
          </Flex>
          <Flex gap={3} mb={3} flexDir={["column", "row"]}>
            <FormControlLabel
              label={t("footer_form.facebook.label")}
              name="facebook"
            >
              <Input value={event.tenant.facebook} id="facebook" disabled />
            </FormControlLabel>
            <FormControlLabel
              label={t("footer_form.twitter.label")}
              name="twitter"
            >
              <Input value={event.tenant.twitter} id="twitter" disabled />
            </FormControlLabel>
          </Flex>
          <Flex gap={3} mb={3} flexDir={["column", "row"]}>
            <FormControlLabel
              label={t("footer_form.support_email.label")}
              name="helpEmail"
            >
              <Input value={event.tenant.helpEmail} id="helpEmail" disabled />
            </FormControlLabel>
            <FormControlLabel
              label={t("footer_form.website.label")}
              name="websiteUrl"
            >
              <Input value={event.tenant.websiteUrl} id="websiteUrl" disabled />
            </FormControlLabel>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default FooterForm;
