import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";

interface Props {
  active: boolean;
  styleProps?: BoxProps;
  children?: JSX.Element | JSX.Element[];
}

const Card = ({ active, styleProps, children }: Props): JSX.Element => {
  return (
    <Box
      border={active ? fT.border.width.md : fT.border.width.sm}
      borderColor={active ? fT.colors.secondary.base : fT.colors.grey.base}
      borderRadius={fT.border.radius.md}
      _hover={{
        borderColor: fT.colors.secondary.base,
      }}
      p={active ? 0 : fT.border.width.sm}
      w="sm"
      {...styleProps}
    >
      {children}
    </Box>
  );
};

export default Card;
