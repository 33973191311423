import React from 'react';
import { render } from '@testing-library/react';
import '@testing-library/jest-dom';
import { axe, toHaveNoViolations } from 'jest-axe';
expect.extend(toHaveNoViolations);

import { Preview } from '.';


describe('Preview should render', () => {
  test('without axe errors', async () => {
    const { container } = render(<Preview />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });
});
