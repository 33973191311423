import React from "react";

import FormPageLayout from "../../../../../common/FormPageLayout";
import { useEvent } from "../../../../../pages/Event";
import CustomHomepageSectionForm from "../../../../../forms/CustomHomepageSectionForm";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";
import { useWebinarFormActions } from "../../../../hooks/useWebinarFormActions";

const AddSection: React.FC = () => {
  const { event } = useEvent();

  const { getStepDetailsByRoute, navigateTo, updateEventWithFormData } =
    useWebinarFormActions();

  const stepDetails = [
    getStepDetailsByRoute("heroSection"),
    getStepDetailsByRoute("additionalFeatures"),
    getStepDetailsByRoute("addSections"),
    getStepDetailsByRoute("footer"),
  ];

  const setDestroyFlag = data => {
    const presentIds = data.customHomepageSections.map(s => s.id);
    event?.customHomepageSections?.forEach?.(customHomePageSection => {
      if (!presentIds.includes(customHomePageSection.id)) {
        data.customHomepageSections.push({
          ...customHomePageSection,
          _destroy: true,
        });
      }
    });
  };

  const addSectionUpdateEvent = async data => {
    setDestroyFlag(data);
    if (data.customHomepageSections.length > 0) {
      return updateEventWithFormData(data);
    }
  };

  return (
    <FormPageLayout
      event={event}
      stepDetails={stepDetails}
      update={addSectionUpdateEvent}
      previousForm={navigateTo.additionalFeatures}
      exitForm={navigateToEventList}
      nextForm={navigateTo.footer}
      previewVariant="None"
    >
      {props => <CustomHomepageSectionForm event={event} {...props} />}
    </FormPageLayout>
  );
};

export default AddSection;
