import React from "react";
import { useNavigate } from "react-router-dom";
import EventService from "../../../../EventWizard/services/eventService";
import AppAdapter from "../../../../../utils/adapter";
import FormPageLayout from "../../../common/FormPageLayout/FormPageLayout";
import EventDetailsForm from "../../../forms/EventDetailsForm";
import { useEvent } from "../../Event/index";
import { navigateToEventList } from "../../../../EventWizard/helpers/navigation";

const stepDetails = [];

const EventDetails: React.FC = (): JSX.Element => {
  const {event, setEvent} = useEvent();
  const navigate = useNavigate();
  const navigator = path => () => navigate(path);

  const updateEvent = async (data) => {
    const payload = await EventService.updateEvent(event.id, data);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, { transform: EventService.transform });
      setEvent(response);
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  return (
    <FormPageLayout event={event}
      stepDetails={stepDetails}
      update={updateEvent}
      exitForm={navigateToEventList}
      nextForm={navigator(`/admin/event-tools/${event.id}/steps/website-style`)}
      previewVariant="Web"
    >
      {
        (props) => <EventDetailsForm event={event} {...props} />
      }
    </FormPageLayout>
  );
};

export default EventDetails;
