import { SearchResult } from "./types";

export function groupSearchResults(searchResults: [SearchResult]) {
  return searchResults.reduce((coll: object, result: SearchResult) => {
    const { attributes } = result;
    const { searchable_type } = attributes;

    const bucket = coll[searchable_type] || [];
    bucket.push(attributes);
    coll[searchable_type] = bucket;
    return coll;
  }, {});
}
