import React from "react";
import { render, screen, fireEvent } from "@testing-library/react";
import "@testing-library/jest-dom";
import IconPicker from "./IconPicker";
import { axe, toHaveNoViolations } from "jest-axe";
expect.extend(toHaveNoViolations);

interface Props {
  inputId: string;
  inputFor: string;
  inputName: string;
  label: string;
  hint: string;
  value: string;
  disabled: boolean;
  required: boolean;
}

const props: Props = {
  inputId: "sample_field_id",
  inputFor: "sample_field_for",
  inputName: "sample_field_name",
  label: "sample_field_label",
  value: "sample_field_value",
  hint: "sample hint",
  disabled: false,
  required: false,
};

describe("IconPicker", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<IconPicker {...props} />);
    fireEvent.focus(container.querySelector('input'));

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  test("can be rendered functionally", async () => {
    render(<IconPicker {...props} />);
    expect(screen.getByRole("combobox")).toHaveValue(props.value);
  });

  test("click opens dropdown", async () => {
    render(<IconPicker {...props} />);
    screen.getByRole("combobox").click();

    const dropdown = screen.queryByRole("combobox");
    expect(dropdown).not.toBeNull();
  });
});
