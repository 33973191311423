import type { SideNavigationVariants } from "./SideNavigation";
import { figmaTheme as fT } from "../../../react/dcTheme";

export const setItemColor = (active: boolean, variant: SideNavigationVariants): string => {
  switch (variant) {
    case "webinar":
      return active ? fT.colors.white : fT.colors.navigation.inactive;
    default:
      return active ? fT.colors.white : fT.colors.navigation.inactive;
  }
}
