import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import {
  ChakraProvider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  CountryCodeAlpha2,
  countryListAlpha2,
} from "../../helpers/country_lists";
import { INVOICE } from "../../GroupRegistration/constants";
import { removeTextTransformation } from "./styles";

type FormAttributes = {
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  state: string;
  postCode: string;
  countryCode: string;
};

export type InvoiceFormProps = {
  pageActions: ReactNode;
  paymentIntentsPath: string;
  groupRegistrationId: string;
  verifyPaymentUrl: string;
  countryCode: string;
  updateCountry: (countryCode: CountryCodeAlpha2) => void;
  setIsCheckingOut: (React.Dispatch<React.SetStateAction<boolean>>)
};

const InvoiceForm = (props: InvoiceFormProps) => {
  const {
    pageActions,
    paymentIntentsPath,
    groupRegistrationId,
    verifyPaymentUrl,
    updateCountry,
    setIsCheckingOut,
    countryCode,
  } = props;

  const { t } = useTranslation();

  const schema = yup.object().shape({
    addressLine1: yup.string().required(t('group_registrations.errors.form.required.address_line_1')),
  });

  const confirmAndNotify = async () => {
    try {
      const paymentProcessingResponse = await fetch(verifyPaymentUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          payment_type: INVOICE,
        }),
      });

      if (paymentProcessingResponse.ok) {
        const responseData = await paymentProcessingResponse.json();
        window.location.assign(responseData.redirect);
      }
    } catch (e) {
      setIsCheckingOut(false);
    }
  };

  const submitInvoiceDetails = async (data: FormAttributes) => {
    const {
      addressLine1,
      addressLine2,
      suburb,
      state,
      postCode,
      countryCode
    } = data;

    setIsCheckingOut(true);
    try {
      await fetch(paymentIntentsPath, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          billing_address: {
            address: addressLine1,
            address_2: addressLine2,
            country: countryListAlpha2[countryCode],
            post_code: postCode,
            suburb: suburb,
            state: state,
          },
          country_code: countryCode,
          country: countryListAlpha2[countryCode],
          group_registration_id: groupRegistrationId,
          payment_method_name: INVOICE,
        }),
      });
      await confirmAndNotify();
    } catch (e) {
      setIsCheckingOut(false);
    }
  };

  const { register, handleSubmit, formState } = useForm<FormAttributes>({
    defaultValues: {
      addressLine1: "",
      addressLine2: "",
      suburb: "",
      state: "",
      postCode: "",
      countryCode: countryCode,
    },
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  return (
    <ChakraProvider resetCSS={false}>
      <form
        className="invoice-form"
        onSubmit={handleSubmit(submitInvoiceDetails)}
      >
        <Stack spacing={8}>
          <div>
            <FormControl isInvalid={!!errors?.addressLine1} isRequired={true}>
              <FormLabel htmlFor="addressLine1" style={removeTextTransformation}>
                {t("addresses.billing.address1.label")}
              </FormLabel>
              <Input
                aria-label={t("addresses.billing.address1.label")}
                size="md"
                {...register("addressLine1")}
                className="form-control text-left"
              />
              <FormErrorMessage>
                {errors.addressLine1 && errors.addressLine1.message}
              </FormErrorMessage>
            </FormControl>
          </div>

          <div>
            <FormControl>
              <FormLabel htmlFor="addressLine2" style={removeTextTransformation}>
                {t("addresses.billing.address2.label")}
              </FormLabel>
              <Input
                aria-label={t("addresses.billing.address2.label")}
                size="md"
                {...register("addressLine2")}
                className="form-control text-left"
              />
              <FormErrorMessage>
                {errors.addressLine2 && errors.addressLine2.message}
              </FormErrorMessage>
            </FormControl>
          </div>

          <HStack spacing={4}>
            <div style={{ width: "100%" }}>
              <FormControl>
                <FormLabel htmlFor="suburb" style={removeTextTransformation}>
                  {t("addresses.billing.suburb.label")}
                </FormLabel>
                <Input
                  aria-label={t("addresses.billing.suburb.label")}
                  size="md"
                  {...register("suburb")}
                  className="form-control text-left"
                />
                <FormErrorMessage>
                  {errors.suburb && errors.suburb.message}
                </FormErrorMessage>
              </FormControl>
            </div>

            <div style={{ width: "100%" }}>
              <FormControl>
                <FormLabel htmlFor="state" style={removeTextTransformation}>
                  {t("addresses.billing.state.label")}
                </FormLabel>
                <Input
                  aria-label={t("addresses.billing.state.label")}
                  size="md"
                  {...register("state")}
                  className="form-control text-left"
                />
                <FormErrorMessage>
                  {errors.state && errors.state.message}
                </FormErrorMessage>
              </FormControl>
            </div>
          </HStack>

          <HStack spacing={4}>
            <div style={{ width: "100%" }}>
              <FormControl>
                <FormLabel htmlFor="postCode" style={removeTextTransformation}>
                  {t("addresses.billing.post_code.label")}
                </FormLabel>
                <Input
                  aria-label={t("addresses.billing.post_code.label")}
                  size="md"
                  {...register("postCode")}
                  className="form-control text-left"
                />
                <FormErrorMessage>
                  {errors.postCode && errors.postCode.message}
                </FormErrorMessage>
              </FormControl>
            </div>

            <div style={{ width: "100%" }}>
              <FormControl>
                <FormLabel htmlFor="country" style={removeTextTransformation}>
                  {t("addresses.billing.country.label")}
                </FormLabel>
                <Select
                  id="payment-country"
                  name="payment-country"
                  aria-label={t("addresses.billing.country.label")}
                  {...register("countryCode")}
                  onChange={(event) =>
                    updateCountry(event.target.value as CountryCodeAlpha2)
                  }
                >
                  <option disabled>{t('registrations.form.country_list.label')}</option>
                  {Object.entries(countryListAlpha2).map(
                    ([countryCode, country]) => (
                      <option key={countryCode} value={countryCode}>
                        {country}
                      </option>
                    )
                  )}
                </Select>

                <FormErrorMessage>
                  {errors.countryCode && errors.countryCode.message}
                </FormErrorMessage>
              </FormControl>
            </div>
          </HStack>
        </Stack>

        {pageActions}
      </form>
    </ChakraProvider>
  );
};

export default InvoiceForm;
