import moment from "moment";

export const getAvailableDays = (startDate: string, endDate: string, previousDates: string[] = []): string[] => {
  const dateArray = [];
  let currentDate = moment(startDate);
  while (currentDate <= moment(endDate)) {
    if (!previousDates.includes(currentDate.toISOString())) {
      dateArray.push(currentDate.toISOString());
    }
    currentDate = currentDate.add(1, "days");
  }
  return dateArray;
};
