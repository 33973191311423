import { useEffect, useState } from "react";
import { OptionType } from "../DCDropdown/DCDropdown";

import TimezoneService from "../../../EventWizard/services/timezoneService";

type Timezone = {
  name: string;
  offset: string;
}

type TimezoneFetcherProps = {
  selection: string;
  render: (options:  Array<OptionType>, selectedData: Timezone) => JSX.Element;
}

const TimezoneFetcher = ({selection, render}: TimezoneFetcherProps) => {
  const [data, setData] = useState<Timezone[]>([]);
  
  useEffect(() => {
    TimezoneService.list().then(response => setData(response.result?.timezones || []));
  }, []);

  const timezoneOptions = (): Array<OptionType> => {
    return data.map((zone) => {
      return {
        label: `${zone.name} (${zone.offset})`,
        value: `${zone.name}`
      };
    });
  };

  const selectedOption = () => {
    return data.find((timezone) => timezone.name == selection)
  };

  return render(timezoneOptions(), selectedOption());
};

export default TimezoneFetcher;
