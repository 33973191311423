import { Addon, AddonOption, DelegateType, DelegateTypeOption, DelegateGroupsAndTypes } from "./types";

export const getDelegateType = (delegateGroupsAndTypes: DelegateGroupsAndTypes, id?: number): DelegateType => {
  if (id === undefined) return;

  const delegateGroups = delegateGroupsAndTypes.delegateGroups;
  const delegateTypes = delegateGroupsAndTypes.delegateTypes;
  const fetchDelegateTypeFromGroup = delegateGroups?.length > 0;

  if (fetchDelegateTypeFromGroup) {
    const delegateGroup = delegateGroups.filter(
      group => group.delegateTypes.find(delegateType => delegateType.id === id))[0];

    return delegateGroup?.delegateTypes.find(type => type.id == id);
  } else {
    return delegateTypes.find(type => type.id == id);
  }
};

export const getAddons = (delegateType?: DelegateType, addonIds?: number[]) => {
  const addons = delegateType?.addons;
  if (addons === undefined) return;

  return addons.filter(addon => addonIds.includes(addon.id));
};

export const getAddonOptionsByType = (delegateType?: DelegateType) => {
  const addons = delegateType?.addons;
  if (addons && addons.length > 0) {
    return addons.map(addon => (mapOption(addon)));
  }
};

export const mapOption = (option: DelegateType | Addon): DelegateTypeOption | AddonOption => (
  option
    ? {
      value: option.id,
      label: option.name,
      price: option.price
    }
    : undefined
);

export const calculateRowPrice = (selectedDelegateType: DelegateTypeOption, selectedAddons: AddonOption[]) => {
  if (!selectedDelegateType) {
    return 0;
  }

  const delegateTypePrice = selectedDelegateType.price;
  const addonPrices: number[] = selectedAddons?.map(addon => addon.price);
  const addonsTotalPrice = addonPrices?.length > 0 ? addonPrices.reduce((prev, curr) => +prev + +curr) : 0;

  return +delegateTypePrice + +addonsTotalPrice;
};
