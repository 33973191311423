import React from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { act } from "@testing-library/react";

import AttendeeConfigurationForm from ".";
import { FAKE_EVENT, FAKE_ZOOM_CONFIGURATION, render } from "../../../EventWizard/test-utils";
import { AttendeeConfigurationProps } from "./AttendeeConfigurationForm";
expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: AttendeeConfigurationProps = {
  event: FAKE_EVENT,
  zoomConfiguration: FAKE_ZOOM_CONFIGURATION,
  submitForm,
  handleErrors,
  formErrors,
};

describe("Attendee Configuration Form", () => {
  test("passes without axe errors", async () => {
    const { container } = render(<AttendeeConfigurationForm {...requiredProps} />);

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
