import React, { createContext, ReactElement } from 'react';
import AppAdapter from '../../../utils/adapter';
import { TenantType, Snakecase_TenantType } from '../models/Tenant';
import UserType, { Snakecase_UserType } from '../models/User';

export interface AuthContextState {
  currentUser: UserType,
  currentTenant: TenantType,
}

export const AuthContext = createContext<AuthContextState | null>(null);

export interface AuthProviderProps {
  user: Snakecase_UserType,
  tenant: Snakecase_TenantType,
  children?: ReactElement;
}

export function AuthProvider({ user, tenant, children }: AuthProviderProps) {
  const currentUser = AppAdapter.camelCaseKeys(user);
  const currentTenant = AppAdapter.camelCaseKeys(tenant);

  const value = {
    currentUser,
    currentTenant,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
