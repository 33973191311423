import React from 'react';
import { render, waitFor } from "@testing-library/react";
import TimezoneFetcher from "./TimezoneFetcher";

const mockData = {
  result: {
    timezones: [
      { name: "Melbourne", offset: "+11" },
      { name: "Perth", offset: "+9" }
    ]
  }
};

jest.mock("../../../EventWizard/services/timezoneService", () => ({
  list: () => Promise.resolve(mockData)
}));

describe('TimezoneFetcher', () => {
  it('should expose timezone option data', async () => {
    const { getByText } = render(
      <TimezoneFetcher
        selection='Melbourne'
        render={(data) => (
          <ul>
            { data.map((d) => <li key={d.label}>{d.value}</li>) }
          </ul>
        )}
      ></TimezoneFetcher>
    );

    await waitFor(() => {
      expect(getByText("Melbourne")).toBeDefined();
      expect(getByText("Perth")).toBeDefined();
    });
  });
});
