import React from "react";
import userEvent from "@testing-library/user-event";
import * as mockReactRouterDom from "react-router-dom";

import {
  mockCustomContexts,
  mockRestClientResolved,
  render,
  screen,
  waitFor,
} from "../../../../../../EventWizard/test-utils";
import HeroSection from ".";

mockCustomContexts();

const mockNavigate = jest.fn();

jest.mock("react-router-dom", () => ({
  ...(jest.requireActual("react-router-dom") as typeof mockReactRouterDom),
  useNavigate: () => mockNavigate,
}));

const assignMock = jest.fn();

delete window.location;
window.location = { assign: assignMock } as unknown as any; // eslint-disable-line @typescript-eslint/no-explicit-any

describe("HeroSection Page", () => {
  afterEach(() => {
    assignMock.mockClear();
    mockNavigate.mockClear();
  });

  test("renders", () => {
    expect(() => render(<HeroSection />)).not.toThrow();
  });

  test("clicking on previous calls navigate", async () => {
    render(<HeroSection />);

    await waitFor(() => {
      userEvent.click(screen.getByText("views.pagination.previous_item"));
    });
    expect(mockNavigate).toHaveBeenCalledWith(
      "/admin/event-tools/1/webinar-website/custom-styling"
    );
  });

  test("clicking on save and exit calls navigate", async () => {
    render(<HeroSection />);
    mockRestClientResolved("patchWithFormData", { data: {} });

    await waitFor(() =>
      userEvent.click(screen.getByText("actions.save_and_exit"))
    );
    expect(window.location.href).toEqual("/admin/event-tools");
  });

  test("clicking on save and continue calls navigate", async () => {
    render(<HeroSection />);
    mockRestClientResolved("patchWithFormData", { data: {} });

    await waitFor(() =>
      userEvent.click(screen.getByText("actions.save_and_continue"))
    );
    expect(mockNavigate).toHaveBeenCalledWith(
      "/admin/event-tools/1/webinar-website/additional-features"
    );
  });
});
