import {
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useMultiStyleConfig,
  VStack,
  useDisclosure
} from "@chakra-ui/react";
import { Clock } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import DCTimeInput from "./DCTimeInput";
import withInputLabel from "../shared/withInputLabel";
import { figmaTheme as fT } from "../../../react/dcTheme";
import { noop } from "../../../../utils";


type DCTimePickerProps = {
  isError: boolean;
  format24: boolean;
  handleChange: (e: Record<string, string>) => void;
  initialStartTime: Date;
  initialEndTime: Date;
  onOpen?: () => void;
  onClose?: () => void;
  htmlId?: string;
  tzinfoName?: string;
}

const borderColor = (isError, isOpen) => {
  if(isError) return fT.colors.alert;
  if(isOpen) return fT.colors.primary.base;
};
const DCTimePicker: React.FC<DCTimePickerProps> = ({
  isError,
  format24,
  handleChange,
  initialStartTime,
  initialEndTime,
  onOpen = noop,
  onClose = noop,
  htmlId,
  tzinfoName
}) => {
  const styles = useMultiStyleConfig('TimePicker', {});
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [time, setTime] = useState('');
  const { t } = useTranslation();
  const placeholder = `${t('events.form.time.start_time')} - ${t('events.form.time.end_time')}`;
  const startTimeInputRef = useRef(null);
  const endTimeInputRef = useRef(null);
  const { isOpen, onClose: handleClose, onOpen: handleOpen } = useDisclosure();
  const open = () => {
    handleOpen();
    onOpen();
  };
  const close = () =>{
    handleClose();
    onClose();
  };

  const clearTimes = () => {
    if(startTimeInputRef) startTimeInputRef.current.clearTime();
    if(endTimeInputRef) endTimeInputRef.current.clearTime();
    setTime('');
    handleChange({startTime: '', endTime: ''});
  };

  useEffect(() => {
    if(!startTime && !endTime) return;
    setTime(`${startTime} - ${endTime}`);
    handleChange({startTime, endTime});
  }, [startTime, endTime]);

  return (
    <Popover variant="timepicker" isOpen={isOpen} onOpen={open} onClose={close}>
      <PopoverTrigger>
        <InputGroup __css={styles.inputGroup} role='group'>
          <Input readOnly
            style={{borderColor: borderColor(isError, isOpen)}}
            placeholder={placeholder}
            value={time}
            isInvalid={isError}
            __css={styles.input}
          />
          <InputRightElement zIndex={0}>
            <Icon as={Clock} size={32} weight="fill" />
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent>
        <VStack py='6' px='4' spacing='6'>
          <DCTimeInput format24={format24} tzinfoName={tzinfoName} ref={startTimeInputRef} initTime={initialStartTime} setTime={setStartTime} htmlId={htmlId && `${htmlId}-start-time`} label={t("time_picker.start_time")}/>
          <DCTimeInput format24={format24} tzinfoName={tzinfoName} ref={endTimeInputRef} initTime={initialEndTime} setTime={setEndTime} htmlId={htmlId && `${htmlId}-end-time`} label={t("time_picker.end_time")}/>
          <HStack width='100%' justify='space-between'>
            <Button variant='outline' onClick={() => clearTimes()}>{t('event_tools.buttons.clear')}</Button>
            <Button onClick={() => close()}>{t('event_tools.buttons.done')}</Button>
          </HStack>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

export default DCTimePicker;
export const DCTimePickerWithLabel = withInputLabel(DCTimePicker);
