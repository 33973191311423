import React from "react";
import { HexColorPicker } from "react-colorful";
import "./colour-selector.css";
import ColourHistory from "./ColourHistory";

type ColourPopoverProps = {
  colour: string;
  colourHistory: Array<string>;
  setColour: (colourValue: string) => void;
};

const ColourPopoverContent: React.FunctionComponent<ColourPopoverProps> = ({
  colour,
  setColour,
  colourHistory,
}: ColourPopoverProps): JSX.Element => {


  return (
    <>
      <div className="hex-color-input--wrapper">
        <HexColorPicker color={colour || "#FFFFFF"} onChange={setColour} />
      </div>
      <ColourHistory
        colour={colour}
        colourHistory={colourHistory}
        setColour={setColour}
      />
    </>
  );
};

export default ColourPopoverContent;
