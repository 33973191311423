import React from 'react';
import { render } from '../../../EventWizard/test-utils';
import '@testing-library/jest-dom';
import userEvent from '@testing-library/user-event';
import { axe, toHaveNoViolations } from 'jest-axe';
expect.extend(toHaveNoViolations);

import ColourPicker, { ColourPickerProps } from './ColourPicker';

const props: ColourPickerProps = {
  value: '#123456',
  placeHolder: 'Pick a Colour',
  onChange: jest.fn(),
};

describe('ColourPicker', () => {
  test('has no accessibility violations', async () => {
    const { container } = render(<ColourPicker {...props} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  test('opens popup when enter key pressed', async () => {
    const colourPicker = render(<ColourPicker {...props} />);
    const input = colourPicker.container.querySelector('input');
    const popupElement = colourPicker.container.querySelector('.chakra-popover__popper');

    userEvent.tab();
    expect(input).toHaveFocus();
    expect(popupElement).not.toBeVisible();

    userEvent.keyboard('{Enter}');
    expect(popupElement).toBeVisible();
  });
});
