import React from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { act } from "@testing-library/react";

import { FAKE_USER, render } from "../../../EventWizard/test-utils";
import UserForm from "./UserForm";
import { UserFormProps } from "..";
import AppAdapter from "../../../../utils/adapter";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: UserFormProps = {
  user: AppAdapter.camelCaseKeys(FAKE_USER),
  submitForm,
  handleErrors,
  formErrors,
};


describe("New Facilitator Form", () => {
  test("passes without axe errors", async () => {
    const { container } = render(<UserForm {...requiredProps} />);

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
