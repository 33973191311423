import React from "react";
import { act, render } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { ThemeProvider } from "@chakra-ui/react";
import dcTheme from "../../../react/dcTheme";
import AdditionalFeaturesForm from ".";
import { AdditionalFeaturesProps } from "./AdditionalFeaturesForm";
import { FAKE_EVENT } from "../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: AdditionalFeaturesProps = {
  event: FAKE_EVENT,
  submitForm, handleErrors, formErrors
};


describe("Homepage Setup Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(
      <ThemeProvider theme={dcTheme}>
        <AdditionalFeaturesForm  {...requiredProps} />
      </ThemeProvider>
    );
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
