import React from "react";
import { act, render } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { ThemeProvider } from "@chakra-ui/react";
import dcTheme from "../../react/dcTheme";
import AgendaView from ".";

expect.extend(toHaveNoViolations);

const events = [
  {
    id: 10,
    title: "Smelly Pillow Fight",
    start: new Date(2022, 3, 27, 7, 0, 0, 0),
    end: new Date(2022, 3, 27, 9, 0, 0, 0),
    startAt: new Date(2022, 3, 27, 7, 0, 0, 0),
    endAt: new Date(2022, 3, 27, 9, 0, 0, 0),
    location: "Virtual Room 1",
    speakers: [["1", "Sample Presenter"], ["2", "Test Talker"]]
  },
  {
    id: 11,
    title: "Buggalo Riding",
    start: new Date(2022, 3, 27, 9, 0, 0, 0),
    end: new Date(2022, 3, 27, 10, 0, 0, 0),
    startAt: new Date(2022, 3, 27, 9, 0, 0, 0),
    endAt: new Date(2022, 3, 27, 10, 0, 0, 0),
    location: "Virtual Room 2",
    speakers: [["1", "Sample Presenter"]]
  },
];

const initialDate = new Date(2022, 3, 27, 0, 0, 0, 0)

const defaultValues = {
  initialDate: initialDate,
  events: events,
  locale: "en",
};

describe("Agenda View", () => {
  it("passes without axe errors", async () => {
    const { container } = render(
      <ThemeProvider theme={dcTheme}>
        <AgendaView {...defaultValues} />
      </ThemeProvider>
    );
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
