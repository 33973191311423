import * as yup from "yup";

export default () => {
  return yup.object({
    waitingRoom: yup.boolean().required(),
    screenSharing: yup.boolean().required(),
    chat: yup.boolean().required(),
    allowParticipantsToRename: yup.boolean().required(),
    requestPermissionToUnmute: yup.boolean().required(),
    whiteboard: yup.boolean().required(),
    participantVideo: yup.boolean().required(),
  });
};
