import React from 'react';
import { render, screen } from '@testing-library/react';
import '@testing-library/jest-dom';
import { axe, toHaveNoViolations } from 'jest-axe';
expect.extend(toHaveNoViolations);

import PaymentSummary, { PaymentSummaryProps } from './PaymentSummary';

const zeroCreditCardFeeTotal = {
  creditCardFees: "0.00",
  subTotal: "190.00",
  tax: "20.00",
  grandTotal: "210.00"
};

const props: PaymentSummaryProps = {
  paymentSummary: {
    lineItems: {
      addOns: [{
        name: "Session One",
        quantity: 1,
        totalExcludingTax: 90.00 // should pass as string
      }],
      registrations: [{
        delegateTypeName: "Standard Member",
        quantity: 1,
        totalExcludingTax: "100.00"
      }],
    },
    totals: {
      creditCardFees: "1.50",
      subTotal: "190.00",
      tax: "20.00",
      grandTotal: "211.50"
    },
    isFree: false,
  },
  currencyCode: "AUD",
  isLoadingPaymentIntent: false,
};

describe('PaymentSummary', () => {
  const { lineItems, totals } = props.paymentSummary;

  test('has no accessibility violations', async () => {
    const { container } = render(<PaymentSummary {...props} />);
    const results = await axe(container);

    expect(results).toHaveNoViolations();
  });

  describe('renders props correctly', () => {
    describe('when the payment summary is loading', () => {
      test('renders a loader in place of other elements', () => {
        render(<PaymentSummary {...props} isLoadingPaymentIntent={true} />);

        const grandTotalRow = screen.getAllByRole('status');
        expect(grandTotalRow.length).toBeGreaterThan(0);
      });
    });

    describe('paymentSummary', () => {
      test('totals.grandTotal for invoice payments', () => {
        const propsInvoiceTotals = {...props, paymentSummary: {...props.paymentSummary, totals: zeroCreditCardFeeTotal}};
        render(<PaymentSummary {...propsInvoiceTotals} />);

        const grandTotalRow = screen.getByText('group_registrations.checkout.payment_summary.total_price');

        expect(grandTotalRow).toBeInTheDocument();
        expect(grandTotalRow.closest('tr').innerHTML).toContain(zeroCreditCardFeeTotal.grandTotal);
      });

      test('totals.grandTotal for credit card payments', () => {
        render(<PaymentSummary {...props} />);

        const grandTotalRow = screen.getByText('group_registrations.checkout.payment_summary.total_price');

        expect(grandTotalRow).toBeInTheDocument();
        expect(grandTotalRow.closest('tr').innerHTML).toContain(totals.grandTotal);
      });

      test('totals.subTotal', () => {
        render(<PaymentSummary {...props} />);

        const subtotalRow = screen.getByText('group_registrations.checkout.payment_summary.sub_total');

        expect(subtotalRow).toBeInTheDocument();
        expect(subtotalRow.closest('tr').innerHTML).toContain(totals.subTotal);
      });

      test('totals.creditCardFees', () => {
        render(<PaymentSummary {...props} />);

        const creditCardFeeTotalRow = screen.getByText('group_registrations.checkout.payment_summary.credit_card_fee');

        expect(creditCardFeeTotalRow).toBeInTheDocument();
        expect(creditCardFeeTotalRow.closest('tr').innerHTML).toContain(totals.creditCardFees);
      });

      test('totals.creditCardFees', () => {
        render(<PaymentSummary {...props} />);

        const creditCardFeeTotalRow = screen.getByText('group_registrations.checkout.payment_summary.credit_card_fee');

        expect(creditCardFeeTotalRow).toBeInTheDocument();
        expect(creditCardFeeTotalRow.closest('tr').innerHTML).toContain(totals.creditCardFees);
      });

      test('credit card fees do not show up when there are none', () => {
        const zeroCreditCardFeeProps = {...props, paymentSummary: {...props.paymentSummary, totals: zeroCreditCardFeeTotal}};
        render(<PaymentSummary {...zeroCreditCardFeeProps} />);

        const creditCardFee = screen.queryByText('group_registrations.checkout.payment_summary.credit_card_fee');

        expect(creditCardFee).not.toBeInTheDocument();
      });

      test('totals.tax', () => {
        render(<PaymentSummary {...props} />);

        const taxRow = screen.getByText('group_registrations.checkout.payment_summary.tax');

        expect(taxRow).toBeInTheDocument();
        expect(taxRow.closest('tr').innerHTML).toContain(totals.tax);
      });

      describe('lineItems', () => {
        const { registrations, addOns } = lineItems;

        test('registrations', () => {
          render(<PaymentSummary {...props} />);

          const registration = screen.getByText(registrations[0].delegateTypeName);
          const registrationRow = registration.closest('tr').innerHTML;

          expect(registrationRow).toContain(registrations[0].delegateTypeName);
          expect(registrationRow).toContain(registrations[0].quantity.toString());
          expect(registrationRow).toContain(registrations[0].totalExcludingTax);
        });

        test('add ons (session users)', () => {
          render(<PaymentSummary {...props} />);

          const addOn = screen.getByText(addOns[0].name);
          const addOnRow = addOn.closest('tr').innerHTML;

          expect(addOnRow).toContain(addOns[0].name);
          expect(addOnRow).toContain(addOns[0].quantity.toString());
          expect(addOnRow).toContain(addOns[0].totalExcludingTax.toString());
        });
      });
    });
  });
});
