import React, { ReactNode, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { setCurrencyFormat } from '../../helpers/currencyFormatter';
import { PaymentIntentData } from '../../GroupRegistration/types';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { removeTextTransformation } from './styles';

type CheckoutWithStripeProps = {
  pageActions: ReactNode
  paymentIntentData: PaymentIntentData
  checkoutRedirectUrl: string
  countryCode: string
  groupRegistrationId: string
  paymentMethodName: string
  placeholderReceiptEmail: string
  updateReceiptEmailPath: string
  setIsCheckingOut: (React.Dispatch<React.SetStateAction<boolean>>)
  verifyPaymentAndRedirect: (event?: React.FormEvent) => Promise<void>
}

const CheckoutWithStripe = ({
  pageActions,
  paymentIntentData,
  checkoutRedirectUrl,
  countryCode,
  groupRegistrationId,
  paymentMethodName,
  placeholderReceiptEmail,
  updateReceiptEmailPath,
  setIsCheckingOut,
  verifyPaymentAndRedirect,
}: CheckoutWithStripeProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string | undefined>();
  const [receiptEmailValid, setReceiptEmailValid] = useState(true);
  const [receiptEmail, setReceiptEmail] = useState<string>(placeholderReceiptEmail);
  const currencyFormatter = setCurrencyFormat(paymentIntentData.currencyCode);

  const receiptEmailSchema = object({
    receiptEmail: string().email()
  });

  const validateReceiptEmail = async (receiptEmail: string) => {
    const receiptEmailValid = !receiptEmail || await receiptEmailSchema.isValid({ receiptEmail: receiptEmail });
    if (receiptEmailValid) {
      setReceiptEmailValid(true);
      setReceiptEmail(receiptEmail);
    } else {
      setReceiptEmailValid(false);
    }
  };

  const updateReceiptEmail = async (receiptEmail: string) => {
    const updateReceiptEmailResponse = await fetch(updateReceiptEmailPath, {
      method: 'PUT',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        group_registration_id: groupRegistrationId,
        payment_method_name: paymentMethodName,
        receipt_email: receiptEmail
      }),
    });
    const updateReceiptEmailResponseData = await updateReceiptEmailResponse.json();
    setReceiptEmail(updateReceiptEmailResponseData.receiptEmail);
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setIsCheckingOut(true);

    try {
      await updateReceiptEmail(receiptEmail);
      const { error } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: document.location.origin + checkoutRedirectUrl,
          payment_method_data: {
            billing_details: {
              address: { country: countryCode },
            },
          },
        },
      });

      if (error) {
        setStripeErrorMessage(error.message);
        setIsCheckingOut(false);
      } else {
        // Kick off a request to verify and process the payment in the background
        // A job is run to pick up any stragglers if the page is redirected before this hits
        await verifyPaymentAndRedirect();
      }
    } catch (e) {
      setStripeErrorMessage(t('flash.error_occurred'));
      setIsCheckingOut(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className='input formInput'>
        <label htmlFor='payment-country' style={removeTextTransformation}>{t('group_registrations.checkout.receipt_email.heading')}</label><br />
        <input
          name='payment-receipt-email'
          className='form-control text-left'
          style={{ height: 38 }}
          type="email"
          onChange={(event) => validateReceiptEmail(event.target.value)}
          placeholder={placeholderReceiptEmail}
        />
        {receiptEmailValid && (
          <p className='payment-receipt-email'>{t('group_registrations.checkout.receipt_email.hint')}</p>
        )}
        {!receiptEmailValid && (
          <span className='error js-error-field'>{t('validations.email')}</span>
        )}
      </div>

      <div className="credit-card-information mx-auto w-100">
        {elements ? (
          <PaymentElement options={{ fields: { billingDetails: { address: { country: 'never' } } } }} />
        ) : (
          <div className='w-100 d-flex justify-content-center mt-5'>
            <div className="spinner-border text-primary text-center mx-auto"></div>
          </div>
        )}
      </div>

      <div className="text-center my-3">
        <h3>{currencyFormatter.format(parseFloat(paymentIntentData.paymentSummary.totals.grandTotal))}</h3> ({t('registrations.tax_rates.including_tax', { tax_name: 'GST' })})
      </div>

      {stripeErrorMessage && (
        <h6 className='text-center text-danger my-3'>{stripeErrorMessage}</h6>
      )}

      {pageActions}
    </form>
  );
};

export default CheckoutWithStripe;
