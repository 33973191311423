import React from "react"
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { Plus } from 'phosphor-react';
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";

export interface AddItemOptionProps {
  onAddItem?: () => void;
  addItemText?: string;
}

const AddItemOption = ({ onAddItem, addItemText }: AddItemOptionProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      pl={2}
      pt={2}
      pb={1}
      alignItems="center"
      onClick={onAddItem}
      cursor="pointer"
      borderBottom={`1px solid ${fT.colors.grey.base}`}
    >
      <Plus weight="bold" size="14px" />
      <Text pl={1.5} fontWeight="bold" fontSize="16px">
        {addItemText || t("actions.add_new_item")}
      </Text>
    </Flex>
  );
};

export default AddItemOption;
