import * as React from "react";
import { G, Path, Rect } from "./AnimatableSvg";

function SvgEmail(props) {
  return (
    <svg
      width={243}
      height={238}
      viewBox="0 0 243 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G id="email_svg_Email" filter="url(#email_svg_filter0_d_819_6406)">
        <Path
          id="email_svg_Page"
          d="M10 16C10 11.5817 13.5817 8 18 8H225C229.418 8 233 11.5817 233 16V176H10V16Z"
          fill="white"
        />
        <Path
          id="email_svg_Footer"
          d="M10 176H233V218C233 222.418 229.418 226 225 226H18C13.5817 226 10 222.418 10 218V176Z"
          fill="#0E153A"
        />
        <Rect
          id="email_svg_Website"
          x={72}
          y={207}
          width={100}
          height={6}
          rx={3}
          fill="#929094"
        />
        <G id="email_svg_Email Body">
          <Rect
            id="email_svg_Body"
            x={25}
            y={96}
            width={80}
            height={6}
            rx={3}
            fill="#E5E1E6"
          />
          <Rect
            id="email_svg_Body_2"
            x={25}
            y={140}
            width={80}
            height={6}
            rx={3}
            fill="#E5E1E6"
          />
          <Rect
            id="email_svg_Body_3"
            x={25}
            y={107}
            width={90}
            height={6}
            rx={3}
            fill="#E5E1E6"
          />
          <Rect
            id="email_svg_Body_4"
            x={25}
            y={151}
            width={90}
            height={6}
            rx={3}
            fill="#E5E1E6"
          />
          <Rect
            id="email_svg_Body_5"
            x={25}
            y={118}
            width={82}
            height={6}
            rx={3}
            fill="#E5E1E6"
          />
          <Rect
            id="email_svg_Body_6"
            x={25}
            y={129}
            width={86}
            height={6}
            rx={3}
            fill="#E5E1E6"
          />
          <Rect
            id="email_svg_Email body"
            x={20.5}
            y={91.5}
            width={100}
            height={70}
            rx={3.5}
            stroke="white"
          />
        </G>
        <G id="email_svg_Email Banner">
          <Path
            id="email_svg_Header"
            d="M10 16C10 11.5817 13.5817 8 18 8H225C229.418 8 233 11.5817 233 16V87H10V16Z"
            fill="white"
          />
          <Rect
            id="email_svg_Email banner"
            x={25}
            y={23}
            width={193}
            height={54}
            rx={4}
            fill="#E5E1E6"
          />
          <G id="email_svg_Image">
            <Path
              id="email_svg_Vector"
              d="M124.5 48.25C124.5 48.4489 124.421 48.6397 124.28 48.7803C124.14 48.921 123.949 49 123.75 49C123.551 49 123.36 48.921 123.22 48.7803C123.079 48.6397 123 48.4489 123 48.25C123 48.0511 123.079 47.8603 123.22 47.7197C123.36 47.579 123.551 47.5 123.75 47.5C123.949 47.5 124.14 47.579 124.28 47.7197C124.421 47.8603 124.5 48.0511 124.5 48.25V48.25ZM128.5 45.5V53.5V54.5C128.5 54.7652 128.395 55.0196 128.207 55.2071C128.02 55.3946 127.765 55.5 127.5 55.5H116.5C116.235 55.5 115.98 55.3946 115.793 55.2071C115.605 55.0196 115.5 54.7652 115.5 54.5V52.5V45.5C115.5 45.2348 115.605 44.9804 115.793 44.7929C115.98 44.6054 116.235 44.5 116.5 44.5H127.5C127.765 44.5 128.02 44.6054 128.207 44.7929C128.395 44.9804 128.5 45.2348 128.5 45.5ZM127.5 52.2937V45.5H116.5V51.2937L118.794 49C118.982 48.8145 119.236 48.7105 119.5 48.7105C119.764 48.7105 120.018 48.8145 120.206 49L123 51.7937L124.294 50.5C124.482 50.3145 124.736 50.2105 125 50.2105C125.264 50.2105 125.518 50.3145 125.706 50.5L127.5 52.2937Z"
              fill="#C8C5CA"
            />
          </G>
        </G>
        <G id="email_svg_Social Links">
          <circle
            id="email_svg_Social"
            cx={133.5}
            cy={191.5}
            r={6.5}
            fill="#AEAEB2"
          />
          <circle
            id="email_svg_Social_2"
            cx={87.5}
            cy={191.5}
            r={6.5}
            fill="#AEAEB2"
          />
          <circle
            id="email_svg_Social_3"
            cx={156.5}
            cy={191.5}
            r={6.5}
            fill="#AEAEB2"
          />
          <circle
            id="email_svg_Social_4"
            cx={110.5}
            cy={191.5}
            r={6.5}
            fill="#AEAEB2"
          />
          <Rect
            id="email_svg_Social links"
            x={73}
            y={179}
            width={99}
            height={25}
            rx={4}
            fill="#C4C4C4"
            fillOpacity={0.01}
          />
        </G>
      </G>
      <defs>
        <filter
          id="email_svg_filter0_d_819_6406"
          x={0}
          y={0}
          width={243}
          height={238}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_819_6406"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_819_6406"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgEmail;

