import React, { useState } from "react";
import DCTable from "../../common/DCTable";
import { Box, Flex, Icon, Avatar, Text } from "@chakra-ui/react";
import { PencilSimple, Trash } from "phosphor-react";
import { useTranslation } from "react-i18next";
import DCModal from "../../common/DCModal/DCModal";

type UserTableEntry = {
  id: number;
  fullname: string;
  email: string;
  profile_title: string;
  bio: string;
  avatar: string;
};

type UserTableProps = {
  data: UserTableEntry[];
  handleEdit: () => void;
  handleDelete: (userId: string) => void;
};

const UsersTable: React.FC<UserTableProps> = ({
  data,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation();
  const [focusedFacilitatorId, setFocusedFacilitatorId] = useState(null);

  const closeDialog = () => setFocusedFacilitatorId(null);

  const handleFacilitatorDelete = () => {
    handleDelete(focusedFacilitatorId);
    setFocusedFacilitatorId(null);
  };

  const columns = [
    {
      Header: t("form.id.label"),
      accessor: "id",
    },
    {
      Header: t("form.name.label"),
      accessor: (row) => (
        <Flex>
          <Avatar name={row.fullname} mr="8px" src={row.avatar} />
          <Text mt="5px" fontWeight="600">
            {row.fullname}
          </Text>
        </Flex>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original["fullname"];
        const b = rowB.original["fullname"];
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0;
        }
      },
    },
    {
      Header: t("form.email.label"),
      accessor: "email",
    },
    {
      Header: t("form.job_title.label"),
      accessor: "profile_title",
    },
    {
      Header: t("form.bio.label"),
      accessor: "bio",
    },
    {
      Header: t("actions.name"),
      disableSortBy: true,
      accessor: ({ sessionFacilitatorId }) => (
        <Flex>
          <Icon
            as={PencilSimple}
            onClick={handleEdit}
            weight="fill"
            _hover={{ cursor: "pointer" }}
          />
          <Icon
            as={Trash}
            onClick={() => setFocusedFacilitatorId(sessionFacilitatorId)}
            weight="fill"
            _hover={{ cursor: "pointer" }}
            ml="15px"
          />
        </Flex>
      ),
    },
  ];

  const cellProps = (cell) => {
    if (cell.key.includes("bio")) {
      return {
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100px",
        },
      };
    } else {
      return {};
    }
  };

  return (
    <Box p="20px">
      <DCTable data={data} columns={columns} cellProps={cellProps} />
      <DCModal
        title={t("facilitator_delete_modal.title")}
        content={t("facilitator_delete_modal.body")}
        primaryButtonLabel={t("facilitator_delete_modal.primary_button")}
        secondaryButtonLabel={t("facilitator_delete_modal.secondary_button")}
        isOpen={focusedFacilitatorId != null}
        onClose={closeDialog}
        onClickPrimary={handleFacilitatorDelete}
        onClickSecondary={closeDialog}
      />
    </Box>
  );
};

export default UsersTable;
