import {
  Flex,
  Text,
  FormControl,
  FormErrorMessage,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { Warning } from "phosphor-react";
import moment from "moment";
import { FieldErrors, UseFormSetValue } from "react-hook-form";

import { errorMessagesCombiner } from "../../../EventWizard/helpers/customValidators";
import DCTimePicker from "../../common/DCTimePicker";
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";
import DCDropdown from "../../common/DCDropdown";
import { DaysSetUpFormValues } from "./DaysSetUpForm";
import { DayValues } from "./types";

type DayFormProps = {
  day: string;
  availableDays: { value: string; label: string }[];
  dayValues: DayValues;
  errors?: FieldErrors;
  nestIndex: number;
  setValue: UseFormSetValue<DaysSetUpFormValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: any;
};

const DayForm: React.FC<DayFormProps> = ({
  day,
  dayValues,
  availableDays,
  errors,
  nestIndex,
  setValue,
  trigger,
}) => {
  const onChangeTimes = e => {
    setValue(`days.${nestIndex}.startTime`, e.startTime);
    setValue(`days.${nestIndex}.endTime`, e.endTime);
    trigger([`days.${nestIndex}.startTime`, `days.${nestIndex}.endTime`]);
  };

  const onChangeDate = e => {
    setValue(`days.${nestIndex}.date`, e.option.value);
  };

  const generateDate = (date: string, time: string): Date => {
    const dateTime = moment(
      `${moment(date).format("DD/MM/YY")} ${time}`,
      "DD/MM/YY HH:mm"
    ).toDate();
    return dateTime;
  };

  const isTimeInvalid = !!(errors?.startTime || errors?.endTime);

  return (
    <Flex my={2} flexGrow={1}>
      <Text alignSelf="center" pr={2} w="10%">
        {day}
      </Text>
      <FormControl mb={0} px={2} isInvalid={errors?.date != undefined} w="initial">
        <DCDropdown
          isError={!!errors?.date}
          options={availableDays}
          handleChange={onChangeDate}
          preselectedOption={{
            value: dayValues.date,
            label: moment(dayValues.date).format("YYYY-MM-DD"),
          }}
        />
        {!!errors?.date && (
          <FormErrorMessage>
            <Icon as={Warning} weight="fill" color={fT.colors.alert} />
            {errors?.date?.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl
        mb={0}
        px={2}
        isInvalid={isTimeInvalid}
        w="initial"
      >
        <DCTimePicker
          isError={isTimeInvalid}
          format24
          initialStartTime={generateDate(dayValues.date, dayValues.startTime)}
          initialEndTime={generateDate(dayValues.date, dayValues.endTime)}
          handleChange={onChangeTimes}
          htmlId={`day-${nestIndex}`}
        />
        {!!(errors?.startTime || errors?.endTime) && (
          <FormErrorMessage>
            <Icon as={Warning} weight="fill" color={fT.colors.alert} />
            {errorMessagesCombiner(["startTime","endTime"], errors)}
          </FormErrorMessage>
        )}
      </FormControl>
    </Flex>
  );
};

export default DayForm;
