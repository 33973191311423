import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { acceptInvite } from './acceptInvite';

const passwordStyle = 'password required';

const schema = yup.object().shape({
  password: yup.string().required('Password is required').min(6, 'Must be at least 6 characters long'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  firstName: yup.string().required('First name is required').min(3, 'First name must be at least 3 characters long'),
  lastName: yup.string().required('Last name is required').min(3, 'Last name must be at least 3 characters long'),
});

type FormAttributes = {
  password: string
  passwordConfirmation: string
  firstName: string
  lastName: string
}

type Props = {
  token: string
  user: {
    firstName?: string
    lastName?: string
    email: string
  }
  formLabels: {
    firstName: string
    lastName: string
    email: string
    password: string,
    passwordConfirmation: string
    confirm: string
  }
}

type FormFields = keyof FormAttributes

const GroupRegistrationInvite = (props: Props) => {
  const { token: invitation_token, user, formLabels } = props;
  // TODO: Need to find a better way to handle Internationalisation, currently all lables just get passed into the component

  const { register, handleSubmit, setError, formState } = useForm<FormAttributes>({
    defaultValues: { firstName: user.firstName, lastName: user.lastName },
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { errors, isSubmitted, isValid } = formState;

  const handleServerErrors = (errors: Record<FormFields, string[]>) => {
    Object.entries(errors).map(([key, value]) => {
      setError(key as FormFields, {
        type: 'validation',
        message: value.join(', ')
      });
    });
  };

  const submitInvite = async (data: FormAttributes) => {
    const result = await acceptInvite({
      invitationToken: invitation_token,
      ...data
    });

    if (result.success) {
      window.location.assign(result.redirect);
    } else {
      handleServerErrors(result.errors);
    }
  };

  return (
    <form onSubmit={handleSubmit(submitInvite)}>
      <div>
        <label htmlFor='firstName'>{formLabels.firstName}</label>
        <input aria-invalid={errors.firstName ? 'true' : 'false'} id='firstName' type='text' {...register('firstName')} className={passwordStyle} />
        <span className='error js-error-field'>{errors.firstName?.message}</span>
      </div>

      <div>
        <label htmlFor='lastName'>{formLabels.lastName}</label>
        <input aria-invalid={errors.lastName ? 'true' : 'false'} id='lastName' type='text' {...register('lastName')} className={passwordStyle} />
        <span className='error js-error-field'>{errors.lastName?.message}</span>
      </div>

      <div>
        <label htmlFor='email'>{formLabels.email}</label>
        <input id='email' type='email' className={passwordStyle} value={user.email} disabled />
      </div>

      <div>
        <label htmlFor='password'>{formLabels.password}</label>
        <input aria-invalid={errors.password ? 'true' : 'false'} id='password' type='password' {...register('password')} className={passwordStyle} />
        <span className='error js-error-field'>{errors.password?.message}</span>
      </div>

      <div>
        <label htmlFor='passwordConfirmation'>{formLabels.passwordConfirmation}</label>
        <input aria-invalid={errors.passwordConfirmation ? 'true' : 'false'} id='passwordConfirmation' type='password' {...register('passwordConfirmation')} className={passwordStyle} />
        <span className='error js-error-field'>{errors.passwordConfirmation?.message}</span>
      </div>

      <input aria-label='invitationToken' type='hidden' name='invitation_token' value={invitation_token} />

      <button className='btn btn-primary rounded-pill px-5 py-3 m-2' disabled={isSubmitted && !isValid}>{formLabels.confirm}</button>
    </form>
  );
};

export default GroupRegistrationInvite;
