import React from "react";
import userEvent from "@testing-library/user-event";
import { act, waitFor } from "@testing-library/react";
import * as mockReactRouterDom from "react-router-dom";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";

import Publish from "./Publish";
import {
  mockCustomContexts,
  mockRestClientResolved,
  render,
  screen,
} from "../../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

mockCustomContexts();

const mockNavigate = jest.fn();

jest.mock("react-router-dom", () => ({
  ...(jest.requireActual("react-router-dom") as typeof mockReactRouterDom),
  useNavigate: () => mockNavigate,
}));

const assignMock = jest.fn();

delete window.location;
window.location = { assign: assignMock } as unknown as any; // eslint-disable-line @typescript-eslint/no-explicit-any

describe("Webinar Publish", () => {
  afterEach(() => {
    assignMock.mockClear();
    mockNavigate.mockClear();
  });

  test("passes without axe errors", async () => {
    const { container } = render(<Publish />);
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  test("preview my event website button should have a href to the webinar home page", async () => {
    render(<Publish />);

    expect(
      screen.getByText("publish_intro.preview_link").closest("a")
    ).toHaveAttribute("href", "/?event_id=1");
  });

  test("clicking on previous calls navigate", async () => {
    render(<Publish />);

    await waitFor(() => {
      userEvent.click(screen.getByText("views.pagination.previous_item"));
    });
    expect(mockNavigate).toHaveBeenCalledWith(
      "/admin/event-tools/1/webinar-website/metadata"
    );
  });

  test("clicking on exit takes you back to webinar dashboard", async () => {
    render(<Publish />);

    await waitFor(() =>
      userEvent.click(screen.getByText("event_tools.buttons.exit"))
    );
    expect(window.location.href).toEqual("/admin/event-tools");
  });

  test("clicking on publish should opens default event modal when other event exists", async () => {
    render(<Publish />);
    mockRestClientResolved("get", { public_events_presence: true });

    await waitFor(() =>
      userEvent.click(screen.getByText("event_tools.buttons.publish"))
    );
    expect(screen.getByText("publish_modal.title")).toBeInTheDocument();
  });

  test("clicking on publish should navigate to webinar index when no other event exists", async () => {
    render(<Publish />);
    mockRestClientResolved("get", { public_events_presence: false });
    mockRestClientResolved("post", { success: true });

    await waitFor(() =>
      userEvent.click(screen.getByText("event_tools.buttons.publish"))
    );
    expect(window.location.href).toEqual("/admin/event-tools");
  });
});
