import { useEffect, useState } from "react";
import UserType from "../../../../EventWizard/models/User";
import UserService from "../../../../EventWizard/services/userService";
import AppAdapter from "../../../../../utils/adapter";

export type SelectOptionType = {
  value: string;
  label: string;
};

const useUserFetcher = (eventId: string, selectedUserIds: string[]) => {
  const [users, setUsers] = useState<SelectOptionType[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SelectOptionType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const userOptions = (data: UserType[]): Array<SelectOptionType> => {
    return data.map(user => {
      return {
        label: user.fullname,
        value: user.id,
      };
    });
  };

  const fetchUsers = async () => {
    const payload = await UserService.list(eventId, "dropdown_selections");

    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result);
      const userSelection = userOptions(response);
      setUsers(userSelection);
      setSelectedUsers(
        userSelection.filter(user => selectedUserIds?.includes(user.value))
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return { selectedUsers, setSelectedUsers, users, setUsers, isLoading };
};

export default useUserFetcher;
