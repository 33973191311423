import React from "react";
import "@testing-library/jest-dom";
import userEvent from "@testing-library/user-event";
import { axe, toHaveNoViolations } from "jest-axe";

import {
  render,
  screen,
  waitFor,
  mockCustomContexts,
} from "../../../../EventWizard/test-utils";
import { SideNavigation } from "./SideNavigation";
expect.extend(toHaveNoViolations);

mockCustomContexts();

describe("SideNavigation", () => {
  describe("accessibility", () => {
    test("has no accessibility violations", async () => {
      const { container } = render(<SideNavigation />);

      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  describe("Agenda", () => {
    describe("Without facilitators and/or speakers page", () => {
      test("does not render presentation element", async () => {
        render(<SideNavigation />);

        await waitFor(() => {
          userEvent.click(screen.getByText("event_tools.navigation.agenda"));
        });

        expect(
          screen.queryByText("event_tools.navigation.presentation")
        ).not.toBeInTheDocument();
      });
    });

    describe("With facilitator and/or speakers page", () => {
      test("renders presentation element", async () => {
        render(<SideNavigation speakers facilitators />);

        await waitFor(() => {
          userEvent.click(screen.getByText("event_tools.navigation.agenda"));
        });

        expect(
          screen.getByText("event_tools.navigation.presentation")
        ).toBeInTheDocument();
      });

      test("renders speakers element", async () => {
        render(<SideNavigation speakers />);

        await waitFor(() => {
          userEvent.click(screen.getByText("event_tools.navigation.agenda"));
        });

        expect(
          screen.getByText("event_tools.navigation.speaker")
        ).toBeInTheDocument();
      });

      test("renders facilitators element", async () => {
        render(<SideNavigation facilitators />);

        await waitFor(() => {
          userEvent.click(screen.getByText("event_tools.navigation.agenda"));
        });

        expect(
          screen.getByText("event_tools.navigation.facilitator")
        ).toBeInTheDocument();
      });

      test("will not render elements when not required", async () => {
        render(<SideNavigation />);

        await waitFor(() => {
          userEvent.click(screen.getByText("event_tools.navigation.agenda"));
        });

        expect(
          screen.queryByText("event_tools.navigation.facilitator")
        ).not.toBeInTheDocument();
        expect(
          screen.queryByText("event_tools.navigation.speaker")
        ).not.toBeInTheDocument();
      });
    });

    describe("Webinar Website", () => {
      test("renders relevant sub navigation links when clicked", async () => {
        render(<SideNavigation />);
        await waitFor(() => {
          userEvent.click(
            screen.getByText("event_tools.navigation.webinar.webinar_website")
          );
        });

        expect(
          screen.getByText("event_tools.navigation.brand_and_style")
        ).toBeInTheDocument();
        expect(
          screen.getByText("event_tools.navigation.brand_and_style")
        ).toBeInTheDocument();
        expect(
          screen.getByText("event_tools.navigation.brand_and_style")
        ).toBeInTheDocument();
      });
    });
  });
});
