import * as yup from "yup";

export default t => {
  return yup.object({
    moderatorCcEmail: yup.string()
      .email(t("event_tools.validations.email"))
      .required(t("event_tools.validations.required", { field: t("event_configurations.email.label") }))
      .typeError(t("event_tools.validations.required", { field: t("event_configurations.email.label") })),
  });
};
