import React, { ReactNode } from "react";
import {
  Flex,
  Text,
  Image,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { figmaTheme as fT } from "../../../react/dcTheme";

type SectionIntroProps = {
  imageSrc: string;
  textContent: ReactNode;
  onClickNext: () => void;
}

const SectionIntro = ({ textContent, imageSrc, onClickNext }: SectionIntroProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        h="100%"
        justify="center"
        align="center"
        direction="column"
      >
        <Image
          src={imageSrc}
          alt={t("images.image.alt")}
          w="30%"
          maxW="300px"
          mb={10}
        />
        <Text
          as="div"
          w="80%"
          maxW="800px"
          textAlign="center"
          lineHeight="24px"
          fontSize="18px"
        >
          {textContent}
        </Text>
      </Flex>
      <Flex
        direction="row-reverse"
        py={5}
        px={10}
        borderTop="1px"
        borderTopColor={fT.colors.grey.base}
        bg={fT.colors.primary[100]}
      >
        <Button m={2} onClick={onClickNext}>
          {t("event_tools.buttons.got_it")}
        </Button>
      </Flex>
    </>
  );
};

export default SectionIntro;
