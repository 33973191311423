import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Image,
  Text,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import "cropperjs/dist/cropper.css";
import { UploadSimple } from "phosphor-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Cropper from "react-cropper";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { noop } from "../../../utils";

type DCFileUploaderProps = {
  acceptedFormats: string;
  aspectRatio: number;
  maxSize: number;
  uploadPhoto: (file: Blob) => void;
  source: string;
  disabled?: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};

interface HTMLImageElementWCropper extends HTMLImageElement {
  cropper: Cropper;
}

const DCFileUploader: React.FunctionComponent<DCFileUploaderProps> = ({
  acceptedFormats = "image/*",
  aspectRatio = 1,
  maxSize = 10000000,
  source = "",
  disabled = false,
  onMouseOver = noop,
  onMouseOut = noop,
  uploadPhoto = noop
}: DCFileUploaderProps): JSX.Element => {
  enum STATUSES {
    EMPTY = "empty", // can load and edit
    EDITING = "editing", // can progress to done
    DONE = "done", //can replace and restart at empty
  }
  const { t } = useTranslation();

  const styles = useMultiStyleConfig("FileUploader", {});

  const baseStyle = styles.dropzone;
  const acceptStyle = styles.dropzone_accepted;
  const rejectStyle = styles.dropzone_rejected;

  // does a pic already exist?
  const initialStatus = source ? STATUSES.DONE : STATUSES.EMPTY;

  const [status, setStatus] = useState<STATUSES>(initialStatus);
  const [src, setSrc] = useState<string>("");
  const [cropResult, setCropResult] = useState<string>(source);
  const cropperRef = useRef<HTMLImageElementWCropper>(null);
  const [files, setFiles] = useState<Array<string>>([]);

  // dont update state if unmounted
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: { [acceptedFormats]: [] },
    maxFiles: 1,
    disabled,
    maxSize,
    onDropAccepted: (acceptedFiles) => {
      setPicture(acceptedFiles[0]);
      setFiles(acceptedFiles.map((file) => Object.assign(file)));
      setStatus(STATUSES.EDITING);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  );

  const error_map = {
    "file-too-large": `${t(
      "events.form.file_uploader.file_size_validation"
    )} : ${maxSize / 1000000}Mb`,
    "file-invalid-type": `${t(
      "events.form.file_uploader.file_type_validation"
    )} : ${acceptedFormats}`,
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    errors.map((e) => (
      <li key={e.code}>
        <WarningIcon /> {file.name} {error_map[e.code]}
      </li>
    ))
  );

  const setPicture = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (mounted.current) {
        setSrc(reader.result as string);
      }
    };
    reader.readAsDataURL(file);
  };

  const fileNamefromUrl = (source) => {
    return source?.split("/").pop()?.split("#")[0]?.split("?")[0];
  };

  const pictureName = (files) => {
    return files.length == 0 ? fileNamefromUrl(source) : files[0].name || t('file_uploader.untitled');
  };

  const onCrop = () => {
    const imageElement: null | HTMLImageElementWCropper = cropperRef?.current;
    if (imageElement) {
      const cropper: Cropper = imageElement.cropper;
      setCropResult(cropper.getCroppedCanvas().toDataURL("image/png", 1.0));
    }
  };

  const onDone = () => {
    const imageElement: HTMLImageElementWCropper = cropperRef?.current;
    if (imageElement) {
      const cropper: Cropper = imageElement.cropper;
      setStatus(STATUSES.DONE);
      cropper.getCroppedCanvas().toBlob((blob) => uploadPhoto(new File([blob], pictureName(files))));
    }
  };

  const onReplace = () => {
    setFiles([]);
    setStatus(STATUSES.EMPTY);
  };

  return (
    <Box onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      {status === STATUSES.EMPTY && (
        <>
          {disabled ?
            <div>{t('file_uploader.no_file_uploaded')}</div> :
            <>
              <div {...getRootProps({ className: "dropzone" })} style={style as React.CSSProperties}>
                <input {...getInputProps()} />
                <UploadSimple size={32} />
                <p>{t("events.form.file_uploader.prompt")}</p>
                <p>
                  {acceptedFormats} : {maxSize / 1000000}Mb
                </p>
              </div>
              {fileRejectionItems.length > 0 && (
                <Box p="4" color={styles.error?.color as string}>
                  <ul>{fileRejectionItems}</ul>
                </Box>
              )}
            </>
          }
        </>
      )}

      {status === STATUSES.EDITING && (
        <>
          <Cropper
            style={styles.cropper as React.CSSProperties}
            checkOrientation={false}
            aspectRatio={aspectRatio}
            initialAspectRatio={aspectRatio}
            guides={false}
            src={src}
            ref={cropperRef}
            crop={onCrop}
            viewMode={0}
            zoomable={true}
            cropBoxMovable={false}
            cropBoxResizable={false}
            autoCropArea={1}
            dragMode="move"
          />
          <Center>
            <Button variant="solid" my={2} onClick={onDone}>
              {t("events.form.file_uploader.crop_image")}
            </Button>
          </Center>
        </>
      )}
      {status === STATUSES.DONE && (
        <>
          <Image
            src={cropResult}
            alt="croppedPicture"
            boxSize="120px"
            w="auto"
            sx={styles.image}
          />
          <Text fontSize="14px">{pictureName(files)}</Text>
          {!disabled && <Button variant="outline" my={2} onClick={onReplace}>
            {t("events.form.file_uploader.replace_image")}
          </Button>}
        </>
      )}
    </Box>
  );
};

export default DCFileUploader;
