import React from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { EventType } from "../../../EventWizard/models/Event";
import SessionPageLayout from "./SessionPageLayout";
import { FAKE_EVENT, render } from "../../../EventWizard/test-utils";
expect.extend(toHaveNoViolations);

type Props = {
  event: EventType;
  update: () => Promise<boolean>;
  exitForm: () => void;
  nextForm: () => void;
};

const props: Props = {
  event: FAKE_EVENT,

  update: () => Promise.resolve(true),
  exitForm: () => console.log("exit form"),
  nextForm: () => console.log("next form"),
};

describe("SessionPageLayout", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<SessionPageLayout {...props} />);
    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });
});
