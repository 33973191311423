import React from "react";
import { fireEvent, screen, waitFor } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
expect.extend(toHaveNoViolations);

import WebsiteStylingForm from ".";
import { WebsiteStylingFormProps } from "./WebsiteStylingForm";
import { FAKE_EVENT, render } from "../../../EventWizard/test-utils";

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: WebsiteStylingFormProps = {
  event: FAKE_EVENT,
  submitForm,
  handleErrors,
  formErrors,
};

describe("Website Styling Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<WebsiteStylingForm {...requiredProps} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe("primary color", () => {
    it("is required", async () => {
      render(<WebsiteStylingForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "branding_page.website_styling.primary_colour.label",
          { exact: false }
        );
        fireEvent.click(inputLabel);
        fireEvent.change(inputLabel, {
          target: { value: "" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    it("must be a hexadecimal string", async () => {
      render(<WebsiteStylingForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "branding_page.website_styling.primary_colour.label",
          { exact: false }
        );
        fireEvent.click(inputLabel);
        fireEvent.change(inputLabel, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("secondary color", () => {
    it("is required", async () => {
      render(<WebsiteStylingForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "branding_page.website_styling.secondary_colour.label",
          { exact: false }
        );
        fireEvent.click(inputLabel);
        fireEvent.change(inputLabel, {
          target: { value: "" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    it("must be a hexadecimal string", async () => {
      render(<WebsiteStylingForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "branding_page.website_styling.secondary_colour.label",
          { exact: false }
        );
        fireEvent.click(inputLabel);
        fireEvent.change(inputLabel, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });
});
