import { PollPropsSource } from "./types";
import { figmaTheme as fT } from "../react/dcTheme/figmaTheme";

export const filterPolls = (
  polls: Array<PollPropsSource>,
  conditions
): Array<PollPropsSource> => {
  if (polls.length == 0) return null;

  const conditionKeys = Object.keys(conditions);

  return polls.filter((poll: PollPropsSource) =>
    conditionKeys.reduce(
      (res, key) => (poll.attributes[key] != conditions[key] ? false : res),
      true
    )
  );
};

export const priorityPoll = (
  polls: Array<PollPropsSource>
): PollPropsSource => {
  const livePolls = filterPolls(polls, { displaying: true, completed: false });

  if (livePolls.length > 0) {
    return livePolls[0];
  }

  const completeResultPolls = filterPolls(polls, {
    completed: true,
    results: true,
  });
  if (completeResultPolls.length > 0) {
    return completeResultPolls[0];
  }

  const remainingPolls = filterPolls(polls, {
    display: true,
  });
  if (remainingPolls.length > 0) return remainingPolls[0] || null;
};

export const bgColourProps = (
  isExistingResponse: boolean,
  percentage: number
) => {
  const greenColour = "#32DD32";
  const dimmedGreenColour = "#32DD3290";
  const checkedResponseColour = isExistingResponse
    ? greenColour
    : fT.colors.primary[70];
  const existingResponseColour = isExistingResponse
    ? dimmedGreenColour
    : fT.colors.primary[90];
  const barSize = percentage * 100;

  const hiddenResultsColorProps = {
    bg: isExistingResponse && existingResponseColour,
    _checked: { bg: checkedResponseColour },
  };

  const resultsColorProps = {
    bgGradient: `linear(90deg, ${existingResponseColour} ${barSize}%, #FFF0 ${barSize}%)`,
    _active: {
      bg: fT.colors.primary[95],
      bgGradient: `linear(90deg, ${fT.colors.primary[50]} ${barSize}%, #FFF0 ${barSize}%)`,
    },
    _hover: {
      bg: fT.colors.primary[95],
      bgGradient: `linear(90deg, ${fT.colors.primary[70]} ${barSize}%, #FFF0 ${barSize}%)`,
    },
    _checked: {
      bg: fT.colors.primary[95],
      bgGradient: `linear(90deg, ${checkedResponseColour} ${barSize}%, #FFF0 ${barSize}%)`,
    },
    _disabled: {
      bg: "#FFF0",
      bgGradient: `linear(90deg, ${checkedResponseColour} ${barSize}%, #FFF0 ${barSize}%)`,
      _active: {
        bg: "#FFF0",
        bgGradient: `linear(90deg, ${checkedResponseColour} ${barSize}%, #FFF0 ${barSize}%)`,
      },
      _hover: {
        bg: "#FFF0",
        bgGradient: `linear(90deg, ${checkedResponseColour} ${barSize}%, #FFF0 ${barSize}%)`,
      },
      _checked: {
        bg: "#FFF0",
        bgGradient: `linear(90deg, ${checkedResponseColour} ${barSize}%, #FFF0 ${barSize}%)`,
      },
    },
  };

  return [hiddenResultsColorProps, resultsColorProps];
};
