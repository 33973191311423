import * as yup from "yup";
import { hexColourRegEx } from "../../../EventWizard/helpers/customValidators";

export default t => {
  return yup.object({
    colourEmailHeader: yup
      .string()
      .required(
        t("event_tools.validations.required", {
          field: t("branding_page.email_styling.header_colour.field_name"),
        })
      )
      .matches(hexColourRegEx, t("colour_hex_error")),
    colourEmailFooter: yup
      .string()
      .required(
        t("event_tools.validations.required", {
          field: t("branding_page.email_styling.footer_colour.field_name"),
        })
      )
      .matches(hexColourRegEx, t("colour_hex_error")),
  });
};
