import React, { MutableRefObject, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, Icon } from "@chakra-ui/react";
import { Warning } from "phosphor-react";
import { useTranslation } from "react-i18next";
import DateRangePicker from "../../common/DCDatePicker/DateRangePicker";
import DCTimePicker from "../../common/DCTimePicker/DCTimePicker";
import { DCDropdownWithLabel } from "../../common/DCDropdown/DCDropdown";
import TimezoneFetcher from "../../common/TimezoneFetcher";
import { EventType } from "../../../EventWizard/models/Event";
import { errorMessagesCombiner } from "../../../EventWizard/helpers/customValidators";
import { usePreviewData } from "../../Preview";
import FormControlLabel from "../../common/shared/FormControlLabel";
import { LayoutForm } from "../../common/FormPageLayout";
import schemaGenerator from "./formSchema";

import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";
import { useSchema } from "../../../helpers/useSchema";

export type EventDetailsProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
}

export const EventDetailsForm: React.FC<EventDetailsProps> = ({ event, submitForm, handleErrors, formErrors }) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const {
    name,
    startDate,
    endDate,
    location,
    startTime,
    endTime,
    timeZone,
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  } = event;

  const defaultValues = { name, startDate, endDate, location, startTime, endTime, timeZone };
  const { setActiveComponent } =   usePreviewData({
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  });

  const { register, setValue, trigger, handleSubmit, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues
  });

  formErrors.current = errors;

  useEffect(() => {
    register("startDate");
    register("endDate");
    register("startTime");
    register("endTime");
    register("timeZone");
  }, []);

  const onChangeDates = (e) => {
    setValue("startDate", e.start);
    setValue("endDate", e.end);
    trigger(["startDate", "endDate"]);
  };

  const onChangeTimes = (e) => {
    setValue("startTime", e.startTime);
    setValue("endTime", e.endTime);
    trigger(["startTime", "endTime"]);
  };

  const onChangeTimezone = (e) => {
    setValue("timeZone", e.option.value);
    trigger(["timeZone"]);
  };

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  const isTimeInvalid = !!(errors?.startTime || errors?.endTime);

  return (
    <LayoutForm onSubmit={(e) => e.preventDefault()}>
      <FormControlLabel
        label={t('event_details.name.label')}
        helperText={t('event_details.name.hint')}
        error={errors.name}
        isRequired
      >
        <Input {...register("name")} />
      </FormControlLabel>

      <FormControl isDisabled>
        <FormLabel>{t('event_details.type.label')}</FormLabel>
        <Input value={event.location} />
      </FormControl>

      <TimezoneFetcher
        selection={event.timeZone}
        render={(data, selectedData) => (
          <DCDropdownWithLabel
            label={t("event_details.timezone.label")}
            helperText={t("event_details.timezone.hint")}
            errorMessage={errorMessagesCombiner(["timeZone"], errors)}
            handleChange={onChangeTimezone}
            isRequired
            {...{
              options: data,
              preselectedOption: {
                label: `${selectedData?.name} (${selectedData?.offset})`,
                value: selectedData?.name,
              }
            }}
          />
        )}
      />

      <DateRangePicker
        label={t("event_details.date.label")}
        helperText={t("event_details.date.hint")}
        errorMessage={errorMessagesCombiner(["startDate", "endDate"], errors)}
        handleChange={onChangeDates}
        onOpen={() => setActiveComponent('preview_svg_Feature Date & Location')}
        onClose={() => setActiveComponent(null)}
        isRequired
        {...{
          initialStartDate: event.startDate,
          initialEndDate: event.endDate
        }}
      />

      <FormControl
        isInvalid={isTimeInvalid}
        w="initial"
        isRequired
      >
        <FormLabel>
          {t("event_details.time.label")}
        </FormLabel>
        <FormHelperText mb={2} mt={0}>
          {t("event_details.time.hint")}
        </FormHelperText>
        <DCTimePicker
          isError={isTimeInvalid}
          format24
          tzinfoName={event.tzinfoName}
          initialStartTime={event.startTime}
          initialEndTime={event.endTime}
          handleChange={onChangeTimes}
          htmlId={`event-time`}
          onOpen={() => setActiveComponent('preview_svg_Feature Date & Location')}
          onClose={() => setActiveComponent(null)}
        />
        {isTimeInvalid && (
          <FormErrorMessage>
            <Icon as={Warning} weight="fill" color={fT.colors.alert} />
            {errorMessagesCombiner(["startTime","endTime"], errors)}
          </FormErrorMessage>
        )}
      </FormControl>
    </LayoutForm>
  );
};

export default EventDetailsForm;
