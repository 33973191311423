export type PaginationProps = {
  pageNumbers: number[]
  activePageNumber: number
  itemsPerPage: number
}

export type SmartPaginationProps = PaginationProps & {
  itemsTotal: number
}

export type DumbPaginationProps = PaginationProps & {
  lastPage: number
}

const buildSmartPaginationNumbers = (activePageNumber: number, itemsPerPage: number, itemsTotal: number) => {
  const firstPage = 1;
  const lastPage = Math.ceil(itemsTotal / itemsPerPage);

  return [
    firstPage,
    (activePageNumber - 1),
    activePageNumber,
    (activePageNumber + 1),
    lastPage
  ]
}

const buildDumbPaginationNumbers = (activePageNumber: number, lastPage: number) => {
  const firstPage = 1;

  return [
    firstPage,
    (activePageNumber - 1),
    activePageNumber,
    (activePageNumber + 1),
    lastPage
  ]
}

const uniquePageNumber = (value: unknown, index: number, array: unknown[]) => {
  const maxValue = array[array.length - 1];
  return array.indexOf(value) === index && value > 0 && value <= maxValue;
}

// useful when building pagination numbers and the last page number
// is not known.
export const smartPaginate = (activePageNumber: number, itemsPerPage: number, itemsTotal: number): SmartPaginationProps => {
  const numbers = buildSmartPaginationNumbers(activePageNumber, itemsPerPage, itemsTotal);

  return {
    pageNumbers: numbers.filter(uniquePageNumber),
    activePageNumber,
    itemsPerPage,
    itemsTotal
  }
}

// useful when building pagination numbers and the last page number
// is already known.
export const dumbPaginate = (activePageNumber: number, itemsPerPage: number, lastPage: number): DumbPaginationProps => {
  const numbers = buildDumbPaginationNumbers(activePageNumber, lastPage);

  return {
    pageNumbers: numbers.filter(uniquePageNumber),
    activePageNumber,
    itemsPerPage,
    lastPage
  }
}
