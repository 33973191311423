import React, { useRef, useState } from "react";
import { Button, Heading } from "@chakra-ui/react";
import FormDrawer from "../../common/FormDrawer";
import UserForm from "../../forms/UserForm";
import UserType from "../../../EventWizard/models/User";
import { useTranslation } from "react-i18next";
import { LabelValueObject } from "../../common/UsersSelect/UsersSelect";

import UserService from "../../../EventWizard/services/userService";
import AppAdapter from "../../../../utils/adapter";

type UserDrawerFormProps = {
  heading: string;
  eventId: string;
  updateUserSelect: (userSelection: LabelValueObject) => void;
  isOpen: boolean;
  onClose: () => void;
  user: UserType;
};

const UserDrawerForm: React.FC<UserDrawerFormProps> = ({
  heading,
  eventId,
  updateUserSelect,
  isOpen,
  onClose,
  user,
}) => {
  const { t } = useTranslation();

  const submitForm = useRef(null);
  const handleErrors = useRef(null);
  const formErrors = useRef(null);

  const [creating, setCreating] = useState(false);

  const createUser = async data => {
    const payload = await UserService.createUser(eventId, data);
    if (payload.ok) {
      updateUserSelect({
        value: payload.result?.data?.id,
        label: payload.result?.data?.attributes?.fullname,
      });
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const updateUser = async data => {
    const payload = await UserService.updateUser(eventId, data, user.id);
    if (payload.ok) {
      updateUserSelect({
        value: payload.result?.data?.id,
        label: payload.result?.data?.attributes?.fullname,
      });
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const submitUserForm = data => {
    setCreating(true);
    if (user.id) return updateUser(data);

    return createUser(data);
  };

  const handleSubmitForm = async () => {
    try {
      await submitForm.current(submitUserForm)();
      if (Object.keys(formErrors.current).length === 0) onClose();
    } catch (errors) {
      for (const name in errors) {
        handleErrors.current(name, errors[name]);
      }
    }
    setCreating(false);
  };

  const buttonLoadingText = () => {
    if (user.id) return t("actions.updating");

    return t("actions.creating");
  };

  return (
    <FormDrawer
      isOpen={isOpen}
      onClose={onClose}
      buttonComponent={
        <>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {t("actions.close")}
          </Button>
          <Button
            isLoading={creating}
            loadingText={buttonLoadingText()}
            onClick={handleSubmitForm}
          >
            {t("actions.save")}
          </Button>
        </>
      }
      headingComponent={
        <Heading size="sm" mb={3}>
          {heading}
        </Heading>
      }
    >
      <UserForm
        user={user}
        submitForm={submitForm}
        handleErrors={handleErrors}
        formErrors={formErrors}
      />
    </FormDrawer>
  );
};

export default UserDrawerForm;
