import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const brandingPageTheme: ComponentStyleConfig = {
  parts: ['eventFormDivider', 'eventsStylingSectionBoxGrid'],
  baseStyle: {
    eventFormDivider: {
      borderColor: '#DEDCDC',
      my: '30px'
    },
    eventsStylingSectionBoxGrid: {
      templateColumns: 'repeat(2, 1fr)',
      gap: '10px',
      pt: '20px'
    }
  }
};
