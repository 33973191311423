import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";

const styles = {
  control: base => ({
    ...base,
    "&:hover": {
      borderColor: fT.colors.skyBlue.base,
    },
  }),
  valueContainer: base => ({
    ...base,
    minHeight: "40px",
    paddingLeft: "16px",
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: fT.colors.primary[90],
    borderRadius: "20px",
    border: `1px solid ${fT.colors.primary.accent}`,
    marginTop: "4px",
    marginBottom: "4px",
    marginRight: "8px",
    paddingLeft: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: fT.colors.primary[80],
    },
    "&:focus": {
      backgroundColor: fT.colors.primary[70],
    },
  }),
  multiValueLabel: base => ({
    ...base,
    display: "flex",
    fontSize: "14px",
    height: "24px",
    paddingRight: "0px",
    paddingLeft: "0px",
    alignItems: "center",
    color: fT.colors.primary.base,
  }),
  indicatorSeparator: () => ({
    display: null,
  }),
  multiValueRemove: base => {
    base[":hover"] = null;

    return {
      ...base,
      display: "flex",
      direction: "column",
      justifyContent: "center",
      paddingLeft: "8px",
      paddingRight: "8px",
      height: "24px",
      cursor: "pointer",
    };
  },
  menuList: base => ({
    ...base,
    overflow: "overlay",
  }),
};

export default styles;
