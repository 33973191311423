import * as mockReactRouterDom from "react-router-dom";
import { renderHook } from "@testing-library/react-hooks";

import {
  FAKE_EVENT,
  mockCustomContexts,
  mockRestClientResolved,
} from "../../../EventWizard/test-utils";
import { useWebinarFormActions } from "./useWebinarFormActions";
import RestClient from "../../../../utils/restClient";

const { mockEventContext } = mockCustomContexts();

const mockNavigate = jest.fn();

jest.mock("react-router-dom", () => ({
  ...(jest.requireActual("react-router-dom") as typeof mockReactRouterDom),
  useOutletContext: jest.fn(),
  useNavigate: () => mockNavigate,
}));

describe("useWebinarFormActions", () => {
  test("navigateTo", () => {
    const { result } = renderHook(() => useWebinarFormActions());
    result.current.navigateTo.footer();
    expect(mockNavigate).toHaveBeenCalledWith(
      "/admin/event-tools/1/webinar-website/footer"
    );
  });

  describe("updateEvent", () => {
    test("successful request", async () => {
      const { result } = renderHook(() => useWebinarFormActions());
      const { updateEvent } = result.current;
      mockRestClientResolved("patch", { data: { attributes: FAKE_EVENT } });

      const updateEventResult = await updateEvent({ title: "Test" });
      expect(mockEventContext.setEvent).toHaveBeenCalledWith(FAKE_EVENT);
      expect(updateEventResult).toEqual(true);
    });

    test("unsuccessful request", async () => {
      const { result } = renderHook(() => useWebinarFormActions());
      const { updateEvent } = result.current;
      const mockResponse = {
        ok: false,
        status: 422,
        statusText: "Unprocessable Entity",
        result: {
          errors: { tagline: ["is too short (minimum is 3 characters)"] },
        },
      };
      jest.spyOn(RestClient, "patch").mockResolvedValueOnce(mockResponse);

      expect.assertions(1);
      return updateEvent({ tagline: "" }).catch((e) =>
        expect(e).toEqual({
          tagline: {
            message: "is too short (minimum is 3 characters)",
            type: "server",
            types: {
              server: "is too short (minimum is 3 characters)",
            },
          },
        })
      );
    });
  });

  test("getStepDetailsByRoute", () => {
    const { result } = renderHook(() => useWebinarFormActions());
    expect(result.current.getStepDetailsByRoute("footer")).toEqual({
      name: "event_tools.navigation.footer",
      path: "/admin/event-tools/1/webinar-website/footer",
      status: "default",
    });
  });
});
