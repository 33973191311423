import * as yup from "yup";

export default t => {
  return yup.object({
    name: yup.string()
      .required(t("event_tools.validations.required", { field: t("session_form.name.field_name") })),
    description: yup.string()
      .required(t("event_tools.validations.required", { field: t("session_form.description.field_name") })),
    time: yup.string()
      .required(t("event_tools.validations.required", { field: t("session_form.time.field_name") })),
    duration: yup.number()
      .required(t("event_tools.validations.required", { field: t("session_form.time.field_name") })),
  });
};
