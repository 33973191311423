import React from "react";
import { act } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import WebinarType from "./WebinarType";
import { render } from "../../../../EventWizard/test-utils";

import { liveFeedDCIconStyles } from "./WebinarTypeIconStyles";

expect.extend(toHaveNoViolations);

const webinarTypeArgs = {
  iconStyles: liveFeedDCIconStyles,
  type: "livefeedDC",
  selectedType: "",
  title: "LivefeedDC title",
  description: "LivefeedDC description",
  handleClick: () => { return {} }
}

const activeWebinarTypeArgs = {
  iconStyles: liveFeedDCIconStyles,
  type: "livefeedDC",
  selectedType: "livefeedDC",
  title: "LivefeedDC title",
  description: "LivefeedDC description",
  handleClick: () => { return {} }
}

describe("normal state Webinar Type", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<WebinarType {...webinarTypeArgs} />);
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});

describe("active state Webinar Type", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<WebinarType {...activeWebinarTypeArgs} />);
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
