import React from "react";
import { act } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import DCTable from "./DCTable";
import { render } from "../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const tableArgs = {
  data: [
    {
      fromUnit: 'inches',
      toUnit: 'millimetres (mm)',
      factor: 25.4,
    },
    {
      fromUnit: 'feet',
      toUnit: 'centimetres (cm)',
      factor: 30.48,
    },
    {
      fromUnit: 'yards',
      toUnit: 'metres (m)',
      factor: 0.91444,
    },
  ],
  columns: [
    {
      Header: 'To convert',
      accessor: 'fromUnit',
    },
    {
      Header: 'Into',
      accessor: 'toUnit',
    },
    {
      Header: 'Multiply by',
      accessor: 'factor',
      isNumeric: true,
    },
  ],
  cellProps: () => { return {} }
}

describe("Users Table", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<DCTable {...tableArgs} />);
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
