import { useState, useMemo, useRef } from "react";
import { debounce } from "throttle-debounce";
import axios from "axios";
import { SearchProp, FetcherProps } from "./types";

export default function SearchDataFetcher(children: FetcherProps) {
  const [searchResults, setSearchResults] = useState(null);
  const [searchState, setSearchState] = useState("new");

  const requestCancel = useRef(null);
  const onSearch = useMemo(
    () =>
      debounce(200, (value: string) => {
        setSearchState("searching");
        requestCancel?.current?.cancel();
        requestCancel.current = axios.CancelToken.source();

        axios
          .get(`/search?query=${encodeURIComponent(value)}`, {
            cancelToken: requestCancel.current.token,
          })
          .then(res => {
            setSearchState("done");
            setSearchResults(res.data.data);
            requestCancel.current = null;
          })
          .catch(thrown => {
            if (!axios.isCancel(thrown)) throw thrown;
          });
      }),
    []
  ) as SearchProp;

  return children(onSearch, searchResults, searchState);
}
