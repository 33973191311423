import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { SearchResultAttrs } from "./types";

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
const GroupElement = styled.div`
  text-decoration: none;
  color: ${props => props.textColor};
  opacity: 0.7;
  font-weight: 700;
  padding: 20px;
`;
const GroupItem = styled(GroupElement)`
  &:hover {
    color: ${props => props.textColor};
    opacity: 0.85;
    background-color: rgba(255, 255, 255, 0.08);
  }
`;
const GroupHeader = styled(GroupElement)`
  text-transform: uppercase;
  color: ${props => props.textColor};
`;

type SearchResultsListProps = {
  groupsList: Array<[string, [SearchResultAttrs]]>;
  textColor: string;
};

export default function SearchResultsList({
  groupsList,
  textColor,
}: SearchResultsListProps): JSX.Element {
  const { t } = useTranslation();
  if (groupsList.length == 0) {
    return <GroupItem>{t("global_search.no_result")}</GroupItem>;
  }

  return (
    <>
      {groupsList.map(([category, results]: [string, [SearchResultAttrs]]) => (
        <Group key={category} textColor={textColor}>
          <GroupHeader textColor={textColor}>{category}s</GroupHeader>
          {results.map(result => (
            <GroupItem
              as={"a"}
              key={result.id}
              href={result.link}
              textColor={textColor}>
              {result.content}
            </GroupItem>
          ))}
        </Group>
      ))}
    </>
  );
}
