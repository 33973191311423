import React from "react";
import {
  FormHelperText,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Icon,
} from "@chakra-ui/react";
import { Warning } from "phosphor-react";

import DCEventTooltip from "../DCTooltip";
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";
import { FieldError } from "react-hook-form";

type FormControlLabelProps = {
  label: string;
  children: React.ReactNode;
  error?: FieldError;
  toolTip?: string;
  helperText?: string;
  name?: string;
  isRequired?: boolean;
};

const FormControlLabel: React.FunctionComponent<FormControlLabelProps> = ({
  children,
  error,
  toolTip,
  helperText,
	label,
  name,
  isRequired = false
}: FormControlLabelProps): JSX.Element => {
  return (
    <FormControl
      isInvalid={!!error}
      isRequired={isRequired}
    >
      <FormLabel htmlFor={name}>
        {label} {toolTip && <DCEventTooltip label={toolTip} />}
      </FormLabel>
      {helperText && (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      )}
      {children}
      {!!error && (
        <FormErrorMessage>
          <Icon as={Warning} weight="fill" color={fT.colors.alert} />
          {error?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormControlLabel;
