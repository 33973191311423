import React, { createContext, useReducer, useContext, useEffect, ReactNode } from 'react';
import { noop } from '../../../utils';

import { segments } from './data';


export type PreviewContextData = {
  activeComponents: string[];
  primaryColour: string;
  secondaryColour: string;
  colourEmailHeader: string;
  colourEmailFooter: string;
  featureBackgroundColour: string;
  featureHeaderTextColour: string;
  featureSectionHeaderTextColour: string;
  featureBackgroundSideMenuColour: string;
  featureBackgroundMenuColour: string;
  footerText: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  visible: {};
}

export type PreviewContextState = {
  setActiveComponent: (name: string | string[]) => void;
  setVisibility: (attr: string, value: boolean) => void;
  setAttribute: (attr: string, value: string) => void;
  state: PreviewContextData
}


const defaults = {
  activeComponents: [],
  primaryColour: null,
  visible: segments.reduce((acc, segment) => { acc[segment] = true; return acc; }, {})
};

const PreviewContext = createContext<PreviewContextState>({
  state: {
    activeComponents: [],
    primaryColour: null,
    secondaryColour: "#252477",
    colourEmailHeader: null,
    colourEmailFooter: null,
    featureBackgroundColour: null,
    featureHeaderTextColour: null,
    featureSectionHeaderTextColour: null,
    featureBackgroundSideMenuColour: null,
    featureBackgroundMenuColour: null,
    footerText: null,
    visible: {}
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActiveComponent: (name: string | string[]) => noop,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setVisibility: (attr: string, value: boolean) => noop,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAttribute: (attr: string, value: string) => noop
});


function reducer(state, action) {
  switch (action.type) {
    case 'setActiveComponent':
      return { ...state, activeComponents: action.activeComponents };
    case 'setVisibility':
      return { ...state, visible: { ...state.visible, [action.attribute]: action.value } };
    case 'setAttribute':
      return { ...state, [action.attribute]: action.value };
    default:
      throw new Error();
  }
}

export function PreviewProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, defaults);

  function setActiveComponent(componentNames) {
    const names = Array.isArray(componentNames) ? componentNames : [componentNames];
    const activeComponents = names.filter(name => !!name);
    dispatch({ type: 'setActiveComponent', activeComponents });
  }

  function setAttribute(attribute, value) {
    dispatch({ type: 'setAttribute', attribute, value });
  }

  function setVisibility(attribute, value) {
    dispatch({ type: 'setVisibility', attribute, value });
  }

  const value: PreviewContextState = {
    setActiveComponent,
    setVisibility,
    setAttribute,
    state
  };

  return <PreviewContext.Provider value={value}>
    {children}
  </PreviewContext.Provider>;
}


export function usePreviewData(defaults = {}) {
  const context = useContext(PreviewContext);

  useEffect(() => {
    Object.entries(defaults)
      .map(([key, value]: [string, string]) => {
        context.setAttribute(key, value);
      });
  }, []);

  return context;
}
