import * as yup from "yup";

const schemaGenerator = (t) => {
  return yup.object({
    eventType: yup.string()
      .required(t("webinar_type.error")),
  })
}

export default schemaGenerator;
