import React, { MutableRefObject, useEffect, useRef } from "react";
import { useForm, FieldError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Icon,
  Textarea,
} from "@chakra-ui/react";
import { Warning } from "phosphor-react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../../common/shared/FormControlLabel";
import { TalkType } from "../../../../EventWizard/models/talk";
import DCTimePicker from "../../../common/DCTimePicker";
import UsersSelect from "../../../common/UsersSelect";
import { errorMessagesCombiner } from "../../../../EventWizard/helpers/customValidators";
import moment from "moment";
import schemaGenerator from "./formSchema";

import { figmaTheme as fT } from "../../../../react/dcTheme/figmaTheme";

export type PresentationFormProps = {
  talk: TalkType;
  eventId: string;
  tzinfoName: string;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
};

export const PresentationForm: React.FC<PresentationFormProps> = ({
  talk,
  eventId,
  tzinfoName,
  submitForm,
  handleErrors,
  formErrors,
}) => {
  const { t } = useTranslation();

  const schema = React.useMemo(() => schemaGenerator(t), []);

  const reFetchSpeakers = useRef(null);

  const {
    title,
    description,
    startTime,
    endTime,
    duration,
    speakers
  } = talk;

  const speakerIds = speakers.map(speaker => {
    return speaker[0].toString();
  });

  const defaultValues = {
    title,
    description,
    startTime,
    endTime,
    duration,
    speakerIds
  };

  const {
    register,
    trigger,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
    register("speakerIds");
    setValue("speakerIds", speakerIds);
    if (startTime && endTime) {
      setValue("duration", calculateDuration(startTime, endTime));
    }
  }, []);

  const onChangeTimes = e => {
    setValue("startTime", e.startTime);
    setValue("endTime", e.endTime);
    setValue("duration", calculateDuration(e.startTime, e.endTime));
    trigger(["startTime", "endTime"]);
  };

  const calculateDuration = (startTime: Date, endTime: Date): number => {
    const start = moment(startTime, "h:mm a");
    const end = moment(endTime, "h:mm a");
    return end.diff(start, "minutes");
  };

  const handleUserIdsChange = (userIds: Array<string>) => {
    setValue("speakerIds", userIds);
    trigger("speakerIds");
  };

  const handleAddNewSpeaker = async newUserSelection => {
    await reFetchSpeakers.current(newUserSelection);
    setValue("speakerIds", [...speakerIds.filter(s => s !== newUserSelection.value), newUserSelection.value]);
  };

  const isTimeInvalid = !!(errors?.startTime || errors?.endTime);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormControlLabel
        label={t("presentation.title.label")}
        error={errors.title}
        isRequired
      >
        <Input {...register("title")} />
      </FormControlLabel>

      <FormControlLabel
        label={t("presentation.description.label")}
        error={errors.description}
        isRequired
      >
        <Textarea {...register("description")} />
      </FormControlLabel>

      <FormControl isInvalid={isTimeInvalid} w="initial" isRequired>
        <FormLabel>{t("presentation.time.label")}</FormLabel>
        <DCTimePicker
          isError={isTimeInvalid}
          format24
          tzinfoName={tzinfoName}
          initialStartTime={startTime}
          initialEndTime={endTime}
          handleChange={onChangeTimes}
          htmlId={`presentation-time`}
        />
        {isTimeInvalid && (
          <FormErrorMessage>
            <Icon as={Warning} weight="fill" color={fT.colors.alert} />
            {errorMessagesCombiner(["startTime", "endTime"], errors)}
          </FormErrorMessage>
        )}
      </FormControl>

      <UsersSelect
        eventId={eventId}
        userIds={speakerIds}
        handleUserIdsChange={handleUserIdsChange}
        error={errors.speakerIds as unknown as FieldError}
        isAddNew={true}
        updateUserSelect={handleAddNewSpeaker}
        addNewUserText={t("actions.add_new_speaker")}
        reFetch={reFetchSpeakers}
        fieldLabel={t("presentation.speaker_names.label")}
        newUserFormHeading={t("speaker.new_heading")}
        editUserFormHeading={t("speaker.edit_heading")}
      />
    </form>
  );
};

export default PresentationForm;
