import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import DCDateInput from "../shared/DCDateInput";
import '../shared/DCDatePicker.css';
import DCDatePickerHeader from "../shared/DCDatePickerHeader";
import withInputLabel from "../../shared/withInputLabel";
import { noop } from "../../../../../utils";


type DateRangePickerProps = {
  isError: boolean;
  handleChange: (e: Record<string, string>) => void;
  initialStartDate: Date;
  initialEndDate: Date;
  onOpen?: () => void;
  onClose?: () => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  isError,
  handleChange,
  initialStartDate,
  initialEndDate,
  onOpen = noop,
  onClose = noop,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const datepickerRef = useRef(null);
  const { t } = useTranslation();

  const placeholder = `${t('events.form.date.start_date')} - ${t('events.form.date.end_date')}`;

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleChange({ start, end });
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    handleChange({ start: undefined, end: undefined });
  };

  const closeDatePicker = () => {
    if (!datepickerRef) return;

    datepickerRef.current.setOpen(false);
  };

  return (
    <DatePicker
      ref={datepickerRef}
      onCalendarClose={onClose}
      onCalendarOpen={onOpen}
      customInput={<DCDateInput onClick={noop} placeholder={placeholder} value={datepickerRef.current?.value} isInvalid={isError} />}
      renderCustomHeader={({
        monthDate,
        decreaseMonth,
        increaseMonth,
      }) => (
        <DCDatePickerHeader monthDate={monthDate} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
      )}
      showPopperArrow={false}
      placeholderText={placeholder}
      dateFormat="dd MMMM yyyy"
      formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      shouldCloseOnSelect={false}
    >
      <div className='picker-actions'>
        <button className='clear-btn' onClick={() => clearDates()}>{t('event_tools.buttons.clear')}</button>
        <button className='done-btn' onClick={() => closeDatePicker()}>{t('event_tools.buttons.done')}</button>
      </div>
    </DatePicker>
  );
};

export default withInputLabel(DateRangePicker);
