import React from "react";
import { Flex, Button, Spacer } from "@chakra-ui/react";
import { ArrowLeft } from "phosphor-react";
import { useTranslation } from "react-i18next";

import { figmaTheme as fT } from "../../../react/dcTheme";

type BottomFormButtonsProps = {
  previousLink?: () => void;
  saveAndContinue: () => void;
  saveAndExit: () => void;
};

export const BottomFormButtons: React.FC<BottomFormButtonsProps> = ({
  previousLink,
  saveAndContinue,
  saveAndExit,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      align="center"
      py={5}
      px={10}
      borderTop="1px"
      borderTopColor={fT.colors.grey.base}
      bg={fT.colors.primary[100]}
    >
      {previousLink && (
        <Button
          m={2}
          variant="ghost"
          leftIcon={<ArrowLeft weight="fill" />}
          onClick={previousLink}
        >
          {t("views.pagination.previous_item")}
        </Button>
      )}
      <Spacer />
      <Button m={2} variant="outline" onClick={saveAndExit}>
        {t("actions.save_and_exit")}
      </Button>
      <Button
        m={2}
        onClick={() => {
          saveAndContinue();
        }}
      >
        {t("actions.save_and_continue")}
      </Button>
    </Flex>
  );
};
