import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

import { usePreviewData } from './PreviewContext';

/*
  This label component currently supports the following localised labels:
  i18n-tasks-use t('event_tools.preview.labels.preview_svg_logo')
  i18n-tasks-use t('event_tools.preview.labels.preview_svg_social_links')
  i18n-tasks-use t('event_tools.preview.labels.preview_svg_favicon')
  i18n-tasks-use t('event_tools.preview.labels.preview_svg_page_content')
  i18n-tasks-use t('event_tools.preview.labels.preview_svg_nav_2')
  i18n-tasks-use t('event_tools.preview.labels.preview_svg_section_header')
  i18n-tasks-use t('event_tools.preview.labels.email_svg_email_body')
  i18n-tasks-use t('event_tools.preview.labels.email_svg_email_banner')
  i18n-tasks-use t('event_tools.preview.labels.email_svg_social_links')
  */

const Label = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid black;
  padding: 4px 6px 4px 6px;
  font-size: 12px;
  white-space: nowrap;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  border-radius: 8px;
  border-top-left-radius: ${props => props.up ? "0" : "8px"};
  border-bottom-left-radius: ${props => !props.up ? "0" : "8px"};
`;

const PreviewWrapper = styled.div`
  position: relative;
  display: flex;
`;


function transformBBoxToPoints(svg, svgComponent) {
  const bbox = svgComponent.getBBox();
  const ctm = svgComponent.getCTM();

  // Coordinates are arranged clockwise, from the top left
  const coords = [
    bbox,
    { x: bbox.x + bbox.width, y: bbox.y },
    { x: bbox.x + bbox.width, y: bbox.y + bbox.height },
    { x: bbox.x, y: bbox.y + bbox.height },
  ];

  const point = svg.createSVGPoint();

  return coords.map(({ x, y }) => {
    point.x = x;
    point.y = y;
    return point.matrixTransform(ctm);
  });
}

const i18nKey = (text?: string) => text?.toLowerCase().replace(/\s/g, '_').replace(/&/g, 'and');

function PreviewLabel({ component }: { component: string }) {
  const { t } = useTranslation();

  const svgComponent = document.getElementById(component) as unknown as SVGElement;

  if (!svgComponent) return null;

  const svg = svgComponent.ownerSVGElement;
  const size = { x: svg.clientWidth, y: svg.clientHeight };

  const coords = transformBBoxToPoints(svg, svgComponent);

  const above = coords[2].y < size.y / 2;
  const left = coords[0].x;
  const top = above ? coords[2].y + 2 : coords[0].y - 30;

  const label = t(`event_tools.preview.labels.${i18nKey(component)}`);
  return <Label left={left} top={top} up={above}>{label}</Label>;
}


export default function PreviewWithLabels({ children }: { children: ReactNode }) {
  const { state: { activeComponents } } = usePreviewData();

  return <PreviewWrapper>
    <div>
      {children}
      {activeComponents.map(component => <PreviewLabel key={component} component={component} />)}
    </div>
  </PreviewWrapper>;
}
