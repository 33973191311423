import React from "react";
import { Box, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import DCFileUploader from "../../DCFileUploader";
import FormControlLabel from "../../common/shared/FormControlLabel";
import { FieldError, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { CustomHomepageSection } from "../../../EventWizard/models/Event";

export type customHomePageSectionError = {
  id?: FieldError;
  title?: FieldError;
  description?: FieldError;
  image?: [FieldError?];
  imageUrl?: FieldError;
}
type HomePageSectionProps = {
  nestIndex: number;
  customHomepageSection?: CustomHomepageSection;
  register: UseFormRegister<{customHomepageSections: CustomHomepageSection[]}>;
  setValue: UseFormSetValue<{customHomepageSections: CustomHomepageSection[]}>;
  errors: customHomePageSectionError;
}

const NestedHomePageSection: React.FC<HomePageSectionProps> = ({ nestIndex, customHomepageSection, register, setValue, errors }) => {
  const { t } = useTranslation();

  const saveFile = (file: File) => {
    setValue(`customHomepageSections.${nestIndex}.image`, [file]);
  };

  return (
    <Box>
      {customHomepageSection.id && <Input hidden {...register(`customHomepageSections.${nestIndex}.id`)} />}
      <FormControlLabel
        label={t("events.form.home_page.add_sections.section_title.label")}
        error={errors?.title}
        isRequired
      >
        <Input name={`customHomepageSections[${nestIndex}].title`} {...register(`customHomepageSections.${nestIndex}.title`)} defaultValue={customHomepageSection.title} />
      </FormControlLabel>
      <FormControlLabel
        label={t("events.form.home_page.add_sections.section_description.label")}
        error={errors?.description}
        isRequired
      >
        <Input name={`sections[${nestIndex}].description`} {...register(`customHomepageSections.${nestIndex}.description`)} defaultValue={customHomepageSection.description} />
      </FormControlLabel>
      <FormControlLabel
        label={t("events.form.home_page.add_sections.section_image.label")}
        error={errors?.imageUrl}
      >
        <DCFileUploader
          acceptedFormats="image/*"
          aspectRatio={1}
          maxSize={10000000}
          uploadPhoto={saveFile}
          source={customHomepageSection.imageUrl}
        />
      </FormControlLabel>
    </Box>
  );
};

export default NestedHomePageSection;
