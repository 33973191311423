import { JsonApiUserType } from "../models/User";
import CONFIG from "../../../utils/config";
import RestClient from "../../../utils/restClient";
import AppAdapter from "../../../utils/adapter";

/**
 * service for users
 */

export type UserListResponseType = {
  ok: boolean;
  result: Array<JsonApiUserType> | null;
  message?: string;
};

class UserService {
  static async list(
    eventId: string,
    option = ""
  ): Promise<UserListResponseType> {
    try {
      const response = await RestClient.get(
        `${CONFIG.URLS.EVENTS}/${eventId}/users?option=${option}`
      );
      const { ok: responseOk } = response;
      if (!responseOk) response.message = "error in response";
      return {
        ok: responseOk,
        result: response.result as Array<JsonApiUserType>,
        message: response.message,
      };
    } catch (e) {
      return { ok: false, message: "error in fetching", result: null };
    }
  }

  static async fetchUser(eventId, userId) {
    try {
      const response = await RestClient.get(
        `${CONFIG.URLS.EVENTS}/${eventId}/users/${userId}`
      );
      const { ok: responseOk } = response;
      if (!responseOk) response.message = "error in response";
      return response;
    } catch (e) {
      return { ok: false, message: "error in fetching", result: null };
    }
  }

  static async createUser(eventId, payload) {
    try {
      const response = await RestClient.postWithFormData(
        `${CONFIG.URLS.EVENTS}/${eventId}/users`,
        { user: AppAdapter.snakeCaseKeys(payload) }
      );
      const { ok: responseOk } = response;
      if (!responseOk) response.message = "error in response";
      return response;
    } catch (e) {
      return { ok: false, message: "error in fetching", result: null };
    }
  }

  static async updateUser(eventId, payload, userId) {
    try {
      const response = await RestClient.patchWithFormData(
        `${CONFIG.URLS.EVENTS}/${eventId}/users/${userId}`,
        { user: AppAdapter.snakeCaseKeys(payload) }
      );
      const { ok: responseOk } = response;
      if (!responseOk) response.message = "error in response";
      return response;
    } catch (e) {
      return { ok: false, message: "error in fetching", result: null };
    }
  }

  static transform(record) {
    record["profileUrl"] = record?.profile;
    return record;
  }
}

export default UserService;
