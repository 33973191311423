import UserService from "./userService";
import {
  mockRestClientResolved,
  mockRestClientRejected,
  mockRestClientFailure,
} from "../test-utils";

describe("UserService", () => {
  const eventId = "1";
  const payload = {};

  describe("list", () => {
    test("valid request 200 - 299", async () => {
      mockRestClientResolved("get", { data: [] });

      const response = await UserService.list("1", "");
      expect(response.ok).toBe(true);
      expect(response.result).toStrictEqual({ data: [] });
    });

    test("error in response 400 - 599", async () => {
      mockRestClientRejected("get", "FAKE_ERROR");

      const response = await UserService.list("", "");
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in response");
    });

    test("error in fetching", async () => {
      mockRestClientFailure("get", "FAKE_FAILURE");

      const response = await UserService.list("1", "");
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in fetching");
    });
  });

  describe("fetchUser", () => {
    test("valid request 200 - 299", async () => {
      mockRestClientResolved("get", { data: {} });

      const response = await UserService.fetchUser(eventId, 1);
      expect(response.ok).toBe(true);
      expect(response.result).toStrictEqual({ data: {} });
    });

    test("error in response 400 - 599", async () => {
      mockRestClientRejected("get", "FAKE_ERROR");

      const response = await UserService.fetchUser(eventId, 1);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in response");
    });

    test("error in fetching", async () => {
      mockRestClientFailure("get", "FAKE_FAILURE");

      const response = await UserService.fetchUser(eventId, 1);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in fetching");
    });
  });

  describe("createUser", () => {
    test("valid request 200 - 299", async () => {
      mockRestClientResolved("postWithFormData", { data: [] });

      const response = await UserService.createUser(eventId, payload);
      expect(response.ok).toBe(true);
      expect(response.result).toStrictEqual({ data: [] });
    });

    test("error in response 400 - 599", async () => {
      mockRestClientRejected("postWithFormData", "FAKE_ERROR");

      const response = await UserService.createUser("", payload);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in response");
    });

    test("error in fetching", async () => {
      mockRestClientFailure("postWithFormData", "FAKE_FAILURE");

      const response = await UserService.createUser(eventId, payload);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in fetching");
    });
  });

  describe("updateUser", () => {
    test("valid request 200 - 299", async () => {
      mockRestClientResolved("patchWithFormData", { data: {} });

      const response = await UserService.updateUser(eventId, payload, 1);
      expect(response.ok).toBe(true);
      expect(response.result).toStrictEqual({ data: {} });
    });

    test("error in response 400 - 599", async () => {
      mockRestClientRejected("patchWithFormData", "FAKE_ERROR");

      const response = await UserService.updateUser(eventId, payload, 1);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in response");
    });

    test("error in fetching", async () => {
      mockRestClientFailure("patchWithFormData", "FAKE_FAILURE");

      const response = await UserService.updateUser(eventId, payload, 1);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in fetching");
    });
  });
});
