import React from "react";
import { useNavigate } from "react-router-dom";
import AppAdapter from "../../../../../../../utils/adapter";
import FormPageLayout from "../../../../../common/FormPageLayout";
import EmailCustomisationForm from "../../../../../forms/EmailCustomisationForm";
import { useEvent, useTenant } from "../../../../Event/Event";
import TenantService from "../../../../../../EventWizard/services/tenantService";
import { useTranslation } from "react-i18next";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";

const EmailCustomisation: React.FC = (): JSX.Element => {
  const {event} = useEvent();
  const {tenant, setTenant} = useTenant();
  const navigate = useNavigate();
  const navigator = path => () => navigate(path);
  const { t } = useTranslation();

  const stepDetails = [
    { name: t("event_tools.navigation.website_style"), status: "done", path: `/admin/event-tools/${event.id}/steps/website-style` },
    { name: t("event_tools.navigation.email_customisation"), status: "active", path: `/admin/event-tools/${event.id}/steps/email-styling` },
    { name: t("event_tools.navigation.more_custom_styling"), status: "default", path: `/admin/event-tools/${event.id}/steps/custom-styling` },
  ];

  const updateTenant = async (data) => {
    const payload = await TenantService.updateTenantWithFormData(tenant.id, data);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, { transform: TenantService.transform });
      setTenant(response);
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  return (
    <FormPageLayout event={event}
      stepDetails={stepDetails}
      update={updateTenant}
      previousForm={navigator(`/admin/event-tools/${event.id}/steps/website-style`)}
      exitForm={navigateToEventList}
      nextForm={navigator(`/admin/event-tools/${event.id}/steps/custom-styling`)}
      previewVariant="Email"
    >
      {
        (props) => <EmailCustomisationForm tenant={tenant} {...props} />
      }
    </FormPageLayout>
  );
};

export default EmailCustomisation;
