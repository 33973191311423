import React, { MutableRefObject, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Switch,
  FormHelperText,
  Spacer,
  Flex,
  Collapse
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../common/shared/FormControlLabel";
import DCFileUploader from "../../DCFileUploader";
import { EventType } from "../../../EventWizard/models/Event";
import { LayoutForm } from "../../common/FormPageLayout";
import { usePreviewData } from "../../Preview/PreviewContext";
import schemaGenerator from "./formSchema";
import { useSchema } from "../../../helpers/useSchema";

export type HeroSectionFormProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
};

export const HeroSectionForm: React.FC<HeroSectionFormProps> = ({ event, submitForm, handleErrors, formErrors }) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);
  
  const { isOpen, onToggle } = useDisclosure({defaultIsOpen: !!(event.backgroundVideoUrl || event.coverImage) });

  const {
    headerTagline,
    subTagline,
    tagline,
    backgroundVideoUrl,
    coverImage,
    eventFeatures,
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  } = event;

  const defaultValues = { headerTagline, subTagline, tagline, backgroundVideoUrl, coverImage, eventFeatures };

  const { register, trigger, handleSubmit, setError, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  const saveFile = (file: File) => {
    setValue('coverImage', [file]);
  };

  const { setActiveComponent } = usePreviewData({
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText,
  });

  const handleVideoInputBlur = () => {
    setActiveComponent(null);
    trigger("backgroundVideoUrl");
  };

  return (
    <LayoutForm onSubmit={(e) => e.preventDefault()}>
      <FormControlLabel
        label={t("homepage_setup.tagline.label")}
        error={errors.tagline}
        isRequired
      >
        <Input
          onFocus={() => setActiveComponent("preview_svg_Tagline")}
          onBlur={() => setActiveComponent(null)}
          {...register("tagline")}
        />
      </FormControlLabel>
      <FormControlLabel
        label={t("homepage_setup.header_tagline.label")}
        error={errors.headerTagline}
        isRequired
      >
        <Input
          placeholder={t("homepage_setup.header_tagline.placeholder")}
          onFocus={() => setActiveComponent("preview_svg_Headline")}
          onBlur={() => setActiveComponent(null)}
          {...register("headerTagline")}
        />
      </FormControlLabel>

      <FormControlLabel
        label={t("homepage_setup.sub_tagline.label")}
        error={errors.subTagline}
        isRequired
      >
        <Input
          placeholder={t("homepage_setup.sub_tagline.placeholder")}
          onFocus={() => setActiveComponent("preview_svg_Subheadline")}
          onBlur={() => setActiveComponent(null)}
          {...register("subTagline")}
        />
      </FormControlLabel>

      <Flex>
        <FormControl>
          <FormLabel>{t("homepage_setup.media_toggle.label")}</FormLabel>
          <FormHelperText>{t("homepage_setup.media_toggle.hint")}</FormHelperText>
        </FormControl>
        <Spacer />
        <Switch id='media-toggle' onChange={onToggle} isChecked={isOpen} aria-label="toggle" />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <FormControlLabel
          label={t("homepage_setup.cover_image.label")}
        >
          <DCFileUploader
            acceptedFormats="image/*"
            aspectRatio={1}
            maxSize={10000000}
            uploadPhoto={saveFile}
            source={event.coverImageUrl}
          />
        </FormControlLabel>

        <FormControlLabel
          label={t("homepage_setup.background_video_url.label")}
          helperText={t("homepage_setup.background_video_url.hint")}
          error={errors.backgroundVideoUrl}
        >
          <Input
            {...register("backgroundVideoUrl")}
            onFocus={() => setActiveComponent("preview_svg_Home Banner")}
            onBlur={handleVideoInputBlur}
          />
        </FormControlLabel>
      </Collapse>

      <FormControl>
        <Flex>
          <div>
            <FormLabel>{t("homepage_setup.countdown_feature.label")}</FormLabel>
            <FormHelperText>{t("homepage_setup.countdown_feature.hint")}</FormHelperText>
          </div>
          <Spacer />
          <Switch id='countdown-timer-toggle' {...register("eventFeatures.countdownTimer.enabled")} aria-label="toggle" />
          <Input hidden {...register("eventFeatures.countdownTimer.id")} />
        </Flex>
      </FormControl>
    </LayoutForm>
  );
};

export default HeroSectionForm;
