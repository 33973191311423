import TalkService from "./talkService";
import RestClient from "../../../utils/restClient";
import { getSuccessJSONResponse, getFailureJSONResponse } from "../test-utils"

describe("TalkService", () => {

  const eventId = "1";
  const talkId = "1";
  const date = new Date();
  const payload = {};

  const mockSuccessResponse = getSuccessJSONResponse({ data: [] });
  const mockFailureResponse = getFailureJSONResponse("error");

  describe("list", () => {
    test("valid request 200 - 299", async () => {
      jest.spyOn(RestClient, "get").mockResolvedValueOnce(mockSuccessResponse);

      const response = await TalkService.list(eventId);
      expect(response.ok).toBe(true);
      expect(response.result).toStrictEqual(mockSuccessResponse.result);
    });

    test("error in response 400 - 599", async () => {
      jest.spyOn(RestClient, "get").mockResolvedValueOnce(mockFailureResponse);

      const response = await TalkService.list("");
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in response");
    });

    test("error in fetching", async () => {
      jest.spyOn(RestClient, "get").mockImplementation(() => {
        throw new Error("RestClient crashed");
      });

      const response = await TalkService.list(eventId);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in fetching");
    });
  });

  describe("createOrUpdateTalk", () => {
    beforeAll(() => {
        jest.spyOn(TalkService, "createTalk").mockResolvedValueOnce(mockSuccessResponse);
        jest.spyOn(TalkService, "updateTalk").mockResolvedValueOnce(mockSuccessResponse);
    });

    afterAll(() => {
        jest.restoreAllMocks();
    });

    test("with talkId", async () => {
      await TalkService.createOrUpdateTalk(eventId, date, talkId, payload);
      expect(TalkService.updateTalk).toBeCalledWith(eventId, date, talkId, payload)
    });

    test("without talkId", async () => {
      await TalkService.createOrUpdateTalk(eventId, date, "", payload);

      expect(TalkService.createTalk).toBeCalledWith(eventId, date, payload)
    });
  });

  describe("createTalk", () => {
    test("valid request 200 - 299", async () => {
      jest.spyOn(RestClient, "postWithFormData").mockResolvedValueOnce(mockSuccessResponse);

      const response = await TalkService.createTalk(eventId, date, payload);
      expect(response.ok).toBe(true);
      expect(response.result).toStrictEqual(mockSuccessResponse.result);
    });

    test("error in response 400 - 599", async () => {
      jest.spyOn(RestClient, "postWithFormData").mockResolvedValueOnce(mockFailureResponse);

      const response = await TalkService.createTalk("", date, payload);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in response");
    });

    test("error in fetching", async () => {
      jest.spyOn(RestClient, "postWithFormData").mockImplementation(() => {
        throw new Error("RestClient crashed");
      });

      const response = await TalkService.createTalk(eventId, date, payload);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in fetching");
    });
  });

  describe("updateTalk", () => {
    test("valid request 200 - 299", async () => {
      jest.spyOn(RestClient, "patchWithFormData").mockResolvedValueOnce(mockSuccessResponse);

      const response = await TalkService.updateTalk(eventId, date, talkId, payload);
      expect(response.ok).toBe(true);
      expect(response.result).toStrictEqual(mockSuccessResponse.result);
    });

    test("error in response 400 - 599", async () => {
      jest.spyOn(RestClient, "patchWithFormData").mockResolvedValueOnce(mockFailureResponse);

      const response = await TalkService.updateTalk("", date, talkId, payload);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in response");
    });

    test("error in fetching", async () => {
      jest.spyOn(RestClient, "patchWithFormData").mockImplementation(() => {
        throw new Error("RestClient crashed");
      });

      const response = await TalkService.updateTalk(eventId, date, talkId, payload);
      expect(response.ok).toBe(false);
      expect(response.message).toBe("error in fetching");
    });
  });

  describe("transformPayload", () => {
    beforeAll(() => {
        jest.spyOn(TalkService, "transformTime").mockImplementation(() => "transformTime");
        jest.spyOn(TalkService, "transformSpeakerIds").mockImplementation(() => "transformSpeakerIds");
    });

    afterAll(() => {
        jest.restoreAllMocks();
    });

    test("calls transformTime and transformSpeakerIds", () => {
      TalkService.transformPayload(payload, date);

      expect(TalkService.transformTime).toBeCalledTimes(1)
      expect(TalkService.transformSpeakerIds).toBeCalledTimes(1)
    });
  });


  describe("transformTime", () => {
    const payloadWithTime = { startTime: date, endTime: date, time: "" }

    test("sets time and delete startTime/endTime", () => {
      TalkService.transformTime(payloadWithTime, date);

      expect(payloadWithTime.startTime).toBeUndefined()
      expect(payloadWithTime.endTime).toBeUndefined()
      expect(payloadWithTime.time).toBeTruthy()
    });
  });

  describe("transformSpeakerIds", () => {
    const payloadWithSpeakerIds = { speakerIds: ["1", "3", "5"], speakers: "" }

    test("sets time and delete startTime/endTime", () => {
      TalkService.transformSpeakerIds(payloadWithSpeakerIds);

      expect(payloadWithSpeakerIds.speakerIds).toBeUndefined()
      expect(payloadWithSpeakerIds.speakers).toEqual("1,3,5")
    });
  });

  describe("transform", () => {
    const record = {
      startAt: "2022-08-10T13:18:27.106+10:00",
      endAt: "2022-08-10T15:18:27.106+10:00",
      indexImage: { url: "indexImageUrl" },
      indexImageUrl: ""
    }

    test("sets time and delete startTime/endTime", () => {
      TalkService.transform(record);

      expect(record.startAt).toBeInstanceOf(Date)
      expect(record.endAt).toBeInstanceOf(Date)
      expect(record.indexImageUrl).toEqual("indexImageUrl")
    });
  });
});
