import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import ProgressService from "../../EventWizard/services/progressService";
import { getInitialStatusState, getPageName } from "../../helpers/navigation";
import {
  WebinarConfigType,
  WebinarGroupType,
  WebinarPage,
  WebinarPageType,
  WebinarSubGroupType,
} from "../config";

const useCompletePageStatus = (config: WebinarConfigType, eventId: string) => {
  const [completePageStatus, setCompletePageStatus] = useState(
    getInitialStatusState(config)
  );
  const [enabledPageStatus, setEnabledPageStatus] = useState(
    getInitialStatusState(config)
  );
  const [currentPage, setCurrentPage] = useState("");

  const order = useMemo(() => {
    return Object.entries(config.pages)
      .sort(
        (a: [string, WebinarPageType], b: [string, WebinarPageType]) =>
          a[1].step - b[1].step
      )
      .map(([key]) => key);
  }, []);

  const setInitialCompleteState = (currentPage: string) => {
    const newState = {
      groups: { ...completePageStatus.groups },
      subGroups: { ...completePageStatus.subGroups },
      pages: { ...completePageStatus.pages },
    };
    let foundPage = false;
    const completedPages = [];
    order.forEach((page: string) => {
      if (currentPage === page) {
        foundPage = true;
      }
      if (!foundPage) {
        newState.pages[page] = true;
        completedPages.push(page);
      }
    });
    Object.entries(config.subGroups).forEach(
      ([key, subGroup]: [string, WebinarSubGroupType]) => {
        if (subGroup.children.every((v) => completedPages.includes(v))) {
          newState.subGroups[key] = true;
        }
      }
    );
    Object.entries(config.groups).forEach(
      ([key, group]: [string, WebinarGroupType]) => {
        if (group.children.every((v) => completedPages.includes(v))) {
          newState.groups[key] = true;
        }
      }
    );
    return newState;
  };

  const updateCompletePageState = (page: string) => {
    const newState = {
      groups: { ...completePageStatus.groups },
      subGroups: { ...completePageStatus.subGroups },
      pages: { ...completePageStatus.pages },
    };

    newState.pages[page] = true;

    const completedPage = config.pages[page];

    const completeIfAllChildrenAreComplete = (
      object: WebinarSubGroupType | WebinarGroupType,
      groupType: "group" | "subGroup"
    ) => {
      if (object.children) {
        if (
          object.children.every(
            (childPage: string) => newState.pages[childPage]
          )
        ) {
          if (groupType === "group") {
            newState.groups[completedPage.group] = true;
          } else {
            newState.subGroups[completedPage.subGroup] = true;
          }
        }
      }
    };

    if (completedPage.subGroup) {
      const subGroup = config.subGroups[config.pages[page].subGroup];
      completeIfAllChildrenAreComplete(subGroup, "subGroup");
    }
    if (completedPage.group) {
      const group = config.groups[completedPage.group];
      completeIfAllChildrenAreComplete(group, "group");
    }

    setCompletePageStatus(newState);
  };

  const getNextPageName = (pageName: WebinarPage): string => {
    const pageIndex = order.indexOf(pageName);
    if (pageIndex < order.length - 1) {
      return order[pageIndex + 1];
    } else {
      return "completed";
    }
  };

  const { pathname } = useLocation();

  const updateProgress = () => {
    const currentStep = ProgressService.getProgress(eventId);
    const pageName = getPageName(pathname);

    if (config.pages[pageName].step === config.pages?.[currentStep]?.step) {
      const nextPageName = getNextPageName(pageName);
      ProgressService.setProgress(nextPageName, eventId);
      setCurrentPage(nextPageName);
      updateCompletePageState(currentStep);
    }
  };

  const fetchCompletedPage = () => {
    const savedCurrentPage = ProgressService.getProgress(eventId);
    if (savedCurrentPage) {
      setCurrentPage(savedCurrentPage);
      setCompletePageStatus(setInitialCompleteState(savedCurrentPage));
    } else {
      ProgressService.setProgress(order[0], eventId);
      setCurrentPage(order[0]);
      setCompletePageStatus(setInitialCompleteState(order[0]));
    }
  };

  const updateEnabledPageStatus = () => {
    const newState = {
      groups: { ...completePageStatus.groups },
      subGroups: { ...completePageStatus.subGroups },
      pages: { ...completePageStatus.pages },
    };
    newState.pages[currentPage] = true;
    const subGroupName = config.pages[currentPage]?.subGroup;
    const groupName = config.pages[currentPage]?.group;
    if (subGroupName) {
      newState.subGroups[subGroupName] = true;
    }
    if (groupName) {
      newState.groups[groupName] = true;
    }

    setEnabledPageStatus(newState);
  };

  useEffect(() => {
    fetchCompletedPage();
  }, []);

  useEffect(() => {
    updateEnabledPageStatus();
  }, [completePageStatus]);

  return { completePageStatus, updateProgress, enabledPageStatus };
};

export default useCompletePageStatus;
