import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { figmaTheme } from "./figmaTheme";
import {
  buttonTheme,
  tooltipTheme,
  inputTheme,
  formLabelTheme,
  modalTheme,
  menuTheme,
  timePickerTheme,
  popoverTheme,
  eventCardTheme,
  eventsListTheme,
  textTheme,
  tagTheme,
  formTheme,
  fileUploaderTheme,
  switchTheme,
  brandingPageTheme,
  headingTheme,
  formErrorTheme,
  tableTheme,
  avatarTheme,
  webinarTypeTheme,
} from "./componentThemes";

import "../../../stylesheets/variables/fonts.css";

const dcTheme = extendTheme(withDefaultColorScheme({ colorScheme: "dc" }), {
  fonts: {
    body: "Poppins",
    heading: "Poppins, Roboto, Georgia, serif",
    mono: "Menlo, monospace",
  },
  colors: {
    dc: {
      500: figmaTheme.colors.primary.light,
      600: figmaTheme.colors.primary.base,
      700: figmaTheme.colors.primary.dark,
    },
    pillDefault: {
      500: figmaTheme.colors.pill.bg.default
    },
    pillTheme: {
      500: figmaTheme.colors.pill.bg.theme
    },
    pillSuccess: {
      500: figmaTheme.colors.pill.bg.success
    },
    pillWarning: {
      500: figmaTheme.colors.pill.bg.warning
    },
    pillDanger: {
      500: figmaTheme.colors.pill.bg.danger
    },
    pillActive: {
      500: figmaTheme.colors.pill.bg.active
    }
  },
  components: {
    Button: buttonTheme,
    Tooltip: tooltipTheme,
    Input: inputTheme,
    FormLabel: formLabelTheme,
    Modal: modalTheme,
    Menu: menuTheme,
    TimePicker: timePickerTheme,
    Popover: popoverTheme,
    EventCard: eventCardTheme,
    EventsList: eventsListTheme,
    Text: textTheme,
    Tag: tagTheme,
    Form: formTheme,
    FormError: formErrorTheme,
    FileUploader: fileUploaderTheme,
    Switch: switchTheme,
    BrandingPage: brandingPageTheme,
    Textarea: inputTheme,
    Heading: headingTheme,
    Table: tableTheme,
    Avatar: avatarTheme,
    WebinarType: webinarTypeTheme,
  },
});

export default dcTheme;

export { figmaTheme };
