import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

type DCModalProps = {
  title: string;
  content: string;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  manualOpen?: boolean;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onClickPrimary: () => void;
  onClickSecondary: () => void;
};

const DCModal: React.FunctionComponent<DCModalProps> = ({
  content,
  title,
  primaryButtonLabel,
  secondaryButtonLabel,
  manualOpen,
  isOpen,
  isLoading,
  onClose,
  onClickPrimary,
  onClickSecondary,
}: DCModalProps): JSX.Element => {

  return (
    <>
      <Modal isOpen={isOpen || manualOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{content}</ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              variant="outline"
              mr={3}
              onClick={onClickSecondary}
            >
              {secondaryButtonLabel}
            </Button>
            <Button
              isLoading={isLoading}
              onClick={onClickPrimary}
            >
              {primaryButtonLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DCModal;
