import React from "react";
import {
  Flex,
  MenuButton,
  MenuItem,
  Menu,
  MenuList,
  Button,
  Text,
  Tag,
  TagLeftIcon,
  Spacer,
  Link
} from "@chakra-ui/react";
import {
  DotsThreeOutlineVertical,
  CalendarBlank,
  Clock,
  PencilSimple,
  Eye,
} from "phosphor-react";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { EventType } from "../../../EventWizard/models/Event";
import Card from "../../common/Card/Card";
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";

type EventCardProps = {
  event: EventType;
};

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const { t } = useTranslation();

  return (
    <Card
      styleProps={{
        minH: "200px",
        margin: "0 40px 40px 0",
        bg: fT.colors.white
      }}
      active={false}
    >
      <Flex
        flexDir="column"
        p={2}
        h="100%"
      >
        <Flex ml={2} justifyContent="space-between">
          <Text variant="card-title" my={2}>
            {event.name}
          </Text>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<DotsThreeOutlineVertical weight="fill" size="20" />}
              variant="ghost-pure"
              aria-label={t("form.cards.options")}
              my="2"
              h="auto"
              alignSelf="flex-start"
            />
            <MenuList pos="absolute" right="-72px" minW="0" w="200px">
              <MenuItem
                as="a"
                href={`/?event_id=${event.id}`}
                icon={<Eye weight="fill" size="16" />}
                role="presentation"
                key={`view-event-${event.id}`}
                w="100%"
                h="30px"
                p={4}
                borderRadius={fT.border.radius.md}
              >
                <Text variant="menu-item">
                  {`${t('actions.view')} ${t('events.name')}`}
                </Text>
              </MenuItem>
              <MenuItem
                as="a"
                href={`/admin/event-tools/${event.id}`}
                icon={<PencilSimple weight="fill" size="16" />}
                role="presentation"
                key={`edit-event-${event.id}`}
                w="100%"
                h="30px"
                p={4}
                borderRadius={fT.border.radius.md}
              >
                <Text variant="menu-item">
                  {`${t('actions.edit')} ${t('events.name')}`}
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        <Flex mx={2}>
          <Tag pl={0} variant="card-detail">
            <TagLeftIcon as={CalendarBlank} weight="fill" />{" "}
            {moment(event.startDate).format("DD MMMM YYYY")}
          </Tag>
          <Tag variant="card-detail">
            <TagLeftIcon as={Clock} weight="fill" />{" "}
            {moment(event.startTime).format("hh:mm a")}
          </Tag>
        </Flex>
        <Spacer />
        <Flex justify="space-between" align="baseline">
          <Tag
            variant={event.public ? "published-status" : "draft-status"}
            role='presentation'
          >
            {event.public ? t('form.status.published') : t('form.status.draft')}
          </Tag>
          {event.public && 
            <Link href={`/agenda?event_id=${event.id}`}
                  _hover={{textDecoration: "none"}}>
              <Tag
                variant="host-meeting"
                role='presentation'
              >
                {t('event_tools.zoom.host_webinar')}
              </Tag>
            </Link>}
        </Flex>
      </Flex>
    </Card>
  );
};

export default EventCard;
