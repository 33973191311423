import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { figmaTheme as fT } from "../figmaTheme";

export const timePickerTheme: ComponentStyleConfig = {
  parts: ['inputGroup', 'input', 'content', 'numberInputBox', 'numberInput', 'numberInputField', 'selectBox', 'select'],
  baseStyle: {
    inputGroup: {
      width: '336px',
      position: 'relative'
    },
    input: {
      _active: {
        borderColor: fT.colors.primary.base
      },
      _focus: {
        borderColor: fT.colors.primary.base
      }
    },
    content: {
      width: '336px',
      background: '#FFFFFF',
      border: '1px solid #FFFFFF',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '0',
      _focus:{
        borderColor: '#FFFFFF'
      }
    },
    numberInputBox: {
      width: '80px',
      height: '40px'
    },
    numberInput: {
      width: '80px',
      height: '40px'
    },
    numberInputField: {
      borderColor: fT.colors.label.tertiary,
      _focus: {
        borderColor: fT.colors.label.tertiary
      },
      _active: {
        borderColor: fT.colors.label.tertiary
      }
    },
    selectBox: {
      width: '100px',
      height: '40px'
    },
    select: {
      borderColor: fT.colors.label.tertiary,
      _focus: {
        borderColor: fT.colors.label.tertiary
      },
      _active: {
        borderColor: fT.colors.label.tertiary
      }
    }
  },
  variants: {
    format24: {
      numberInput: {
        width: '120px'
      },
      numberInputBox: {
        width: '120px'
      },
    }
  }
};
