import { figmaTheme as fT } from "../figmaTheme";

export const inputTheme = {
  variants: {
    outline: {
      field: {
        color: fT.colors.label.primary,
        borderColor: fT.colors.label.tertiary,
        _disabled: {
          bg: fT.colors.grey.base,
        },
        _hover: {
          borderColor: fT.colors.skyBlue.base,
        },
        _active: {
          borderColor: fT.colors.skyBlue.base,
        },
        _focus: {
          borderColor: fT.colors.skyBlue.base,
          boxShadow: `0 0 0 1px ${fT.colors.skyBlue.base}`,
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: "14px",
      },
    },
  },
  defaultProps: {
    focusBorderColor: fT.colors.primary.base,
    errorBorderColor: fT.colors.alert,
    fontSize: "14px",
  },
};
