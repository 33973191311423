import React, { MutableRefObject, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../common/shared/FormControlLabel";
import { EventType } from "../../../EventWizard/models/Event";
import { useSchema } from "../../../helpers/useSchema";
import schemaGenerator from "./formSchema";

export type SupportEmailFormProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
}

export const SupportEmailForm: React.FC<SupportEmailFormProps> = ({ event, submitForm, handleErrors, formErrors }) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const defaultValues = (({ moderatorCcEmail }) => ({ moderatorCcEmail }))(event);
  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormControl>
        <FormControlLabel
          label={t("event_configurations.email.label")}
          error={errors.moderatorCcEmail}
          helperText={t("event_configurations.email.hint")}
          isRequired
        >
          <Input {...register("moderatorCcEmail")} />
        </FormControlLabel>
      </FormControl>
    </form>
  );
};

export default SupportEmailForm;
