import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";
import { CheckoutPageProps, PaymentIntentData } from "../../GroupRegistration/types";
import { CountryCodeAlpha2, countryListAlpha2 } from '../../helpers/country_lists';

export default function CheckoutPage(props: CheckoutPageProps) {
  const {
    stripePublishableKey,
    paymentIntentsPath,
    groupRegistrationId,
    checkoutRedirectUrl,
    verifyPaymentUrl,
    editGroupRegistrationUrl,
    requestCountryCode,
    updateReceiptEmailPath,
    placeholderReceiptEmail,
    hasPayByInvoiceFeature,
  } = props;

  { /* Stripe promise set first on the page so it doesn't get reloaded on subsequent renders */ }
  const stripePromise = loadStripe(stripePublishableKey);

  const [paymentIntentData, setPaymentIntentData] = useState<PaymentIntentData | undefined>();
  const [isLoadingPaymentIntentData, setLoadingPaymentIntentData] = useState(true);
  const [paymentIntentError, setPaymentIntentError] = useState();
  const [countryCode, setCountryCode] = useState<CountryCodeAlpha2>((requestCountryCode as CountryCodeAlpha2 | undefined) || 'AU');

  // TODO: #8644 Add setter and pass down to checkout form to update the payment method marked on group registration
  // This will require recalculation at this stage (through a re-fetch,) so the fee applicable to the payment method is reflected in the summary
  const [paymentMethodName, setPaymentMethodName] = useState<string>('stripe');

  const fetchUpdatedPaymentIntent = async () => {
    try {
      setLoadingPaymentIntentData(true);
      const paymentIntentResponse = await fetch(paymentIntentsPath, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country_code: countryCode,
          country: countryListAlpha2[countryCode],
          group_registration_id: groupRegistrationId,
          payment_method_name: paymentMethodName,
        }),
      });
      const paymentIntentResponseData: PaymentIntentData = await paymentIntentResponse.json();
      setPaymentIntentData(paymentIntentResponseData);
    } catch (e) {
      setPaymentIntentError(e);
    } finally {
      setLoadingPaymentIntentData(false);
    }
  };

  useEffect(() => {
    fetchUpdatedPaymentIntent();
  }, [countryCode, paymentMethodName]);

  const loadingContent = (
    <div className='w-100 d-flex justify-content-center mt-5'>
      <div className="spinner-border text-primary text-center mx-auto"></div>
    </div>
  );

  const errorContent = (
    <p className="text-danger">
      {paymentIntentError}
    </p>
  );

  return (
    <div className="group-registration-checkout-page">
      {paymentIntentError ? (
        errorContent
      ) : !paymentIntentData ? (
        loadingContent
      ) : (
        <CheckoutForm
          checkoutRedirectUrl={checkoutRedirectUrl}
          editGroupRegistrationUrl={editGroupRegistrationUrl}
          verifyPaymentUrl={verifyPaymentUrl}
          paymentIntentData={paymentIntentData}
          isLoadingPaymentIntent={isLoadingPaymentIntentData}
          countryCode={countryCode}
          stripePromise={stripePromise}
          placeholderReceiptEmail={placeholderReceiptEmail}
          groupRegistrationId={groupRegistrationId}
          paymentMethodName={paymentMethodName}
          paymentIntentsPath={paymentIntentsPath}
          updateReceiptEmailPath={updateReceiptEmailPath}
          updateCountry={setCountryCode}
          setPaymentMethodName={setPaymentMethodName}
          hasPayByInvoiceFeature={hasPayByInvoiceFeature}
        />
      )}
    </div>
  );
}
