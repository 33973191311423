import React, { MutableRefObject, useEffect } from "react";
import { Input, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../common/shared/FormControlLabel";
import { EventType } from "../../../EventWizard/models/Event";
import { useSchema } from "../../../helpers/useSchema";
import schemaGenerator from "./formSchema";

export type MetadataFormProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
};

const MetadataForm: React.FC<MetadataFormProps> = ({ event, submitForm, handleErrors, formErrors }) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const defaultValues = (({ metaTitle, metaDescription, metaKeywords }) => ({ metaTitle, metaDescription, metaKeywords }))(event);

  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Text
        mb={3}
      >
        {t("metadata.introduction")}
      </Text>

      <FormControlLabel
        label={t("metadata.meta_title.label")}
        error={errors.metaTitle}
        isRequired
      >
        <Input
          placeholder={t("metadata.meta_title.placeholder")}
          {...register("metaTitle")}
        />
      </FormControlLabel>

      <FormControlLabel
        label={t("metadata.meta_description.label")}
        error={errors.metaDescription}
        isRequired
      >
        <Input
          placeholder={t("metadata.meta_description.placeholder")}
          {...register("metaDescription")}
        />
      </FormControlLabel>

      <FormControlLabel
        label={t("metadata.meta_tags.label")}
        error={errors.metaKeywords}
        isRequired
      >
        <Input
          placeholder={t("metadata.meta_tags.placeholder")}
          {...register("metaKeywords")}
        />
      </FormControlLabel>
    </form>
  );
};

export default MetadataForm;
