import React from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import SupportEmailForm, { SupportEmailFormProps } from "./SupportEmailForm";
import {
  FAKE_EVENT,
  render,
  screen,
  waitFor,
  fireEvent,
} from "../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: SupportEmailFormProps = {
  event: FAKE_EVENT,
  submitForm,
  handleErrors,
  formErrors,
};

describe("Homepage Setup Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<SupportEmailForm {...requiredProps} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe("moderator cc email", () => {
    test("is required", async () => {
      render(<SupportEmailForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "event_configurations.email.label",
          { exact: false }
        );
        fireEvent.change(inputLabel, { target: { value: ""}});
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    test("must be an email", async () => {
      render(<SupportEmailForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "event_configurations.email.label",
          { exact: false }
        );
        fireEvent.change(inputLabel, { target: { value: "FAKE_EMAIL"}});
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText("event_tools.validations.email")
      ).toBeInTheDocument();
    });
  });
});
