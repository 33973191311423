import { figmaTheme as fT } from "../figmaTheme";

export const formTheme = {
  baseStyle: {
    fontFamily: "Poppins",
    helperText: {
      mt: 0,
      mb: 2,
      color: fT.colors.label.secondary,
      fontSize: "12px"
    },
    container: { // container refers to FormControl
      mb: 6
    }
  }
};
