import React, { Dispatch, useEffect, useState } from "react";
import { useParams, Outlet, useOutletContext } from "react-router-dom";
import EventService from "../../../EventWizard/services/eventService";
import AppAdapter from "../../../../utils/adapter";
import { EventType } from "../../../EventWizard/models/Event";
import TenantService from "../../../EventWizard/services/tenantService";
import { TenantType } from "../../../EventWizard/models/Tenant";
import { WebinarStatusType, WEBINAR_CONFIG } from "../../config";
import useActivePageStatus from "../../hooks/useActivePageStatus";
import useCompletePageStatus from "../../hooks/useCompletePageStatus";

export type EventContextType = {
  event: EventType;
  setEvent: Dispatch<EventType>;
};
type TenantContextType = {
  tenant: TenantType;
  setTenant: Dispatch<TenantType>;
};
export type PageStatusContextType = {
  activePageStatus: WebinarStatusType;
  completePageStatus: WebinarStatusType;
  updateProgress: () => void;
  enabledPageStatus: WebinarStatusType;
};

const Event: React.FC = (): JSX.Element => {
  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [tenant, setTenant] = useState();
  const { activePageStatus } = useActivePageStatus(WEBINAR_CONFIG);
  const { completePageStatus, updateProgress, enabledPageStatus } =
    useCompletePageStatus(WEBINAR_CONFIG, eventId);

  async function fetchEvent() {
    const payload = await EventService.getEvent(eventId);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, {
        transform: EventService.transform,
      });
      setEvent(response);
      await fetchTenant(response.tenantId);
    }
  }

  async function fetchTenant(tenantId) {
    const payload = await TenantService.getTenant(tenantId);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, {
        transform: TenantService.transform,
      });
      setTenant(response);
    }
  }

  useEffect(() => {
    fetchEvent();
  }, []);

  if (!event) return null;
  if (!tenant) return null;

  return (
    <Outlet
      context={{
        event,
        setEvent,
        tenant,
        setTenant,
        activePageStatus,
        completePageStatus,
        updateProgress,
        enabledPageStatus,
      }}
    />
  );
};

export default Event;

export function useEvent() {
  return useOutletContext<EventContextType>();
}
export function useTenant() {
  return useOutletContext<TenantContextType>();
}
export function usePageStatus() {
  return useOutletContext<PageStatusContextType>();
}
