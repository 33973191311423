import { useTranslation } from "react-i18next";

export const setCurrencyFormat = ((currencyCode: string) => {
  const { i18n } = useTranslation();

  return Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currencyCode
  });
});
