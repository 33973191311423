import { figmaTheme as fT } from "../figmaTheme";

export const formLabelTheme = {
  baseStyle: {
    color: fT.colors.label.primary,
    fontSize: "14px",
    _disabled:  {
      opacity: fT.opacities.opaque
    }
  }
};
