import React from "react";
import { Box, Flex, Text, Image, useMultiStyleConfig, BoxProps, FlexProps } from "@chakra-ui/react";

interface WebinarTypeProps {
  type: string;
  selectedType: string;
  iconStyles: {
    iconSrc: string;
    activeIconSrc: string;
    bg: string;
  };
  title: string;
  description: string;
  handleClick: (type: string) => void;
}

const WebinarType = ({ type, selectedType, iconStyles, title, description, handleClick }: WebinarTypeProps) => {
  const styles = useMultiStyleConfig("WebinarType", {});
  const active = type == selectedType;
  const styleProps = active ? styles.webinarTypeCardActive : styles.webinarTypeCard;
  const icon = active ? iconStyles.activeIconSrc : iconStyles.iconSrc;
  const bg = active ? "#FFFFFF" : iconStyles.bg;

  return (
    <Box {...styleProps as BoxProps} onClick={() => handleClick(type)}>
      <Flex {...styles.webinarTypeIcon as FlexProps} background={bg} >
        <Image src={icon} alt={title} h="42px" />
      </Flex>
      <Text variant="webinar-type-title">{title}</Text>
      <Text variant="webinar-type-description">{description}</Text>
    </Box>
  );
};

export default WebinarType;
