import React, { useRef, useState } from "react";
import { Button, Heading } from "@chakra-ui/react";
import FormDrawer from "../../../common/FormDrawer";
import PresentationForm from "./PresentationForm";
import { TalkType } from '../../../../EventWizard/models/talk';
import { EventType } from '../../../../EventWizard/models/Event';
import { useTranslation } from "react-i18next";

import TalkService from "../../../../EventWizard/services/talkService";
import AppAdapter from "../../../../../utils/adapter";

type PresentationDrawerFormProps = {
  talk: TalkType;
  event: EventType;
  refreshTalks: () => void;
  isOpen: boolean;
  onClose: () => void;
};


const PresentationDrawerForm: React.FC<PresentationDrawerFormProps> = ({
  talk,
  event,
  refreshTalks,
  isOpen,
  onClose
}) => {

  const { t } = useTranslation();

  const submitForm = useRef(null);
  const handleErrors = useRef(null);
  const formErrors = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const createOrUpdateTalk = async (data) => {
    setSubmitting(true)
    const payload = await TalkService.createOrUpdateTalk(event.id, event.startDate, talk.id, data);
    if (payload.ok) {
      refreshTalks();
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const handleSubmitForm = async () => {
    try {
      await submitForm.current(createOrUpdateTalk)();
      if (Object.keys(formErrors.current).length === 0) onClose();
    } catch (errors) {
      for (const name in errors) {
        handleErrors.current(name, errors[name]);
      }
      if (errors["time"]) {
        handleErrors.current("startTime", errors["time"]);
      }
      if (errors["duration"]) {
        handleErrors.current("endTime", errors["duration"]);
      }
    }
    setSubmitting(false)
  };

  const formHeading = () => {
    if (talk.id) {
      return t("presentation.edit_heading");
    } else {
      return t("presentation.new_heading");
    }
  }

  const buttonLoadingText = () => {
    if (talk.id) {
      return t("actions.updating")
    } else {
      return t("actions.creating")
    }
  }

  return (
    <FormDrawer
      isOpen={isOpen}
      onClose={onClose}
      buttonComponent={
        <>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {t("actions.close")}
          </Button>
          <Button isLoading={submitting} loadingText={buttonLoadingText()} onClick={handleSubmitForm}>
            {t("actions.save")}
          </Button>
        </>
      }
      headingComponent={
        <Heading
          size="sm"
          mb={3}
        >
          {formHeading()}
        </Heading>
      }
    >
      <PresentationForm
        talk={talk}
        eventId={event.id}
        tzinfoName={event.tzinfoName}
        submitForm={submitForm}
        handleErrors={handleErrors}
        formErrors={formErrors}
      />
    </FormDrawer>
  );
};

export default PresentationDrawerForm;
