export const tableTheme = {
  variants: {
    dcTable: {
      tr: {
        _hover: {
          background: "#F4F4F4"
        }
      },
      th: {
        fontFamily: "Inter",
        color: "#666666",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
        borderBottom: "1px",
        borderColor: "#CCCCCC",
        textTransform: "none"
      },
      td: {
        fontFamily: "Inter",
        color: "#333333",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400
      }
    }
  }
};
