import React, { MutableRefObject, useEffect, useRef } from "react";
import { FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Icon,
} from "@chakra-ui/react";
import { Warning } from "phosphor-react";
import { useTranslation } from "react-i18next";

import DCTimePicker from "../../common/DCTimePicker/DCTimePicker";
import DCDropdown from "../../common/DCDropdown/DCDropdown";
import TimezoneFetcher from "../../common/TimezoneFetcher";
import { EventType } from "../../../EventWizard/models/Event";
import FormControlLabel from "../../common/shared/FormControlLabel";
import { LayoutForm } from "../../common/FormPageLayout";
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";
import SingleDatePicker from "../../common/DCDatePicker/SingleDatePicker";
import schemaGenerator from "./formSchema";
import { errorMessagesCombiner } from "../../../EventWizard/helpers/customValidators";
import { useSchema } from "../../../helpers/useSchema";
import UsersSelect from "../../common/UsersSelect";

export type WebinarDetailsProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
};

export const WebinarDetailsForm: React.FC<WebinarDetailsProps> = ({
  event,
  submitForm,
  handleErrors,
  formErrors,
}) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const {
    name,
    startDate,
    endDate,
    location,
    startTime,
    endTime,
    timeZone,
    facilitatorIds,
  } = event;

  const defaultValues = {
    name,
    startDate,
    endDate,
    location,
    startTime,
    endTime,
    timeZone,
    facilitatorIds,
  };

  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });

  formErrors.current = errors;

  useEffect(() => {
    register("startDate");
    register("endDate");
    register("startTime");
    register("endTime");
    register("timeZone");
  }, []);

  const onChangeDate = e => {
    setValue("startDate", e);
    setValue("endDate", e);
    trigger("startDate");
  };

  const onChangeTimes = e => {
    setValue("startTime", e.startTime);
    setValue("endTime", e.endTime);
    trigger(["startTime", "endTime"]);
  };

  const onChangeTimezone = e => {
    setValue("timeZone", e.option.value);
    trigger(["timeZone"]);
  };

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  const isTimeInvalid = !!(errors?.startTime || errors?.endTime);

  const handleUserIdsChange = (userIds: Array<string>) => {
    setValue("facilitatorIds", userIds);
    trigger("facilitatorIds");
  }

  const reFetchFacilitators = useRef(null);

  const handleAddNewFacilitator = async newUserSelection => {
    await reFetchFacilitators.current(newUserSelection);
    setValue("facilitatorIds", [...facilitatorIds.filter(f => f !== newUserSelection.value), newUserSelection.value]);
  };

  return (
    <LayoutForm onSubmit={e => e.preventDefault()}>
      <FormControlLabel
        label={t("webinar_details_form.name.label")}
        error={errors.name}
        isRequired
      >
        <Input {...register("name")} />
      </FormControlLabel>

      <TimezoneFetcher
        selection={event.timeZone}
        render={(data, selectedData) => (
          <FormControl isInvalid={!!errors?.timeZone} w="initial" isRequired>
            <FormLabel>{t("webinar_details_form.timezone.label")}</FormLabel>
            {selectedData && <DCDropdown
              isError={!!errors?.timeZone}
              options={data}
              handleChange={onChangeTimezone}
              preselectedOption={{
                label: `${selectedData?.name} (${selectedData?.offset})`,
                value: selectedData?.name,
              }}
            />}
          </FormControl>
        )}
      />

      <FormControlLabel
        label={t("webinar_details_form.date.label")}
        error={errors.startDate}
        isRequired
      >
        <SingleDatePicker
          isError={!!errors.startDate}
          onChange={onChangeDate}
          initialDate={startDate}
        />
      </FormControlLabel>

      <FormControl isInvalid={isTimeInvalid} w="initial" isRequired>
        <FormLabel>{t("webinar_details_form.time.label")}</FormLabel>
        <DCTimePicker
          isError={isTimeInvalid}
          format24
          tzinfoName={event.tzinfoName}
          initialStartTime={event.startTime}
          initialEndTime={event.endTime}
          handleChange={onChangeTimes}
          htmlId={`event-time`}
        />
        {isTimeInvalid && (
          <FormErrorMessage>
            <Icon as={Warning} weight="fill" color={fT.colors.alert} />
            {errorMessagesCombiner(["startTime", "endTime"], errors)}
          </FormErrorMessage>
        )}
      </FormControl>

      <UsersSelect
        eventId={event.id}
        userIds={facilitatorIds}
        handleUserIdsChange={handleUserIdsChange}
        error={errors.facilitatorIds as unknown as FieldError}
        isAddNew
        updateUserSelect={handleAddNewFacilitator}
        addNewUserText={t("actions.add_new_facilitator")}
        reFetch={reFetchFacilitators}
        fieldLabel={t("webinar_details_form.facilitator.label")}
        newUserFormHeading={t("event_tools.new_facilitator")}
        editUserFormHeading={t("facilitator_page.edit_facilitator")}
      />
    </LayoutForm>
  );
};

export default WebinarDetailsForm;
