import React, { useEffect, useState } from "react";
import { Skeleton, Heading, Flex, Button } from "@chakra-ui/react";
import { PollProps, UserProps } from "../types";
import SingleSelect from "./SingleSelect";
import MultiSelect from "./MultiSelect";
import {
  createResponse,
  fetchResponse,
  setAdditionField,
  setResults,
  updateResponse,
} from "../requests";
import { useTranslation } from "react-i18next";

interface Props {
  poll: PollProps;
  user: UserProps;
}

const PollForm: React.FunctionComponent<Props> = ({
  poll,
  user,
}: Props): JSX.Element => {
  const [currentField, setCurrentField] = useState(null);
  const [currentResults, setCurrentResults] = useState(null);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [userResponse, setUserResponse] = useState(null); // Current user response
  const [fieldValue, setFieldValue] = useState(null); // Current field value - not user response
  const [responseCheck, setResponseCheck] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const SelectComponent =
    currentField?.attributes.fieldType == "single_select"
      ? SingleSelect
      : MultiSelect;

  const { t } = useTranslation();

  const resultsSetter = async () => {
    setRefreshLoading(true);
    await setResults(currentField.id, setCurrentResults);
    setRefreshLoading(false);
  };

  const saveResponse = async () => {
    if (userResponse) {
      await updateResponse(
        currentField.id,
        user.id,
        userResponse.id,
        fieldValue,
        setUserResponse
      );
    } else {
      await createResponse(
        currentField.id,
        user.id,
        fieldValue,
        setUserResponse
      );
    }
    if (showResults) {
      resultsSetter();
    }
  };

  const toggleResults = async () => {
    if (!showResults) {
      setResultsLoading(true);
      await resultsSetter();
      setShowResults(true);
      setResultsLoading(false);
    } else {
      setShowResults(false);
    }
  };

  useEffect(() => {
    if (
      currentResults &&
      Object.values(currentResults.results_as_percent).length > 0
    ) {
      const totalVotes = Object.values(
        currentResults.results_as_percent
      ).reduce((sum: number, n: number) => sum + n);
      setCurrentTotal(Number(totalVotes));
    }
  }, [currentResults]);

  useEffect(() => {
    setUserResponse(null);
    if (currentField) {
      resultsSetter();
      fetchResponse(currentField.id, user.id, setUserResponse);
      setTimeout(() => setResponseCheck(true), 300);
    }
  }, [currentField]);

  useEffect(() => {
    if (poll) {
      setAdditionField(poll.id, setCurrentField);
    }
    setResponseCheck(false);
  }, [poll]);

  const buttonClassName = "btn btn-primary rounded-pill px-5 py-3 m-2";

  return (
    <Skeleton
      isLoaded={currentField != null}
      height={currentField === null ? "150px" : "100%"}
      width="100%"
    >
      {currentField && (
        <Flex flexDir="column" alignItems="center">
          <Heading fontSize={"14px"} mb={2}>
            {currentField.attributes.fieldLabel}
          </Heading>
          {responseCheck && (
            <>
              <Flex direction="row" align="center">
                {poll.results && !poll.completed && (
                  <Button
                    className={buttonClassName}
                    onClick={toggleResults}
                    isLoading={resultsLoading}
                  >
                    {showResults
                      ? t("pollings.hide_results")
                      : t("pollings.show_results")}
                  </Button>
                )}
                {showResults && poll.results && !poll.completed && (
                  <Button
                    mx={4}
                    className={buttonClassName}
                    onClick={resultsSetter}
                    isLoading={refreshLoading}
                  >
                    {t("pollings.refresh")}
                  </Button>
                )}
              </Flex>
              <SelectComponent
                results={{ ...currentResults }.results_as_percent}
                totalVotes={currentTotal || 0}
                onChange={setFieldValue}
                existingResponse={
                  userResponse && userResponse.attributes.options
                }
                showResults={poll.results && (showResults || poll.completed)}
                field={currentField}
                disabled={poll && poll.completed}
              />
              {poll && !poll.completed && (
                <Button
                  className={buttonClassName}
                  onClick={saveResponse}
                  disabled={fieldValue === null}
                >
                  {t("actions.submit")}
                </Button>
              )}
            </>
          )}
        </Flex>
      )}
    </Skeleton>
  );
};

export default PollForm;
