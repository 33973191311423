import React from "react";
import { act } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import FormDrawer from "./FormDrawer";
import { render } from "../../../EventWizard/test-utils";
import { noop } from "../../../../utils";
expect.extend(toHaveNoViolations);

describe("SessionPageLayout", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(
      <FormDrawer
        isOpen
        onClose={noop}
        buttonComponent={<button>Test</button>}
        headingComponent={<h1>Heading</h1>}
      >
        <div>Test Content</div>
      </FormDrawer>
    );

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
