import React, { useState, useRef, useEffect } from "react";
import IconSearch from "./IconSearch";
import IconDropdown from "./IconDropdown";
import useOnClickOutside from "use-onclickoutside";
import styled from "styled-components";
import { IconEntry } from "../../phosphorIcons/types";

import { search, getByName } from "../../phosphorIcons";
import RequiredLabelPrefix from "../RequiredLabelPrefix";

const SearchLabel = styled.label`
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`;

const SearchHint = styled.p`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  vertical-align: middle;
  margin: 0;
  padding: 10px 0;
`;

const phosphorPrefixStrip = (iconName: string): string =>
  iconName && iconName?.length > 0 ? iconName.replace(/^ph-/, "") : "";

interface Props {
  inputId: string;
  inputFor: string;
  inputName: string;
  label: string;
  hint: string;
  value: string;
  disabled: boolean;
  required: boolean;
}

const IconPicker: React.FunctionComponent<Props> = ({
  inputId,
  inputFor,
  inputName,
  label,
  hint,
  value,
  disabled = false,
  required = false,
}: Props): JSX.Element => {
  const [selectedIcon, setSelectedIcon] = useState(
    getByName(phosphorPrefixStrip(value))
  );
  const [dropdownActive, setDropdownActive] = useState(false);
  const [searchHintActive, setSearchHintActive] = useState(true);
  const [iconList, setIconList] = useState([]);
  const { name: iconName = "" } = selectedIcon;
  const [searchValue, setSearchValue] = useState(iconName);

  const openDropdown = (): void => {
    if (!disabled) setDropdownActive(true);
  };
  const closeDropdown = (): void => setDropdownActive(false);
  const activateSearchHint = (): void => setSearchHintActive(true);
  const deactivateSearchHint = (): void => setSearchHintActive(false);
  const selectIcon = (icon: IconEntry): void => {
    setSelectedIcon(icon);
    setSearchValue(icon.name);
    closeDropdown();
  };

  useEffect(() => {
    if (selectedIcon && iconName && !dropdownActive) {
      deactivateSearchHint();
    } else {
      activateSearchHint();
    }
  }, [selectedIcon, dropdownActive]);

  useEffect((): void => {
    setSelectedIcon(getByName(searchValue));
    setIconList(search(searchValue, { limit: 18 }));
  }, [searchValue]);

  const ref = useRef(null);
  useOnClickOutside(ref, closeDropdown);

  return (
    <div ref={ref}>
      <SearchLabel id={inputFor} htmlFor={inputFor}>
        {required && <RequiredLabelPrefix />}
        {label}
      </SearchLabel>
      <IconSearch
        value={searchValue}
        setValue={setSearchValue}
        openDropdown={openDropdown}
        selectedIcon={selectedIcon}
        inputId={inputId}
        inputFor={inputFor}
        inputName={inputName}
        disabled={disabled}
        required={required}
      />
      {searchHintActive && !disabled && (
        <SearchHint role="presentation">{hint}</SearchHint>
      )}
      <IconDropdown
        iconList={iconList}
        dropdownActive={dropdownActive}
        selectIcon={selectIcon}
      />
    </div>
  );
};

export default IconPicker;
