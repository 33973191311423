import { getAvailableDays } from "./utils";

describe("getAvailableDays", () => {
  it("Returns all dates between start date and end date", () => {
    expect(
      getAvailableDays(
        "2022-03-25T00:00:00.000Z",
        "2022-03-27T00:00:00.000Z",
        []
      )
    ).toEqual([
      "2022-03-25T00:00:00.000Z",
      "2022-03-26T00:00:00.000Z",
      "2022-03-27T00:00:00.000Z",
    ]);
  });

  it("Returns an array of dates excluding unavailable days", () => {
    expect(
      getAvailableDays("2022-03-25T00:00:00.000Z", "2022-03-27T00:00:00.000Z", [
        "2022-03-26T00:00:00.000Z",
      ])
    ).toEqual(["2022-03-25T00:00:00.000Z", "2022-03-27T00:00:00.000Z"]);
  });

  it("Returns an empty array when all dates between start date and end date are provided", () => {
    expect(
      getAvailableDays("2022-03-25T00:00:00.000Z", "2022-03-27T00:00:00.000Z", [
        "2022-03-25T00:00:00.000Z",
        "2022-03-26T00:00:00.000Z",
        "2022-03-27T00:00:00.000Z",
      ])
    ).toEqual([]);
  });
});
