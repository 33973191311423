import React from "react";

import FormPageLayout from "../../../../../common/FormPageLayout";
import WebsiteStylingForm from "../../../../../forms/WebsiteStylingForm";
import { useEvent } from "../../../../../pages/Event";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";
import { useWebinarFormActions } from "../../../../hooks/useWebinarFormActions";

const WebsiteStyle: React.FC = (): JSX.Element => {
  const { event } = useEvent();

  const { getStepDetailsByRoute, navigateTo, updateEventWithFormData } =
    useWebinarFormActions();

  const stepDetails = [
    getStepDetailsByRoute("websiteStyle"),
    getStepDetailsByRoute("customStyling"),
  ];

  const websiteStyleUpdateEvent = async data => {
    if (!data.favicon) delete data.favicon;

    return updateEventWithFormData(data);
  };

  return (
    <FormPageLayout
      event={event}
      stepDetails={stepDetails}
      update={websiteStyleUpdateEvent}
      previousForm={navigateTo.agenda}
      exitForm={navigateToEventList}
      nextForm={navigateTo.customStyling}
      previewVariant="Web"
    >
      {props => <WebsiteStylingForm event={event} {...props} />}
    </FormPageLayout>
  );
};

export default WebsiteStyle;
