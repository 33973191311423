import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Event from "../EventTools/pages/Event";
import { ChakraProvider } from "@chakra-ui/react";
import dcTheme from "../react/dcTheme";
import { Snakecase_UserType } from "./models/User";
import { Snakecase_TenantType } from "./models/Tenant";
import { AuthProvider } from "./auth/AuthProvider";
import EventDetails from "../EventTools/pages/steps/EventDetails";
import WebsiteStyle from "../EventTools/pages/steps/EventWebsite/BrandAndStyle/WebsiteStyle";
import CustomStyling from "../EventTools/pages/steps/EventWebsite/BrandAndStyle/CustomStyling";
import HeroSection from "../EventTools/pages/steps/EventWebsite/HomePage/HeroSection";
import AdditionalFeatures from "../EventTools/pages/steps/EventWebsite/HomePage/AdditionalFeatures";
import AddSection from "../EventTools/pages/steps/EventWebsite/HomePage/AddSection";
import FooterSection from "../EventTools/pages/steps/EventWebsite/HomePage/FooterSection";
import SupportEmail from "../EventTools/pages/steps/EventWebsite/Configuration/SupportEmail";
import MetaData from "../EventTools/pages/steps/EventWebsite/Configuration/MetaData";
import Publish from "../EventTools/pages/steps/Publish";
import FacilitatorPage from "../EventTools/Webinar/pages/Agenda/Facilitators/FacilitatorsPage";
import GeneralDetails from "../EventTools/Webinar/pages/WebinarDetails/General";
import WebinarAgendaPage from "../EventTools/Webinar/pages/Agenda";
import WebinarWebsiteStyle from "../EventTools/Webinar/pages/WebinarWebsite/BrandAndStyle/WebsiteStyle";
import WebinarCustomStyling from "../EventTools/Webinar/pages/WebinarWebsite/BrandAndStyle/CustomStyling";
import WebinarHeroSection from "../EventTools/Webinar/pages/WebinarWebsite/HomePage/HeroSection";
import WebinarAdditionalFeatures from "../EventTools/Webinar/pages/WebinarWebsite/HomePage/AdditionalFeatures";
import WebinarAddSection from "../EventTools/Webinar/pages/WebinarWebsite/HomePage/AddSection";
import WebinarFooterSection from "../EventTools/Webinar/pages/WebinarWebsite/HomePage/FooterSection";
import WebinarSupportEmail from "../EventTools/Webinar/pages/WebinarWebsite/Configuration/SupportEmail";
import WebinarMetadata from "../EventTools/Webinar/pages/WebinarWebsite/Configuration/Metadata";
import WebinarPublish from "../EventTools/Webinar/pages/Publish";
// import AttendeeConfigurations from "../EventTools/Webinar/pages/WebinarDetails/AttendeeConfigurations";

import "./styles/wizard.css";
import ProgressProvider from "./progress/ProgressProvider";
import WizardEntry from "./progress/WizardEntry";

type EventWizardProps = {
  user: Snakecase_UserType;
  tenant: Snakecase_TenantType;
};

const EventWizard: React.FC<EventWizardProps> = ({ user, tenant }) => {
  return (
    <AuthProvider user={user} tenant={tenant}>
      <ChakraProvider theme={dcTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/admin/event-tools/:eventId" element={<Event />}>
              <Route index element={<WizardEntry />} />
              <Route element={<ProgressProvider />}>
                <Route element={<EventDetails />} />
                <Route path="steps/event-details" element={<EventDetails />} />
                <Route path="steps/website-style" element={<WebsiteStyle />} />
                <Route
                  path="steps/custom-styling"
                  element={<CustomStyling />}
                />
                <Route path="steps/hero-section" element={<HeroSection />} />
                <Route
                  path="steps/additional-features"
                  element={<AdditionalFeatures />}
                />
                <Route path="steps/add-section" element={<AddSection />} />
                <Route
                  path="steps/footer-section"
                  element={<FooterSection />}
                />
                <Route path="steps/support-email" element={<SupportEmail />} />
                <Route path="steps/metadata" element={<MetaData />} />
                <Route path="steps/publish" element={<Publish />} />

                {/* Webinar Flow */}
                <Route path="webinar/details" element={<GeneralDetails />} />
                {/* <Route
                  path="webinar/attendee-configurations"
                  element={<AttendeeConfigurations />}
                /> */}
                <Route path="agenda" element={<WebinarAgendaPage />} />
                <Route
                  path="agenda/facilitators"
                  element={<FacilitatorPage />}
                />
                <Route
                  path="webinar-website/website-style"
                  element={<WebinarWebsiteStyle />}
                />
                <Route
                  path="webinar-website/custom-styling"
                  element={<WebinarCustomStyling />}
                />
                <Route
                  path="webinar-website/hero-section"
                  element={<WebinarHeroSection />}
                />
                <Route
                  path="webinar-website/additional-features"
                  element={<WebinarAdditionalFeatures />}
                />
                <Route
                  path="webinar-website/add-sections"
                  element={<WebinarAddSection />}
                />
                <Route
                  path="webinar-website/footer"
                  element={<WebinarFooterSection />}
                />
                <Route
                  path="webinar-website/support-email"
                  element={<WebinarSupportEmail />}
                />
                <Route
                  path="webinar-website/metadata"
                  element={<WebinarMetadata />}
                />
                <Route path="webinar/publish" element={<WebinarPublish />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </AuthProvider>
  );
};

export default EventWizard;
