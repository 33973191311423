import React from "react";

import FormPageLayout from "../../../../../common/FormPageLayout";
import { useEvent } from "../../../../../pages/Event";
import CustomStylingSectionForm from "../../../../../forms/CustomStylingSectionForm";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";
import { useWebinarFormActions } from "../../../../hooks/useWebinarFormActions";

const EmailStyling: React.FC = (): JSX.Element => {
  const { event } = useEvent();

  const { getStepDetailsByRoute, navigateTo, updateEvent } =
    useWebinarFormActions();

  const stepDetails = [
    getStepDetailsByRoute("websiteStyle"),
    getStepDetailsByRoute("customStyling"),
  ];

  return (
    <FormPageLayout
      event={event}
      stepDetails={stepDetails}
      update={updateEvent}
      previousForm={navigateTo.websiteStyle}
      exitForm={navigateToEventList}
      nextForm={navigateTo.heroSection}
      previewVariant="Web"
    >
      {props => <CustomStylingSectionForm event={event} {...props} />}
    </FormPageLayout>
  );
};

export default EmailStyling;
