import { padNum, pad2Num, maxHour, getInitHour, getInitMeridian } from "./utils";

describe("DCTimePicker helper methods", () => {
  describe("padNum", () => {
    it("should add '0' as padding for length 2 by default", () => {
      expect(padNum(0)).toBe("00");
      expect(padNum(1)).toBe("01");
    });
    it("should add specified padding value for specified length", () => {
      expect(padNum(10, 4, "X")).toBe("XX10");
    });
  });

  describe("pad2Num", () => {
    it("should add '0' as padding for length 2 by default", () => {
      expect(pad2Num(0)).toBe("00");
      expect(pad2Num(1)).toBe("01");
    });
    it("should return last 2 digits of number greater than 2", () => {
      expect(pad2Num(987)).toBe("87");
    });
  });

  describe("maxHour", () => {
    it("should return 24 if formatting is 24 hours and minute value is 0", () => {
      expect(maxHour(true, "0")).toBe(24);
    });
    it("should return 23 if formatting is 24 hours and minute value is not 0", () => {
      expect(maxHour(true, "59")).toBe(23);
    });
    it("should return 12 if formatting is 12 hours and minute value is 0", () => {
      expect(maxHour(false, "0")).toBe(12);
    });
    it("should return 12 if formatting is 12 hours and minute value is 0", () => {
      expect(maxHour(false, "59")).toBe(12);
    });
  });

  const tzinfoName = "Australia/Melbourne";
  describe("getInitHour", () => {
    describe("formatting is 24 hours", () => {
      it("should return 0 when hour value is 0", () => {
        const date = new Date("2022-07-19T00:00:00.000+10:00");
        expect(getInitHour(true, date, tzinfoName)).toBe("00");
      });
      it("should return 11 when hour value is 11", () => {
        const date = new Date("2022-07-19T11:00:00.000+10:00");
        expect(getInitHour(true, date, tzinfoName)).toBe("11");
      });
      it("should return 12 when hour value is 12", () => {
        const date = new Date("2022-07-19T12:00:00.000+10:00");
        expect(getInitHour(true, date, tzinfoName)).toBe("12");
      });
      it("should return 13 when hour value is 13", () => {
        const date = new Date("2022-07-19T13:00:00.000+10:00");
        expect(getInitHour(true, date, tzinfoName)).toBe("13");
      });
      it("should return 23 when hour value is 23", () => {
        const date = new Date("2022-07-19T23:00:00.000+10:00");
        expect(getInitHour(true, date, tzinfoName)).toBe("23");
      });
      it("should return 0 when hour value is 24", () => {
        const date = new Date("2022-07-19T24:00:00.000+10:00");
        expect(getInitHour(true, date, tzinfoName)).toBe("00");
      });  
    });
    describe("formatting is 12 hours", () => {
      it("should return 12 when hour value is 0", () => {
        const date = new Date("2022-07-19T00:00:00.000+10:00");
        expect(getInitHour(false, date, tzinfoName)).toBe("12");
      });
      it("should return 11 when hour value is 11", () => {
        const date = new Date("2022-07-19T11:00:00.000+10:00");
        expect(getInitHour(false, date, tzinfoName)).toBe("11");
      });
      it("should return 12 when hour value is 12", () => {
        const date = new Date("2022-07-19T12:00:00.000+10:00");
        expect(getInitHour(false, date, tzinfoName)).toBe("12");
      });
      it("should return 1 when hour value is 13", () => {
        const date = new Date("2022-07-19T13:00:00.000+10:00");
        expect(getInitHour(false, date, tzinfoName)).toBe("01");
      });
      it("should return 11 when hour value is 23", () => {
        const date = new Date("2022-07-19T23:00:00.000+10:00");
        expect(getInitHour(false, date, tzinfoName)).toBe("11");
      });
      it("should return 12 when hour value is 24", () => {
        const date = new Date("2022-07-19T24:00:00.000+10:00");
        expect(getInitHour(false, date, tzinfoName)).toBe("12");
      });  
    });
  });
  describe("getInitMeridian", () => {
    it("should return AM if hour is equal to 0", () => {
      const date = new Date("2022-07-19T00:00:00.000+10:00");
      expect(getInitMeridian(date, "AM", "PM", tzinfoName)).toBe("AM");
    });
    it("should return AM if hour is less than 12", () => {
      const date = new Date("2022-07-19T11:59:00.000+10:00");
      expect(getInitMeridian(date, "AM", "PM", tzinfoName)).toBe("AM");
    });
    it("should return PM if hour is equal to 12", () => {
      const date = new Date("2022-07-19T12:00:00.000+10:00");
      expect(getInitMeridian(date, "AM", "PM", tzinfoName)).toBe("PM");
    });
    it("should return PM if hour is greater than 12", () => {
      const date = new Date("2022-07-19T23:59:00.000+10:00");
      expect(getInitMeridian(date, "AM", "PM", tzinfoName)).toBe("PM");
    });
  });
});
