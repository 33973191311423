import * as yup from "yup";

export default t => {
  return yup.object({
    firstName: yup.string().required(
      t("event_tools.validations.required", {
        field: t("user_form.first_name.label"),
      })
    ),
    lastName: yup.string().required(
      t("event_tools.validations.required", {
        field: t("user_form.last_name.label"),
      })
    ),
    pronoun: yup.string().nullable(),
    jobTitle: yup.string().nullable(),
    bio: yup.string().nullable(),
    email: yup
      .string()
      .required(
        t("event_tools.validations.required", {
          field: t("user_form.email.label"),
        })
      )
      .email(t("event_tools.validations.email")),
    phone: yup.string().nullable(),
    company: yup.string().nullable(),
    location: yup.string().nullable(),
  });
};
