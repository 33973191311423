import React, { useEffect } from "react";
import { Flex, Stack, useCheckboxGroup } from "@chakra-ui/react";
import { noop } from "../../../../utils";
import { FieldPropsSource, ResultsProps } from "../../types";
import MSOption from "./Option";

interface Props {
  results: ResultsProps;
  totalVotes: number;
  onChange: (any) => void;
  existingResponse: string;
  field: FieldPropsSource;
  showResults: boolean;
  disabled: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const MultiSelect: React.FC<Props> = ({
  results,
  totalVotes,
  onChange,
  existingResponse,
  field,
  showResults,
  disabled,
  onMouseOver = noop,
  onMouseOut = noop,
}): JSX.Element => {
  const saveChange = (value) => {
    onChange(value.join(","));
  };

  const { setValue, getCheckboxProps } = useCheckboxGroup({
    defaultValue: existingResponse?.split(","),
    onChange: saveChange,
  });

  useEffect(() => {
    if (existingResponse) {
      setValue(existingResponse.split(","));
    }
  }, [existingResponse]);

  return (
    <Flex flexDir="column" alignItems="center" w="100%">
      <Stack onMouseOver={onMouseOver} onMouseOut={onMouseOut} w="100%" my={2}>
        {results &&
          field.attributes.options.split(",").map((option) => {
            return (
              <MSOption
                key={option}
                showResult={showResults}
                votes={results[option] || 0}
                percentage={results[option] / totalVotes || 0}
                existingResponse={existingResponse}
                isDisabled={disabled}
                {...getCheckboxProps({ value: option })}
              />
            );
          })}
      </Stack>
    </Flex>
  );
};
export default MultiSelect;
