import { Button, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import DCEventTooltip from "../DCTooltip";

export const AddSessionHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex py={2}>
      <Text fontWeight="700">
        {t("events.agenda.session.schedule_sessions")}{" "}
        <DCEventTooltip
          label={t("events.agenda.session.schedule_sessions_tooltip")}
        />
      </Text>

      <Spacer />
      <Button variant="secondary">
        + {t("events.agenda.session.add_session_button")}
      </Button>
    </Flex>
  );
};
