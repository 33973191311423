import React, { useEffect, useState } from "react";
import { useEvent } from "../../../../pages/Event";
import AppAdapter from "../../../../../../utils/adapter";
import FormPageLayout from "../../../../common/FormPageLayout";
import { useWebinarFormActions } from "../../../hooks/useWebinarFormActions";
import AttendeeConfigurationForm from "../../../../forms/AttendeeConfigurationForm";
import { navigateToEventList } from "../../../../../EventWizard/helpers/navigation";
import ZoomConfigurationService from "../../../../../EventWizard/services/zoomConfigurationService";
import { ZoomConfigurationType } from "../../../../../EventWizard/models/ZoomConfiguration";

const AttendeeConfigurations: React.FC = () => {
  const { event } = useEvent();
  const [ zoomConfiguration, setZoomConfiguration ] = useState<ZoomConfigurationType>();

  const { getStepDetailsByRoute, navigateTo } =
  useWebinarFormActions();

  const stepDetails = [
    getStepDetailsByRoute("webinarDetails"),
    // getStepDetailsByRoute("attendeeConfigurations"),
  ];

  const fetchZoomConfiguration  = async () => {
    const payload = await ZoomConfigurationService.getZoomConfiguration(event.id);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result);
      setZoomConfiguration(response);
    }
  }

  const updateZoomConfiguration = async (data) => {
    const payload = await ZoomConfigurationService.updateZoomConfiguration(event.id, data);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result);
      setZoomConfiguration(response);
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };
  useEffect(() => {
    fetchZoomConfiguration();
  }, []);

  return (
    <FormPageLayout
      event={event}
      stepDetails={stepDetails}
      update={updateZoomConfiguration}
      previousForm={navigateTo.webinarDetails}
      exitForm={navigateToEventList}
      nextForm={navigateTo.agenda}
      previewVariant="Web"
    >
      {(props) =>
        zoomConfiguration && <AttendeeConfigurationForm zoomConfiguration={zoomConfiguration} {...props}></AttendeeConfigurationForm>
      }
    </FormPageLayout>
  );
};

export default AttendeeConfigurations;
