export interface TalkType {
  id: string;
  title: string;
  description: string;
  startTime: Date;
  endTime: Date;
  duration: number;
  speakers: Array<string>;
  indexImage?: [File];
  indexImageUrl?: string;
  vod?: boolean;
}

export interface JsonApiTalkType {
  id: string
  type: 'talk'
  attributes: {
    title: string
    description: string
    start_at: string
    end_at: string
    index_image: string
    speakers: Array<string>
  }
}

const currentTime = new Date();
const oneHourFromNow = new Date();
oneHourFromNow.setHours(currentTime.getHours() + 1);

export const NEW_TALK = {
  id: "",
  title: "",
  description: "",
  startTime: currentTime,
  endTime: oneHourFromNow,
  duration: 0,
  indexImageUrl: "",
  speakers: [],
  vod: null
};
