import React, { MutableRefObject } from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Flex,
  Spacer,
  Switch,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../common/shared/FormControlLabel";
import TenantOnlyFieldNotice from "../../common/TenantOnlyFieldNotice";
import { TenantType } from "../../../EventWizard/models/Tenant";
import { LayoutForm } from "../../common/FormPageLayout";


export type PrivacyPolicyFormProps = {
  tenant: TenantType;
  submitForm: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
}

const PrivacyPolicyForm: React.FC<PrivacyPolicyFormProps> = ({ tenant, submitForm, formErrors }) => {
  const { t } = useTranslation();
  submitForm.current = (cb) => cb;
  formErrors.current = {};

  return (
    <LayoutForm onSubmit={e => e.preventDefault()}>
      <TenantOnlyFieldNotice />
      <FormControl>
        <Flex>
          <div>
            <FormLabel>{t("privacy_policy.gdpr_compliance.label")}</FormLabel>
            <FormHelperText>{t("privacy_policy.gdpr_compliance.hint")}</FormHelperText>
          </div>
          <Spacer />
          <Switch isChecked={tenant.gdprCompliance} disabled />
        </Flex>
      </FormControl>

      <FormControlLabel
        label={t("privacy_policy.cookie_banner_title.label")}
      >
        <Input value={tenant.gdprCookieBannerHeader} disabled />
      </FormControlLabel>

      <FormControlLabel
        label={t("privacy_policy.cookie_banner_copy.label")}
      >
        <Textarea value={tenant.gdprCookieBannerText} disabled />
      </FormControlLabel>

      <FormControlLabel
        label={t("privacy_policy.privacy_policy_link.label")}
      >
        <Input
          placeholder={t("privacy_policy.privacy_policy_link.placeholder")}
          value={tenant.privacyPolicyLink}
          disabled
        />
      </FormControlLabel>
    </LayoutForm>
  );
};

export default PrivacyPolicyForm;
