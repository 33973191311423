import { mapKeys, camelCase, snakeCase } from 'lodash';
import { GroupRegistrationFormAttributes, GroupRegistrationRecord } from './types';

type UpdateGroupRegistrationProps = {
  groupRegistrationId: string;
  data: GroupRegistrationFormAttributes;
}

type UpdateGroupRegistrationResponse = {
  success: boolean;
  redirect?: string;
  group_registration_records?: GroupRegistrationRecord[];
  errors?: GroupRegistrationRecord[];
};

export const updateGroupRegistration = async ({
  groupRegistrationId,
  data
}: UpdateGroupRegistrationProps): Promise<UpdateGroupRegistrationResponse> => {
  const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
  const requestHeaders = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'X-CSRF-Token': csrf
  };

  const snakeCasedGroupRegistrationRecords = data.records.map(record => mapKeys(record, (_, k) => snakeCase(k)));
  const body = {
    'id': groupRegistrationId,
    'group_registration_records': snakeCasedGroupRegistrationRecords
  };

  const response = await fetch(`/registration/group/${groupRegistrationId}`, {
    method: 'PUT',
    headers: requestHeaders,
    body: JSON.stringify(body)
  });

  const responseBody = await response.json();

  const camelCaseResponse = responseBody.group_registration_records.map(record => mapKeys(record, (_, k) => camelCase(k)));
  const responseErrors = responseBody.errors?.map(record => mapKeys(record, (_, k) => camelCase(k)));

  return {
    success: responseBody.success,
    redirect: responseBody.redirect,
    group_registration_records: camelCaseResponse,
    errors: responseErrors
  };
};
