import React from "react";
import { useNavigate } from "react-router-dom";
import EventService from "../../../../../../EventWizard/services/eventService";
import AppAdapter from "../../../../../../../utils/adapter";
import FormPageLayout from "../../../../../common/FormPageLayout";
import { useEvent } from "../../../../Event/index";
import CustomHomepageSectionForm from "../../../../../forms/CustomHomepageSectionForm";
import { useTranslation } from "react-i18next";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";

const AddSection: React.FC = () => {
  const {event, setEvent} = useEvent();
  const navigate = useNavigate();
  const navigator = path => () => navigate(path);
  const { t } = useTranslation();

  const stepDetails = [
    { name: t("event_tools.navigation.hero_section"), status: "done", path: `/admin/event-tools/${event.id}/steps/hero-section` },
    { name: t("event_tools.navigation.additional_features"), status: "done", path: `/admin/event-tools/${event.id}/steps/additional-features` },
    { name: t("event_tools.navigation.add_sections"), status: "active", path: `/admin/event-tools/${event.id}/steps/add-section` },
    { name: t("event_tools.navigation.footer"), status: "default", path: `/admin/event-tools/${event.id}/steps/footer-section` },
  ];

  const setDestroyFlag = (data) => {
    const presentIds = data.customHomepageSections.map(s => s.id);
    event.customHomepageSections.forEach(customHomePageSection => {
      if(!presentIds.includes(customHomePageSection.id)) {
        data.customHomepageSections.push({...customHomePageSection, _destroy: true});
      }
    });
  };

  const updateEvent = async (data) => {
    setDestroyFlag(data);
    if (data.customHomepageSections.length > 0) {
      const payload = await EventService.updateEventWithFormData(event.id, data);
      if (payload.ok) {
        const response = await AppAdapter.deserialize(payload.result, { transform: EventService.transform });
        setEvent(response);
        return true;
      } else {
        const errors = AppAdapter.deserializeErrors(payload.result);
        throw errors;
      }
    }
  };

  return (
    <FormPageLayout event={event}
      stepDetails={stepDetails}
      update={updateEvent}
      previousForm={navigator(`/admin/event-tools/${event.id}/steps/additional-features`)}
      exitForm={navigateToEventList}
      nextForm={navigator(`/admin/event-tools/${event.id}/steps/footer-section`)}
      previewVariant="Web"
    >
      {
        (props) => <CustomHomepageSectionForm event={event} {...props} />
      }
    </FormPageLayout>
  );
};

export default AddSection;
