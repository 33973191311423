import React from "react";
import { act } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import SingleDatePicker from "./SingleDatePicker";
import { render } from "../../../../EventWizard/test-utils";
import { noop } from "../../../../../utils";
expect.extend(toHaveNoViolations);

const props = {
  isError: true,
  onChange: noop,
  initialDate: new Date(),
  onOpen: noop,
  onClose: noop
};

describe("SingleDatePicker", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<SingleDatePicker {...props} />);

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
