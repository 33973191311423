import React, { useState } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from "@chakra-ui/react";
import { Drop } from "phosphor-react";
import ColourPopoverContent from "./ColourPopoverContent";
import { updateColourHistory } from "./utils";
import { RefCallBack } from "react-hook-form";
import { noop } from "../../../../utils";

export type ColourPickerProps = {
  value: string;
  placeHolder?: string;
  onChange: (value) => void;
  onOpen?: () => void;
  onClose?: () => void;
  inputRef?: RefCallBack;
  id?: string;
  disabled?: boolean;
  onBlur?: () => void;
};

const ColourPicker: React.FC<ColourPickerProps> = ({
  value,
  placeHolder,
  onChange,
  onOpen = noop,
  onClose = noop,
  inputRef,
  id,
  disabled = false,
  onBlur,
}) => {
  const { onOpen: handleOpen, onClose: handleClose, isOpen } = useDisclosure();
  const [colourHistory, setColourHistory] = useState([
    "#FFFFFF",
    ...Array(9).fill(null),
  ]);

  function manageOpen() {
    handleOpen();
    onOpen();
  }

  function manageClose() {
    handleClose();
    onClose();
  }

  const handleKeyDown = event => {
    if (event.code === 'Enter') {
      manageOpen();
    }
  };

  const handleClosePopover = () => {
    setColourHistory(updateColourHistory(colourHistory, value));
    manageClose();
  };

  return (
    <Popover
      onClose={handleClosePopover}
      placement="bottom-start"
      isOpen={isOpen && !disabled}
    >
      <PopoverTrigger>
        <InputGroup
          onClick={manageOpen}
          onBlur={() => {
            if (!isOpen) onBlur();
          }}
          role="group"
        >
          <Input
            disabled={disabled}
            placeholder={placeHolder}
            value={value}
            onChange={event => onChange(event.target.value)}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            id={id}
          />
          <InputRightElement zIndex={1}>
            <Drop color={value} weight="fill" />
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent p={4}>
        <ColourPopoverContent
          colour={value}
          setColour={event => onChange(event)}
          colourHistory={colourHistory}
        />
      </PopoverContent>
    </Popover>
  );
};

export default ColourPicker;
