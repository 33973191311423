import { updateColourHistory } from "./utils";

describe("updateColourHistory", () => {
  test("it should return an array with new colour in front", () => {
    const testColourArray = ["#FFFFFF"];
    expect(updateColourHistory(testColourArray, "#000000")).toEqual([
      "#000000",
      "#FFFFFF",
    ]);
  });

  test("it should move a item to front if colour is already in array", () => {
    const testColourArray = ["#FFFFFF", "#000000", "#123456"];
    expect(updateColourHistory(testColourArray, "#123456")).toEqual([
      "#123456",
      "#FFFFFF",
      "#000000",
    ]);
  });

  test("it should remove the last item when given an array with 10 colours", () => {
    const testColourArray = Array(10).fill(null);
    expect(updateColourHistory(testColourArray, "#000000")).toEqual([
      "#000000",
      ...Array(9).fill(null),
    ]);
  });

  test("it should not allow invalid hex colours to be saved", () => {
    const testColourArray = ["#FFFFFF"];
    expect(updateColourHistory(testColourArray, "invalid")).toEqual([
      "#FFFFFF"
    ]);
  });
});
