import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Link } from "@chakra-ui/react";
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";

function getColor(status) {
  switch (status) {
    case 'default':
      return fT.colors.navigation.topNavigation.default;
    case 'active':
      return fT.colors.primary.base;
    case 'done':
      return fT.colors.success;
    case 'disabled':
      return fT.colors.navigation.topNavigation.default;
    default:
      return '';
  }
}

function getTextSVG(status) {
  const color = getColor(status);
  return `<svg width="78" height="48" viewBox="0 0 78 48" fill="${color}" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><rect width="78" height="48"/></svg>`;
}

function getPrefixSVG(step, status) {
  const color = getColor(status);
  switch (step) {
    case 'first-step':
      return `<svg width="16" height="48" viewBox="0 0 16 48" fill="${color}" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 4C0 1.79086 1.79086 0 4 0L16 0V48H4C1.79086 48 0 46.2091 0 44L0 4Z"/></svg>`;
    case 'middle-step':
    case 'last-step':
      return `<svg width="29" height="48" viewBox="0 0 29 48" fill="${color}" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H29V24V48H0L16.3905 29.268C19.0296 26.2518 19.0296 21.7482 16.3905 18.732L0 0Z"/></svg>`;
    default:
      return '';
  }
}

function getSuffixSVG(step, status) {
  const color = getColor(status);
  switch (step) {
    case 'first-step':
    case 'middle-step':
      return `<svg width="27" height="48" viewBox="0 0 27 48" fill="${color}" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H4.36985C6.67675 0 8.87136 0.995844 10.3905 2.73196L24.3905 18.732C27.0296 21.7482 27.0296 26.2518 24.3905 29.268L10.3905 45.268C8.87136 47.0042 6.67675 48 4.36985 48H0V24V0Z"/></svg>`;
    case 'last-step':
      return `<svg width="16" height="48" viewBox="0 0 16 48" fill="${color}" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H12C14.2091 0 16 1.79086 16 4V44C16 46.2091 14.2091 48 12 48H0V0Z"/></svg>`;
    default:
      return '';
  }
}

const defaultTextSvg = getTextSVG('default');
const activeTextSvg = getTextSVG('active');
const doneTextSvg = getTextSVG('done');

const firstStepDefaultPrefixSvg = getPrefixSVG('first-step', 'default');
const firstStepActivePrefixSvg = getPrefixSVG('first-step', 'active');
const firstStepDonePrefixSvg = getPrefixSVG('first-step', 'done');
const middleStepDefaultPrefixSvg = getPrefixSVG('middle-step', 'default');
const middleStepActivePrefixSvg = getPrefixSVG('middle-step', 'active');
const middleStepDonePrefixSvg = getPrefixSVG('middle-step', 'done');
const lastStepDefaultPrefixSvg = getPrefixSVG('last-step', 'default');
const lastStepActivePrefixSvg = getPrefixSVG('last-step', 'active');
const lastStepDonePrefixSvg = getPrefixSVG('last-step', 'done');

const firstStepDefaultSuffixSvg = getSuffixSVG('first-step', 'default');
const firstStepActiveSuffixSvg = getSuffixSVG('first-step', 'active');
const firstStepDoneSuffixSvg = getSuffixSVG('first-step', 'done');
const middleStepDefaultSuffixSvg = getSuffixSVG('middle-step', 'default');
const middleStepActiveSuffixSvg = getSuffixSVG('middle-step', 'active');
const middleStepDoneSuffixSvg = getSuffixSVG('middle-step', 'done');
const lastStepDefaultSuffixSvg = getSuffixSVG('last-step', 'default');
const lastStepActiveSuffixSvg = getSuffixSVG('last-step', 'active');
const lastStepDoneSuffixSvg = getSuffixSVG('last-step', 'done');

const Tag = styled.div`
  display: flex;
  width: auto;
  height: 40px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  & > span {
    display: flex;
    width: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
  };
  &.default > span, &.disabled > span {
    background-image: url('data:image/svg+xml;utf8, ${encodeURIComponent(defaultTextSvg)}')
  };
  &.active > span {
    background-image: url('data:image/svg+xml, ${encodeURIComponent(activeTextSvg)}')
  };
  &.done > span {
    background-image: url('data:image/svg+xml, ${encodeURIComponent(doneTextSvg)}')
  };
  &:before {
    margin-right: -1px
  };
  &:after {
    margin-left: -1px
  };
  &:before, &:after {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  };
  &.first-step {
    &:before {
      width: 10px;
      height: 40px;
      content: '';
    };
    &:after {
      width: 18px;
      height: 40px;
      content: '';
    }
  };
  &.first-step.default, &.first-step.disabled {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(firstStepDefaultPrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(firstStepDefaultSuffixSvg)}')
    }
  };
  &.first-step.active {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(firstStepActivePrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(firstStepActiveSuffixSvg)}')
    }
  };
  &.first-step.done {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(firstStepDonePrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(firstStepDoneSuffixSvg)}')
    }
  };
  &.middle-step {
    &:before {
      width: 18px;
      height: 40px;
      content: '';
    };
    &:after {
      width: 18px;
      height: 40px;
      content: '';
    }
  };
  &.middle-step.default, &.middle-step.disabled {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(middleStepDefaultPrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(middleStepDefaultSuffixSvg)}')
    }
  };
  &.middle-step.active {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(middleStepActivePrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(middleStepActiveSuffixSvg)}')
    }
  };
  &.middle-step.done {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(middleStepDonePrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(middleStepDoneSuffixSvg)}')
    }
  };
  &.last-step {
    &:before {
      width: 18px;
      height: 40px;
      content: '';
    };
    &:after {
      width: 10px;
      height: 40px;
      content: '';
    }
  };
  &.last-step.default, &.last-step.disabled {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(lastStepDefaultPrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(lastStepDefaultSuffixSvg)}')
    }
  };
  &.last-step.active {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(lastStepActivePrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(lastStepActiveSuffixSvg)}')
    }
  };
  &.last-step.done {
    &:before {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(lastStepDonePrefixSvg)}')
    };
    &:after {
      background-image: url('data:image/svg+xml, ${encodeURIComponent(lastStepDoneSuffixSvg)}')
    }
  };
  &.middle-step, &.last-step {
    margin-left: -7px;
  };
  &.default, &.disabled {
    color: #929094;
  };
  &.active, &.done {
    color: #F4F0F4;
  }
`;

export type StepDetailType = {
  name: string;
  status: string;
  path: string;
};

type TopNavigationBarProps = {
  stepDetail: StepDetailType;
  firstStep: boolean;
  lastStep: boolean;
};

const TopNavigationBar: React.FC<TopNavigationBarProps> = ({ stepDetail, firstStep, lastStep }) => {
  let stepClass = stepDetail.status;
  if (firstStep) {
    stepClass += ' first-step';
  } else if (lastStep) {
    stepClass += ' last-step';
  } else {
    stepClass += ' middle-step';
  }

  return (
    <Link
      as={RouterLink}
      to={stepDetail.path}
      _hover={{ textDecoration: 'none' }}
      style={{ pointerEvents: stepDetail.status === "disabled" ? "none" : "auto" }}
    >
      <Tag className={stepClass}>
        <span>
          {stepDetail.status == 'done' &&
            <CheckCircleIcon boxSize='12px' mr='6px' />
          }
          {stepDetail.name}
        </span>
      </Tag>
    </Link>
  );
};

export default TopNavigationBar;
