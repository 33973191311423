import React, { ReactNode } from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { EventType } from "../../../EventWizard/models/Event";
import FormPageLayout from "./FormPageLayout";
import { FAKE_EVENT, mockCustomContexts, render } from "../../../EventWizard/test-utils";
expect.extend(toHaveNoViolations);

mockCustomContexts()

type FormStep = {
  name: string;
  status: string;
  path: string;
  enabled: boolean;
};

type Props = {
  event: EventType;
  update: () => Promise<boolean>;
  stepDetails: Array<FormStep>;
  previousForm: () => void;
  exitForm: () => void;
  nextForm: () => void;
  children: () => ReactNode
};

const props: Props = {
  event: FAKE_EVENT,
  stepDetails: [
    { name: "Page 1", status: "done", path: "#", enabled: true },
    { name: "Email style customization", status: "active", path: "#", enabled: true },
    { name: "More custom styling", status: "default", path: "#", enabled: false },
  ],
  update: () => Promise.resolve(true),
  previousForm: () => console.log('previous form'),
  exitForm: () => console.log('exit form'),
  nextForm: () => console.log('next form'),
  children: () => <div></div>
};

describe("FormPageLayout", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<FormPageLayout {...props} />);
    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });
});
