import React, { FC, useEffect, useState } from 'react';
import { Channel, useChatContext } from 'stream-chat-react';
import { getChannelListOptions } from './channelListOptions';

import {
  ChannelInner,
  CreateChannel,
  MessagingSidebar,
  MessagingThreadHeader,
  SendButton,
} from './components';

import { v4 as uuidv4 } from 'uuid';

import { GiphyContextProvider } from './context';

import { useMobileView, useTheme, useUpdateAppHeightOnResize } from './hooks';
import { StreamChatGenerics } from './types';

type ChatPageProps = {
  userToConnect: { id: string; name?: string; image?: string };
  team: string;
  chatParticipants: string[];
  channelName: string;
  channelImage: string;
};

const ChatPage: FC<ChatPageProps> = ({
  userToConnect,
  team,
  chatParticipants,
  channelName,
  channelImage,
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const theme = useTheme();
  const {
    client,
    setActiveChannel,
    channel: activeChannel,
  } = useChatContext<StreamChatGenerics>();
  const [initialLoad, setInitialLoad] = useState(true);
  const [isChannelSetManually, setIsChannelSetManually] = useState(false);
  const channelListOptions = getChannelListOptions(userToConnect.id);

  const toggleMobile = useMobileView();

  useUpdateAppHeightOnResize();

  useEffect(() => {
    if (!isChannelSetManually && client && (activeChannel || initialLoad)) {
      const createChannel = async () => {
        if (chatParticipants.length > 0 && client) {
          let channel;

          const response = await client.queryChannels({
            members: { $eq: [...chatParticipants, client.userID] },
          });
          if (response.length > 0) {
            channel = response[0];
          } else {
            channel = client.channel('messaging', `${team}-${uuidv4()}`, {
              // @ts-expect-error getstream are missing team param in ChannelData type
              team: team,
              members: [...chatParticipants, client.userID],
            });
            await channel.watch();
            if (channelName != '') {
              await channel.updatePartial({ set: { name: channelName } });
            }
            if (channelImage != '') {
              await channel.updatePartial({ set: { image: channelImage } });
            }
          }
          setActiveChannel?.(channel);
        }
      };
      createChannel();
      setInitialLoad(false);
    }
  }, [activeChannel, client, isChannelSetManually, initialLoad]);

  const onCreateChannel = () => {
    toggleMobile();
    setIsCreating(!isCreating);
  };
  return (
    <>
      <MessagingSidebar
        channelListOptions={channelListOptions}
        toggleMobile={toggleMobile}
        onCreateChannel={onCreateChannel}
        onPreviewSelect={() => setIsCreating(false)}
        setIsChannelSetManually={setIsChannelSetManually}
        theme={theme}
      />
      <Channel
        maxNumberOfFiles={10}
        multipleUploads={true}
        SendButton={SendButton}
        ThreadHeader={MessagingThreadHeader}
        TypingIndicator={() => null}
      >
        {isCreating && (
          <CreateChannel
            toggleMobile={toggleMobile}
            onClose={() => setIsCreating(false)}
            team={team}
          />
        )}
        <GiphyContextProvider>
          <ChannelInner theme={theme} team={team} toggleMobile={toggleMobile} />
        </GiphyContextProvider>
      </Channel>
    </>
  );
};

export default ChatPage;
