import CONFIG from "../../../utils/config";
import RestClient from "../../../utils/restClient";

class FacilitatorService {
  static async list(sessionId: number) {
    try {
      const response = await RestClient.get(
        CONFIG.URLS.FACILITATORS + `?session_id=${sessionId}`
      );
      if (!response.ok) response.message = "error in response";

      return response;
    } catch (e) {
      console.log(e);
      return { ok: false, message: "error in fetching", result: null };
    }
  }

  static async delete(sessionFacilitatorId: string) {
    try {
      const response = await RestClient.delete(
        CONFIG.URLS.FACILITATORS + `/${sessionFacilitatorId}`
      );
      if (!response.ok) response.message = "error in response";

      return response;
    } catch (e) {
      console.log(e);
      return { ok: false, message: "error in deleting", result: null };
    }
  }
}

export default FacilitatorService;
