import React, { ReactElement, useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import ProgressService from "../services/progressService";
import { getPageName } from "../../helpers/navigation";
import { useWebinarFormActions } from "../../EventTools/Webinar/hooks/useWebinarFormActions";
import { usePageStatus } from "../../EventTools/pages/Event/Event";

const ProgressProvider = (): ReactElement => {
  const { eventId } = useParams();
  const context = useOutletContext();
  const { pathname } = useLocation();
  const { completePageStatus } = usePageStatus();
  const { pathsByRoute } = useWebinarFormActions();
  const navigate = useNavigate()

  useEffect(()=>{
    if (pathname !== `/admin/event-tools/${eventId}`) {
      const progress = ProgressService.getProgress(eventId);
      if (progress !== "completed") {
        const path = getPageName(pathname);
        if (!completePageStatus.pages[path] && path !== progress) {
          navigate(pathsByRoute[progress])
        }
      }
  }})

  return <Outlet context={context} />;
};

export default ProgressProvider;
