import AppAdapter from "../../../utils/adapter";
import CONFIG from "../../../utils/config";
import RestClient from "../../../utils/restClient";

class ZoomConfigurationService {
  static async getZoomConfiguration(eventId: string) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.EVENTS}/${eventId}/${CONFIG.URLS.ZOOM_CONFIGURATIONS}`);
      if (!response.ok) response.message = "error in response";

      return response;
    } catch (e) {
      console.log(e);
      return { ok: false, message: "error in fetching", result: null };
    }
  }

  static async updateZoomConfiguration(eventId: string, payload) {
    try {
      const response = await RestClient.patch(`${CONFIG.URLS.EVENTS}/${eventId}/${CONFIG.URLS.ZOOM_CONFIGURATIONS}`, { zoom_configuration: AppAdapter.snakeCaseKeys(payload) });
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }
}

export default ZoomConfigurationService;
