import React from "react";
import { render } from "@testing-library/react";
import "@testing-library/jest-dom";
import { MemoryRouter } from 'react-router-dom';
import { SideNavigation } from "./SideNavigation";
import { axe, toHaveNoViolations } from "jest-axe";
expect.extend(toHaveNoViolations);

describe("SideNavigation", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(
      <MemoryRouter>
        <SideNavigation eventId="0" />
      </MemoryRouter>
    );

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });
});
