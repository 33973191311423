import React from "react";
import { act, waitFor } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { render, screen } from "../../../EventWizard/test-utils";
import { noop } from "../../../../utils";
import SelectComponent from "./SelectComponent";
import userEvent from "@testing-library/user-event";
expect.extend(toHaveNoViolations);

const testOptions = [
  { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
  { value: 'chocolate', label: 'Chocolate', rating: 'good' },
  { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
  { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' },
];

describe("SelectComponent", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(
      <SelectComponent
        options={testOptions}
        onAddItem={noop}
        isMulti
      />
    );

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  describe("when adding items", () => {
    test("invokes provided callback", async () => {
      const onAddItem = jest.fn();
      render(
        <SelectComponent
          options={testOptions}
          onAddItem={onAddItem}
          isMulti
        />
      );

      await waitFor(() => {
        userEvent.click(screen.getByText("Select..."));
        userEvent.click(screen.getByText("actions.add_new_item"));
      });
      expect(onAddItem).toHaveBeenCalledTimes(1);
    });
  });
});
