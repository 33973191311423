import { figmaTheme as fT } from "../figmaTheme";

export const modalTheme = {
  parts: ["dialog", "header", "footer"],
  baseStyle: {
    dialog: {
      borderRadius: "16px",
      padding: "0px",
      fontFamily: "Poppins",
      fontSize: fT.components.fontSize,
      lineHeight: fT.lineHeights[16],
    },
    header: {
      fontFamily: "Roboto",
      lineHeight: fT.lineHeights[24],
      fontSize: "18px",
      fontWeight: "bold",
    },
    footer: {
      paddingInlineStart: 4,
      paddingInlineEnd: 4
    }
  },
};
