import React, { ReactNode } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from "@chakra-ui/react";

type FormDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  buttonComponent: ReactNode;
  headingComponent: ReactNode;
  children: ReactNode;
}

const FormDrawer: React.FC<FormDrawerProps> = ({
  isOpen,
  onClose,
  buttonComponent,
  headingComponent,
  children
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size={"lg"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          {headingComponent}
        </DrawerHeader>
        <DrawerBody>
          {children}
        </DrawerBody>
        <DrawerFooter>
          {buttonComponent}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default FormDrawer;
