import React from "react";
import { GridItem } from "@chakra-ui/react";

type ColourHistoryItemProps = {
  colour: string;
  setColour: (string) => void;
};

const ColourHistoryItem: React.FunctionComponent<ColourHistoryItemProps> = ({
  colour,
  setColour,
}: ColourHistoryItemProps): JSX.Element => {
  if (colour === null) {
    return (
      <GridItem
        w="32px"
        h="32px"
        background="gray.200"
        border="1px"
        borderColor="gray.200"
        borderRadius="8px"
        aria-disabled
      />
    );
  } else {
    return (
      <GridItem
        w="32px"
        h="32px"
        background={colour}
        onClick={() => setColour(colour)}
        border="1px"
        borderColor="gray.200"
        borderRadius="8px"
        tabIndex={0}
      />
    );
  }
};

export default ColourHistoryItem;
