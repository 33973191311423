import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { usePreviewData, PreviewContextData } from './PreviewContext';
import { segments, segmentHeights } from './data';


const segmentVisibility = (segment: string) => (state: PreviewContextData) => ({
  display: state.visible[segment] ? "inline" : "none"
});

const visibilityMapping = segments.reduce((acc, segment) => {
  acc[segment] = [segmentVisibility(segment)];
  return acc;
}, {});

const mappings = {
  preview_svg_Nav_2: [(state: PreviewContextData) => ({ fill: state.primaryColour })],
  preview_svg_Filter: [(state: PreviewContextData) => ({ fill: state.primaryColour })],
  "preview_svg_Footer BG": [(state: PreviewContextData) => ({ fill: state.secondaryColour })],
  "preview_svg_Left Nav BG": [(state: PreviewContextData) => ({ fill: state.featureBackgroundSideMenuColour })],
  "preview_svg_Top Nav BG": [(state: PreviewContextData) => ({ fill: state.featureBackgroundMenuColour })],
  "preview_svg_Section Header": [(state: PreviewContextData) => ({ fill: state.featureSectionHeaderTextColour })],
  "preview_svg_Copy right": [(state: PreviewContextData) => ({ fill: state.footerText })],
  email_svg_Header: [(state: PreviewContextData) => ({ fill: state.colourEmailHeader })],
  email_svg_Footer: [(state: PreviewContextData) => ({ fill: state.colourEmailFooter })],
  preview_svg_Headline: [(state: PreviewContextData) => ({ fill: state.featureHeaderTextColour })],
  ...visibilityMapping
};


function getTransformForSegment(tag, id, visible) {
  if (tag !== 'g') return;

  const segmentIndex = segments.indexOf(id);
  if (segmentIndex == -1) return;

  const antecedents = segments.slice(0, segmentIndex);
  const height = antecedents.reduce((acc, segment) => {
    const diff = visible[segment] ? 0 : segmentHeights[segment];
    return acc - diff;
  }, 0);

  return `translate(0, ${height})`;
}

function useAnimatable(tag, id) {
  const { state } = usePreviewData();
  const { activeComponents, visible } = state;

  const controls = useAnimation();
  const triggerOutline = () => (
    controls.start({
      strokeWidth: [0, 3, 0, 3, 0, 3, 0,],
      stroke: 'red',
      transition: { duration: 2 }
    })
  );

  useEffect(() => {
    if (id && activeComponents.includes(id)) {
      triggerOutline();
    }
  }, [activeComponents]);

  const mapping = mappings[id] || [];
  const overrides = Object.assign(
    {}, ...mapping.map(mapper => mapper(state))
  );

  const transform = getTransformForSegment(tag, id, visible);
  return { overrides, controls, transform };
}

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const animatable = (tag) => (props: { id?: string } & any) => {
  const { id } = props;

  const Tag = motion[tag];

  const { overrides, controls, transform } = useAnimatable(tag, id);

  return <Tag
    {...props}
    {...overrides}
    transform={transform}
    animate={controls}
  />;
};

export const Rect = animatable('rect');
export const G = animatable('g');
export const Path = animatable('path');
export const LinearGradient = (props) => {
  const { state } = usePreviewData();
  const { primaryColour, secondaryColour } = state;

  return (<linearGradient {...props} >
    <stop stopColor={primaryColour} />
    <stop offset={1} stopColor={secondaryColour} />
  </linearGradient>);
}
