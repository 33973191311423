import { Box, Button, IconButton, Icon } from "@chakra-ui/react";
import { House } from "phosphor-react";
import React from "react";

type DCButtonProps = {
  behaviour: 'icon-only' | 'left-icon' | 'right-icon' | 'text-only';
  size: 'md' | 'sm';
  variant: 'solid' | 'outline' | 'ghost';
  disabled: boolean;
  label: string;
};

const getHeight = (size) => size == "md" ? "50px" : "40px";
const getWidth = (behaviour, size) => {
  let width = "107px";
  switch (behaviour) {
    case "left-icon":
      width = "107px";
      break;
    case "right-icon":
      width = "214px";
      break;
    case "icon-only":
      width = getHeight(size);
      break;
    case "text-only":
      width = "83px";
      break;
    default:
      width = "200px";
  }
  return width;
};

const getLeftIcon = (behaviour) => behaviour == "left-icon" ? <Icon as={House} weight="fill" size="16" /> : null;
const getRightIcon = (behaviour) => behaviour == "right-icon" ? (
  <Box ml={24}>
    <Icon as={House} weight="fill" size="24" />
  </Box>
) : null;

// We will probably won't be needing this component. It is here for Storybook demonstration.
const DCButton: React.FC<DCButtonProps> = ({behaviour, size, variant, disabled, label}) => {
  const height = getHeight(size);
  const width = getWidth(behaviour, size);
  const leftIcon = getLeftIcon(behaviour);
  const rightIcon = getRightIcon(behaviour);

  return (
    <>
      {(behaviour === "icon-only") ? 
        <IconButton
          variant={variant}
          disabled={disabled}
          aria-label={label}
          size={size}
          m={2}
          h={height}
          w={width}
          icon={<Icon as={House} weight="fill" size="24" />}
        /> :
        <Button
          variant={variant}
          disabled={disabled}
          size={size}
          m={2}
          h={height}
          w={width}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
        >
          {label}
        </Button>
      }
    </>
  );
};

export default DCButton;
