import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { figmaTheme as fT } from "../figmaTheme";

export const fileUploaderTheme: ComponentStyleConfig = {
  baseStyle: {
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: fT.colors.grey.base,
      borderStyle: "dashed",
      backgroundColor: "",
      color: fT.colors.primary.base,
      outline: "none",
    },
    dropzone_accepted: {
      backgroundColor: fT.colors.primary[90],
    },
    dropzone_rejected: {
      borderColor: fT.colors.alert,
    },
    image: {
      border: "1px",
      borderColor: fT.colors.grey.base,
      fontSize: fT.components.fontSize,
    },
    cropper: {
      height: "120px",
      width: "100%",
    },
    error: {
      color: fT.colors.alert,
    },
  },
  defaultProps: {},
};
