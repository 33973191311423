import React from "react";
import styled from "styled-components";
import { IconEntry } from "../../phosphorIcons/types";

const PaletteItem = styled.div`
  margin: 0px 15px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  min-height: 70px;

  opacity: 0.7;
  border-radius: 10px;

  &:hover {
    background-color: #ebebeb;
    opacity: 1;
  }

  &:active {
    background-color: #ababab;
    opacity: 1;
  }
`;

const PaletteItemLabel = styled.p`
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  word-break: break-all;
  margin-top: 8.5px;
`;

interface Props {
  icon: IconEntry;
  selectIcon: (icon: IconEntry) => void;
}

const IconPreview: React.FunctionComponent<Props> = ({
  icon,
  selectIcon,
}: Props): JSX.Element => {
  const { Icon, name } = icon;

  const onIconClick = (): void => selectIcon(icon);

  return (
    <PaletteItem onClick={onIconClick} id={name} aria-label={name} role="option">
      <Icon
        size={28}
        weight="fill"
        color="#0E153A"
        role="presentation"
      />
      <PaletteItemLabel>{name}</PaletteItemLabel>
    </PaletteItem>
  );
};

export default IconPreview;
