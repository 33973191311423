import React from 'react';
import { act, fireEvent, render, screen } from '@testing-library/react';
import '@testing-library/jest-dom';
import { axe, toHaveNoViolations } from 'jest-axe';
expect.extend(toHaveNoViolations);

import GroupRegistrationInvite from './GroupRegistrationInvite';
import * as acceptInvite from './acceptInvite';

type Props = {
  token: string
  user: {
    firstName?: string
    lastName?: string
    email: string
  }
  formLabels: {
    firstName: string
    lastName: string
    email: string
    password: string,
    passwordConfirmation: string
    confirm: string
  }
}

const props: Props = {
  token: 'jb2020-11x03',
  user: {
    firstName: 'Johnny',
    lastName: 'Bravo',
    email: 'Johnny.B@email.com'
  },
  formLabels: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
    confirm: 'Confirm'
  }
};

describe('GroupRegistrationInvite', () => {
  test('has no accessibility violations', async () => {
    const { container } = render(<GroupRegistrationInvite {...props} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe('renders props correctly', () => {
    test('firstName', async () => {
      render(<GroupRegistrationInvite {...props} />);
      expect(screen.getByLabelText('First Name')).toHaveValue(props.user.firstName);
    });

    test('lastName', async () => {
      render(<GroupRegistrationInvite {...props} />);
      expect(screen.getByLabelText('Last Name')).toHaveValue(props.user.lastName);
    });

    test('email', async () => {
      render(<GroupRegistrationInvite {...props} />);
      expect(screen.getByLabelText('Email')).toHaveValue(props.user.email);
    });

    test('token', async () => {
      render(<GroupRegistrationInvite {...props} />);
      const invitationToken = screen.getByLabelText('invitationToken');
      expect(invitationToken).toHaveValue(props.token);
    });
  });

  describe('shows the correct errors', () => {
    test('invalid first name', async () => {
      render(<GroupRegistrationInvite {...props} />);
      const input = screen.getByLabelText('First Name');

      await act(async () => {
        fireEvent.change(input, { target: { value: 'm' } });
        fireEvent.blur(input);
      });

      const validationError = screen.getByText('First name must be at least 3 characters long');
      expect(validationError).toBeInTheDocument();
    });

    test('invalid last name', async () => {
      render(<GroupRegistrationInvite {...props} />);
      const input = screen.getByLabelText('Last Name');

      await act(async () => {
        fireEvent.change(input, { target: { value: 'a' } });
        fireEvent.blur(input);
      });

      const validationError = screen.getByText('Last name must be at least 3 characters long');
      expect(validationError).toBeInTheDocument();
    });

    test('invalid password', async () => {
      render(<GroupRegistrationInvite {...props} />);
      const input = screen.getByLabelText('Password');

      await act(async () => {
        fireEvent.change(input, { target: { value: '12345' } });
        fireEvent.blur(input);
      });

      const validationError = screen.getByText('Must be at least 6 characters long');
      expect(validationError).toBeInTheDocument();
    });

    test('password confirmation incorrect', async () => {
      render(<GroupRegistrationInvite {...props} />);
      const passwordInput = screen.getByLabelText('Password');
      const confirmationInput = screen.getByLabelText('Password Confirmation');

      await act(async () => {
        fireEvent.change(passwordInput, { target: { value: '1234567' } });
        fireEvent.blur(passwordInput);
        fireEvent.change(confirmationInput, { target: { value: '12345678' } });
        fireEvent.blur(confirmationInput);
      });

      const validationError = screen.getByText('Passwords must match');
      expect(validationError).toBeInTheDocument();
    });
  });

  describe('submits the form when valid', () => {
    test('submits the form', async () => {
      render(<GroupRegistrationInvite {...props} />);
      const passwordInput = screen.getByLabelText('Password');
      const confirmationInput = screen.getByLabelText('Password Confirmation');

      await act(async () => {
        fireEvent.change(passwordInput, { target: { value: '1234567' } });
        fireEvent.change(confirmationInput, { target: { value: '1234567' } });
      });

      const button = screen.getByRole('button', { name: 'Confirm' });

      const mock = jest.spyOn(acceptInvite, 'acceptInvite').mockImplementation(
        () => Promise.resolve({
          success: true,
          redirect: '#'
        })
      );

      await act(async () => {
        fireEvent.click(button);
      });

      expect(mock).toHaveBeenCalledWith({
        invitationToken: props.token,
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        password: '1234567',
        passwordConfirmation: '1234567',
      });
    });
  });
});
