import * as yup from "yup";

export default t => {
  return yup.object({
    metaTitle: yup.string()
      .required(t("event_tools.validations.required", { field: t("metadata.meta_title.label") }))
      .typeError(t("event_tools.validations.required", { field: t("metadata.meta_title.label") })),
    metaDescription: yup.string()
      .required(t("event_tools.validations.required", { field: t("metadata.meta_description.label") }))
      .typeError(t("event_tools.validations.required", { field: t("metadata.meta_description.label") })),
    metaKeywords: yup.string()
      .required(t("event_tools.validations.required", { field: t("metadata.meta_tags.label") }))
      .typeError(t("event_tools.validations.required", { field: t("metadata.meta_tags.label") })),
  });
};
