import React from "react";
import EventsList from "../EventsList";
import {
  renderWithAuthProvider,
  screen,
  waitFor,
  within,
  act,
  mockRestClientResolved,
  mockRestClientRejected,
} from "../../../../EventWizard/test-utils";

const cardsWithTag = (cards: HTMLElement[], tag: string) : HTMLElement[] => {
  return cards.filter(card => within(card).queryByText(tag));
};

describe("EventList Component", () => {
  it("displays loading message", async () => {
    act(() => {
      renderWithAuthProvider(<EventsList/>);
    });

    await waitFor(() => {
      expect(screen.getByText("event_tools.loading_events")).toBeInTheDocument();
    });
  });

  describe("when the server response is ok", () => {
    const publishedEvent = { id: "FAKE_1", type: "event", attributes: { name: "Published Event", public: true, start_date: "2022-01-01", end_date: "2022-01-03" } };
    const draftEvent = { id: "FAKE_2", type: "event", attributes: { name: "Draft Event", public: false, start_date: "2022-01-01", end_date: "2022-01-03" } };

    describe("with no event", () => {
      it("renders no event cards and renders the first event message", async () => {
        const response = { data: [] };
        mockRestClientResolved("request", response)
        act(() => {
          renderWithAuthProvider(<EventsList/>);
        });

        await waitFor(() => {
          const cards = screen.queryAllByRole("presentation");
          expect(cardsWithTag(cards, "form.status.published")).toHaveLength(0);
          expect(cardsWithTag(cards, "form.status.draft'")).toHaveLength(0);
          expect(screen.getByText("event_tools.welcome_message.first_event")).toBeInTheDocument();
        });
      });
    });

    describe("with only published events", () => {
      it("renders the published event card", async () => {
        const response = { data: [publishedEvent] };
        mockRestClientResolved("request", response)
        act(() => {
          renderWithAuthProvider(<EventsList/>);
        });

        await waitFor(() => {
          const cards = screen.queryAllByRole("presentation");
          expect(cardsWithTag(cards, "form.status.published")).toHaveLength(1);
          expect(screen.getByText(publishedEvent.attributes.name)).toBeInTheDocument();
        });
      });
    });

    describe("with only draft events", () => {
      it("renders the draft event card", async () => {
        const response = { data: [draftEvent] };
        mockRestClientResolved("request", response)
        act(() => {
          renderWithAuthProvider(<EventsList/>);
        });

        await waitFor(() => {
          const cards = screen.queryAllByRole("presentation");
          expect(cardsWithTag(cards, "form.status.draft")).toHaveLength(1);
          expect(screen.getByText(draftEvent.attributes.name)).toBeInTheDocument();
        });
      });
    });

    describe("with both event status types", () => {
      it("renders both event cards", async () => {
        const response = { data: [draftEvent, publishedEvent] };
        mockRestClientResolved("request", response)
        act(() => {
          renderWithAuthProvider(<EventsList/>);
        });

        await waitFor(() => {
          const cards = screen.queryAllByRole("presentation");
          expect(cardsWithTag(cards, "form.status.draft")).toHaveLength(1);
          expect(cardsWithTag(cards, "form.status.published")).toHaveLength(1);
          expect(screen.getByText(draftEvent.attributes.name)).toBeInTheDocument();
          expect(screen.getByText(publishedEvent.attributes.name)).toBeInTheDocument();
        });
      });
    });
  });

  describe("when the server response is not 200", () =>{
     it("renders error message", async () => {
      mockRestClientRejected("request", "error")
      act(() => {
        renderWithAuthProvider(<EventsList/>);
      });

      await waitFor(() => {
        expect(screen.getByText("event_tools.welcome_message.error")).toBeInTheDocument();
      });
    });
  });
});
