import React from "react";
import styled from "styled-components";

import SearchResultsList from "../SearchResultsList";
import { SearchResult, SearchResultAttrs } from "../types";
import { groupSearchResults } from "../utils";

const Container = styled.div`
  padding: 16px;
  border-bottom: 1px solid gray;
`;

type SearchResultsProps = {
  searchResults: [SearchResult];
  textColor: string;
};

export default function SearchResults({
  searchResults,
  textColor,
}: SearchResultsProps): JSX.Element {
  if (!searchResults) return null;

  const groups = groupSearchResults(searchResults);
  const groupsList: Array<[string, [SearchResultAttrs]]> =
    Object.entries(groups);

  return (
    <Container>
      <SearchResultsList groupsList={groupsList} textColor={textColor} />
    </Container>
  );
}
