import React, { useEffect, useState } from "react";
import Agenda from "./Agenda";
import FormPageLayout from "../../common/FormPageLayout";
import TalkService from "../../../../EventWizard/services/talkService";
import AppAdapter from "../../../../../utils/adapter";
import { useEvent } from "../../../pages/Event/index";
import { navigateToEventList } from "../../../../EventWizard/helpers/navigation";
import { useWebinarFormActions } from "../../hooks/useWebinarFormActions";

const AgendPage: React.FC = () => {

  const { event } = useEvent();
  const [talks, setTalks] = useState([]);
  const { updateEvent, navigateTo } = useWebinarFormActions();

  async function fetchTalks() {
    const payload = await TalkService.list(event.id);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, { transform: TalkService.transform });
      const tempTalks = response.map((talk) => {
        talk.displayDate = event.startDate
        talk.start = talk.startAt
        talk.end = talk.endAt
        talk.location = event.location
        return talk
      })
      setTalks(tempTalks);
    }
  }

  useEffect(() => {
    fetchTalks();
  }, []);

  return(
    <FormPageLayout
      event={event}
      update={updateEvent}
      previousForm={navigateTo.webinarDetails}
      exitForm={navigateToEventList}
      nextForm={navigateTo.websiteStyle}
      pageType="agenda"
    >
      {
        () => <Agenda event={event} talks={talks} refreshTalks={fetchTalks} />
      }

    </FormPageLayout>
  )
};

export default AgendPage;
