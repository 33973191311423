import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Button = styled.button`
  border-radius: 4px;
  background-color:#d7d7d7;
  padding: 5px;
  margin: 10px 0;
`;

type FallbackComponentProps = {
  error: Error;
};

const FallbackComponent: React.FC<FallbackComponentProps> = ({ error }) => {
  const { t } = useTranslation();

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div role="alert">
      <p>{t("flash.request_error")}</p>
      <pre>{error.message}</pre>
      <Button onClick={refreshPage}>{t("actions.reload_page")}</Button>
    </div>
  );
};

export default FallbackComponent;
