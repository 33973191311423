import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BottomFormButtons, AddSessionHeader } from ".";
import sessionIntroImage from "../../../EventWizard/assets/images/session-intro.svg";
import { EventType } from "../../../EventWizard/models/Event";
import { figmaTheme as fT } from "../../../react/dcTheme";
import AgendaView from "../../Agenda/AgendaView";
import PageLayout from "../../common/PageLayout";

type SessionLayoutProps = {
  event: EventType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update: (data: any) => Promise<boolean>;
  exitForm: () => void;
  nextForm: () => void;
};

const SessionLayout: React.FC<SessionLayoutProps> = ({
  event,
  exitForm,
  nextForm,
}) => {
  const { t } = useTranslation();

  const saveAndContinue = async () => {
    nextForm();
  };

  const saveAndExit = async () => {
    exitForm();
  };

  return (
    <PageLayout event={event}>
      <Flex flexDir="row" flex="auto">
        <Flex flexDir="column" flex="auto" minW="70%" px={10} py={2}>
          <AddSessionHeader />
          <AgendaView />
        </Flex>
        <Flex flexDir="column" justify="space-between" flex="auto">
          <Flex h="100%">
            <Box
              width="1px"
              bgGradient={`linear(transparent, ${fT.colors.grey.base}, ${fT.colors.grey.base})`}
            />
            <Box w="100%" px={3}>
              <Tabs isFitted>
                <TabList>
                  <Tab _selected={{ borderColor: fT.colors.secondary.base }}>
                    {t("events.agenda.session.sessions_tab")}
                  </Tab>
                  <Tab _selected={{ borderColor: fT.colors.secondary.base }}>
                    {t("events.agenda.session.preview_tab")}
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Center>
                      <Image
                        src={sessionIntroImage}
                        alt={t("images.image.alt")}
                        p={5}
                        w="60%"
                      />
                    </Center>
                    <Center>
                      <p>
                        {t("events.agenda.session.sessions_tab_empty_text")}
                      </p>
                    </Center>
                  </TabPanel>
                  <TabPanel>
                    <a href="/agenda">
                      {t("events.agenda.session.preview_agenda_link_text")}
                      {` `}
                      <ExternalLinkIcon />
                    </a>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <BottomFormButtons
        saveAndContinue={saveAndContinue}
        saveAndExit={saveAndExit}
      />
    </PageLayout>
  );
};

export default SessionLayout;
