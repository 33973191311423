import * as React from "react";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { array, number, object, string } from "yup";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AddIcon } from '@chakra-ui/icons';

import {
  GroupRegistrationFormAttributes,
  GroupRegistrationRecord,
  DelegateGroupsAndTypes,
} from "./types";

import FormLabels from "./FormLabels";
import FormFields from "./FormFields";

import { updateGroupRegistration } from "./updateGroupRegistration";
import { addUniquePropertyValidationToYup } from "../../extensions/yup/array_methods";

export const GROUP_REGISTRATION_FORM_ID = "group_registration_form";

type FormProps = {
  groupRegistrationId: string;
  registrationRecords: GroupRegistrationRecord[];
  delegateGroupsAndTypes: DelegateGroupsAndTypes;
  currencyCode: string;
};

// A wrapper around the form to stop application-level CSS styles mess with the height of the form inputs
const FormWrapper = styled.div`
  form input {
    height: auto;
  };
`;

const GroupRegistrationForm = (props: FormProps) => {
  const {
    groupRegistrationId,
    registrationRecords,
    delegateGroupsAndTypes,
    currencyCode,
  } = props;

  const { t } = useTranslation();

  const [records, setRecords] =
    useState<GroupRegistrationRecord[]>(registrationRecords);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  addUniquePropertyValidationToYup();
  const schema = object().shape({
    records: array()
      .of(
        object().shape({
          firstName: string().required(t('validations.blank_error')),
          lastName: string().required(t('validations.blank_error')),
          email: string()
            .email(t('validations.invalid_email'))
            .required(t('validations.blank_error')),
          delegateTypeId: number()
            .required(t('validations.required'))
            .typeError(t('validations.required')),
        })
      )
      .required()
      .uniqueProperty('email', t('group_registrations.errors.form.email.uniqueness'))
  });

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState,
    setError,
    reset,
  } = useForm<GroupRegistrationFormAttributes>({
    defaultValues: { records: records },
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "records",
  });
  const canRemoveRow = fields.length > 1;

  const { errors } = formState;
  useEffect(() => {
    reset({ records: records });
  }, [records]);

  const blankRecord: GroupRegistrationRecord = {
    positionId: records?.length,
    recordId: "",
    firstName: "",
    lastName: "",
    email: "",
    delegateTypeId: undefined,
    addonIds: [],
  };

  const handleServerErrors = (recordErrors: GroupRegistrationRecord[]) => {
    recordErrors.forEach((record) => {
      Object.keys(record.errors).forEach((key) => {
        const field = key as "email";
        setError(`records.${record.positionId}.${field}`, {
          type: "validation",
          message: record.errors[key],
        });
      });
    });
  };

  const onSubmit = async (data: GroupRegistrationFormAttributes) => {
    data.records.map((_record, index) => setValue(`records.${index}.positionId`, index));
    setIsLoading(true);
    const result = await updateGroupRegistration({
      groupRegistrationId: groupRegistrationId,
      data,
    });

    if (result.success) {
      setRecords(result.group_registration_records);
      document.location.assign(result.redirect);
    } else {
      handleServerErrors(result.errors);
      setIsLoading(false);
    }
  };

  return (
    <FormWrapper>
      <form id={GROUP_REGISTRATION_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <FormLabels />
        <hr />
        {fields.map((field, index) => (
          <div className="form-row" key={field.id}>
            <FormFields
              control={control}
              remove={remove}
              errors={errors.records ? errors.records[index] : undefined}
              register={register}
              formId={GROUP_REGISTRATION_FORM_ID}
              index={index}
              registrationRecord={field}
              delegateGroupsAndTypes={delegateGroupsAndTypes}
              currencyCode={currencyCode}
              canRemoveRow={canRemoveRow}
            />
          </div>
        ))}
        <span className="form-footer-buttons">
          <button
            type="button"
            data-testid="add-new-row"
            onClick={() => append(blankRecord)}
            className="btn btn-secondary rounded-pill px-5 py-3 m-2 registration-btn"
          >
            <AddIcon w={12} h={13} verticalAlign="center" viewBox="0 0 30 30" />   {t("group_registrations.form.add_attendee.label")}
          </button>
          <button
            className="btn btn-primary rounded-pill px-5 py-3 m-2"
            type="submit"
            data-testid="submit"
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm mr-3"
                role="status"
                aria-hidden="true"
              ></span>
            )}

            {t("views.pagination.next_item")}
          </button>
        </span>
      </form>
    </FormWrapper>
  );
};

export default GroupRegistrationForm;
