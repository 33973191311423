import { JsonApiEventType } from '../models/Event';
import AppAdapter from '../../../utils/adapter';
import CONFIG from '../../../utils/config';
import RestClient from '../../../utils/restClient';

/**
 * service for events
 */

export type ListResponseType = {
  ok: boolean;
  result: Array<JsonApiEventType> | null;
  message?: string;
}
class TenantService {
  static async getTenant(tenantId: string) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.TENANTS}/${tenantId}`);
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async updateTenantWithFormData(tenantId: string, payload) {
    try {
      this.transformFiles(payload);
      const response = await RestClient.patchWithFormData(`${CONFIG.URLS.TENANTS}/${tenantId}`, { tenant: AppAdapter.snakeCaseKeys(payload) });
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async updateTenant(tenantId: string, payload) {
    try {
      const response = await RestClient.patch(`${CONFIG.URLS.TENANTS}/${tenantId}`, { tenant: AppAdapter.snakeCaseKeys(payload) });
      const { ok: responseOk } = response;
      if(!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static transformFiles(record) {
    if(!record.headerBannerImage) delete record.headerBannerImage;
  }

  static transform(record) {
    record['headerBannerImageUrl'] = record?.headerBannerImage?.url;
    delete record['headerBannerImage'];
    return record;
  }
}

export default TenantService;
