import { renderHook } from "@testing-library/react-hooks";
import RestClient from "../../../../../utils/restClient";
import {
  getSuccessJSONResponse,
  waitFor,
} from "../../../../EventWizard/test-utils";
import useUserFetcher from "./useUserFetcher";

const restClientMocked = jest.spyOn(RestClient, "get");

describe("UserFetcher", () => {
  test("should expose user option data", async () => {
    restClientMocked.mockResolvedValueOnce(
      getSuccessJSONResponse({
        data: [
          {
            id: "1",
            attributes: {
              fullname: "Test User",
              id: "1",
            },
          },
        ],
      })
    );
    const { result } = renderHook(() => useUserFetcher("1", ["1"]));
    await waitFor(async () => {
      expect(result.current.users).toEqual([
        { label: "Test User", value: "1" },
      ]);
    });
  });

  test("exposes correct user option data for selected users", async () => {
    restClientMocked.mockResolvedValueOnce(
      getSuccessJSONResponse({
        data: [
          {
            id: "1",
            attributes: {
              fullname: "Test User",
              id: "1",
            },
          },
          {
            id: "2",
            attributes: {
              fullname: "Test User 2",
              id: "2",
            },
          },
        ],
      })
    );
    const { result } = renderHook(() => useUserFetcher("1", ["1"]));
    await waitFor(async () => {
      expect(result.current.selectedUsers).toEqual([
        { label: "Test User", value: "1" },
      ]);
    });
  });
});
