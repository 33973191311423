import React, { useState, useEffect, MutableRefObject } from "react";
import FormControlLabel from "../shared/FormControlLabel";
import SelectComponent from "../SelectComponent";

import UserService from "../../../EventWizard/services/userService";
import AppAdapter from "../../../../utils/adapter";
import { FieldError } from "react-hook-form";
import UserDrawerForm from "../../forms/UserDrawerForm";
import { useDisclosure } from "@chakra-ui/react";
import UserType, { NEW_USER } from "../../../EventWizard/models/User";
import useUserFetcher from "../../Webinar/hooks/useUserFetcher";

export type UsersSelectProps = {
  eventId: string;
  userIds: Array<string>;
  error: FieldError;
  isAddNew?: boolean;
  handleUserIdsChange: (ids: Array<string>) => void;
  updateUserSelect?: (user: LabelValueObject) => void;
  addNewUserText?: string;
  reFetch: MutableRefObject<unknown>;
  fieldLabel: string;
  newUserFormHeading: string;
  editUserFormHeading: string;
};

export type LabelValueObject = {
  value: string;
  label: string;
};

export const UsersSelect: React.FC<UsersSelectProps> = ({
  eventId,
  userIds,
  isAddNew = false,
  error,
  handleUserIdsChange,
  updateUserSelect,
  addNewUserText,
  reFetch,
  fieldLabel,
  newUserFormHeading,
  editUserFormHeading,
}) => {
  const { users, setUsers, selectedUsers, setSelectedUsers, isLoading } =
    useUserFetcher(eventId, userIds);

  const [user, setUser] = useState<UserType>(NEW_USER);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const addNewSelection = (newSelection: LabelValueObject) => {
    setUsers(userSelections => [...userSelections.filter(u => u.value !== newSelection.value), newSelection]);
    setSelectedUsers(selectedUsers => [...selectedUsers.filter(u => u.value !== newSelection.value), newSelection]);
  };

  useEffect(() => {
    reFetch.current = addNewSelection;
  }, []);

  const handleSelectChange = (
    selectedOptions: LabelValueObject[] | null | void
  ) => {
    if (selectedOptions) {
      setSelectedUsers(selectedOptions);
      handleUserIdsChange(selectedOptions.map(option => option.value));
    }
  };

  const handleNewUser = async (userId = null) => {
    if (!userId) {
      setUser(NEW_USER);
      onOpen();
    }

    const payload = await UserService.fetchUser(eventId, userId);

    if (payload.ok) {
      const userInfo = await AppAdapter.deserialize(payload.result, {
        transform: UserService.transform,
      });
      setUser(userInfo);
      onOpen();
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  return (
    <>
      <FormControlLabel label={fieldLabel} error={error}>
        <SelectComponent
          options={users}
          value={selectedUsers}
          onChange={handleSelectChange}
          isAddNew={isAddNew}
          onAddItem={handleNewUser}
          addItemText={addNewUserText}
          isLoading={isLoading}
          isMulti
        />
      </FormControlLabel>
      <UserDrawerForm
        heading={user.id ? editUserFormHeading : newUserFormHeading}
        updateUserSelect={updateUserSelect}
        eventId={eventId}
        isOpen={isOpen}
        onClose={onClose}
        user={user}
      />
    </>
  );
};

export default UsersSelect;
