import React from "react";
import { render, act } from "@testing-library/react";
import "@testing-library/jest-dom";
import DCModal from "./DCModal";
import { axe, toHaveNoViolations } from "jest-axe";
expect.extend(toHaveNoViolations);

const doNothing = () => {
  // Prevents eslint empty method errors
};

describe("DCModal", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(
      <DCModal
        isOpen={true}
        onClose={doNothing}
        title="Test Modal"
        content="Test Modal content"
        primaryButtonLabel="Primary"
        secondaryButtonLabel="Secondary"
        onClickPrimary={doNothing}
        onClickSecondary={doNothing}
      />);

    await act(async() => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
