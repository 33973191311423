import * as yup from "yup";
import { videoUrlRegex } from "../../../EventWizard/helpers/customValidators";

export default t => {
  return yup.object({
    homeFeaturedVideoUrl: yup.string()
      .matches(videoUrlRegex, { message: t('homepage_setup.video_url_format_error'), excludeEmptyString: true })
      .nullable(),
    homePageSections: yup.object({
      location: yup.object({
        id: yup.string().required(),
        enabled: yup.boolean().required()
      }),
      featuredVideo: yup.object({
        id: yup.string().required(),
        enabled: yup.boolean().required()
      })
    })
  });
};
