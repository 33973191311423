import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppAdapter from "../../../../utils/adapter";
import EventService from "../../../EventWizard/services/eventService";
import { useEvent } from "../../pages/Event";
import { usePageStatus } from "../../pages/Event/Event";

export type Status = "default" | "active" | "done" | "disabled";
type WebinarRoute =
  | "webinarDetails"
  // | "attendeeConfigurations"
  | "agenda"
  | "facilitators"
  | "websiteStyle"
  | "customStyling"
  | "heroSection"
  | "additionalFeatures"
  | "addSections"
  | "footer"
  | "supportEmail"
  | "metadata"
  | "publish";
type WebinarRoutes = { [key in WebinarRoute]: string };
type WebinarNavigateTo = { [key in WebinarRoute]?: () => void };

export const useWebinarFormActions = () => {
  const { event, setEvent } = useEvent();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activePageStatus, completePageStatus, enabledPageStatus } =
    usePageStatus();

  const baseEventUrl = `/admin/event-tools/${event.id}`;
  const pathsByRoute: WebinarRoutes = {
    webinarDetails: `${baseEventUrl}/webinar/details`,
    // attendeeConfigurations: `${baseEventUrl}/webinar/attendee-configurations`,
    agenda: `${baseEventUrl}/agenda`,
    facilitators: `${baseEventUrl}/agenda/facilitators`,
    websiteStyle: `${baseEventUrl}/webinar-website/website-style`,
    customStyling: `${baseEventUrl}/webinar-website/custom-styling`,
    heroSection: `${baseEventUrl}/webinar-website/hero-section`,
    additionalFeatures: `${baseEventUrl}/webinar-website/additional-features`,
    addSections: `${baseEventUrl}/webinar-website/add-sections`,
    footer: `${baseEventUrl}/webinar-website/footer`,
    supportEmail: `${baseEventUrl}/webinar-website/support-email`,
    metadata: `${baseEventUrl}/webinar-website/metadata`,
    publish: `${baseEventUrl}/webinar/publish`,
  };

  const namesByRoute = {
    webinarDetails: t("event_tools.navigation.webinar.webinar_details"),
    // attendeeConfigurations: t("event_tools.navigation.attendee_configurations"),
    agenda: t("event_tools.navigation.agenda"),
    facilitators: t("event_tools.navigation.facilitators"),
    websiteStyle: t("event_tools.navigation.website_style"),
    customStyling: t("event_tools.navigation.more_custom_styling"),
    heroSection: t("event_tools.navigation.hero_section"),
    additionalFeatures: t("event_tools.navigation.additional_features"),
    addSections: t("event_tools.navigation.add_sections"),
    footer: t("event_tools.navigation.footer"),
    supportEmail: t("event_tools.navigation.support_email"),
    metadata: t("event_tools.navigation.meta_data"),
    publish: t("event_tools.navigation.publish"),
  };

  const updateEvent = async (data, withFormData = false) => {
    let payload = null;
    if (withFormData) {
      payload = await EventService.updateEventWithFormData(event.id, data);
    } else {
      payload = await EventService.updateEvent(event.id, data);
    }
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, {
        transform: EventService.transform,
      });
      setEvent(response);
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const updateEventWithFormData = async (data) => updateEvent(data, true);

  const navigateTo = useMemo(() => {
    const routeHandlers: WebinarNavigateTo = {};
    Object.entries(pathsByRoute).forEach(([key, value]) => {
      routeHandlers[key] = () => navigate(value);
    });
    return routeHandlers;
  }, []);

  const getPageStatus = (pageName: WebinarRoute): string => {
    if (activePageStatus.pages[pageName]) {
      return "active";
    }
    if (completePageStatus.pages[pageName]) {
      return "done";
    }
    if (!enabledPageStatus.pages[pageName]) {
      return "disabled";
    }
    return "default";
  };

  const getStepDetailsByRoute = (route: WebinarRoute) => ({
    path: pathsByRoute[route],
    name: namesByRoute[route],
    status: getPageStatus(route),
  });

  return {
    getStepDetailsByRoute,
    updateEvent,
    updateEventWithFormData,
    navigateTo,
    pathsByRoute,
  };
};
