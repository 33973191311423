import React from "react";
import {
  act,
  fireEvent,
  render,
  screen,
  waitFor,
  FAKE_EVENT,
} from "../../../EventWizard/test-utils";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import CustomHomepageSectionForm, {
  CustomHomepageSectionFormProps,
} from "./CustomHomepageSectionForm";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: CustomHomepageSectionFormProps = {
  event: FAKE_EVENT,
  submitForm,
  handleErrors,
  formErrors,
};

describe("DC Home Page Sections", () => {
  it("has no accessibility violations", async () => {
    const { container } = render(
      <CustomHomepageSectionForm {...requiredProps} />
    );

    await waitFor(() => {
      fireEvent.click(
        screen.getByText(
          "events.form.home_page.add_sections.section_button.name"
        )
      );
    });

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe("valid states for extra sections", () => {
    test("can add a section and remove", async () => {
      render(<CustomHomepageSectionForm {...requiredProps} />);

      const createButton = screen.getByRole("button", {
        name: "events.form.home_page.add_sections.section_button.name",
      });

      await act(async () => {
        fireEvent.click(createButton);
      });

      const newSectionTitle = screen.getByText(
        "events.form.home_page.add_sections.section_title.label"
      );
      expect(newSectionTitle).toBeInTheDocument();

      const deleteButton = screen.getByRole("button", {
        name: "events.form.home_page.add_sections.remove_section_button.name",
      });

      await act(async () => {
        fireEvent.click(deleteButton);
      });

      expect(
        screen.queryByText(
          "events.form.home_page.add_sections.section_title.label"
        )
      ).not.toBeInTheDocument();
    });
  });

  describe("section title", () => {
    test("is required", async () => {
      render(<CustomHomepageSectionForm {...requiredProps} />);

      await waitFor(() => {
        fireEvent.click(
          screen.getByText(
            "events.form.home_page.add_sections.section_button.name"
          )
        );
      });
      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "events.form.home_page.add_sections.section_title.label",
          { exact: false }
        );
        fireEvent.change(inputLabel, { target: { value: "" } });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("section description", () => {
    test("is required", async () => {
      render(<CustomHomepageSectionForm {...requiredProps} />);

      await waitFor(() => {
        fireEvent.click(
          screen.getByText(
            "events.form.home_page.add_sections.section_button.name"
          )
        );
      });
      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "events.form.home_page.add_sections.section_description.label",
          { exact: false }
        );
        fireEvent.change(inputLabel, { target: { value: "" } });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });
});
