import React from "react";
import { fireEvent, screen, waitFor } from "@testing-library/react";
import "@testing-library/jest-dom";
import EmailCustomisationForm, {
  EmailCustomisationFormProps,
} from "./EmailCustomisationForm";
import { axe, toHaveNoViolations } from "jest-axe";
import { FAKE_TENANT, render } from "../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: EmailCustomisationFormProps = {
  tenant: FAKE_TENANT,
  submitForm,
  handleErrors,
  formErrors,
};

describe("EmailCustomisation", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<EmailCustomisationForm {...requiredProps} />);
    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe("colour email header", () => {
    test("is required", async () => {
      render(<EmailCustomisationForm {...requiredProps} />);
      const input = screen.getByLabelText(
        "branding_page.email_styling.header_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "" },
        });
        fireEvent.blur(input);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    test("must be a hexadecimal string", async () => {
      render(<EmailCustomisationForm {...requiredProps} />);
      const input = screen.getByLabelText(
        "branding_page.email_styling.header_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });

  describe("colour email footer", () => {
    test("is required", async () => {
      render(<EmailCustomisationForm {...requiredProps} />);
      const input = screen.getByLabelText(
        "branding_page.email_styling.footer_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "" },
        });
        fireEvent.blur(input);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    test("must be a hexadecimal string", async () => {
      render(<EmailCustomisationForm {...requiredProps} />);
      const input = screen.getByLabelText(
        "branding_page.email_styling.footer_colour.label",
        { exact: false }
      );

      await waitFor(() => {
        fireEvent.change(input, {
          target: { value: "FAKE_HEX_COLOUR" },
        });
        fireEvent.blur(input);
      });

      expect(screen.getByText("colour_hex_error")).toBeInTheDocument();
    });
  });
});
