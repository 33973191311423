import React, { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Icon } from '@chakra-ui/react'
import { Column, useTable, useSortBy } from 'react-table'
import { ArrowUp, ArrowDown } from "phosphor-react";

export type DCTableProps = {
  data: object[];
  columns: Column<object>[];
  cellProps?: unknown;
};

const DCTable: React.FC<DCTableProps> = ({data, columns, cellProps}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)

  const [hoveringHeader, setHoveringHeader] = useState<string>("");

  const ascIconDisplay = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return "none";
      } else {
        return "inline";
      }
    } else {
      if (!column.disableSortBy && column.Header == hoveringHeader) {
        return "inline";
      } else {
        return "none";
      }
    }
  }

  const descIconDisplay = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return "inline";
      } else {
        return "none";
      }
    } else {
      return "none";
    }
  }

  return (
    <Table variant="dcTable" {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup, headerIndex) => (
          <Tr key={`header-group-${headerIndex}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, columnIndex) => (
              <Th
                key={`header-${columnIndex}`} {...column.getHeaderProps(column.getSortByToggleProps())}
                onMouseEnter={() => setHoveringHeader(column.Header.toString())}
                onMouseLeave={() => setHoveringHeader("")}
              >
                {column.render('Header')}
                <chakra.span pl='3px' pt='5px'>
                  <Icon display={ascIconDisplay(column)} as={ArrowUp} weight="fill" aria-label='sorted ascending' transform="translate(0, 3px)" />
                  <Icon display={descIconDisplay(column)} as={ArrowDown} weight="fill" aria-label='sorted descending' transform="translate(0, 3px)" />
                </chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row)
          return (
            <Tr key={`row-${rowIndex}`} {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => (
                <Td key={`cell-${cellIndex}`} {...cell.getCellProps(cellProps)}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  );
};

export default DCTable;
