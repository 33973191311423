import React from "react";
import "@testing-library/jest-dom";
import { toHaveNoViolations } from "jest-axe";
import { waitFor, fireEvent, screen } from "@testing-library/react";

import {
  FAKE_EVENT,
  FAKE_TENANT,
  render,
} from "../../../EventWizard/test-utils";
import EventDetailsForm, { EventDetailsProps } from "./EventDetailsForm";
expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: EventDetailsProps = {
  event: { ...FAKE_EVENT, tenant: FAKE_TENANT },
  submitForm,
  handleErrors,
  formErrors,
};

const mockData = {
  result: {
    timezones: ["Melbourne", "Perth"],
  },
};

jest.mock("../../../EventWizard/services/timezoneService", () => ({
  list: () => Promise.resolve(mockData),
}));

describe("Event Details Form", () => {
  describe("name", () => {
    test("is required", async () => {
      render(<EventDetailsForm {...requiredProps} />);
      await waitFor(() => {
        const inputLabel = screen.getByLabelText("event_details.name.label", {
          exact: false,
        });
        fireEvent.click(inputLabel);
        fireEvent.change(inputLabel, {
          target: { value: "" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    test("must be atleast 3 characters", async () => {
      render(<EventDetailsForm {...requiredProps} />);
      await waitFor(() => {
        const inputLabel = screen.getByLabelText("event_details.name.label", {
          exact: false,
        });
        fireEvent.click(inputLabel);
        fireEvent.change(inputLabel, {
          target: { value: "HI" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText("event_details.name.too_short")
      ).toBeInTheDocument();
    });

    test("can't be longer than 348 characters", async () => {
      render(<EventDetailsForm {...requiredProps} />);
      await waitFor(() => {
        const inputLabel = screen.getByLabelText("event_details.name.label", {
          exact: false,
        });
        fireEvent.click(inputLabel);
        fireEvent.change(inputLabel, {
          target: { value: "X".repeat(349) },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText("event_details.name.too_long")
      ).toBeInTheDocument();
    });
  });

  describe("start date and end date", () => {
    test("is required", async () => {
      render(<EventDetailsForm {...requiredProps} />);
      const inputLabel = screen.getByLabelText("event_details.date.label", {
        exact: false,
      });

      await waitFor(() => {
        fireEvent.click(inputLabel);
        fireEvent.click(screen.getByText("event_tools.buttons.clear"));
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("start time and end time", () => {
    test("is required", async () => {
      render(<EventDetailsForm {...requiredProps} />);
      const timeInput = screen.getByLabelText("event_details.time.label", {
        exact: false,
      });

      await waitFor(() => {
        fireEvent.click(timeInput);
        fireEvent.click(screen.getByText("event_tools.buttons.clear"));
        fireEvent.blur(timeInput);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });
});
