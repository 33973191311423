import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { figmaTheme as fT } from "../figmaTheme";

export const eventCardTheme: ComponentStyleConfig = {
  parts: ['gridItem', 'stack', 'nameText', 'datesText', 'progressText', 'statusTag', 'link'],
  baseStyle: {
    gridItem: {
      borderWidth: "1px",
      borderRadius: "6px",
      p: "10px",
      bg: "white",
      pos: "relative",
      minW: "280px",
      h: "200px"
    },
    stack: {
      spacing: "5px",
      mb: "40px"
    },
    nameText: {
      marginBlockStart: "10px",
      marginBlockEnd: "0",
      fontWeight: "bold",
      fontSize: "large"
    },
    datesText: {
      fontSize: "small"
    },
    statusTag: {
      bg: fT.colors.yellow,
      color: fT.colors.black,
      w: 'fit-content',
      px: '10px',
      py: '5px',
      fontSize: 'small',
      fontStyle: 'italic',
      borderRadius: '3px',
    },
    progressText: {
      fontSize: "small",
      color: "#165FCD",
      pos: "relative",
      marginBlockStart: '0',
      marginBlockEnd: '20px',
      w: 'fit-content',
      _before: {
        content: `''`,
        position: 'absolute',
        width: '90%',
        height: '1px',
        bottom: '-10px',
        borderBottom: '2px solid'
      }
    },
    link: {
      pos: "absolute",
      bottom: "20px",
      right: "30px",
      fontSize: 'small',
      textDecoration: 'none',
      color: fT.colors.blue
    },
  }
};
