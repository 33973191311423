import * as mockReactRouterDom from "react-router-dom";
import { renderHook } from "@testing-library/react-hooks";
import { FAKE_EVENT } from "../../EventWizard/test-utils";
import { WEBINAR_CONFIG } from "../config";
import useCompletePageStatus from "./useCompletePageStatus";
import ProgressService from "../../EventWizard/services/progressService";

const mockNavigate = jest.fn();

const eventId = FAKE_EVENT.id;

jest.mock("react-router-dom", () => ({
  ...(jest.requireActual("react-router-dom") as typeof mockReactRouterDom),
  useOutletContext: jest.fn(),
  useLocation: jest.fn(),
  useNavigate: () => mockNavigate,
}));

describe("useCompletePageStatus", () => {
  const mockStorage = {};
  let setItemSpy, getItemSpy;

  beforeAll(() => {
    setItemSpy = jest
      .spyOn(global.Storage.prototype, "setItem")
      .mockImplementation((key, value) => {
        mockStorage[key] = value;
      });
    getItemSpy = jest
      .spyOn(global.Storage.prototype, "getItem")
      .mockImplementation((key) => mockStorage[key]);
  });

  afterAll(() => {
    getItemSpy.mockRestore();
    setItemSpy.mockRestore();
  });

  test("returns correct progress status object when entering flow for first time", () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar/details`,
    });
    const { result } = renderHook(() =>
      useCompletePageStatus(WEBINAR_CONFIG, eventId)
    );
    expect(result.current.completePageStatus).toEqual({
      groups: {
        webinarDetailsGroup: false,
        webinarWebsite: false,
      },
      subGroups: {
        brandAndStyle: false,
        homePage: false,
        configurations: false,
      },
      pages: {
        webinarDetails: false,
        websiteStyle: false,
        // attendeeConfigurations: false,
        agenda: false,
        customStyling: false,
        heroSection: false,
        additionalFeatures: false,
        addSections: false,
        footer: false,
        supportEmail: false,
        metadata: false,
        publish: false,
      },
    });
  });

  test("returns correct progress status object for /webinar-website/additional-features when additionalFeatures is stored in local storage as progress.", () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar-website/additional-features`,
    });
    ProgressService.setProgress("additionalFeatures", eventId);
    const { result } = renderHook(() =>
      useCompletePageStatus(WEBINAR_CONFIG, eventId)
    );
    expect(result.current.completePageStatus).toEqual({
      groups: {
        webinarDetailsGroup: true,
        webinarWebsite: false,
      },
      subGroups: {
        brandAndStyle: true,
        homePage: false,
        configurations: false,
      },
      pages: {
        webinarDetails: true,
        websiteStyle: true,
        // attendeeConfigurations: true,
        agenda: true,
        customStyling: true,
        heroSection: true,
        additionalFeatures: false,
        addSections: false,
        footer: false,
        supportEmail: false,
        metadata: false,
        publish: false,
      },
    });
  });

  test("returns correct progress status object when updating/saving progress on additionalFeatures page.", () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar-website/additional-features`,
    });
    ProgressService.setProgress("additionalFeatures", eventId);
    const { result } = renderHook(() =>
      useCompletePageStatus(WEBINAR_CONFIG, eventId)
    );
    result.current.updateProgress();

    expect(result.current.completePageStatus).toEqual({
      groups: {
        webinarDetailsGroup: true,
        webinarWebsite: false,
      },
      subGroups: {
        brandAndStyle: true,
        homePage: false,
        configurations: false,
      },
      pages: {
        webinarDetails: true,
        websiteStyle: true,
        // attendeeConfigurations: true,
        agenda: true,
        customStyling: true,
        heroSection: true,
        additionalFeatures: true,
        addSections: false,
        footer: false,
        supportEmail: false,
        metadata: false,
        publish: false,
      },
    });
  });

  test("returns correct enabled status object for /webinar-website/additional-features when additionalFeatures is stored in local storage as progress.", () => {
    (mockReactRouterDom.useLocation as jest.Mock).mockReturnValue({
      pathname: `/admin/event-tools/${eventId}/webinar-website/additional-features`,
    });
    ProgressService.setProgress("additionalFeatures", eventId);
    const { result } = renderHook(() =>
      useCompletePageStatus(WEBINAR_CONFIG, eventId)
    );
    expect(result.current.enabledPageStatus).toEqual({
      groups: {
        webinarDetailsGroup: true,
        webinarWebsite: true,
      },
      subGroups: {
        brandAndStyle: true,
        homePage: true,
        configurations: false,
      },
      pages: {
        webinarDetails: true,
        websiteStyle: true,
        // attendeeConfigurations: true,
        agenda: true,
        customStyling: true,
        heroSection: true,
        additionalFeatures: true,
        addSections: false,
        footer: false,
        supportEmail: false,
        metadata: false,
        publish: false,
      },
    });
  });
});
