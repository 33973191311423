import React, { useState } from "react";
import {
  Flex,
  Image,
  Box,
  Text,
  Link,
  Spacer,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ArrowLeft } from "phosphor-react";

import publishGraphic from "../../../../EventWizard/assets/images/publish-graphic.svg";
import PageLayout from "../../../common/PageLayout";
import { useEvent } from "../../../pages/Event";
import { figmaTheme as fT } from "../../../../react/dcTheme";
import { useWebinarFormActions } from "../../hooks/useWebinarFormActions";
import { navigateToEventList } from "../../../../EventWizard/helpers/navigation";
import EventService from "../../../../EventWizard/services/eventService";
import DCModal from "../../../common/DCModal";
import AppAdapter from "../../../../../utils/adapter";
import { usePageStatus } from "../../../pages/Event/Event";

const Publish: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { event } = useEvent();
  const { navigateTo } = useWebinarFormActions();
  const { updateProgress } = usePageStatus();
  const [isLoading, setIsLoading] = useState(false);

  const {
    onOpen: onPublishModalOpen,
    isOpen: isPublishModalOpen,
    onClose: onPublishModalClose,
  } = useDisclosure();

  const publishEventCheck = async () => {
    const payload = await EventService.publishEventCheck(event.id);
    if (payload.ok) {
      const response = AppAdapter.camelCaseKeys(payload.result);
      if (response.publicEventsPresence) {
        onPublishModalOpen();
      } else {
        publishEvent({ setAsDefault: true });
      }
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const publishEvent = async data => {
    const payload = await EventService.publishEvent(event.id, data);
    if (payload.ok) {
      updateProgress();
      navigateToEventList();
    } else {
      setIsLoading(false);
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const onClickPublish = (data = {}) => {
    setIsLoading(true);
    publishEvent(data);
  };

  return (
    <PageLayout event={event}>
      <Flex flexDir="column" flex="auto">
        <Flex h="100%" justify="center" align="center" direction="column">
          <Image
            src={publishGraphic}
            alt={t("images.image.alt")}
            w="20%"
            maxW="230px"
            mb={10}
          />
          <Box w="60%" maxW="800px" textAlign="center" fontFamily="Inter">
            <Text variant="webinar-success">{t("publish_intro.line_one")}</Text>
            <Text mb={8}>{t("publish_intro.line_two")}</Text>
            <Link href={`/?event_id=${event.id}`} textDecoration="underline">
              {t("publish_intro.preview_link")}
            </Link>
          </Box>
        </Flex>
        <Flex
          align="center"
          py={5}
          px={10}
          borderTop="1px"
          borderTopColor={fT.colors.grey.base}
          bg={fT.colors.primary[100]}
        >
          <Button
            m={2}
            variant="ghost"
            leftIcon={<ArrowLeft weight="fill" />}
            onClick={navigateTo.metadata}
          >
            {t("views.pagination.previous_item")}
          </Button>
          <Spacer />
          <Button variant="outline" m={2} onClick={navigateToEventList}>
            {t("event_tools.buttons.exit")}
          </Button>
          <Button m={2} onClick={publishEventCheck}>
            {t("event_tools.buttons.publish")}
          </Button>
        </Flex>
      </Flex>
      <DCModal
        title={t("publish_modal.title")}
        content={event.public ? t("publish_modal.alt_body") : t("publish_modal.body")}
        primaryButtonLabel={t("publish_modal.primary_button")}
        secondaryButtonLabel={t("publish_modal.secondary_button")}
        isOpen={isPublishModalOpen}
        onClose={onPublishModalClose}
        isLoading={isLoading}
        onClickPrimary={() => onClickPublish({ setAsDefault: true })}
        onClickSecondary={() => onClickPublish({ setAsDefault: false })}
      />
    </PageLayout>
  );
};

export default Publish;
