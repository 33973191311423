import React from "react";
import styled from "styled-components";

import { groupSearchResults } from "../utils";
import SearchResultsList from "../SearchResultsList";
import { SearchResult, SearchResultAttrs } from "../types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.bgColor};
  color: ${props => props.textColor};
  border-radius: 10px;
  font-size: 16px;
  font-size: 12px;
  width: 100%;
  filter: contrast(80%);
`;

type SearchResultsViewProps = {
  searchResults: [SearchResult];
  bgColor: string;
  textColor: string;
};

function SearchResultsView({
  searchResults,
  bgColor,
  textColor,
}: SearchResultsViewProps): JSX.Element {
  if (!searchResults) return null;

  const groups = groupSearchResults(searchResults);
  const groupsList: Array<[string, [SearchResultAttrs]]> =
    Object.entries(groups);

  return (
    <Container bgColor={bgColor} textColor={textColor}>
      <SearchResultsList groupsList={groupsList} textColor={textColor} />
    </Container>
  );
}

SearchResultsView.displayName = "SearchResultsView";

export default SearchResultsView;
