import React from "react";
import Select, {
  MenuListProps,
  MultiValueGenericProps,
  Props,
  components,
} from "react-select";
import { CaretDown, XCircle } from "phosphor-react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import AddItemOption from "./AddItemOption";
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";
import styles from "./styles";
import { useTranslation } from "react-i18next";

export interface SelectComponentProps extends Props {
  onAddItem?: (id: string | null) => void;
  isAddNew?: boolean;
  addItemText?: string;
  isLoading?: boolean;
}

const MultiValueRemove = ({ innerProps }: MultiValueGenericProps) => (
  <Box {...innerProps}>
    <XCircle weight="fill" size="16px" color={fT.colors.primary.base} />
  </Box>
);

const DropdownIndicator = () => (
  <Box pr={2}>
    <CaretDown weight="fill" />
  </Box>
);

const SelectComponent = ({
  onAddItem,
  isAddNew = true,
  addItemText,
  isLoading = false,
  ...rest
}: SelectComponentProps) => {
  const { t } = useTranslation();

  const handleMultiValueClick = (e, { data }) => {
    e.preventDefault();
    onAddItem(data.value);
  };

  const MultiValueLabel = (props: MultiValueGenericProps) => (
    <div onClick={e => handleMultiValueClick(e, props)}>
      <components.MultiValueLabel {...props} />
    </div>
  );

  const MenuList = (innerProps: MenuListProps) => (
    <Box>
      {isAddNew && (
        <AddItemOption
          addItemText={addItemText}
          onAddItem={() => onAddItem(null)}
        />
      )}
      <Box
        as={components.MenuList}
        {...innerProps}
        sx={{
          "&::-webkit-scrollbar": {
            backgroundColor: "#0000",
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#0000",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: fT.colors.label.secondary,
            borderRadius: "10px",
          },
        }}
      />
    </Box>
  );

  const noOptionsMessage = () => {
    return (
      <Flex align="center" justify="center">
        {isLoading ? (
          <>
            <Spinner size="sm" mr={2} />
            <Text>{t("actions.loading")}</Text>
          </>
        ) : (
          <Text>{t("event_tools.no_options")}</Text>
        )}
      </Flex>
    );
  };

  return (
    <Select
      components={{
        MenuList,
        DropdownIndicator,
        MultiValueRemove,
        MultiValueLabel,
      }}
      styles={styles}
      maxMenuHeight={130}
      isClearable={false}
      aria-label="select"
      noOptionsMessage={noOptionsMessage}
      {...rest}
    />
  );
};

export default SelectComponent;
