import React from "react";
import { render } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import Card from "./Card";
expect.extend(toHaveNoViolations);

const props = {
  active: false,
};

describe("DC Theme Card", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<Card {...props}></Card>);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });
});
