import React from 'react'
import { render, getByRole, waitFor } from '@testing-library/react'
import userEvent from '@testing-library/user-event'
import '@testing-library/jest-dom'
import { axe, toHaveNoViolations } from 'jest-axe'
import SearchInput from './SearchInput'
import { SearchInputProps } from './types'
expect.extend(toHaveNoViolations)

const defaultProps: SearchInputProps = {
  label: "Label",
  placeholder: "placeholder",
  searchHandler: jest.fn()
}

afterEach(() => {
  jest.clearAllMocks()
})

describe('Search Input Component', () => {
  test('has no accessibility violations', async () => {
    const { container } = render(<SearchInput {...defaultProps} />)
    const results = await axe(container)

    expect(results).toHaveNoViolations()
  })

  describe('on change', () => {
    test('debounces the requests', async () => {
      jest.useFakeTimers();
      const { container } = render(<SearchInput {...defaultProps} />);
      const searchInput = getByRole(container, 'textbox');

      userEvent.type(searchInput, '{a>2}{b}{c}');
      expect(defaultProps.searchHandler).not.toHaveBeenCalledWith('aabc');

      jest.runAllTimers()
      await waitFor(
        () => expect(defaultProps.searchHandler).toHaveBeenCalledWith('aabc'),
      )
    })
  })
})
