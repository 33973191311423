import React from "react";

import FormPageLayout from "../../../../../common/FormPageLayout";
import { useEvent } from "../../../../../pages/Event";
import SupportEmailForm from "../../../../../forms/SupportEmailForm";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";
import { useWebinarFormActions } from "../../../../hooks/useWebinarFormActions";

const SupportEmail: React.FC = (): JSX.Element => {
  const { event } = useEvent();

  const { getStepDetailsByRoute, updateEvent, navigateTo } =
    useWebinarFormActions();

  const stepDetails = [
    getStepDetailsByRoute("supportEmail"),
    getStepDetailsByRoute("metadata"),
  ];

  return (
    <FormPageLayout
      event={event}
      stepDetails={stepDetails}
      update={updateEvent}
      previousForm={navigateTo.footer}
      exitForm={navigateToEventList}
      nextForm={navigateTo.metadata}
      previewVariant="None"
    >
      {props => <SupportEmailForm event={event} {...props} />}
    </FormPageLayout>
  );
};

export default SupportEmail;
