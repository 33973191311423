import * as yup from "yup";

export default t => {
  return yup.object({
    footerText: yup
      .string()
      .required(
        t("event_tools.validations.required", {
          field: t("footer_form.footer_text.label"),
        })
      )
      .typeError(
        t("event_tools.validations.required", {
          field: t("footer_form.footer_text.label"),
        })
      ),
  });
};
