import React from "react";
import { act } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import WebinarTypeSelecting, { WebinarTypeSelectingProps } from "./WebinarTypeSelecting";
import { FAKE_EVENT, render } from "../../../../EventWizard/test-utils";

expect.extend(toHaveNoViolations);

const requiredProps: WebinarTypeSelectingProps = {
  webinar: FAKE_EVENT,
  submitForm: () => { return {} }
};

describe("WebinarTypeSelecting", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<WebinarTypeSelecting {...requiredProps} />);
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });
});
