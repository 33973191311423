import React from "react";
import { Tooltip } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

type DCTooltipProps = {
  label: string;
};

const DCTooltip: React.FC<DCTooltipProps> = ({label}) => {
  return(
    <Tooltip hasArrow arrowSize={15} label={label} aria-label='A tooltip' gutter={20} placement='right'>
      <InfoIcon />
    </Tooltip>
  );
};

export default DCTooltip;
