import React from "react";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useTranslation } from "react-i18next";

type DCDatePickerHeaderProps = {
  monthDate: Date;
  decreaseMonth: () => null;
  increaseMonth: () => null;
};
const DCDatePickerHeader: React.FC<DCDatePickerHeaderProps> = ({monthDate, decreaseMonth, increaseMonth}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className='react-datepicker__title'>
      <button
        aria-label={t('events.form.date.prev_month')}
        className={
          "react-datepicker__navigation react-datepicker__navigation--previous"
        }
        onClick={decreaseMonth}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
          }
        >
          <CaretLeft size={16} weight="fill" />
        </span>
      </button>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString(i18n.language, {
          month: "long",
          year: "numeric",
        })}
      </span>
      <button
        aria-label={t('events.form.date.next_month')}
        className={
          "react-datepicker__navigation react-datepicker__navigation--next"
        }
        onClick={increaseMonth}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
          }
        >
          <CaretRight size={16} weight="fill" />
        </span>
      </button>
    </div>
  );
};

export default DCDatePickerHeader;
