import React from "react";
import { render } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import SingleSelect from './SingleSelect';
import { FieldPropsSource, ResultsProps } from "../../types";
import { noop } from "../../../../utils";
expect.extend(toHaveNoViolations);

const results: ResultsProps = {
  "Option A": 21,
  "Option B": 83,
  "Option C": 12,
};

const totalVotes: number = Object.values(results).reduce(
  (sum: number, n: number) => sum + n
);

const field: FieldPropsSource = {
  id: 1,
  type: "additional_field",
  attributes: {
    id: 1,
    klassId: 1,
    fieldType: "single_select",
    fieldLabel: "A sample poll!",
    fieldRequired: false,
    options: Object.keys(results).join(","),
    updatedAt: null,
    deletedAt: null,
    createdAt: null,
  },
};

const props = {
  results: results,
  totalVotes: totalVotes,
  onChange: noop,
  existingResponse: Object.keys(results)[0],
  field: field,
  showResults: false,
  disabled: false,
};

describe("SingleSelect", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(<SingleSelect {...props} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });
});
