import React from "react";
import { Text, Flex, Button, useRadio } from "@chakra-ui/react";
import { UseRadioProps } from "@chakra-ui/radio/src/use-radio";
interface Props extends UseRadioProps {
  children: string;
}

const ColorRadioButton = ({children, ...rest}: Props): JSX.Element => {
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  return (
    <Flex as="label" flexDir="column" alignItems="center" mx="10px">
      <input {...getInputProps()} />
      <Button
        {...getCheckboxProps()}
        as="div"
        variant="colorPreview"
        bg={String(rest.value)}
        w="45px"
        h="45px"
      />
      <Text fontSize="14px">{children}</Text>
    </Flex>
  );
};

export default ColorRadioButton;
