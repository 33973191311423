import { Button, ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import dcTheme from "../../react/dcTheme";
import { dumbPaginate, PaginationProps } from './paginate';
import styled from 'styled-components';
import { DCPaginationProps } from './types';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const Card = styled.div`
  background: transparent;
  border: 0;
  display: inline-block;
  border-radius: 3px;
  margin-bottom: 4px;
`;

const DCPaginator = ({
  handlePageSelection,
  currentPage,
  totalPages,
  limitValue,
  nextPage,
  prevPage
}: DCPaginationProps) => {
  const { t } = useTranslation()
  if (totalPages < 2) return (null);

  const rangeButton = (activePageNumber: number, pageNumbers: number[]) => {
    if (activePageNumber < 2) return null;

    if (!pageNumbers.includes(activePageNumber - 1)) {
      return (
        <Button variant='paginationRange' m={2} key={`range-before-${activePageNumber}`}>
          {'...'}
        </Button>
      )
    } else if (!pageNumbers.includes(activePageNumber + 1) && (activePageNumber != Math.max(...pageNumbers))) {
      <Button variant='paginationRange' m={2} key={`range-after-${activePageNumber}`}>
        {'...'}
      </Button>
    }
  }

  const handleButtonClick = (pageNumber: number) => {
    if (pageNumber != currentPage) {
      handlePageSelection(pageNumber)
    }
  }

  const buildButtons = (paginationData: PaginationProps) => {
    const { pageNumbers } = paginationData;

    return pageNumbers.map((pageNumber: number, index: number) => {
      const variant = pageNumber === currentPage ? 'paginationActive' : 'paginationGrey';

      return (
        <React.Fragment key={index}>
          {rangeButton(pageNumber, pageNumbers)}
          <Button variant={variant} m={2} key={`page-number-${pageNumber}`} data-testid={`page-number-${pageNumber}`} onClick={() => { handleButtonClick(pageNumber) }}>
            {pageNumber}
          </Button>
        </React.Fragment>
      )
    })
  }

  const basicPagination = dumbPaginate(currentPage, limitValue, totalPages);
  const pageNumberButtons = buildButtons(basicPagination);

  return (
    <ChakraProvider theme={dcTheme}>
      <Card data-testid='dc-paginator'>
        <Button variant='paginationGrey' data-testid='paginate-prev-btn' m={2} onClick={() => { handleButtonClick(currentPage - 1) }} disabled={!prevPage} aria-label={t('views.pagination.next_item')}>
          <ChevronLeftIcon />
        </Button>
        {pageNumberButtons}
        <Button variant='paginationGrey' data-testid='paginate-next-btn' m={2} onClick={() => { handleButtonClick(currentPage + 1) }} disabled={!nextPage} aria-label={t('views.pagination.previous_item')}>
          <ChevronRightIcon />
        </Button>
      </Card>
    </ChakraProvider>
  )
}

export default DCPaginator
