import React, { useEffect, useState } from "react";
import AgendaView from "../../../Agenda"
import TalkService from "../../../../EventWizard/services/talkService";
import AppAdapter from "../../../../../utils/adapter";
import { useEvent } from "../../../pages/Event/index";

const WebinarAgenda: React.FC = () => {

  const { event } = useEvent();
  const [talks, setTalks] = useState([])

  async function fetchTalks() {
    const payload = await TalkService.list(event.id);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, { transform: TalkService.transform });
      const tempTalks = response.map((talk) => {
        talk.displayDate = event.startDate
        talk.start = talk.startAt
        talk.end = talk.endAt
        talk.location = event.location
        return talk
      })
      setTalks(tempTalks);
    }
  }

  useEffect(() => {
    fetchTalks();
  }, []);

  return(
    <AgendaView events={talks} initialDate={event.startDate} locale="en"/>
  )
};

export default WebinarAgenda;
