import * as yup from "yup";
import { timeDiffValidator } from "../../../EventWizard/helpers/customValidators";

export default t => {
  return yup.object({
    name: yup.string()
      .required(t("event_tools.validations.required", { field: t("event_details.name.label") }))
      .min(3, t("event_details.name.too_short"))
      .max(348, t("event_details.name.too_long")),
    startDate: yup.date()
      .required(t("event_tools.validations.required", { field: t("event_details.date.start_field_name") })),
    endDate: yup.date()
      .required(t("event_tools.validations.required", { field: t("event_details.date.end_field_name") }))
      .typeError(t("event_tools.validations.required", { field: t("event_details.date.end_field_name") })),
    startTime: yup.string()
      .required(t("event_tools.validations.required", { field: t("event_details.time.start_field_name") })),
    endTime: yup.string()
      .required(t("event_tools.validations.required", { field: t("event_details.time.end_field_name") }))
      .test("afterStart", t('event_details.time.invalid_length'), timeDiffValidator),
    timeZone: yup.string()
      .required(t("event_tools.validations.required", { field: t("event_details.timezone.label") }))
  });
};
