import React from 'react';

export const CommandIcon = () => (
  <svg
    width="8"
    height="16"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.63636 0H8L5.09091 5.81818H8L2.54545 16L3.63636 8.72727H0L3.63636 0Z"
      fill="white"
    />
  </svg>
);
