import React from "react"

const RequiredLabelPrefix = () => {
  return (
    <abbr title="required">
      *
    </abbr>
  );
}

export default RequiredLabelPrefix;
