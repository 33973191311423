import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const popoverTheme: ComponentStyleConfig = {
  parts: ["popover", "trigger", "content"],
  baseStyle: {},
  variants: {
    timepicker: {
      content: {
        width: "336px",
        background: "#FFFFFF",
        border: "1px solid #FFFFFF",
        boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "0",
        _focus: {
          boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
          borderColor: "#FFFFFF"
        }
      }
    },
    agenda: {
      content: {
        width: "350px",
        background: "#FFFFFF",
        border: "1px solid #CCCCCC",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        _focus: {
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          borderColor: "#CCCCCC"
        }
      }
    }
  }
};
