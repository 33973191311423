import React, { MutableRefObject, useEffect } from "react";
import moment from 'moment';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Flex,
  Input,
  Textarea,
  Divider,
  Heading,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "../../common/shared/FormControlLabel";
import { SessionType } from "../../../EventWizard/models/session";
import DCTimePicker from "../../common/DCTimePicker";
import DCFileUploader from "../../DCFileUploader";
import { useSchema } from "../../../helpers/useSchema";
import schemaGenerator from "./formSchema";

export type SessionFormProps = {
  day: string;
  session: SessionType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
}

export const SessionForm: React.FC<SessionFormProps> = ({
  day,
  session,
  submitForm,
  handleErrors,
  formErrors
}) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const defaultValues = (({ name, description, time, duration, indexImage }) => ({ name, description, time, duration, indexImage }))(session);

  const { register, setValue, trigger, handleSubmit, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });

  formErrors.current = errors;

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  const onChangeTimes = (e) => {
    setValue("time", e.startTime);
    setValue("duration", calculateDuration(e.startTime, e.endTime));
    trigger(["time"]);
  };

  const setFile = (file: File) => {
    setValue('indexImage', [file]);
  };


  const calculateDuration = (startTime: Date, endTime: Date): number => {
    const start =  moment(startTime, "h:mm a");
    const end = moment(endTime, "h:mm a");
    return start.diff(end, "minutes");
  };

  const calculateEndTimeFromDuration = (startTime: Date, duration: number): Date => {
    if (!startTime || !duration) return new Date();

    const start = moment(startTime, "h:mm a");
    return moment(start).add(duration, 'minutes').toDate();
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormControlLabel
        label={t("session_form.name.label")}
        error={errors.name}
        isRequired
      >
        <Input {...register("name")} />
      </FormControlLabel>
      <FormControlLabel
        label={t("session_form.description.label")}
        error={errors.description}
        isRequired
      >
        <Textarea {...register("description")} />
      </FormControlLabel>

      <Divider my={4}/>

      <Heading size="sm" mb={3}>{t("session_form.schedule_session_header")}</Heading>
      <Flex>
        <FormControlLabel
          label={t("session_form.day.label")}
        >
          <Input
            value={day}
            name="day"
            width={"50%"}
            disabled
          />
        </FormControlLabel>

        <FormControlLabel
          label={t("session_form.time.label")}
          error={errors.time}
          isRequired
        >
          <DCTimePicker
            isError={!!errors?.time}
            handleChange={onChangeTimes}
            htmlId={"session-time"}
            format24
            initialStartTime={session.time}
            initialEndTime={calculateEndTimeFromDuration(session.time, session.duration)}
          />
        </FormControlLabel>
      </Flex>

      <Divider my={4}/>

      <Heading size="sm" mb={3}>{t("session_form.indexImage.header")}</Heading>
      <FormControlLabel
        name="headerBannerImage"
        label={t("session_form.indexImage.label")}
        helperText={t("session_form.indexImage.hint")}
      >
        <DCFileUploader
          acceptedFormats="image/*"
          aspectRatio={1}
          maxSize={10000000}
          uploadPhoto={setFile}
          source={session.indexImageUrl}
        />
      </FormControlLabel>
    </form>
  );
};

export default SessionForm;
