import React from 'react';
import { FAKE_TENANT_PROP, FAKE_USER, render, RenderResult } from '../../test-utils';

import { AuthProvider, AuthProviderProps } from '../AuthProvider';

describe('AuthProvider', () => {
  const requiredProps: AuthProviderProps = {
    user: FAKE_USER,
    tenant: FAKE_TENANT_PROP,
    children: <div>{'RENDERED_TEXT'}</div>,
  };

  const renderWithProps = (props: AuthProviderProps): RenderResult => render(<AuthProvider {...props} />);

  it('renders', () => {
    const { getByText } = renderWithProps(requiredProps);

    expect(getByText('RENDERED_TEXT')).toBeTruthy();
  });
});
