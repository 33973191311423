import React from 'react';

import type { MouseEventHandler } from 'react';
import { ChannelList, ChannelListProps } from 'stream-chat-react';
import { MessagingChannelListHeader, MessagingChannelPreview } from '../index';
import type { ThemeClassName } from '../../hooks';

type MessagingSidebarProps = {
  channelListOptions: {
    filters: ChannelListProps['filters'];
    sort: ChannelListProps['sort'];
    options: ChannelListProps['options'];
  };
  onCreateChannel: () => void;
  onPreviewSelect: MouseEventHandler;
  theme: ThemeClassName;
  setIsChannelSetManually: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMobile: () => void;
};

const MessagingSidebar = ({
  channelListOptions,
  onCreateChannel,
  onPreviewSelect,
  theme,
  toggleMobile,
  setIsChannelSetManually,
}: MessagingSidebarProps) => {
  return (
    <div
      className={`str-chat messaging__sidebar ${theme}`}
      id="mobile-channel-list"
    >
      <MessagingChannelListHeader
        onCreateChannel={onCreateChannel}
        toggleMobile={toggleMobile}
      />
      <ChannelList
        {...channelListOptions}
        Preview={(props) => (
          <MessagingChannelPreview
            {...props}
            onClick={onPreviewSelect}
            setIsChannelSetManually={setIsChannelSetManually}
          />
        )}
      />
    </div>
  );
};

export default MessagingSidebar;
