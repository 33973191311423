import React from "react";
import { render } from "@testing-library/react";
import "@testing-library/jest-dom";
import SectionIntro from "./SectionIntro";
import { axe, toHaveNoViolations } from "jest-axe";
expect.extend(toHaveNoViolations);

import sessionIntroImage from "../../../EventWizard/assets/images/session-intro.svg";

describe("SectionIntro", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(
      <SectionIntro
        imageSrc={sessionIntroImage}
        onClickNext={() => {
          // Do Nothing
        }}
        textContent={() => (
          <p>Example Text Content</p>
        )}
      />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });
});
