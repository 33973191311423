import React, { useEffect, useState } from "react";
import {
  Heading,
  Text,
  Box,
  Flex,
  Spacer,
  Button,
  useMultiStyleConfig,
  TextProps,
  FlexProps,
} from "@chakra-ui/react";
import { Plus } from "phosphor-react";
import EventCard from "./EventCard";
import { EventType } from "../../../EventWizard/models/Event";
import { useAuth } from "../../../EventWizard/auth/useAuth";
import AppAdapter from "../../../../utils/adapter";
import EventService from "../../../EventWizard/services/eventService";
import { useTranslation } from "react-i18next";
import UserType from "../../../EventWizard/models/User";
import CreateFirstEventCard from "./CreateFirstEventCard";

const EventsList: React.FC = () => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig("EventsList", {});
  const { currentUser } = useAuth();

  const [publishedEvents, setPublishedEvents] = useState<Array<EventType>>([]);
  const [draftEvents, setDraftEvents] = useState<Array<EventType>>([]);
  const [welcomeText, setWelcomeText] = useState(
    t("event_tools.loading_events", {
      event_type: t("event_tools.webinars").toLocaleLowerCase(),
    }).toString()
  );
  const [creating, setCreating] = useState(false);
  const [noEventsCheck, setNoEventsCheck] = useState<boolean>(false);

  const getWelcomeText = (
    currentUser: UserType,
    eventCount: number
  ): string => {
    if (!currentUser) return "";

    if (eventCount === 1) {
      return t("event_tools.welcome_message.single", {
        first_name: currentUser.firstName,
        event_type: t("events.types.webinar").toLocaleLowerCase(),
      });
    } else {
      return t("event_tools.welcome_message.multiple", {
        first_name: currentUser.firstName,
        count: eventCount,
        event_type: t("event_tools.webinars").toLocaleLowerCase(),
      });
    }
  };

  async function fetchEvents() {
    const payload = await EventService.list();
    let nextPublishedEvents = [];
    let nextDraftEvents = [];
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result);
      nextDraftEvents = response.filter((e: EventType) => !e.public);
      nextPublishedEvents = response.filter((e: EventType) => e.public);
      setDraftEvents(nextDraftEvents);
      setPublishedEvents(nextPublishedEvents);
      const numberOfEvents = nextDraftEvents.length + nextPublishedEvents.length
      setWelcomeText(
        getWelcomeText(
          currentUser,
          numberOfEvents
        )
      );
      if (numberOfEvents === 0) {
        setNoEventsCheck(true);
      }
    } else {
      setWelcomeText(
        t("event_tools.welcome_message.error", {
          first_name: currentUser.firstName,
        })
      );
    }
  }

  useEffect(() => {
    fetchEvents();
  }, []);

  const createEvent = async () => {
    setCreating(true);
    const payload = await EventService.createEvent();
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result);
      location.href = `/admin/event-tools/${response.id}`;
    } else {
      alert("something went wrong");
      setCreating(false);
    }
  };

  if (!currentUser) return null;

  return (
    <Box>
      <Flex {...(styles.headingContainer as FlexProps)}>
        <Heading {...(styles.headingText as TextProps)}>
          {t("event_tools.webinars")}
        </Heading>
      </Flex>
      {noEventsCheck ? (
        <CreateFirstEventCard
          userFirstName={currentUser.firstName}
          onCreateEvent={createEvent}
          isCreatingEvent={creating}
        />
      ) : (
        <>
          <Flex {...(styles.subHeadingContainer as FlexProps)}>
            <Text {...(styles.subHeadingText as TextProps)}>{welcomeText}</Text>
            <Spacer />
            <Button
              isLoading={creating}
              loadingText={t("actions.creating")}
              onClick={createEvent}
              leftIcon={<Plus weight="fill" size="20" />}
              variant="secondary"
            >
              {t("actions.create_webinar")}
            </Button>
          </Flex>
          {draftEvents.length > 0 && (
            <Flex {...(styles.cardContainer as FlexProps)}>
              {draftEvents.map(event => (
                <EventCard event={event} key={event.id} />
              ))}
            </Flex>
          )}
          {publishedEvents.length > 0 && (
            <Flex {...(styles.cardContainer as FlexProps)}>
              {publishedEvents.map(event => (
                <EventCard event={event} key={event.id} />
              ))}
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default EventsList;
