import * as React from 'react';
import GroupRegistrationForm from './GroupRegistrationForm';

import {
  GroupRegistrationPageProps,
  GroupRegistrationRecord,
} from "./types";

const GroupRegistrationPage = ({ groupRegistrationId, groupRegistrationRecords, delegateGroupsAndTypes, currencyCode }: GroupRegistrationPageProps) => {
  const initialRecord: GroupRegistrationRecord = {
    positionId: 0,
    recordId: '',
    firstName: '',
    lastName: '',
    email: '',
    delegateTypeId: undefined,
    addonIds: []
  };

  const registrationRecords: GroupRegistrationRecord[] = groupRegistrationRecords || [];
  if (groupRegistrationRecords.length == 0) {
    groupRegistrationRecords.push(initialRecord);
  }

  return (
    <>
      <GroupRegistrationForm
        groupRegistrationId={groupRegistrationId}
        registrationRecords={registrationRecords}
        delegateGroupsAndTypes={delegateGroupsAndTypes}
        currencyCode={currencyCode}
      />
    </>
  );
};

export default GroupRegistrationPage;
