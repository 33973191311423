import * as yup from "yup";

export default t => {
  return (yup.object({
    customHomepageSections: yup.array().of(
      yup.object({
        title: yup.string().required(t("event_tools.validations.required", { field: t("events.form.home_page.add_sections.section_title.label") })),
        description: yup.string().required(t("event_tools.validations.required", { field: t("events.form.home_page.add_sections.section_description.label") }))
      })
    )
  }));
};
