import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { figmaTheme as fT } from "../figmaTheme";

export const menuTheme: ComponentStyleConfig = {
  parts: ['menu', 'button', 'list', 'item'],
  baseStyle: {
    button: {
      textAlign: "left",
      height: 10,
      fontSize: "14px",
      p: 4,
      background: fT.colors.white,
      borderRadius: "lg",
      w: "336px",
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: fT.colors.label.tertiary,
      color: fT.colors.label.secondary,
      _hover: {
        bg: fT.colors.white,
        borderColor: fT.colors.skyBlue.base,
      },
      _active: {
        bg: fT.colors.white
      },
      _focus: {
        outline: 'none',
        borderColor: fT.colors.primary.base
      }
    },
    list: {
      minW: "336px",
      boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: "lg",
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: fT.colors.white,
      padding: "0",
      maxH: "300px",
      overflow: 'auto'
    },
    item: {
      w: "336px",
      p: 4,
      _hover: {
        bg: fT.colors.primary[90],
        color: "initial",
      },
      _focus: {
        bg: fT.colors.primary[90],
        color: "initial",
      }
    },
  },
  variants: {
    isOpen: {
      button: {
        borderColor: fT.colors.primary.base,
        color: fT.colors.label.primary,
      }
    },
    isSelected: {
      button: {
        color: fT.colors.label.primary
      }
    },
    isError: {
      button: {
        borderColor: fT.colors.alert
      }
    }
  }
};
