import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import DCDateInput from "../shared/DCDateInput";
import '../shared/DCDatePicker.css';
import DCDatePickerHeader from "../shared/DCDatePickerHeader";
import { noop } from "../../../../../utils";

type SingleDatePickerProps= {
  isError: boolean;
  onChange: (date: string) => void;
  initialDate: string | Date;
  onOpen?: () => void;
  onClose?: () => void;
}

const SingleDatePicker: React.FC<SingleDatePickerProps> = ({
  isError,
  onChange,
  initialDate,
  onOpen = noop,
  onClose = noop,
}) => {
  const [date, setDate] = useState(initialDate);
  const datepickerRef = useRef(null);
  const { t } = useTranslation();

  const placeholder = t('events.form.date.select_date');

  const handleChange = (date: string) => {
    setDate(date);
    onChange(date);
  };

  const clearDates = () => {
    setDate(null);
    onChange(null);
  };

  const closeDatePicker = () => {
    if(!datepickerRef) return;

    datepickerRef.current.setOpen(false);
  };

  return (
    <DatePicker
    ref={datepickerRef}
    onCalendarClose={onClose}
    onCalendarOpen={onOpen}
    customInput={<DCDateInput onClick={noop} placeholder={placeholder} value={datepickerRef.current?.value} isInvalid={isError} />}
      renderCustomHeader={({
        monthDate,
        decreaseMonth,
        increaseMonth,
      }) => (
        <DCDatePickerHeader monthDate={monthDate} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
      )}
      showPopperArrow={false}
      placeholderText={placeholder}
      dateFormat="dd MMMM yyyy"
      formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
      selected={date}
      onChange={handleChange}
      shouldCloseOnSelect={false}
    >
      <div className='picker-actions'>
        <button className='clear-btn' onClick={() => clearDates()}>{t('event_tools.buttons.clear')}</button>
        <button className='done-btn' onClick={() => closeDatePicker()}>{t('event_tools.buttons.done')}</button>
      </div>
    </DatePicker>
  );
};

export default SingleDatePicker;
