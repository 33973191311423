import React from "react";

const RequiredLabelPrefix = (): JSX.Element => {
  return (
    <abbr title="required">
      *
    </abbr>
  );
};

export default RequiredLabelPrefix;
