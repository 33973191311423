import { icons } from './icons';
import { IconEntry } from './types';

function match(string: string, icon: IconEntry) {
  return icon.name.includes(string) || icon.tags.some(tag => tag.includes(string));
}

export function search(string: string, { limit = 100 }) {
  const results = [];

  if (!string) {
    return icons.slice(0, limit);
  } else {
    for (let i = 0; i < icons.length; i++) {
      const icon = icons[i];

      if (match(string, icon)) results.push(icon);
      if (results.length == limit) break;
    }
  }
  return results;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getByName(name: string): any {
  return icons.find(i => i.name == name) || { name: name, Icon: () => '' };
}
