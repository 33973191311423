import React, { ReactNode, useRef } from "react";
import { Flex, useDisclosure, Spacer, Hide } from "@chakra-ui/react";
import PageLayout from "../../../common/PageLayout";
import { BottomFormButtons } from "../../../common/FormPageLayout/BottomFormButtons";
import { EventType } from "../../../../EventWizard/models/Event";
import DCModal from "../../../common/DCModal";
import { useTranslation } from "react-i18next";
import { usePageStatus } from "../../../pages/Event/Event";

type FormPageLayoutProps = {
  event: EventType;
  update: (data: unknown) => Promise<boolean>;
  previousForm?: () => void;
  exitForm: () => void;
  nextForm: () => void;
  children: (Function) => ReactNode;
  pageType?: "form" | "agenda";
};

const FormPageLayout: React.FC<FormPageLayoutProps> = ({
  event,
  update,
  previousForm,
  exitForm,
  nextForm,
  children,
  pageType = "form",
}) => {
  const { t } = useTranslation();

  const submitForm = useRef(null);
  const handleErrors = useRef(null);
  const formErrors = useRef(null);
  const {
    onOpen: onExitModalOpen,
    isOpen: isExitModalOpen,
    onClose: onExitModalClose,
  } = useDisclosure();
  const { updateProgress } = usePageStatus();

  const saveAndContinue = async () => {
    try {
      if (submitForm.current) {
        await submitForm.current(update)();
        if (Object.keys(formErrors.current).length === 0) {
          updateProgress();
          nextForm();
        }
      } else {
        updateProgress();
        nextForm();
      }
    } catch (errors) {
      for (const name in errors) {
        handleErrors.current(name, errors[name]);
      }
    }
  };

  const saveAndExit = async () => {
    try {
      if (submitForm.current) {
        await submitForm.current(update)();
        if (Object.keys(formErrors.current).length === 0) {
          exitForm();
        } else {
          onExitModalOpen();
        }
      }else{
        exitForm();
      }
    } catch (errors) {
      for (const name in errors) {
        handleErrors.current(name, errors[name]);
      }
    }
  };

  return (
    <PageLayout event={event}>
      <Flex flexDir="column" grow={1}>
        <Flex flexDir="column" justify="space-between" grow={1}>
          <Flex grow={1}>
            <Flex px={10} overflow="auto" grow={1} flexDir="column">
              {children({ submitForm, handleErrors, formErrors })}
            </Flex>
            {pageType == "form" && (
              <Hide below="lg">
                <Spacer minW="350px" />
              </Hide>
            )}
          </Flex>
          <BottomFormButtons
            previousLink={previousForm}
            saveAndContinue={saveAndContinue}
            saveAndExit={saveAndExit}
          />
        </Flex>
      </Flex>
      <DCModal
        title={t("save_and_exit_modal.title")}
        content={t("save_and_exit_modal.body")}
        primaryButtonLabel={t("save_and_exit_modal.primary_button")}
        secondaryButtonLabel={t("save_and_exit_modal.secondary_button")}
        isOpen={isExitModalOpen}
        onClose={onExitModalClose}
        onClickPrimary={exitForm}
        onClickSecondary={onExitModalClose}
      />
    </PageLayout>
  );
};

export default FormPageLayout;
