import React from "react";
import { render, fireEvent, screen } from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { ThemeProvider } from "@chakra-ui/react";

import dcTheme from "../../react/dcTheme";
import DCFileUploader from "./DCFileUploader";
expect.extend(toHaveNoViolations);

interface Props {
  acceptedFormats: string;
  aspectRatio: number;
  maxSize: number;
  uploadPhoto: (file: Blob) => void;
  source: string;
}

function dummyfcn(file) {
  console.log(file);
}

const props: Props = {
  acceptedFormats: "image/*",
  aspectRatio: 1,
  maxSize: 500000,
  uploadPhoto: dummyfcn,
  source: "",
};

describe("DCFileUploader", () => {
  test("has no accessibility violations", async () => {
    const { container } = render(
      <ThemeProvider theme={dcTheme}>
        <DCFileUploader {...props} />
      </ThemeProvider>
    );

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  beforeEach(() => jest.clearAllMocks());

  test("should receive dropped file and show crop button", async () => {
    render(
      <ThemeProvider theme={dcTheme}>
        <DCFileUploader {...props} />
      </ThemeProvider>
    );
    window.URL.createObjectURL = jest.fn().mockImplementation(() => "url");
    const inputEl = screen.getByRole("button");
    const file = new File(["file"], "ping.jpeg", {
      type: "image/jpeg",
    });
    Object.defineProperty(inputEl, "files", {
      value: [file],
    });
    fireEvent.drop(inputEl);
    expect(
      await screen.findByText("events.form.file_uploader.crop_image")
    ).toBeInTheDocument();
  });

  test("should receive dropped file and show error if wrong filetype and size", async () => {
    render(
      <ThemeProvider theme={dcTheme}>
        <DCFileUploader {...props} />
      </ThemeProvider>
    );
    window.URL.createObjectURL = jest.fn().mockImplementation(() => "url");
    const inputEl = screen.getByRole("button");
    const file = new File(["file"], "ping.json", {
      type: "application/json",
    });
    Object.defineProperty(file, "size", { value: 500001 });
    Object.defineProperty(inputEl, "files", {
      value: [file],
    });

    fireEvent.drop(inputEl);
    expect(
      await screen.findByText(/events.form.file_uploader.file_type_validation/)
    ).toBeInTheDocument();
    expect(
      await screen.findByText(/events.form.file_uploader.file_size_validation/)
    ).toBeInTheDocument();
  });
});
