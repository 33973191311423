import React from "react";
import { ChakraProvider } from "@chakra-ui/react";

import { AuthProvider } from "../EventWizard/auth/AuthProvider";
import dcTheme from "../react/dcTheme";
import { Snakecase_UserType } from "../EventWizard/models/User";
import { Snakecase_TenantType } from "../EventWizard/models/Tenant";
import EventsList from "../EventTools/pages/EventsList";

type EventIndexProps = {
  user: Snakecase_UserType;
  tenant: Snakecase_TenantType;
};

const EventIndex: React.FC<EventIndexProps> = ({ user, tenant }) => {
  return (
    <AuthProvider user={user} tenant={tenant}>
      <ChakraProvider theme={dcTheme}>
        <EventsList />
      </ChakraProvider>
    </AuthProvider>
  );
};

export default EventIndex;
