import { mapKeys, camelCase, rearg } from 'lodash';

type AcceptInviteProps = {
  invitationToken: string
  firstName?: string
  lastName?: string
  password: string
  passwordConfirmation: string
}

type AcceptInviteResponse = {
  success: boolean
  redirect?: string
  errors?: Record<string, string[]>
}

export const acceptInvite = async ({
  invitationToken,
  firstName,
  lastName,
  password,
  passwordConfirmation
}: AcceptInviteProps): Promise<AcceptInviteResponse> => {
  const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
  const requestHeaders = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'X-CSRF-Token': csrf
  };

  const response = await fetch(`/registration/group/invites/${invitationToken}`, {
    method: 'PUT',
    headers: requestHeaders,
    body: JSON.stringify({
      'registration_invite': {
        'first_name': firstName,
        'last_name': lastName,
        'password': password,
        'password_confirmation': passwordConfirmation,
      }
    })
  });

  const responseBody = await response.json();

  return {
    success: responseBody.success,
    redirect: responseBody.redirect,
    errors: mapKeys(responseBody.errors, rearg(camelCase, 1))
  };
};
