import React from "react";
import {
  FAKE_EVENT,
  FAKE_TENANT,
  render,
  screen,
  waitFor,
  fireEvent,
} from "../../../EventWizard/test-utils";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import FooterForm, { FooterFormProps } from "./FooterForm";

expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: FooterFormProps = {
  event: { ...FAKE_EVENT, tenant: FAKE_TENANT },
  submitForm,
  handleErrors,
  formErrors,
};

describe("Footer Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(<FooterForm {...requiredProps} />);

    const results = await axe(container);
    expect(results).toHaveNoViolations();
  });

  describe("footer text", () => {
    test("is required", async () => {
      render(<FooterForm {...requiredProps} />);

      await waitFor(() => {
        const inputLabel = screen.getByLabelText(
          "footer_form.footer_text.label",
          { exact: false }
        );
        fireEvent.change(inputLabel, {
          target: { value: "" },
        });
        fireEvent.blur(inputLabel);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("tenant fields", () => {
    test("are visible when tenantFieldsVisible prop is passed", () => {
      render(<FooterForm {...requiredProps} tenantFieldsVisible />);
      
      expect(
        screen.getByText(/footer_form\.social_sub_title/)
      ).toBeInTheDocument();
    });
    test("are not visible when tenantFieldsVisible prop is not passed", () => {
      render(<FooterForm {...requiredProps} />);

      expect(screen.queryByText(/footer_form\.social_sub_title/)).toBeNull()
    });
  });
});
