import React, { useMemo, useEffect } from "react";
import { Flex, Box, Spacer, useDisclosure, FlexProps } from "@chakra-ui/react";
import { CheckCircle, CaretUp, CaretDown, IconProps } from "phosphor-react";

import { figmaTheme as fT } from "../../../react/dcTheme";
import type { SideNavigationVariants } from "./SideNavigation";
import { setItemColor } from "./utils";

type SideNavigationGroupProps = {
  displayText: string;
  isActive?: boolean;
  isComplete?: boolean;
  iconComponent: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  variant?: SideNavigationVariants;
  children?: React.ReactNode;
};

export const SideNavigationGroup: React.FC<SideNavigationGroupProps> = ({
  displayText,
  isActive,
  isComplete,
  variant = "default",
  children,
  iconComponent: Icon,
}) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  const Caret = isOpen ? CaretUp : CaretDown;
  const flexStyles = useMemo<FlexProps>(() => {
    return {
      color: setItemColor(isActive, variant),
      py: 2,
      my: 4,
      mr: 6,
      align: "center",
    };
  }, [variant, isActive]);

  useEffect(() => isActive ? onOpen() : onClose(), [isActive]);

  return (
    <>
      <Flex
        style={{ cursor: "pointer" }}
        onClick={() => onToggle()}
        {...flexStyles}
      >
        {isComplete ? (
          <CheckCircle weight="fill" size="24" color={fT.colors.success} />
        ) : (
          <Icon weight="fill" size="24" />
        )}
        <Box as="span" ml={3} fontWeight="medium" fontSize="14px">
          {displayText}
        </Box>
        <Spacer />
        {<Caret weight="fill" size="16" />}
      </Flex>
      {isOpen && children}
    </>
  );
};

export default SideNavigationGroup;
