import React from "react";
import { House } from "phosphor-react";
import { render } from "@testing-library/react";
import { screen, fireEvent } from "@testing-library/dom";
import "@testing-library/jest-dom";
import { SideNavigationGroup } from "./SideNavigationGroup";

describe("SideNavigation", () => {
  test("click toggles show/hiding the children", async () => {
    render(
      <SideNavigationGroup
        displayText="Test Group"
        iconComponent={House}
      >
        <div>Test Content</div>
      </SideNavigationGroup>
    );
    const group = screen.getByText("Test Group");
    fireEvent.click(group);

    expect(screen.getByText("Test Content")).toBeInTheDocument();

    fireEvent.click(group);

    expect(screen.queryByText("Test Content")).not.toBeInTheDocument();
  });
});
