import React, { FC } from 'react';
import { Chat } from 'stream-chat-react';
import ChatPage from './ChatPage';
import { useConnectUser, useTheme } from './hooks';
import { useTranslation } from 'react-i18next';
import type { StreamChatGenerics } from './types';

type AppProps = {
  apiKey: string;
  userToConnect: {
    id: string;
    name?: string;
    image?: string;
    language: string;
  };
  userToken: string | undefined;
  team: string;
  chatParticipants: string[];
  channelName: string;
  channelImage: string;
};

const App: FC<AppProps> = ({
  apiKey,
  userToConnect,
  userToken,
  team,
  chatParticipants,
  channelName,
  channelImage,
}) => {
  const theme = useTheme();
  const { i18n } = useTranslation();

  userToConnect.language = i18n.language;

  const chatClient = useConnectUser<StreamChatGenerics>(
    apiKey,
    // @ts-expect-error Stream's user type missing language as a field
    userToConnect,
    userToken
  );

  if (!chatClient) {
    return null;
  }

  return (
    <div className="d-flex chat-container">
      <Chat client={chatClient} theme={`messaging ${theme}`}>
        <ChatPage
          team={team}
          chatParticipants={chatParticipants}
          channelName={channelName}
          channelImage={channelImage}
          userToConnect={userToConnect}
        />
      </Chat>
    </div>
  );
};

export default App;
