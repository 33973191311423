import React from "react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { waitFor, fireEvent, screen, act } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

import {
  FAKE_EVENT,
  FAKE_TENANT,
  render,
} from "../../../EventWizard/test-utils";
import WebinarDetailsForm, { WebinarDetailsProps } from "./WebinarDetailsForm";
expect.extend(toHaveNoViolations);

const submitForm = { current: null };
const handleErrors = { current: null };
const formErrors = { current: null };

const requiredProps: WebinarDetailsProps = {
  event: { ...FAKE_EVENT, tenant: FAKE_TENANT },
  submitForm,
  handleErrors,
  formErrors,
};

const mockData = {
  result: {
    timezones: [
      { name: "Melbourne", offset: '+11' },
      { name: "Perth", offset: '+9' }
    ]
  },
};

jest.mock("../../../EventWizard/services/timezoneService", () => ({
  list: () => Promise.resolve(mockData),
}));

describe("Webinar Details Form", () => {
  test("passes without axe errors", async () => {
    const { container } = render(<WebinarDetailsForm {...requiredProps} />);

    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  describe("name", () => {
    test("is required", async () => {
      render(<WebinarDetailsForm {...requiredProps} />);
      await waitFor(() => {
        const nameInput = screen.getByLabelText(
          "webinar_details_form.name.label",
          {
            exact: false,
          }
        );
        userEvent.clear(nameInput);
        fireEvent.blur(nameInput);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    test("must be atleast 3 characters", async () => {
      render(<WebinarDetailsForm {...requiredProps} />);
      await waitFor(() => {
        const nameInput = screen.getByLabelText(
          "webinar_details_form.name.label",
          {
            exact: false,
          }
        );
        userEvent.clear(nameInput);
        userEvent.type(nameInput, "Hi");
        fireEvent.blur(nameInput);
      });

      expect(
        screen.getByText("event_details.name.too_short")
      ).toBeInTheDocument();
    });

    test("can't be longer than 348 characters", async () => {
      render(<WebinarDetailsForm {...requiredProps} />);
      await waitFor(() => {
        const nameInput = screen.getByLabelText(
          "webinar_details_form.name.label",
          {
            exact: false,
          }
        );
        userEvent.clear(nameInput);
        userEvent.type(nameInput, "X".repeat(349));
        fireEvent.blur(nameInput);
      });

      expect(
        screen.getByText("event_details.name.too_long")
      ).toBeInTheDocument();
    });
  });

  describe("date", () => {
    test("is required", async () => {
      render(<WebinarDetailsForm {...requiredProps} />);
      const dateInput = screen.getByLabelText(
        "webinar_details_form.date.label",
        {
          exact: false,
        }
      );

      await waitFor(() => {
        userEvent.click(dateInput);
        userEvent.click(screen.getByText("event_tools.buttons.clear"));
        fireEvent.blur(dateInput);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });

  describe("start time and end time", () => {
    test("is required", async () => {
      render(<WebinarDetailsForm {...requiredProps} />);
      const timeInput = screen.getByLabelText(
        "webinar_details_form.time.label",
        {
          exact: false,
        }
      );

      await waitFor(() => {
        userEvent.click(timeInput);
        userEvent.click(screen.getByText("event_tools.buttons.clear"));
        fireEvent.blur(timeInput);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });
  });
});
