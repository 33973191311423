import * as yup from "yup";
import { get, filter, isEmpty } from "lodash";
import Lazy from "yup/lib/Lazy";
import { AnyObject, Maybe, Optionals } from "yup/lib/types";

/* eslint-disable @typescript-eslint/no-explicit-any */
declare module 'yup' {
  interface ArraySchema<T extends yup.AnySchema | Lazy<any, any>, C extends AnyObject = AnyObject, TIn extends Maybe<yup.TypeOf<T>[]> = yup.TypeOf<T>[] | undefined, TOut extends Maybe<yup.Asserts<T>[]> = yup.Asserts<T>[] | Optionals<TIn>> extends yup.BaseSchema<TIn, C, TOut> {
    uniqueProperty(field: string, message: string): ArraySchema<T, C>;
  }
}

export const addUniquePropertyValidationToYup = () =>
  yup.addMethod(yup.array, 'uniqueProperty', function (propertyPath: string, message: string) {
    return this.test('unique', '', function (list: any[]) {
      const errors = [];

      list.forEach((item, index) => {
        const propertyValue = get(item, propertyPath);

        if (propertyValue && filter(list, [propertyPath, propertyValue]).length > 1) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${propertyPath}`,
              message,
            })
          );
        }
      });

      if (!isEmpty(errors)) {
        throw new yup.ValidationError(errors);
      }

      return true;
    });
  });
