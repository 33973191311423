import React, { useState } from "react";
import { Box } from "@chakra-ui/layout";
import { Flex, Image, Text, Button, Spacer, useDisclosure } from "@chakra-ui/react";
import { figmaTheme as fT } from "../../../../react/dcTheme";
import { useTranslation } from "react-i18next";
import publishGraphic from "../../../../EventWizard/assets/images/publish-graphic.svg";
import PageLayout from "../../../common/PageLayout";
import { TopNavigation } from "../../../common/FormPageLayout";
import { useEvent } from "../../Event";
import { ArrowLeft } from "phosphor-react";
import EventService from "../../../../EventWizard/services/eventService";
import AppAdapter from '../../../../../utils/adapter';
import DCModal from "../../../common/DCModal";
import { navigateToEventList } from "../../../../EventWizard/helpers/navigation";

const stepDetails = [];

const Publish: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { event } = useEvent();

  const publishEventCheck = async () => {
    const payload = await EventService.publishEventCheck(event.id);
    if (payload.ok) {
      const response = AppAdapter.camelCaseKeys(payload.result);
      if (response.publicEventsPresence) {
        onPublishModalOpen();
      } else {
        publishEvent({ setAsDefault: true });
      }
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const publishEvent = async (data) => {
    const payload = await EventService.publishEvent(event.id, data);
    if (payload.ok) {
      navigateToEventList();
    } else {
      setIsLoading(false);
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const onClickPublish = (data = {}) => {
    setIsLoading(true);
    publishEvent(data);
  };

  const onClickExit = () => navigateToEventList();

  const [isLoading, setIsLoading] = useState(false);

  const {
    onOpen: onPublishModalOpen,
    isOpen: isPublishModalOpen,
    onClose: onPublishModalClose } = useDisclosure();

  return (
    <PageLayout event={event}>
      <Flex flexDir="column" flex="auto">
        <TopNavigation stepDetails={stepDetails} />
        <Box h="80vh">
          <Flex h="100%" justify="center" align="center" direction="column">
            <Image
              src={publishGraphic}
              alt={t("images.image.alt")}
              w="30%"
              maxW="300px"
              mb={10}
            />
            <Box
              w="80%"
              maxW="800px"
              textAlign="center"
              lineHeight="24px"
              fontSize="18px"
            >
              <Text variant="success">{t("publish_intro.line_one")}</Text>
              <br />
              <Text>{t("publish_intro.line_two")}</Text>
            </Box>
          </Flex>
          <Flex
            align="center"
            py={5}
            px={10}
            borderTop="1px"
            borderTopColor={fT.colors.grey.base}
            bg={fT.colors.primary[100]}
          >
            <Button
              m={2}
              variant="ghost"
              leftIcon={<ArrowLeft weight="fill" />}
              onClick={console.log}
            >
              {t("views.pagination.previous_item")}
            </Button>
            <Spacer />
            <Button variant="outline" m={2} onClick={onClickExit}>
              {t("event_tools.buttons.exit")}
            </Button>
            <Button m={2} onClick={publishEventCheck}>
              {t("event_tools.buttons.publish")}
            </Button>
          </Flex>
        </Box>
      </Flex>
      <DCModal
        title={t("publish_modal.title")}
        content={t("publish_modal.body")}
        primaryButtonLabel={t("publish_modal.primary_button")}
        secondaryButtonLabel={t("publish_modal.secondary_button")}
        isOpen={isPublishModalOpen}
        onClose={onPublishModalClose}
        isLoading={isLoading}
        onClickPrimary={() => onClickPublish({ setAsDefault: true })}
        onClickSecondary={() => onClickPublish({ setAsDefault: false })}
      />
    </PageLayout>
  );
};

export default Publish;
