import React from "react";
import FormPageLayout from "../../../../../common/FormPageLayout";
import { useEvent } from "../../../../../pages/Event";
import MetadataForm from "../../../../../forms/MetadataForm";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";
import { useWebinarFormActions } from "../../../../hooks/useWebinarFormActions";

const Metadata: React.FC = (): JSX.Element => {
  const { event } = useEvent();

  const { updateEvent, navigateTo, getStepDetailsByRoute } =
    useWebinarFormActions();

  const stepDetails = [
    getStepDetailsByRoute("supportEmail"),
    getStepDetailsByRoute("metadata"),
  ];

  return (
    <FormPageLayout
      event={event}
      stepDetails={stepDetails}
      update={updateEvent}
      previousForm={navigateTo.supportEmail}
      exitForm={navigateToEventList}
      nextForm={navigateTo.publish}
      previewVariant="None"
    >
      {props => <MetadataForm event={event} {...props} />}
    </FormPageLayout>
  );
};

export default Metadata;
