import * as React from 'react';
import { useState } from 'react';
import { CountryCodeAlpha2, countryListAlpha2 } from '../../helpers/country_lists';
import PaymentSummary from './PaymentSummary';
import { CheckoutFormProps, PaymentType } from '../../GroupRegistration/types';
import { useTranslation } from "react-i18next";
import CheckoutWithStripe from './CheckoutWithStripe';
import { Elements } from '@stripe/react-stripe-js';
import InvoiceForm from './InvoiceForm';
import { Box, ChakraProvider, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { CREDIT_CARD, INVOICE } from '../../GroupRegistration/constants';

const CheckoutForm = ({
  checkoutRedirectUrl,
  editGroupRegistrationUrl,
  verifyPaymentUrl,
  paymentIntentData,
  isLoadingPaymentIntent,
  countryCode,
  stripePromise,
  placeholderReceiptEmail,
  groupRegistrationId,
  paymentMethodName,
  paymentIntentsPath,
  updateReceiptEmailPath,
  hasPayByInvoiceFeature,
  setPaymentMethodName,
  updateCountry,
}: CheckoutFormProps) => {
  const { t } = useTranslation();
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [checkoutError, setCheckoutError] = useState<string>();
  const requiresPayment = !paymentIntentData.paymentSummary.isFree;
  const [paymentType, setPaymentType] = useState<PaymentType>(CREDIT_CARD);

  const updatePaymentType = (paymentType: PaymentType) => {
    const paymentMethodName = paymentType === CREDIT_CARD ? "stripe" : null;

    setPaymentMethodName(paymentMethodName);
    setPaymentType(paymentType);
  };

  const verifyPaymentAndRedirect = async (event?: React.FormEvent) => {
    event?.preventDefault();

    try {
      setIsCheckingOut(true);
      const paymentProcessingResponse = await fetch(verifyPaymentUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Accept": "application/json" },
      });

      if (paymentProcessingResponse.ok) {
        const responseData = await paymentProcessingResponse.json();
        if (!responseData.success) {
          setCheckoutError(responseData.error?.toString());
          setIsCheckingOut(false);
        } else {
          window.location.assign(responseData.redirect);
        }
      } else {
        setCheckoutError("Something went wrong trying to process your payment.");
        setIsCheckingOut(false);
      }
    } catch (e) {
      setCheckoutError(e);
      setIsCheckingOut(false);
    }
  };

  const pageActions = (
    <div className="d-flex flex-wrap justify-content-center my-3">
      <button type='button' className='btn btn-secondary rounded-pill px-5 py-3 m-2' onClick={() => document.location.assign(editGroupRegistrationUrl)}>
        {t("views.pagination.previous_item")}
      </button>

      <button type='submit' className='btn btn-primary rounded-pill px-5 py-3 m-2 registration-btn' disabled={isCheckingOut}>
        {isCheckingOut && (
          <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
        )}
        {requiresPayment ? t('actions.confirm_payment') : t('actions.confirm')}
      </button>
    </div>
  );

  return (
    <div id='group-registration-checkout-page' className='w-100 registration-payment-page'>
      <div className='mx-auto d-flex flex-column justify-content-center justify-items-center my-5 px-4' style={{ maxWidth: '600px' }}>
        <div>
          <PaymentSummary
            paymentSummary={paymentIntentData.paymentSummary}
            isLoadingPaymentIntent={isLoadingPaymentIntent}
            currencyCode={paymentIntentData.currencyCode}
          />
        </div>

        {requiresPayment && hasPayByInvoiceFeature && (
          <PaymentMethodRadioGroup
            setPaymentMethod={updatePaymentType}
            selectedPaymentMethod={paymentType}
          />
        )}

        {requiresPayment && paymentType === CREDIT_CARD ? (
          <>
            {/* Tax is based on the selected country */}
            <div className='input formInput' style={{ marginTop: '20px' }}>
              <label htmlFor="payment-country">{t('addresses.billing.country.label')}</label><br />
              <select name="payment-country" onChange={(event) => updateCountry(event.target.value as CountryCodeAlpha2)} className="custom-select" defaultValue={countryCode} id="payment-country">
                <option disabled>{t('registrations.form.country_list.label')}</option>
                {Object.entries(countryListAlpha2).map(([countryCode, country]) => (
                  <option key={countryCode} value={countryCode}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            {!isLoadingPaymentIntent && paymentIntentData.clientSecret && (
              <Elements options={{ clientSecret: paymentIntentData.clientSecret }} stripe={stripePromise}>
                <CheckoutWithStripe
                  checkoutRedirectUrl={checkoutRedirectUrl}
                  countryCode={countryCode}
                  paymentIntentData={paymentIntentData}
                  placeholderReceiptEmail={placeholderReceiptEmail}
                  groupRegistrationId={groupRegistrationId}
                  paymentMethodName={paymentMethodName}
                  updateReceiptEmailPath={updateReceiptEmailPath}
                  pageActions={pageActions}
                  setIsCheckingOut={setIsCheckingOut}
                  verifyPaymentAndRedirect={verifyPaymentAndRedirect}
                />
              </Elements>
            )}
          </>
        ) : paymentType === INVOICE && hasPayByInvoiceFeature ? (
          <InvoiceForm
            pageActions={pageActions}
            paymentIntentsPath={paymentIntentsPath}
            groupRegistrationId={groupRegistrationId}
            verifyPaymentUrl={verifyPaymentUrl}
            countryCode={countryCode}
            updateCountry={updateCountry}
            setIsCheckingOut={setIsCheckingOut}
          />
        ) : (
          <form onSubmit={verifyPaymentAndRedirect}>
            {checkoutError && (
              <div className="text-danger">
                {checkoutError}
              </div>
            )}

            {pageActions}
          </form>
        )}
      </div>
    </div >
  );
};

type PaymentMethodRadioGroupProps = {
  setPaymentMethod: (value: React.SetStateAction<PaymentType>) => void,
  selectedPaymentMethod: PaymentType
}
type PaymentMethodRadioOption = {
  type: PaymentType,
  label: string
}
const PaymentMethodRadioGroup = ({ setPaymentMethod, selectedPaymentMethod }: PaymentMethodRadioGroupProps) => {
  const { t } = useTranslation();
  const paymentMethodOptions: PaymentMethodRadioOption[] = [
    { type: CREDIT_CARD, label: t('registrations.payment_methods.credit_card') },
    { type: INVOICE, label: t('registrations.payment_methods.invoice') },
  ];

  return (
    <ChakraProvider resetCSS={false}>
      <RadioGroup onChange={val => setPaymentMethod(val as PaymentType)} value={selectedPaymentMethod} className='payment-method-selection' w="100%">
        <Stack direction='row' fontSize="16px">
          {paymentMethodOptions.map((paymentMethodOption) => (
            <Box h="50px" w="100%" border='1px solid #E2E8F0' borderRadius='md' key={`payment-method-${paymentMethodOption.type}`}>
              <Radio w="100%" h="100%" name={paymentMethodOption.type} value={paymentMethodOption.type} px="20px" aria-label={paymentMethodOption.label}>
                {paymentMethodOption.label}
              </Radio>
            </Box>
          ))}
        </Stack>
      </RadioGroup>
    </ChakraProvider>
  );
};

export default CheckoutForm;
