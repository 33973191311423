import React, { ReactNode } from "react";
import { Heading, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import SideNavigation from "../SideNavigation/SideNavigation";
import WebinarSideNavigation from "../../Webinar/common/SideNavigation";
import { figmaTheme as fT } from "../../../react/dcTheme";
import { EventType } from "../../../EventWizard/models/Event";
import { EVENT_TYPES } from "../../../EventWizard/constants";

export type PageLayoutProps = {
  event: EventType;
  children: ReactNode;
  hideEventName?: boolean;
};

const PageLayout: React.FC<PageLayoutProps> = ({ event, children, hideEventName }) => {
  const { t } = useTranslation();

  const renderSideNavigation = () => {
    if (event.eventType === EVENT_TYPES.webinar) {
      return <WebinarSideNavigation />;
    }
    return <SideNavigation eventId={event.id} />;
  };

  const renderHeading = () => {
    if (event.eventType === EVENT_TYPES.webinar) {
      return t("actions.create_webinar");
    }
    return t("actions.create_event");
  };

  return (
    <Flex width="100%" height="100%">
      {renderSideNavigation()}
      <Flex flexDir="column" height="100%" grow={1}>
        <Heading
          as="h6"
          size="md"
          fontWeight="bold"
          borderBottom="1px"
          borderBottomColor={fT.colors.grey.base}
          py={6}
          pl={8}
        >
          {renderHeading() + (hideEventName ? "" : (" - " + event.name))}
        </Heading>
        {children}
      </Flex>
    </Flex>
  );
};

export default PageLayout;
