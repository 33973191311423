import React, { ReactElement } from "react";
import { figmaTheme as fT } from "../../../react/dcTheme/figmaTheme";
import {
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Tag,
  TagLeftIcon,
  Portal
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  ArrowsOutSimple,
  CalendarBlank,
  Clock,
  MapPin,
  User,
} from "phosphor-react";
import { timeToString } from "../../../EventWizard/helpers/timeConverter";

export interface AgendaPopoverProps {
  title: string;
  date: Date;
  startAt: Date;
  endAt: Date;
  location: string;
  speakers: Array<string>;
  children?: ReactElement;
  handleClick?: (talk: unknown) => void;
  tzinfoName?: string;
}

const AgendaPopover = ({
  title,
  date,
  startAt,
  endAt,
  location,
  speakers,
  children,
  handleClick,
  tzinfoName
}: AgendaPopoverProps) => {
  const { t } = useTranslation();

  const displayTimeRange = () => {
    if (startAt && endAt) {
      return `${timeToString(startAt, "hh:mm a", tzinfoName)} - ${timeToString(endAt, "hh:mm a", tzinfoName)}`;
    } else {
      return "";
    }
  }
  const displaySpeakers = () => {
    if (speakers) {
      return speakers.map(speaker => speaker[1]).join(", ");
    } else {
      return "";
    }
  }

  return (
    <Popover variant="agenda" arrowSize={15}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent zIndex="popover">
          <PopoverArrow />
          <PopoverCloseButton aria-label={t("actions.expand")} onClick={handleClick}>
            <Icon as={ArrowsOutSimple} weight="fill" boxSize={4} mt={2} />
          </PopoverCloseButton>
          <PopoverHeader
            textTransform="uppercase"
            pb={0}
            pr={10}
            border={0}
            color={fT.colors.label.primary}
            fontWeight={700}
            fontSize="14px"
          >
            {title}
          </PopoverHeader>
          <PopoverBody>
            <Stack>
              <Tag pl={0} py={1} variant="card-detail">
                <TagLeftIcon
                  as={CalendarBlank}
                  weight="fill"
                  size={8}
                  color={fT.colors.label.primary}
                />
                {date && timeToString(date, "ddd, DD MMMM YYYY", tzinfoName)}
              </Tag>
              <Tag pl={0} py={1} variant="card-detail">
                <TagLeftIcon
                  as={Clock}
                  weight="fill"
                  size={8}
                  color={fT.colors.label.primary}
                />
                {displayTimeRange()}
              </Tag>
              <Tag pl={0} py={1} variant="card-detail">
                <TagLeftIcon
                  as={MapPin}
                  weight="fill"
                  size={8}
                  color={fT.colors.label.primary}
                />
                {location}
              </Tag>
              <Tag pl={0} py={1} variant="card-detail">
                <TagLeftIcon
                  as={User}
                  weight="fill"
                  size={8}
                  color={fT.colors.label.primary}
                />
                {displaySpeakers()}
              </Tag>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default AgendaPopover;
