import React from 'react';
import { fireEvent, render, getByTestId } from '@testing-library/react';
import '@testing-library/jest-dom';
import { axe, toHaveNoViolations } from 'jest-axe';
import DCPaginator from './DCPaginator';
import { DCPaginationProps } from './types';
expect.extend(toHaveNoViolations);

const defaultProps: DCPaginationProps = {
  handlePageSelection: jest.fn(),
  currentPage: 1,
  firstPage: true,
  lastPage: false,
  limitValue: 25,
  nextPage: 2,
  outOfRange: false,
  prevPage: null,
  totalPages: 10
};

afterEach(() => {
  jest.clearAllMocks();
});

describe('DC Paginator Component', () => {
  test('has no accessibility violations', async () => {
    const { container } = render(<DCPaginator {...defaultProps} />);
    const results = await axe(container);

    expect(results).toHaveNoViolations();
  })

  describe('clicking page change button calls the prop handler with appropriate page number', () => {
    test('clicking a number button of a page that is not current/active triggers the page change handler', () => {
      const modifiedProps = {
        currentPage: 1,
        totalPages: 3,
        nextPage: 2
      };
      const props: DCPaginationProps = { ...defaultProps, ...modifiedProps };
      const { container } = render(<DCPaginator {...props} />);
      const pageTwoBtn = getByTestId(container, 'page-number-2');

      pageTwoBtn.click();

      expect(defaultProps.handlePageSelection).toHaveBeenCalledTimes(1);
      expect(defaultProps.handlePageSelection).toHaveBeenCalledWith(2);
    })

    describe('prev and next arrow buttons', () => {
      const modifiedProps = {
        currentPage: 55,
        totalPages: 100,
        nextPage: 56,
        prevPage: 54
      }
      const props: DCPaginationProps = { ...defaultProps, ...modifiedProps };

      test('clicking the previous page button calls the event handler with the previous page number', () => {
        const { container } = render(<DCPaginator {...props} />);
        const prevPageBtn = getByTestId(container, 'paginate-prev-btn');

        fireEvent.click(prevPageBtn);

        expect(defaultProps.handlePageSelection).toHaveBeenCalledTimes(1);
        expect(defaultProps.handlePageSelection).toHaveBeenCalledWith(54);
      })

      test('clicking the next page button calls the event handler with the next pages number', () => {
        const { container } = render(<DCPaginator {...props} />);
        const nextPageBtn = getByTestId(container, 'paginate-next-btn');

        fireEvent.click(nextPageBtn)

        expect(defaultProps.handlePageSelection).toHaveBeenCalledTimes(1);
        expect(defaultProps.handlePageSelection).toHaveBeenCalledWith(56);
      })
    })

    describe('clicking the active page button', () => {
      it('does not fire a page handler event', () => {
        const modifiedProps = { handlePageSelection: jest.fn() };
        const props: DCPaginationProps = { ...defaultProps, ...modifiedProps };
        const { container } = render(<DCPaginator {...props} />);
        const currentPageBtn = getByTestId(container, `page-number-${defaultProps.currentPage}`);

        fireEvent.click(currentPageBtn);

        expect(defaultProps.handlePageSelection).not.toHaveBeenCalled();
      })
    })
  })

  describe('page number generation', () => {
    describe('when there is only a single page to display', () => {
      const props: DCPaginationProps = { ...defaultProps, ...{ totalPages: 1 } };
      const emptyDiv = document.createElement('div');
      test('the component returns an empty div', () => {
        const { container } = render(<DCPaginator {...props} />);

        expect(container).toEqual(emptyDiv);
      })
    })

    describe('when first page of two is displayed', () => {
      test('it returns multiple page numbers', () => {
        const props: DCPaginationProps = { ...defaultProps, ...{ totalPages: 2, } };
        const { container } = render(<DCPaginator {...props} />);
        const paginator = getByTestId(container, 'dc-paginator');
        const prevBtn = paginator.firstElementChild;
        const nextBtn = paginator.lastElementChild;

        expect(prevBtn).toBeDisabled();
        expect(nextBtn).not.toBeDisabled();
        expect(paginator).toHaveTextContent('12');
      })
    })
    describe('when the second page (also last page) is active', () => {
      it('displays the correct UI interactions', () => {
        const props: DCPaginationProps = { ...defaultProps, ...{ totalPages: 2, currentPage: 2 } };
        const { container } = render(<DCPaginator {...props} />);
        const paginator = getByTestId(container, 'dc-paginator');
        const prevBtn = paginator.firstElementChild;
        const nextBtn = paginator.lastElementChild;

        expect(paginator).toHaveTextContent('12');
        expect(prevBtn).toHaveAttribute('disabled');
        expect(nextBtn).not.toHaveAttribute('disabled');
      })
    })

    describe('when the page that is not close to start or finish is active', () => {
      const props: DCPaginationProps = {
        ...defaultProps,
        ...{ totalPages: 100, currentPage: 56, nextPage: 57, prevPage: 55 }
      }
      const { container } = render(<DCPaginator {...props} />);
      const paginator = getByTestId(container, 'dc-paginator');
      const prevBtn = paginator.firstElementChild;
      const nextBtn = paginator.lastElementChild;

      expect(paginator).toHaveTextContent('1...555657...100');
      expect(prevBtn).not.toHaveAttribute('disabled');
      expect(nextBtn).not.toHaveAttribute('disabled');
    })

    describe('when the second to last page is displayed', () => {
      test('it includes the third to last, second to last, and last pages', () => {
        const props: DCPaginationProps = {
          ...defaultProps,
          ...{
            totalPages: 100, currentPage: 99, nextPage: 100, prevPage: 98
          }
        };
        const { container } = render(<DCPaginator {...props} />);
        const paginator = getByTestId(container, 'dc-paginator');
        const prevBtn = paginator.firstElementChild;
        const nextBtn = paginator.lastElementChild;

        expect(paginator).toHaveTextContent('1...9899100');
        expect(prevBtn).not.toHaveAttribute('disabled');
        expect(nextBtn).not.toHaveAttribute('disabled');
      })
    })

    describe('when last page is displayed', () => {
      const props: DCPaginationProps = {
        ...defaultProps,
        ...{
          totalPages: 100, currentPage: 100, nextPage: null, prevPage: 99
        }
      };
      const { container } = render(<DCPaginator {...props} />);
      const paginator = getByTestId(container, 'dc-paginator');
      expect(paginator).toHaveTextContent('1...99100');
    })
  })
})
