import React, { MutableRefObject, useEffect} from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaGenerator from "./formSchema";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import DCColourPicker from "../../common/DCColourPicker";
import DCColorRadioButtons from "../../common/DCColourRadioButtons";
import FontSelect from "../../common/DCFontSelect";
import FormControlLabel from "../../common/shared/FormControlLabel";
import DCFileUploader from "../../DCFileUploader";
import { EventType } from "../../../EventWizard/models/Event";
import { usePreviewData } from "../../Preview";
import { LayoutForm } from "../../common/FormPageLayout";
import { useSchema } from "../../../helpers/useSchema";

export type WebsiteStylingFormProps = {
  event: EventType;
  submitForm: MutableRefObject<unknown>;
  handleErrors: MutableRefObject<unknown>;
  formErrors: MutableRefObject<unknown>;
}

const WebsiteStylingForm: React.FC<WebsiteStylingFormProps> = ({ event, submitForm, handleErrors, formErrors }) => {
  const { t } = useTranslation();
  const schema = useSchema(schemaGenerator);

  const {
    featurePageBackgroundColour,
    primaryColour,
    secondaryColour,
    fontBody,
    fontHeader,
    menuLogo,
    favicon,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText
  } = event;

  const defaultValues = {
    featurePageBackgroundColour,
    primaryColour,
    secondaryColour,
    fontBody,
    fontHeader,
    favicon,
    menuLogo
  };

  const { watch, control, setValue, handleSubmit, setError, formState: { errors }, } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues,
  });


  const { setActiveComponent, setAttribute } = usePreviewData({
    primaryColour,
    secondaryColour,
    featureBackgroundColour,
    featureHeaderTextColour,
    featureSectionHeaderTextColour,
    featureBackgroundSideMenuColour,
    featureBackgroundMenuColour,
    footerText
  });

  useEffect(() => {
    const fields = [ 'primaryColour', 'secondaryColour' ];
    const subscription = watch((value, { name }) => {

      if (fields.includes(name)) {
        setAttribute(name, value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);


  formErrors.current = errors;

  const saveEventLogo = (file: File) => {
    setValue('menuLogo', [file]);
  };

  const saveFavicon = (file: File) => {
    setValue('favicon', [file]);
  };

  useEffect(() => {
    submitForm.current = handleSubmit;
    handleErrors.current = setError;
  }, []);

  const backgroundColourName = (hexCode) => {
    return {'#FAFAFA': t('colour_radio_buttons.white'), '#E1DDDD': t('colour_radio_buttons.muted_grey')}[hexCode];
  };

  return (
    <Flex flexDir="column" as={LayoutForm} gap={5} onSubmit={(e) => e.preventDefault()}>
      <FormControlLabel
        name="eventLogo"
        label={t("branding_page.website_styling.event_logo.label")}
        helperText={t("branding_page.website_styling.event_logo.hint")}>
        <DCFileUploader
          acceptedFormats="image/*"
          aspectRatio={1}
          maxSize={10000000}
          uploadPhoto={saveEventLogo}
          source={event.menuLogoUrl}
          onMouseOver={() => setActiveComponent('preview_svg_Logo')}
          onMouseOut={() => setActiveComponent(null)}
        />
      </FormControlLabel>
      <FormControlLabel
        name="favicon"
        label={t("branding_page.website_styling.favicon.label")}
        helperText={t("branding_page.website_styling.favicon.hint")}>
        <DCFileUploader
          acceptedFormats="image/*"
          aspectRatio={1}
          maxSize={10000000}
          uploadPhoto={saveFavicon}
          source={event.faviconUrl}
          onMouseOver={() => setActiveComponent('preview_svg_Favicon')}
          onMouseOut={() => setActiveComponent(null)}
        />
      </FormControlLabel>
      <FormControlLabel
        name="featurePageBackgroundColour"
        label={t(
          "branding_page.website_styling.feature_page_background_colour.label"
        )}
        error={errors.featurePageBackgroundColour}
        isRequired
      >
        <Controller
          control={control}
          name="featurePageBackgroundColour"
          render={({ field: { onChange } }) => (
            <DCColorRadioButtons
              fieldName="featurePageBackgroundColour"
              onChange={onChange}
              onMouseOver={() => setActiveComponent('preview_svg_Page Content') }
              onMouseOut={() => setActiveComponent(null) }
              colors={[
                { name: "White", hexCode: "#FAFAFA" },
                { name: "Muted Grey", hexCode: "#E1DDDD" },
              ]}
              defaultValue={{ name: backgroundColourName(event.featurePageBackgroundColour), hexCode: event.featurePageBackgroundColour }}
            />
          )}
        />
      </FormControlLabel>
      <Flex gap={3} flexDir={["column", "row"]}>
        <FormControlLabel
          label={t("branding_page.website_styling.primary_colour.label")}
          error={errors.primaryColour}
          toolTip={t("branding_page.website_styling.primary_colour.tool_tip")}
          name="primaryColour"
          isRequired
        >
          <Controller
            control={control}
            name="primaryColour"
            render={({ field: { onChange, value, ref, name, onBlur } }) => (
              <DCColourPicker
                onChange={onChange}
                value={value}
                inputRef={ref}
                id={name}
                onBlur={onBlur}
              />
            )}
          />
        </FormControlLabel>
        <FormControlLabel
          label={t("branding_page.website_styling.secondary_colour.label")}
          error={errors.secondaryColour}
          toolTip={t("branding_page.website_styling.secondary_colour.tool_tip")}
          name="secondaryColour"
          isRequired
        >
          <Controller
            control={control}
            name="secondaryColour"
            render={({ field: { onChange, value, ref, name, onBlur } }) => (
              <DCColourPicker
                onChange={onChange}
                value={value}
                inputRef={ref}
                id={name}
                onBlur={onBlur}
              />
            )}
          />
        </FormControlLabel>
      </Flex>
      <Flex gap={3} flexDir={["column", "row"]}>
        <FormControlLabel
          label={t("branding_page.website_styling.font_header.label")}
          error={errors.fontHeader}
          name="fontHeader">
          <Controller
            control={control}
            name="fontHeader"
            render={({
              field: { onChange, value, name },
              fieldState: { invalid },
            }) => (
              <FontSelect
                onChange={onChange}
                onOpen={() => setActiveComponent('preview_svg_Nav_2')}
                onClose={() => setActiveComponent(null)}
                value={value}
                isError={invalid}
                id={name}
              />
            )}
          />
        </FormControlLabel>
        <FormControlLabel
          label={t("branding_page.website_styling.font_body.label")}
          error={errors.fontBody}>
          <Controller
            control={control}
            name="fontBody"
            render={({
              field: { onChange, value, name },
              fieldState: { invalid },
            }) => (
              <FontSelect
                onChange={onChange}
                onOpen={() => setActiveComponent('preview_svg_Page Content')}
                onClose={() => setActiveComponent(null)}
                value={value}
                isError={invalid}
                id={name}
              />
            )}
          />
        </FormControlLabel>
      </Flex>
    </Flex>
  );
};

export default WebsiteStylingForm;
