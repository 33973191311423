import React, { useEffect } from "react";
import SSOption from "./Option";
import { Flex, Stack, useRadioGroup } from "@chakra-ui/react";
import { noop } from "../../../../utils";
import { FieldPropsSource, ResultsProps } from "../../types";

interface Props {
  results: ResultsProps;
  totalVotes: number;
  onChange: (any) => void;
  existingResponse: string;
  field: FieldPropsSource;
  showResults: boolean;
  disabled: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const SingleSelect: React.FC<Props> = ({
  results,
  totalVotes,
  onChange,
  existingResponse,
  field,
  showResults,
  disabled,
  onMouseOver = noop,
  onMouseOut = noop,
}): JSX.Element => {
  const { setValue, getRootProps, getRadioProps } = useRadioGroup({
    name: `${field.id}_${field.type}`,
    defaultValue: existingResponse,
    onChange: onChange,
  });

  useEffect(() => {
    if (existingResponse) {
      setValue(existingResponse);
    }
  }, [existingResponse]);

  return (
    <Flex flexDir="column" alignItems="center" w="100%">
      <Stack
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        {...getRootProps()}
        w="100%"
        my={2}
      >
        {results &&
          field.attributes.options.split(",").map((option) => {
            return (
              <SSOption
                key={option}
                showResult={showResults}
                votes={results[option] || 0}
                percentage={results[option] / totalVotes || 0}
                existingResponse={existingResponse}
                isDisabled={disabled}
                {...getRadioProps({ value: option })}
              />
            );
          })}
      </Stack>
    </Flex>
  );
};

export default SingleSelect;
