import React from "react";

import FormPageLayout from "../../../../../common/FormPageLayout";
import { useEvent } from "../../../../../pages/Event";
import AdditionalFeaturesForm from "../../../../../forms/AdditionalFeaturesForm";
import { navigateToEventList } from "../../../../../../EventWizard/helpers/navigation";
import { useWebinarFormActions } from "../../../../hooks/useWebinarFormActions";

const AdditionalFeatures: React.FC = (): JSX.Element => {
  const { event } = useEvent();

  const { updateEvent, navigateTo, getStepDetailsByRoute } =
    useWebinarFormActions();

  const stepDetails = [
    getStepDetailsByRoute("heroSection"),
    getStepDetailsByRoute("additionalFeatures"),
    getStepDetailsByRoute("addSections"),
    getStepDetailsByRoute("footer"),
  ];

  return (
    <FormPageLayout
      event={event}
      stepDetails={stepDetails}
      update={updateEvent}
      previousForm={navigateTo.heroSection}
      exitForm={navigateToEventList}
      nextForm={navigateTo.addSections}
      previewVariant="None"
    >
      {props => <AdditionalFeaturesForm event={event} {...props} />}
    </FormPageLayout>
  );
};

export default AdditionalFeatures;
