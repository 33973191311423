import moment from 'moment';
import { FieldErrors } from 'react-hook-form';
import * as yup from "yup";
import { AnyObject } from 'yup/lib/types';

const timeDiffValidator = (value: string, ctx: yup.TestContext<AnyObject>) => {
  const startTime =  moment(ctx.parent.startTime, "h:mm a");
  const endTime = moment(value, "h:mm a");
  return startTime.isBefore(endTime);
};

const videoUrlRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be|vimeo.com))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\\S+)?$/i;

const hexColourRegEx = /^#([a-f0-9]{6}|[a-f0-9]{3})$/i;

const errorMessagesCombiner = (keys: string[], errors: FieldErrors) => {
  const result = [];
  keys.forEach((k) => {
    if (errors[k]) {
      result.push(Object.values(errors[k].types));
    }
  });
  return result.flat().join(", ");
}

export {
  timeDiffValidator,
  videoUrlRegex,
  hexColourRegEx,
  errorMessagesCombiner
};
