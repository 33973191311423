import { LocaleSingularArg } from "@fullcalendar/core";
import allLocales from "@fullcalendar/core/locales-all";
import FullCalendar from "@fullcalendar/react";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from "@fullcalendar/timegrid";
import AgendaPopover from "./Popover";
import { Box, Text, Center } from "@chakra-ui/react";
import React from "react";
import { timeToString } from "../../EventWizard/helpers/timeConverter";
import "./agenda_styles.css";

interface AgendaViewProps {
  tzinfoName?: string;
  calendarStartTime?: string;
  calendarEndTime?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events?: Array<any>;
  initialDate?: Date;
  locale?: LocaleSingularArg;
  editTalk?: (talk: unknown) => void;
}


const AgendaView: React.FC<AgendaViewProps> = ({
  tzinfoName,
  calendarStartTime,
  calendarEndTime,
  events = [],
  initialDate,
  locale = 'en',
  editTalk
}) => {

  const renderEventContent = (info) => {
    const handleEditTalk = () => {
      const talk = {
        id: info.event.id,
        title: info.event.title,
        description: info.event.extendedProps.description,
        startTime: info.event.extendedProps.startAt,
        endTime: info.event.extendedProps.endAt,
        duration: 0,
        indexImageUrl: info.event.extendedProps.indexImage?.url,
        vod: info.event.extendedProps.vod,
        speakers: info.event.extendedProps.speakers
      }
      editTalk(talk);
    }

    return(
      <AgendaPopover
        talkId={info.event.id}
        title={info.event.title}
        date={info.event.extendedProps.displayDate}
        handleClick={handleEditTalk}
        tzinfoName={tzinfoName}
        {...info.event.extendedProps}
      >
        <Text onDoubleClick={handleEditTalk} h="100%" p="4px">{info.event.title}</Text>
      </AgendaPopover>
    )
  }

  return(
    <>
      <Center>
        <Box w="fit-content">
          <Text variant="calendar-date">{timeToString(initialDate, "DD MMMM YYYY", tzinfoName)}</Text>
          <Text variant="calendar-week-day">{timeToString(initialDate, "dddd", tzinfoName)}</Text>
        </Box>
      </Center>
      <Box h="100%" w="100%">
        <FullCalendar
          headerToolbar={false}
          dayHeaders={false}
          initialView="timeGridDay"
          slotMinTime={calendarStartTime || "00:00:00"}
          slotMaxTime={calendarEndTime || "24:00:00"}
          slotDuration="1:00"
          slotLabelFormat={{
            hour: 'numeric',
            omitZeroMinute: true,
            meridiem: true
          }}
          expandRows={true}
          plugins={[momentTimezonePlugin, timeGridPlugin]}
          timeZone={tzinfoName || "local"}
          events={events}
          eventContent={renderEventContent}
          initialDate={initialDate}
          locales={allLocales}
          locale={locale}
          allDaySlot={false}
          height='100%'
        />
      </Box>
    </>
  )
};

export default AgendaView;
