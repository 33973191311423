import type { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';

export const getChannelListOptions = (user: string | undefined) => {
  const filters: ChannelFilters = {
    type: 'messaging',
    members: { $in: [user!] },
  };

  const options: ChannelOptions = {
    state: true,
    watch: true,
    presence: true,
    limit: 20,
  };

  const sort: ChannelSort = [{ last_message_at: -1 }, { updated_at: -1 }];

  return {
    filters,
    options,
    sort,
  };
};
