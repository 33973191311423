import * as yup from "yup";
import { hexColourRegEx } from "../../../EventWizard/helpers/customValidators";

export default t => {
  return yup.object({
    featureBackgroundColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureBackgroundSecondaryColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureHeaderTextColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureSectionHeaderTextColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureBackgroundMenuColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureTextColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureIconColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureBackgroundSideMenuColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureSideMenuTextColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureSideMenuIconColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureProfileSideMenuTextColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
    featureScrollLoadColour: yup.string().matches(hexColourRegEx, t("colour_hex_error")),
  });
};
