import * as yup from "yup";
import { videoUrlRegex } from "../../../EventWizard/helpers/customValidators";

export default t => {
  return yup.object({
    headerTagline: yup.string()
      .required(t("event_tools.validations.required", { field: t("homepage_setup.header_tagline.field_name")}))
      .typeError(t("event_tools.validations.required", { field: t("homepage_setup.header_tagline.field_name")})),
    subTagline:yup.string()
      .required(t("event_tools.validations.required", { field: t("homepage_setup.sub_tagline.field_name")}))
      .typeError(t("event_tools.validations.required", { field: t("homepage_setup.sub_tagline.field_name")})),
    tagline: yup.string()
      .required(t("event_tools.validations.required", { field: t("homepage_setup.tagline.field_name")}))
      .typeError(t("event_tools.validations.required", { field: t("homepage_setup.tagline.field_name")}))
      .min(3, t("homepage_setup.tagline.too_short"))
      .max(124, t("homepage_setup.tagline.too_long")),
    backgroundVideoUrl: yup.string()
      .matches(videoUrlRegex, { message: t('homepage_setup.video_url_format_error'), excludeEmptyString: true })
      .nullable(),
    eventFeatures: yup.object({
      countdownTimer: yup.object({
        id: yup.string().required(),
        enabled: yup.boolean().required()
      })
    })
  });
};
