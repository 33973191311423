import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Box, LinkProps } from "@chakra-ui/react";
import { CheckCircle, IconProps } from "phosphor-react";

import { figmaTheme as fT } from "../../../react/dcTheme";
import type { SideNavigationVariants } from "./SideNavigation";
import { setItemColor } from "./utils";

type SideNavigationHeaderProps = {
  displayText: string;
  path: string;
  isActive?: boolean;
  isComplete?: boolean;
  isEnabled?: boolean;
  iconComponent: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
  variant?: SideNavigationVariants;
};

export const SideNavigationHeader: React.FC<SideNavigationHeaderProps> = ({
  displayText,
  path,
  isActive,
  isComplete,
  isEnabled = true,
  variant = "default",
  iconComponent: Icon
}) => {
  const linkStyles = useMemo<LinkProps>(() => {
    return {
      color: setItemColor(isActive, variant),
      py: 2,
      my: 4,
      borderRadius: 4,
      d: "flex",
      alignItems: "center",
    }
  }, [variant, isActive]);

  return(
    <Link
      as={RouterLink}
      to={path}
      style={{textDecoration: "none", pointerEvents: isEnabled ? "auto" : "none" }}
      {...linkStyles}
    >
      {isComplete ?
        <CheckCircle
          weight="fill"
          size="24"
          color={fT.colors.success}
        />
        :
        <Icon weight="fill" size="24" />
      }
      <Box
        as="span"
        ml={3}
        fontWeight="medium"
        fontSize="14px"
      >
        {displayText}
      </Box>
    </Link>
  );
};

export default SideNavigationHeader;
