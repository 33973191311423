import React from "react";
import {
  act,
  fireEvent,
  render,
  screen,
  waitFor,
} from "@testing-library/react";
import "@testing-library/jest-dom";
import { axe, toHaveNoViolations } from "jest-axe";
import { ThemeProvider } from "@chakra-ui/react";
import dcTheme from "../../../react/dcTheme";
import DaysSetUpForm, { DaysSetUpFormProps } from "./DaysSetUpForm";

expect.extend(toHaveNoViolations);

const requiredProps: DaysSetUpFormProps = {
  startDate: new Date("2022-03-20").toISOString(),
  endDate: new Date("2022-03-30").toISOString(),
  defaultValues: {
    nicknameSchedule: "TEST_AGENDA",
    days: [
      {
        date: new Date("2022-03-20").toISOString(),
        startTime: "09:00",
        endTime: "17:00",
      },
    ],
  },
};

describe("Days Set Up Form", () => {
  it("passes without axe errors", async () => {
    const { container } = render(
      <ThemeProvider theme={dcTheme}>
        <DaysSetUpForm {...requiredProps} />
      </ThemeProvider>
    );
    await act(async () => {
      const results = await axe(container);
      expect(results).toHaveNoViolations();
    });
  });

  describe("date", () => {
    it("new days are added with date set as next available day", async () => {
      render(<DaysSetUpForm {...requiredProps} />);
      await waitFor(() => {
        fireEvent.click(screen.getByText("days_set_up_form.add_day_label"));
      });

      expect(screen.getByText("2022-03-21")).toBeInTheDocument();
    });

    it("adds new days in between two days if available", async () => {
      const props: DaysSetUpFormProps = {
        startDate: new Date("2022-03-20").toISOString(),
        endDate: new Date("2022-03-22").toISOString(),
        defaultValues: {
          nicknameSchedule: "TEST_AGENDA",
          days: [
            {
              date: new Date("2022-03-20").toISOString(),
              startTime: "09:00",
              endTime: "17:00",
            },
            {
              date: new Date("2022-03-22").toISOString(),
              startTime: "09:00",
              endTime: "17:00",
            },
          ],
        },
      };
      render(<DaysSetUpForm {...props} />);
      await waitFor(() => {
        fireEvent.click(screen.getByText("days_set_up_form.add_day_label"));
      });

      expect(screen.getByText("2022-03-21")).toBeInTheDocument();
    });

    it("add day button is disabled if no other days are available", async () => {
      const props: DaysSetUpFormProps = {
        startDate: new Date("2022-03-20").toISOString(),
        endDate: new Date("2022-03-21").toISOString(),
        defaultValues: {
          nicknameSchedule: "TEST_AGENDA",
          days: [
            {
              date: new Date("2022-03-20").toISOString(),
              startTime: "09:00",
              endTime: "17:00",
            },
            {
              date: new Date("2022-03-21").toISOString(),
              startTime: "09:00",
              endTime: "17:00",
            },
          ],
        },
      };
      render(<DaysSetUpForm {...props} />);

      await waitFor(() => {
        expect(
          screen.getByText("days_set_up_form.add_day_label")
        ).toHaveAttribute("disabled");
      });
    });
  });

  describe("time", () => {
    it("start time and end time is required", async () => {
      render(<DaysSetUpForm {...requiredProps} />);
      const timeInput = screen.getByPlaceholderText(
        "events.form.time.start_time - events.form.time.end_time"
      );

      await waitFor(() => {
        fireEvent.click(timeInput);
        fireEvent.click(screen.getByText("event_tools.buttons.clear"));
        fireEvent.blur(timeInput);
      });

      expect(
        screen.getByText(/event_tools\.validations\.required/)
      ).toBeInTheDocument();
    });

    it("end time must be after start time", async () => {
      const props: DaysSetUpFormProps = {
        startDate: new Date("2022-03-20").toISOString(),
        endDate: new Date("2022-03-30").toISOString(),
        defaultValues: {
          nicknameSchedule: "TEST_AGENDA",
          days: [
            {
              date: new Date("2022-03-20").toISOString(),
              startTime: "08:00",
              endTime: "10:00",
            },
          ],
        },
      };
      render(<DaysSetUpForm {...props} />);

      await waitFor(() => {
        const timeInput = screen.getByPlaceholderText(
          "events.form.time.start_time - events.form.time.end_time"
        );
        fireEvent.click(timeInput);
        const startTimeInput = screen.getByLabelText("time_picker.start_time time_picker.hour");
        fireEvent.change(startTimeInput, { target: { value: "9" } });
        const endTimeInput = screen.getByLabelText("time_picker.end_time time_picker.hour");
        fireEvent.change(endTimeInput, { target: { value: "8" } });
        fireEvent.blur(timeInput);
      });

      expect(
        screen.getByText("event_details.time.invalid_length")
      ).toBeInTheDocument();
    });
  });
});
