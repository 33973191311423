import * as React from "react";
import { G, Path, Rect, LinearGradient } from "./AnimatableSvg";

function SvgPreview(props) {
  return (
    <svg
      width={280}
      height={522}
      viewBox="0 0 280 522"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        id="preview_svg_Preview"
        filter="url(#preview_svg_filter0_d_2253_22416)"
      >
        <G id="preview_svg_Browser window">
          <Path
            id="preview_svg_Window BG"
            d="M10 12C10 9.79086 11.7909 8 14 8H266C268.209 8 270 9.79086 270 12V23H10V12Z"
            fill="#E5E1E6"
          />
          <circle id="preview_svg_Close" cx={18} cy={16} r={2} fill="#EC6A5E" />
          <circle id="preview_svg_Min" cx={25} cy={16} r={2} fill="#F5BF4F" />
          <circle id="preview_svg_Max" cx={32} cy={16} r={2} fill="#61C554" />
          <G id="preview_svg_Browser Tab">
            <Path
              id="preview_svg_Browser Tab BG"
              d="M49 15C49 12.7909 50.7909 11 53 11H121C123.209 11 125 12.7909 125 15V23H49V15Z"
              fill="#9396A6"
            />
            <Rect
              id="preview_svg_Favicon"
              x={52}
              y={13}
              width={8}
              height={8}
              rx={2}
              fill="#E7E8EB"
            />
            <Rect
              id="preview_svg_Website Title"
              x={66}
              y={15}
              width={48}
              height={4}
              rx={2}
              fill="#767676"
            />
          </G>
        </G>
        <G id="preview_svg_Top Nav">
          <Path
            id="preview_svg_Top Nav BG"
            d="M10 23H270V60H10V23Z"
            fill="#0E153A"
          />
          <Rect
            id="preview_svg_Avatar"
            x={243.073}
            y={32.1309}
            width={18.3072}
            height={18}
            rx={9}
            fill="#E7E8EB"
          />
          <G id="preview_svg_Top Nav Button">
            <Rect
              id="preview_svg_Top Nav Button_2"
              x={216.144}
              y={35.7825}
              width={18}
              height={10.2175}
              rx={2}
              fill="#9396A6"
            />
          </G>
          <G id="preview_svg_Top Nav Button_3">
            <Rect
              id="preview_svg_Top Nav Button_4"
              x={192.001}
              y={35.7825}
              width={18}
              height={10}
              rx={2}
              fill="#9396A6"
            />
          </G>
          <G id="preview_svg_Top Nav Button_5">
            <Rect
              id="preview_svg_Top Nav Button_6"
              x={167.857}
              y={35.7825}
              width={18}
              height={10}
              rx={2}
              fill="#9396A6"
            />
          </G>
          <G id="preview_svg_Top Nav Button_7">
            <Rect
              id="preview_svg_Top Nav Button_8"
              x={143.715}
              y={35.7825}
              width={18}
              height={10}
              rx={2}
              fill="#9396A6"
            />
          </G>
          <Rect
            id="preview_svg_Logo"
            x={15}
            y={30}
            width={24}
            height={24}
            rx={4}
            fill="#E7E8EB"
          />
        </G>
        <G id="preview_svg_Page">
          <G id="preview_svg_Left Nav">
            <Path
              d="M10 60H42.57V510H14C11.7909 510 10 508.209 10 506V60Z"
              fill="#0E153A"
            />
            <Rect
              id="preview_svg_Left Nav Button"
              x={16.9993}
              y={68}
              width={18.5714}
              height={18.2598}
              rx={4}
              fill="#9396A6"
            />
            <Rect
              id="preview_svg_Left Nav Button_2"
              x={16.9993}
              y={90.2598}
              width={18.5714}
              height={18.2598}
              rx={4}
              fill="#9396A6"
            />
            <Rect
              id="preview_svg_Left Nav Button_3"
              x={16.9993}
              y={112.52}
              width={18.5714}
              height={18.2598}
              rx={4}
              fill="#9396A6"
            />
            <Rect
              id="preview_svg_Left Nav Button_4"
              x={16.9993}
              y={134.779}
              width={18.5714}
              height={18.2598}
              rx={4}
              fill="#9396A6"
            />
            <Rect
              id="preview_svg_Left Nav Button_5"
              x={16.9993}
              y={157.039}
              width={18.5714}
              height={18.2598}
              rx={4}
              fill="#9396A6"
            />
          </G>
          <G id="preview_svg_Page Content">
            <G id="preview_svg_Timezone">
              <Rect
                id="preview_svg_Timezone_2"
                x={42.5699}
                y={60}
                width={223}
                height={16}
                fill="#3A3B65"
              />
              <Rect
                id="preview_svg_Text"
                x={126}
                y={65}
                width={56}
                height={6}
                rx={3}
                fill="#9396A6"
              />
            </G>
            <G id="preview_svg_Hero Section">
              <Path
                id="preview_svg_Home Banner"
                d="M42.5699 76H265.57V198H42.5699V76Z"
                fill="url(#preview_svg_paint0_linear_2253_22416)"
              />
              <Rect
                id="preview_svg_Tagline"
                x={125.57}
                y={103}
                width={56}
                height={6}
                rx={3}
                fill="#E7E8EB"
              />
              <Rect
                id="preview_svg_Tagline_2"
                x={125.57}
                y={159}
                width={56}
                height={6}
                rx={3}
                fill="#E7E8EB"
              />
              <Rect
                id="preview_svg_Subheadline"
                x={108.57}
                y={137}
                width={90}
                height={8}
                rx={4}
                fill="#E7E8EB"
              />
              <Rect
                id="preview_svg_Headline"
                x={88.5699}
                y={117}
                width={130}
                height={12}
                rx={6}
                fill="#E7E8EB"
              />
              <G id="preview_svg_Button">
                <Rect
                  id="preview_svg_Primary Button"
                  x={130.14}
                  y={174.993}
                  width={48}
                  height={15.4326}
                  rx={7.71631}
                  fill="#3A3B65"
                />
                <Rect
                  id="preview_svg_Text_2"
                  x={142.14}
                  y={180.78}
                  width={24}
                  height={3.85816}
                  rx={1.92908}
                  fill="#E7E8EB"
                />
              </G>
            </G>
            <G id="preview_svg_Feature Date &amp; Location">
              <Rect
                id="preview_svg_Section BG"
                x={42.5699}
                y={198}
                width={223}
                height={36}
                fill="white"
              />
              <Rect
                id="preview_svg_Date"
                x={82.5699}
                y={211}
                width={60}
                height={10}
                rx={4}
                fill="#E5E1E6"
              />
              <Rect
                id="preview_svg_Location"
                x={165.57}
                y={211}
                width={60}
                height={10}
                rx={4}
                fill="#E5E1E6"
              />
            </G>
            <G id="preview_svg_Feature Video">
              <Rect
                id="preview_svg_Section BG_2"
                x={42.5699}
                y={234}
                width={223}
                height={70}
                fill="white"
              />
              <G id="preview_svg_Video">
                <Rect
                  id="preview_svg_Video_2"
                  x={109.71}
                  y={247}
                  width={87}
                  height={44}
                  rx={4}
                  fill="#E5E1E6"
                />
                <G id="preview_svg_YoutubeLogo">
                  <Path
                    id="preview_svg_Vector"
                    d="M159.854 265.362C159.796 265.132 159.684 264.919 159.526 264.742C159.368 264.564 159.17 264.428 158.947 264.344C156.81 263.519 153.397 263.525 153.21 263.525C153.022 263.525 149.61 263.519 147.472 264.344C147.25 264.428 147.052 264.564 146.894 264.742C146.736 264.919 146.624 265.132 146.566 265.362C146.404 265.981 146.21 267.119 146.21 269C146.21 270.881 146.404 272.019 146.566 272.637C146.624 272.868 146.736 273.081 146.894 273.258C147.052 273.436 147.25 273.572 147.472 273.656C149.522 274.45 152.735 274.475 153.166 274.475H153.254C153.685 274.475 156.897 274.45 158.947 273.656C159.17 273.572 159.368 273.436 159.526 273.258C159.684 273.081 159.796 272.868 159.854 272.637C160.016 272.019 160.21 270.881 160.21 269C160.21 267.119 160.016 265.981 159.854 265.362ZM155.347 269.206L152.347 271.206C152.308 271.236 152.259 271.251 152.21 271.25C152.169 271.248 152.128 271.238 152.091 271.219C152.051 271.198 152.018 271.167 151.995 271.128C151.972 271.089 151.959 271.045 151.96 271V267C151.959 266.955 151.972 266.911 151.995 266.872C152.018 266.833 152.051 266.802 152.091 266.781C152.131 266.76 152.176 266.75 152.221 266.752C152.266 266.754 152.31 266.769 152.347 266.794L155.347 268.794C155.382 268.816 155.411 268.846 155.431 268.882C155.451 268.918 155.461 268.959 155.461 269C155.461 269.041 155.451 269.082 155.431 269.118C155.411 269.154 155.382 269.184 155.347 269.206Z"
                    fill="#9396A6"
                  />
                </G>
              </G>
            </G>
            <G id="preview_svg_Section 1">
              <Rect
                id="preview_svg_Section"
                x={42.5699}
                y={304}
                width={223}
                height={81}
                fill="white"
              />
              <Rect
                id="preview_svg_Headline_2"
                x={67.1399}
                y={317}
                width={63}
                height={10}
                rx={4}
                fill="#E5E1E6"
              />
              <Rect
                id="preview_svg_Body"
                x={67.1399}
                y={335}
                width={80}
                height={6}
                rx={3}
                fill="#E5E1E6"
              />
              <Rect
                id="preview_svg_Body_2"
                x={67.1399}
                y={345}
                width={90}
                height={6}
                rx={3}
                fill="#E5E1E6"
              />
              <Rect
                id="preview_svg_Body_3"
                x={67.1399}
                y={355}
                width={82}
                height={6}
                rx={3}
                fill="#E5E1E6"
              />
              <Rect
                id="preview_svg_Body_4"
                x={67.1399}
                y={365}
                width={86}
                height={6}
                rx={3}
                fill="#E5E1E6"
              />
              <G id="preview_svg_Image">
                <Rect
                  id="preview_svg_Image_2"
                  x={172.14}
                  y={317}
                  width={70}
                  height={54}
                  rx={4}
                  fill="#E5E1E6"
                />
                <G id="preview_svg_Image_3">
                  <Path
                    id="preview_svg_Vector_2"
                    d="M209.64 342.25C209.64 342.449 209.561 342.64 209.42 342.78C209.28 342.921 209.089 343 208.89 343C208.691 343 208.5 342.921 208.36 342.78C208.219 342.64 208.14 342.449 208.14 342.25C208.14 342.051 208.219 341.86 208.36 341.72C208.5 341.579 208.691 341.5 208.89 341.5C209.089 341.5 209.28 341.579 209.42 341.72C209.561 341.86 209.64 342.051 209.64 342.25V342.25ZM213.64 339.5V347.5V348.5C213.64 348.765 213.535 349.02 213.347 349.207C213.159 349.395 212.905 349.5 212.64 349.5H201.64C201.375 349.5 201.12 349.395 200.933 349.207C200.745 349.02 200.64 348.765 200.64 348.5V346.5V339.5C200.64 339.235 200.745 338.98 200.933 338.793C201.12 338.605 201.375 338.5 201.64 338.5H212.64C212.905 338.5 213.159 338.605 213.347 338.793C213.535 338.98 213.64 339.235 213.64 339.5ZM212.64 346.294V339.5H201.64V345.294L203.934 343C204.122 342.814 204.376 342.711 204.64 342.711C204.904 342.711 205.158 342.814 205.346 343L208.14 345.794L209.434 344.5C209.622 344.314 209.876 344.211 210.14 344.211C210.404 344.211 210.658 344.314 210.846 344.5L212.64 346.294Z"
                    fill="#9396A6"
                  />
                </G>
              </G>
            </G>
            <G id="preview_svg_Presentations">
              <Rect
                id="preview_svg_Section_2"
                x={42.5699}
                y={385}
                width={223}
                height={102}
                fill="white"
              />
              <Rect
                id="preview_svg_Section Header"
                x={101.71}
                y={398}
                width={103}
                height={10}
                rx={4}
                fill="#9396A6"
              />
              <Rect
                id="preview_svg_Body_5"
                x={77.71}
                y={451}
                width={37}
                height={6}
                rx={3}
                fill="#E5E1E6"
              />
              <Rect
                id="preview_svg_Body_6"
                x={191.71}
                y={451}
                width={37}
                height={6}
                rx={3}
                fill="#E5E1E6"
              />
              <Rect
                id="preview_svg_Body_7"
                x={134.71}
                y={451}
                width={37}
                height={6}
                rx={3}
                fill="#E5E1E6"
              />
              <G id="preview_svg_Image_4">
                <Rect
                  id="preview_svg_Image_5"
                  x={77.71}
                  y={420}
                  width={37}
                  height={28}
                  rx={4}
                  fill="#E5E1E6"
                />
                <G id="preview_svg_Image_6">
                  <Path
                    id="preview_svg_Vector_3"
                    d="M98.78 432.25C98.78 432.449 98.701 432.64 98.5604 432.78C98.4197 432.921 98.2289 433 98.03 433C97.8311 433 97.6404 432.921 97.4997 432.78C97.359 432.64 97.28 432.449 97.28 432.25C97.28 432.051 97.359 431.86 97.4997 431.72C97.6404 431.579 97.8311 431.5 98.03 431.5C98.2289 431.5 98.4197 431.579 98.5604 431.72C98.701 431.86 98.78 432.051 98.78 432.25V432.25ZM102.78 429.5V437.5V438.5C102.78 438.765 102.675 439.02 102.487 439.207C102.3 439.395 102.045 439.5 101.78 439.5H90.78C90.5148 439.5 90.2605 439.395 90.0729 439.207C89.8854 439.02 89.78 438.765 89.78 438.5V436.5V429.5C89.78 429.235 89.8854 428.98 90.0729 428.793C90.2605 428.605 90.5148 428.5 90.78 428.5H101.78C102.045 428.5 102.3 428.605 102.487 428.793C102.675 428.98 102.78 429.235 102.78 429.5ZM101.78 436.294V429.5H90.78V435.294L93.0738 433C93.262 432.814 93.5157 432.711 93.78 432.711C94.0443 432.711 94.298 432.814 94.4863 433L97.28 435.794L98.5738 434.5C98.762 434.314 99.0157 434.211 99.28 434.211C99.5443 434.211 99.798 434.314 99.9863 434.5L101.78 436.294Z"
                    fill="#9396A6"
                  />
                </G>
              </G>
              <G id="preview_svg_Image_7">
                <Rect
                  id="preview_svg_Image_8"
                  x={191.71}
                  y={420}
                  width={37}
                  height={28}
                  rx={4}
                  fill="#E5E1E6"
                />
                <G id="preview_svg_Image_9">
                  <Path
                    id="preview_svg_Vector_4"
                    d="M212.78 432.25C212.78 432.449 212.701 432.64 212.56 432.78C212.42 432.921 212.229 433 212.03 433C211.831 433 211.64 432.921 211.5 432.78C211.359 432.64 211.28 432.449 211.28 432.25C211.28 432.051 211.359 431.86 211.5 431.72C211.64 431.579 211.831 431.5 212.03 431.5C212.229 431.5 212.42 431.579 212.56 431.72C212.701 431.86 212.78 432.051 212.78 432.25V432.25ZM216.78 429.5V437.5V438.5C216.78 438.765 216.675 439.02 216.487 439.207C216.3 439.395 216.045 439.5 215.78 439.5H204.78C204.515 439.5 204.26 439.395 204.073 439.207C203.885 439.02 203.78 438.765 203.78 438.5V436.5V429.5C203.78 429.235 203.885 428.98 204.073 428.793C204.26 428.605 204.515 428.5 204.78 428.5H215.78C216.045 428.5 216.3 428.605 216.487 428.793C216.675 428.98 216.78 429.235 216.78 429.5ZM215.78 436.294V429.5H204.78V435.294L207.074 433C207.262 432.814 207.516 432.711 207.78 432.711C208.044 432.711 208.298 432.814 208.486 433L211.28 435.794L212.574 434.5C212.762 434.314 213.016 434.211 213.28 434.211C213.544 434.211 213.798 434.314 213.986 434.5L215.78 436.294Z"
                    fill="#9396A6"
                  />
                </G>
              </G>
              <G id="preview_svg_Image_10">
                <Rect
                  id="preview_svg_Image_11"
                  x={134.71}
                  y={420}
                  width={37}
                  height={28}
                  rx={4}
                  fill="#E5E1E6"
                />
                <G id="preview_svg_Image_12">
                  <Path
                    id="preview_svg_Vector_5"
                    d="M155.78 432.25C155.78 432.449 155.701 432.64 155.56 432.78C155.42 432.921 155.229 433 155.03 433C154.831 433 154.64 432.921 154.5 432.78C154.359 432.64 154.28 432.449 154.28 432.25C154.28 432.051 154.359 431.86 154.5 431.72C154.64 431.579 154.831 431.5 155.03 431.5C155.229 431.5 155.42 431.579 155.56 431.72C155.701 431.86 155.78 432.051 155.78 432.25V432.25ZM159.78 429.5V437.5V438.5C159.78 438.765 159.675 439.02 159.487 439.207C159.3 439.395 159.045 439.5 158.78 439.5H147.78C147.515 439.5 147.26 439.395 147.073 439.207C146.885 439.02 146.78 438.765 146.78 438.5V436.5V429.5C146.78 429.235 146.885 428.98 147.073 428.793C147.26 428.605 147.515 428.5 147.78 428.5H158.78C159.045 428.5 159.3 428.605 159.487 428.793C159.675 428.98 159.78 429.235 159.78 429.5ZM158.78 436.294V429.5H147.78V435.294L150.074 433C150.262 432.814 150.516 432.711 150.78 432.711C151.044 432.711 151.298 432.814 151.486 433L154.28 435.794L155.574 434.5C155.762 434.314 156.016 434.211 156.28 434.211C156.544 434.211 156.798 434.314 156.986 434.5L158.78 436.294Z"
                    fill="#9396A6"
                  />
                </G>
              </G>
              <G id="preview_svg_Button_2">
                <Rect
                  id="preview_svg_Primary Button_2"
                  x={138.57}
                  y={469}
                  width={31.1029}
                  height={10}
                  rx={5}
                  fill="#3A3B65"
                />
                <Rect
                  id="preview_svg_Text_3"
                  x={146.346}
                  y={472.75}
                  width={15.5515}
                  height={2.5}
                  rx={1.25}
                  fill="#E7E8EB"
                />
              </G>
            </G>
            <G id="preview_svg_Footer">
              <Path
                id="preview_svg_Footer BG"
                d="M42.5699 487H265.57V506C265.57 508.209 263.779 510 261.57 510H42.5699V487Z"
                fill="#0E153A"
              />
              <Rect
                id="preview_svg_Social links"
                x={210.71}
                y={493}
                width={44}
                height={11}
                rx={4}
                fill="#9396A6"
              />
              <Rect
                id="preview_svg_Copy right"
                x={128.57}
                y={496}
                width={50}
                height={6}
                rx={3}
                fill="#9396A6"
              />
            </G>
          </G>
          <Rect
            id="preview_svg_Scroll"
            x={265.57}
            y={60}
            width={4}
            height={100}
            rx={2}
            fill="#3A3B65"
          />
        </G>
      </G>
      <defs>
        <filter
          id="preview_svg_filter0_d_2253_22416"
          x={0}
          y={0}
          width={280}
          height={522}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2253_22416"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2253_22416"
            result="shape"
          />
        </filter>
        <LinearGradient
          id="preview_svg_paint0_linear_2253_22416"
          x1={37.9999}
          y1={137}
          x2={266}
          y2={137}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A3B65" />
          <stop offset={1} stopColor="#B8006A" />
        </LinearGradient>
      </defs>
    </svg>
  );
}

export default SvgPreview;

