import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Text, Button, HStack, useMultiStyleConfig, FlexProps } from "@chakra-ui/react";
import WebinarType from "./WebinarType";
import { EventType } from "../../../../EventWizard/models/Event";
import { useTranslation } from "react-i18next";
import { liveFeedDCIconStyles, liveFeedIconStyles, interactiveIconStyles } from "./WebinarTypeIconStyles";
import { errorMessagesCombiner } from "../../../../EventWizard/helpers/customValidators";
import schemaGenerator from "./formSchema";

export type WebinarTypeSelectingProps = {
  webinar: EventType;
  submitForm: () => void;
}

const WebinarTypeSelecting: React.FC<WebinarTypeSelectingProps> = ({ webinar, submitForm }) => {
  const { t } = useTranslation();

  const styles = useMultiStyleConfig("WebinarType", {});

  const { eventType } = webinar;
  const defaultValues = { eventType };

  const [selectedType, setSelectedType] = useState<string>(eventType)

  const schema = React.useMemo(() => schemaGenerator(t), []);
  const { setValue, getValues, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues
  });

  useEffect(() => {
    register("eventType");
  }, []);

  const onChangeEventType = (type) => {
    setValue("eventType", type);
    setSelectedType(getValues("eventType"))
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Flex {...styles.webinarTypeSelectingPage as FlexProps}>
        <Text variant="webinar-selecting-title">{t("webinar_type.label")}</Text>
        <Text variant="webinar-type-error">{errorMessagesCombiner(["eventType"], errors)}</Text>
        <HStack spacing="40px">
          <WebinarType
            iconStyles={liveFeedDCIconStyles}
            type="webinar"
            selectedType={selectedType}
            title={t("webinar_type.livefeed_dc.title")}
            description={t("webinar_type.livefeed_dc.description")}
            handleClick={onChangeEventType}
          />
          <WebinarType
            iconStyles={liveFeedIconStyles}
            type="virtual"
            selectedType={selectedType}
            title={t("webinar_type.livefeed.title")}
            description={t("webinar_type.livefeed.description")}
            handleClick={onChangeEventType}
          />
          <WebinarType
            iconStyles={interactiveIconStyles}
            type="inPerson"
            selectedType={selectedType}
            title={t("webinar_type.interactive.title")}
            description={t("webinar_type.interactive.description")}
            handleClick={onChangeEventType}
          />
        </HStack>
      </Flex>
      <Flex {...styles.webinarTypeSelectingFooter as FlexProps}>
        <Button w="80px" h="40px" onClick={handleSubmit(submitForm)}>
          {t("event_tools.buttons.next")}
        </Button>
      </Flex>
    </form>
  );
};

export default WebinarTypeSelecting;
