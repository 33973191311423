import consumer from "../../channels/consumer";
import { useEffect, useState } from "react";

export interface Room {
  channel: string;
  type: string;
  id: number;
}

export interface Concern {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (data: any) => any;
  tags?: string[];
}

export interface StandardCableData {
  concern: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  tags?: string[];
}

const concernMatches = (concern: Concern, data: StandardCableData) => {
  if (concern.name === data?.concern) {
    if (
      !data?.tags ||
      (concern?.tags &&
        data.tags.filter((t: string) => concern.tags.includes(t)).length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const useCable = (room: Room, concern: Concern) => {
  const [sub, setSub] = useState(null);

  useEffect(() => {
    const sub = consumer.subscriptions.create(room, {
      received: (data: StandardCableData) => {
        if (concernMatches(concern, data)) concern.callback(data);
      },
    });

    setSub(sub);

    return () => sub.unsubscribe();
  }, []);

  return sub;
};
