import * as React from 'react';
import { AddOns, PaymentSummaryAttributes } from '../../GroupRegistration/types';
import { setCurrencyFormat } from '../../helpers/currencyFormatter';
import { useTranslation } from "react-i18next";

export type PaymentSummaryProps = {
  paymentSummary: PaymentSummaryAttributes,
  currencyCode: string
  isLoadingPaymentIntent?: boolean
}

const PaymentSummary = ({ paymentSummary, isLoadingPaymentIntent, currencyCode }: PaymentSummaryProps) => {
  const { t } = useTranslation();
  const summaryTranslationPrefix = 'group_registrations.checkout.payment_summary';

  const currencyFormatter = setCurrencyFormat(currencyCode);
  const { lineItems, totals } = paymentSummary;
  const addonLines = addOns(lineItems.addOns, currencyFormatter);

  const borderTop = { borderTop: '1pt solid #303030' };
  const tableRowBorders = { borderBottom: '1pt solid #303030', ...borderTop };

  const contentOrLoader = (content: React.ReactNode): React.ReactNode => {
    return isLoadingPaymentIntent ? (
      <div className="spinner-border spinner-border-sm text-primary loading" role="status"></div>
    ) : (
      content
    );
  };

  return (
    <div>
      <h2 className="mb-5">{t('group_registrations.payment.confirmation_heading')}</h2>
      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={tableRowBorders}>{t(`${summaryTranslationPrefix}.item`)}</th>
            <th style={tableRowBorders}>{t(`${summaryTranslationPrefix}.quantity`)}</th>
            <th style={tableRowBorders}>{t(`${summaryTranslationPrefix}.price`)}</th>
          </tr>
        </thead>

        <tbody>
          <tr className="table-active">
            <td colSpan={4}><i>{t('delegate_types.name_plural')}</i></td>
          </tr>
          {lineItems.registrations.map((lineItem, index) => {
            const { delegateTypeName, totalExcludingTax, quantity } = lineItem;
            return (
              <tr key={index}>
                <td className='pl-4'>{delegateTypeName}</td>
                <td>{quantity}</td>
                <td>{currencyFormatter.format(parseFloat(totalExcludingTax))}</td>
              </tr>
            );
          })}

          {addonLines}

          <tr>
            <td style={borderTop}></td>
            <td style={borderTop}><strong>{t(`${summaryTranslationPrefix}.sub_total`)}</strong></td>
            <td style={borderTop}>{currencyFormatter.format(parseFloat(totals.subTotal))}</td>
          </tr>
          <tr>
            <td></td>
            <td><strong>{t(`${summaryTranslationPrefix}.tax`)}</strong></td>
            <td>
              {contentOrLoader(currencyFormatter.format(parseFloat(totals.tax)))}
            </td>
          </tr>
          {parseFloat(totals.creditCardFees) > 0 && (
            <tr>
              <td></td>
              <td><strong>{t(`${summaryTranslationPrefix}.credit_card_fee`)}</strong></td>
              <td>
                {contentOrLoader(currencyFormatter.format(parseFloat(totals.creditCardFees)))}
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td><strong>{t(`${summaryTranslationPrefix}.total_price`)}</strong></td>
            <td>
              {contentOrLoader(
                <div>
                  <span>{currencyFormatter.format(parseFloat(totals.grandTotal))}</span>
                  <br />
                  <span>({t('registrations.tax_rates.including_tax', { tax_name: 'GST' })})</span>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const addOns = (
  addOns: AddOns,
  currencyFormatter: Intl.NumberFormat,
) => {
  if (!addOns.length) return null;

  const { t } = useTranslation();

  return (
    <>
      <tr className="table-active">
        <td colSpan={3}><i>{t("group_registrations.addons")}</i></td>
      </tr>

      {addOns.map((addOnLineItem, index) => {
        const { name, totalExcludingTax, quantity } = addOnLineItem;
        return (
          <tr key={index}>
            <td className='pl-4'>{name}</td>
            <td>{quantity}</td>
            <td>{currencyFormatter.format(totalExcludingTax)}</td>
          </tr>
        );
      })}
    </>
  );
};

export default PaymentSummary;
