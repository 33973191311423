import React, { useEffect } from "react";
import { useEvent } from "../../../../pages/Event";
import EventService from "../../../../../EventWizard/services/eventService";
import AppAdapter from "../../../../../../utils/adapter";
import FormPageLayout from "../../../../common/FormPageLayout";
import WebinarDetailsForm from "../../../../forms/WebinarDetailsForm";
import SessionService from "../../../../../EventWizard/services/sessionService";
import { useWebinarFormActions } from "../../../hooks/useWebinarFormActions";
import { navigateToEventList } from "../../../../../EventWizard/helpers/navigation";

const GeneralDetails: React.FC = (): JSX.Element => {
  const { event, setEvent } = useEvent();

  const { navigateTo } = useWebinarFormActions();

  const stepDetails = [];

  const updateSession = async data => {
    const payload = await SessionService.updateSession(data);

    if (payload.ok) {
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  const updateEvent = async data => {
    const { registrationRequired, cpdEnabled, facilitatorIds, ...eventData } =
      data;

    updateSession({
      registrationRequired,
      cpdEnabled,
      facilitatorIds,
      sessionId: event.sessionIds.at(-1),
    });

    const payload = await EventService.updateEvent(event.id, eventData);
    if (payload.ok) {
      const response = await AppAdapter.deserialize(payload.result, {
        transform: EventService.transform,
      });
      setEvent(response);
      return true;
    } else {
      const errors = AppAdapter.deserializeErrors(payload.result);
      throw errors;
    }
  };

  useEffect(() => {
    (async (sessionId: string) => {
      if (!sessionId) {
        setEvent(event);
        return;
      }

      const payload = await SessionService.getSession(sessionId);
      if (payload.ok) {
        const { cpdEnabled, registrationRequired, facilitatorIds } =
          await AppAdapter.deserialize(payload.result, {
            transform: SessionService.transform,
          });
        const transformedFacilitatorIds = facilitatorIds.map(id =>
          id.toString()
        );
        setEvent({
          cpdEnabled,
          registrationRequired,
          facilitatorIds: transformedFacilitatorIds,
          ...event,
        });
      } else {
        const errors = AppAdapter.deserializeErrors(payload.result);
        throw errors;
      }
    })(event.sessionIds?.at(-1)?.toString());
  }, []);

  return (
    <FormPageLayout
      event={event}
      stepDetails={stepDetails}
      update={updateEvent}
      previousForm={navigateToEventList}
      exitForm={navigateToEventList}
      nextForm={navigateTo.agenda}
      previewVariant="None"
      hideEventName={true}
    >
      {props =>
        event.registrationRequired !== undefined && (
          <WebinarDetailsForm event={event} {...props}></WebinarDetailsForm>
        )
      }
    </FormPageLayout>
  );
};

export default GeneralDetails;
