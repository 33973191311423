import * as yup from "yup";
import { hexColourRegEx } from "../../../EventWizard/helpers/customValidators";

export default t => {
  return (yup
    .object({
      featurePageBackgroundColour: yup
        .string()
        .required(t("event_tools.validations.required", { field: t("branding_page.website_styling.feature_page_background_colour.field_name")}))
        .matches(hexColourRegEx, t("colour_hex_error")),
      primaryColour: yup
        .string()
        .required(t("event_tools.validations.required", { field: t("branding_page.website_styling.primary_colour.field_name")}))
        .matches(hexColourRegEx, t("colour_hex_error")),
      secondaryColour: yup
        .string()
        .required(t("event_tools.validations.required", { field: t("branding_page.website_styling.secondary_colour.field_name")}))
        .matches(hexColourRegEx, t("colour_hex_error")),
      fontHeader: yup.string(),
      fontBody: yup.string(),
    })
    .required()
  );
};
