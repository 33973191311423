import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { figmaTheme as fT } from "../figmaTheme";

export const formErrorTheme: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: fT.colors.alert
    }
  }
};
