import AppAdapter from '../../../../utils/adapter';
import { FAKE_TENANT_PROP, FAKE_USER, renderHook, renderHookWithAuthProvider } from '../../test-utils';

import { useAuth } from '../useAuth';

describe('auth/hooks/useAuth', () => {
  it('renders', () => {
    const { result } = renderHookWithAuthProvider(() => useAuth());
    
    const fakeUser = AppAdapter.camelCaseKeys(FAKE_USER);
    const fakeTenant = AppAdapter.camelCaseKeys(FAKE_TENANT_PROP);

    expect(result.current.currentUser).toEqual(fakeUser);
    expect(result.current.currentTenant).toEqual(fakeTenant);
  });

  describe('without AuthProvider', () => {
    it('throws an error', () => {
      const { result } = renderHook(() => useAuth());

      expect(result.error?.message).toEqual('useAuth must be used within a AuthProvider');
    });
  });
});
