export default interface UserType {
  id: string,
  firstName: string,
  lastName: string,
  fullname: string,
  email: string,
  jobTitle: string,
  bio: string,
  phone: string,
  pronoun: string,
  company: string,
  location: string,
  profile?: [File],
  profileUrl: string,
}

export interface Snakecase_UserType {
  id: string,
  first_name: string,
  last_name: string,
  fullname: string,
  email: string,
  job_title: string,
  bio: string,
  phone: string,
  pronoun: string,
  company: string,
  location: string,
  profile_url: string,
}

export interface JsonApiUserType {
  id: string,
  type: 'user',
  attributes: {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    fullname?: string,
  }
}

export const NEW_USER = {
  id: "",
  firstName: "",
  lastName: "",
  fullname: "",
  email: "",
  phone: "",
  pronoun: "",
  jobTitle: "",
  bio: "",
  company: "",
  location: "",
  profileUrl: ""
}
